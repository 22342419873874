import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { ROOM_TAGS, TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import Room from './Room';
import DraggableTube from './DraggableTube';
import Mesh from '../../components/Mesh';
import MeshLine from '../../components/MeshLine';
import ExtrudeMesh from '../../components/ExtrudeMesh';
import OriginalPlan from '../../components/OriginalPlan';
import Tile from './Tile';
import FloorWalls from './FloorWalls';

const wallDepth = 50;
const wallHeightPosition = -3;
const wallsColor = '#ffffff';
const corridorColor = '#ffffff';
const groundFloorColor = '#eef1f6';
const planLineThickness = 0.08;
const planLineColor = '#454545';

const wallSettings = { wallHeightPosition, planLineColor, planLineThickness, wallDepth, wallsColor };

const Floor = (props) => {
  const { floor, floorIndex, isOrthographic, handleFitToView, profileId, isOnlyOneFloor } = props;
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const editorType = useSelector(({ editor }) => editor.editorType);
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const renderTiles = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES;
  const highlightedTubeId = !lodashIsEmpty(selectedObject) && editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS ? selectedObject.tubeId : null;
  const isSelectedObjectHasLockTag = lodashGet(selectedObject, `tags[${ROOM_TAGS.LOCK}]`);
  const renderExtraRooms = useMemo(() => floor.extraRooms.map((room) => (
    <Room
      key={`${profileId}_${room.id}`}
      isImperial={isImperial}
      profileId={profileId}
      planLineColor={planLineColor}
      room={room}
    />
  )), [floor, isImperial]);

  const renderTubes = useMemo(() => floor.tubes.map((tube, index) => {
    const activeSubTube = lodashGet(tube, 'subTubes', []).find((subTube) => lodashIncludes(subTube?.roomIds, selectedObject?.id));

    return (
      <group key={`${index}_${tube.id}`} name={`tube_${index}`}>
        {/* ============ Render Tubes (for QA) ============ */}
        {/* <MeshLine vertices={tube.points} color="hotpink" lineWidth={0.15} /> */}

        <DraggableTube visible={highlightedTubeId === tube.tubeId && !isSelectedObjectHasLockTag} tube={tube} />
        {tube.rooms.map((room) => ( // rooms in tubes
          <Room
            profileId={profileId}
            key={`${profileId}_${room.id}`}
            isImperial={isImperial}
            disabled={lodashIncludes(activeSubTube?.roomIds, room.id)}
            planLineColor={planLineColor}
            room={room}
          />
        ))}

        {/* ============ Tiles ============ */}
        {renderTiles && tube.tiles.map((tile) => <Tile key={tile.id} tile={tile} />)}
      </group>
    );
  }), [floor, isImperial, renderTiles, highlightedTubeId, isSelectedObjectHasLockTag]);

  const renderCorridors = useMemo(() => floor.corridorPolygons.map((corridor, index) => (
    <group key={`${profileId}_${index}`} name="corridors" position={[0, 0, 1]}>
      <ExtrudeMesh holes={corridor.holes} envelope={corridor.boundary} extrudeDepth={0.5} color={corridorColor} unlit />
    </group>
  )), [floor, profileId]);

  const renderStructureLines = useMemo(() => (
    <group name="=StructureLines" position={[0, 0, 0]}>
      {floor.structures.map((structure, index) => <MeshLine key={`${floorIndex}_structure_${index}`} vertices={structure} color={planLineColor} lineWidth={0.1} />)}
    </group>
  ), [floor, profileId]);

  const renderGroundFloor = useMemo(() => {
    const contour = lodashIsEmpty(floor.outerContours)
      ? [[...floor.boundaryPoints, floor.boundaryPoints[0]]]
      : floor.outerContours.map((item) => [...item, item[0]]);

    return contour.map((item, index) => (
      <group key={`contour_${index}`} position={[0, 0, wallHeightPosition - 10]} name="groundFloor">
        {/* <MeshLine vertices={item} color={planLineColor} lineWidth={0.05} /> */}
        <Mesh contour={item} color={groundFloorColor} unlit />
      </group>
    ));
  }, [floor, profileId]);

  return (
    <group scale={[0.05, 0.05, 0.05]} name={`Floor_${floorIndex}`}>
      {/* ============== Corridors ============== */}
      {renderCorridors}

      {/* ============== Extra rooms ============== */}
      {renderExtraRooms}

      {/* ============== Tunes ============== */}
      {renderTubes}

      {/* ============== walls ============== */}
      <FloorWalls floor={floor} wallSettings={wallSettings} />

      {/* ============== StructureLines ============== */}
      {renderStructureLines}

      {/* ============== Original Plan ============== */}
      <OriginalPlan floor={floor} isOrthographic={isOrthographic} isOnlyOneFloor={isOnlyOneFloor} url={floor.envUrl} floorIndex={floorIndex} handleFitToView={handleFitToView} />

      {/* ============== Ground Floor ============== */}
      {renderGroundFloor}
    </group>
  );
};

Floor.propTypes = {
  floor: PropTypes.object,
  floorIndex: PropTypes.number,
  isOrthographic: PropTypes.bool,
  isOnlyOneFloor: PropTypes.bool,
  handleFitToView: PropTypes.func,
  profileId: PropTypes.number,
};

export default React.memo(Floor);
