import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashStartsWith from 'lodash/startsWith';
import lodashSum from 'lodash/sum';
import lodashSortBy from 'lodash/sortBy';
import { extendPolygon } from 'utils/algorithms/jstsHelpers';
import {
  BUILDING_INFO_KEYS,
  BUILDING_INFO_TYPES,
  MODEL_ANALYSIS_TYPES,
  LEGEND_COLOR_SETS,
  COMMUNAL_SPACES,
  UNIT_SORT_LIST,
  FEASIBILITY_MASS_TYPES,
  HVAC,
  BUILDING_INFO_SUB_TYPES,
  STANDARDS,
  ALL_APARTMENT_TYPES_ARRAY,
  ALL_COMMUNAL_SPACES_TYPES_ARRAY,
} from 'constants/feasibilityConts';
import { useActiveProfileEnrichmentUpdate } from 'store/activeProfile';
import SwpProject from '@swapp/swappcommonjs/dist/swpProject/SwpProject';
import { numberWithComma } from '../helpers/dataDisplay';
import { kwhSqmToBtuToSqf, kwhSqmToKBtuToSqf, sqmToSqf } from '../helpers/unitsHelpers';
import { getPolygonsUnion, getPolygonArea } from '../algorithms/algorithmHelpers';
import { setIn } from '../helpers/immutableHelpers';
import { getSwpProjectBuildings, enrichProjectProperties } from './swpProjectModel';
import { getHbEnergyAnalysisData } from './energy/hbEnergyAnalysisAdapter';

export const SELECTION_CATEGORY_TYPES = {
  UNIT_TYPE: 'UNIT_TYPE',
  UNIT_POSITION: 'UNIT_POSITION',
  UNIT_ASPECTS: 'UNIT_ASPECTS',
};

export const FEASIBILITY_SELECTION_CATEGORIES = {
  // DEFAULT : SELECTION_CATEGORIES.UNIT_TYPE
  [BUILDING_INFO_KEYS.ROOF_APARTMENT]: SELECTION_CATEGORY_TYPES.UNIT_POSITION,
  [BUILDING_INFO_KEYS.GARDEN_APARTMENT]: SELECTION_CATEGORY_TYPES.UNIT_POSITION,
  [BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS]: SELECTION_CATEGORY_TYPES.UNIT_ASPECTS,
  [BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS]: SELECTION_CATEGORY_TYPES.UNIT_ASPECTS,
  [BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS]: SELECTION_CATEGORY_TYPES.UNIT_POSITION,
};

const buildingInfoATableInfo = [
  { key: BUILDING_INFO_KEYS.BCR, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.GEA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.NIA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.GIA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.NIA_TO_GIA, type: BUILDING_INFO_TYPES.PERCENTAGE },
  { key: BUILDING_INFO_KEYS.FAR, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.IL_PRIMARY_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.IL_MMD_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.IL_COMMON_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.IL_NET_TO_GROSS, type: BUILDING_INFO_TYPES.PERCENTAGE },
  { key: BUILDING_INFO_KEYS.IL_GROSS_BALCONY_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.STORIES, type: BUILDING_INFO_TYPES.LIST },
  { key: BUILDING_INFO_KEYS.COMMUNAL_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.RETAIL, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.CORE, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.ENVELOPE_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.MECHANICAL_ROOM, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.NO_OF_MASSES, type: BUILDING_INFO_TYPES.UNIT },
  { key: COMMUNAL_SPACES.FULL_CORE, type: BUILDING_INFO_TYPES.UNIT },
  { key: COMMUNAL_SPACES.ELEVATORS_CORE, type: BUILDING_INFO_TYPES.UNIT },
  { key: COMMUNAL_SPACES.STAIRCASE_CORE, type: BUILDING_INFO_TYPES.UNIT },
  { key: COMMUNAL_SPACES.SHAFT, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.APARTMENT_PER_FLOOR, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.APARTMENT_PER_BUILDING, type: BUILDING_INFO_TYPES.UNIT },
];

const buildingInfoCardA = [
  { key: BUILDING_INFO_KEYS.BCR, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.NIA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.GIA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.IL_PRIMARY_AREA, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.IL_COMMON_AREA, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.IL_MMD_AREA, type: BUILDING_INFO_TYPES.UNIT },
];

const buildingInfoCardB = [
  // { key: BUILDING_INFO_KEYS.IL_GROSS_BALCONY_AREA, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.CORE, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.STORIES, type: BUILDING_INFO_TYPES.LIST },
  { key: BUILDING_INFO_KEYS.NIA_TO_GIA, type: BUILDING_INFO_TYPES.PERCENTAGE },
  { key: BUILDING_INFO_KEYS.IL_NET_TO_GROSS, type: BUILDING_INFO_TYPES.PERCENTAGE },
];

const unitsInfoCard = [
  ...ALL_APARTMENT_TYPES_ARRAY.map((unit) => ({ key: unit.key, type: BUILDING_INFO_TYPES.UNIT })),
];

const buildingInfoBTableInfo = [
  ...ALL_APARTMENT_TYPES_ARRAY.map((unit) => ({ key: unit.key, type: BUILDING_INFO_TYPES.AREA, isUnit: true })),
  { key: BUILDING_INFO_KEYS.TOTAL_APARTMENTS, type: BUILDING_INFO_TYPES.AREA, isUnit: true, highlight: true },
  { key: BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS, type: BUILDING_INFO_TYPES.EMPTY, isUnit: true },
];

const communalSpaceTableInfo = [
  ...ALL_COMMUNAL_SPACES_TYPES_ARRAY.map((unit) => ({ key: unit.key, type: BUILDING_INFO_TYPES.AREA, isUnit: true })),
];

const enhancedApartmentsTableInfo = [
  { key: BUILDING_INFO_KEYS.ROOF_APARTMENT, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.GARDEN_APARTMENT, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS, type: BUILDING_INFO_TYPES.UNIT },
];

const efficiencyScoreTableInfo = [
  { key: BUILDING_INFO_KEYS.CONST_COST_SCORE, type: BUILDING_INFO_TYPES.SCORE },
  { key: BUILDING_INFO_KEYS.MARKETABILITY_SCORE, type: BUILDING_INFO_TYPES.SCORE },
  { key: BUILDING_INFO_KEYS.WELLBING_SCORE, type: BUILDING_INFO_TYPES.SCORE },
  { key: BUILDING_INFO_KEYS.PRIORITY_FIT_SCORE, type: BUILDING_INFO_TYPES.SCORE, highlight: true },
];

const passiveHouseTableInfo = [
  { key: BUILDING_INFO_KEYS.PH_HLFF, type: BUILDING_INFO_TYPES.LIST },
  { key: BUILDING_INFO_KEYS.PH_SVR, type: BUILDING_INFO_TYPES.LIST },
  { key: BUILDING_INFO_KEYS.SUFFICIENT_DAYLIGHT, type: BUILDING_INFO_TYPES.ROW_PERCENTAGE },
  { key: BUILDING_INFO_KEYS.SUFFICIENT_VIEW, type: BUILDING_INFO_TYPES.ROW_PERCENTAGE },
  { key: BUILDING_INFO_KEYS.SUFFICIENT_WIND, type: BUILDING_INFO_TYPES.ROW_PERCENTAGE },
  { key: BUILDING_INFO_KEYS.SUFFICIENT_VSC, type: BUILDING_INFO_TYPES.ROW_PERCENTAGE },
];

const parkingTableInfo = [
  { key: BUILDING_INFO_KEYS.PARKING_AREA, type: BUILDING_INFO_TYPES.AREA },
  { key: BUILDING_INFO_KEYS.PARKING_PERCENTAGE, type: BUILDING_INFO_TYPES.PERCENTAGE },
  { key: BUILDING_INFO_KEYS.NUM_OF_PARKING, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.PARKING_RATIO, type: BUILDING_INFO_TYPES.UNIT },
];

const apartmentOrientationTableInfo = [
  { key: BUILDING_INFO_KEYS.NORTH, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.SOUTH, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.EAST, type: BUILDING_INFO_TYPES.UNIT },
  { key: BUILDING_INFO_KEYS.WEST, type: BUILDING_INFO_TYPES.UNIT },
];

const modelAnalysisItemsOrder = [
  { key: MODEL_ANALYSIS_TYPES.AREA_TYPE },
  { key: MODEL_ANALYSIS_TYPES.SUN_ANALYSIS },
  { key: MODEL_ANALYSIS_TYPES.VIEWS_ANALYSIS },
  { key: MODEL_ANALYSIS_TYPES.WIND_ANALYSIS },
  { key: MODEL_ANALYSIS_TYPES.VSC_ANALYSIS },
];

const getValueByType = (data, value, unit, isImperial) => {
  if (!value) {
    return;
  }

  const GIA = lodashGet(data, `study.buildingInfo[${BUILDING_INFO_KEYS.IL_PRIMARY_AREA}].value`) || data.study.buildingInfo[BUILDING_INFO_KEYS.GIA].value;

  switch (unit.type) {
    case BUILDING_INFO_TYPES.EMPTY:
      return '';
    case BUILDING_INFO_TYPES.AREA:
      return `${numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: 0, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` })}`;
    case BUILDING_INFO_TYPES.PERCENTAGE:
      return `${numberWithComma(value * 100, { fixed: 1, suffix: '%' })}`;
    case BUILDING_INFO_TYPES.ROW_PERCENTAGE:
      return Math.round(value * 100);
    case BUILDING_INFO_TYPES.UNIT:
      return `${numberWithComma(value, { fixed: 1, suffix: '' })}`;
    case BUILDING_INFO_TYPES.LIST:
      return value.map((item) => numberWithComma(item, { fixed: 1, suffix: '' })).join(' | ');
    case BUILDING_INFO_TYPES.SCORE:
      return `${numberWithComma(Math.round(value * 10), { fixed: 1, suffix: '' })}`;
    case BUILDING_INFO_TYPES.ENERGY:
      switch (unit.subType) {
        case BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW: {
          return numberWithComma(isImperial ? kwhSqmToKBtuToSqf(value) : value, { fixed: 0 });
        }
        case BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT: {
          return numberWithComma(isImperial ? kwhSqmToKBtuToSqf(value) : value, { fixed: 0, suffix: isImperial ? ' kBtu/h' : ' kW' });
        }
        case BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_PER_AREA: {
          const area = isImperial ? sqmToSqf(GIA) : GIA;

          return numberWithComma(((isImperial ? kwhSqmToBtuToSqf(value) : value) / area), { fixed: 0, suffix: isImperial ? ` Btu/h ${T.translate('SQF')}` : ` w ${T.translate('SQM')}` });
        }
        case BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_TIMES_AREA: {
          if (unit.isRaw) {
            return GIA * value;
          }
          const area = isImperial ? sqmToSqf(GIA) : GIA;
          const energy = isImperial ? kwhSqmToBtuToSqf(value) : value;
          const amount = energy * area;

          return numberWithComma(isImperial ? amount / 1000 : amount, { fixed: 0, suffix: isImperial ? ' MBtu' : ' kWh' });
        }
        case BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_PER_AREA_PER_YEAR: {
          return numberWithComma(isImperial ? kwhSqmToKBtuToSqf(value) : value, { fixed: 0, suffix: isImperial ? ` kBtu/${T.translate('SQF')}/yr` : ` kWh/${T.translate('SQM')}/yr` });
        }
        default:
          return value;
      }
    default:
      return value;
  }
};

const toolTipNames = [
  BUILDING_INFO_KEYS.NIA,
  BUILDING_INFO_KEYS.GIA,
  BUILDING_INFO_KEYS.NIA_TO_GIA,
  BUILDING_INFO_KEYS.FULL_CORE,
];

const percentageToolTipNames = [
  BUILDING_INFO_KEYS.BCR,
  BUILDING_INFO_KEYS.COMMUNAL_AREA,
  BUILDING_INFO_KEYS.CORE,
  BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS,
  BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS,
  BUILDING_INFO_KEYS.THREE_FLAT_ASPECTS,
  BUILDING_INFO_KEYS.ROOF_APARTMENT,
  BUILDING_INFO_KEYS.GARDEN_APARTMENT,
];

const valueToolTipNames = [
  BUILDING_INFO_KEYS.TOTAL_APARTMENTS,
];

const excludePercentage = [
  COMMUNAL_SPACES.FULL_CORE,
  COMMUNAL_SPACES.STAIRCASE_CORE,
  COMMUNAL_SPACES.ELEVATORS_CORE,
  COMMUNAL_SPACES.SHAFT,
  BUILDING_INFO_KEYS.NIA,
  BUILDING_INFO_KEYS.GIA,
  BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS,
];

const includeFilterableKeys = [
  BUILDING_INFO_KEYS.ROOF_APARTMENT,
  BUILDING_INFO_KEYS.GARDEN_APARTMENT,
  BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS,
  BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS,
  BUILDING_INFO_KEYS.THREE_FLAT_ASPECTS,
  BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS,
];

const excludeFilterableKeys = [
  BUILDING_INFO_KEYS.CORE,
];

const hvacItemList = [
  { key: HVAC.COOLING_LOAD_PEAK, type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT },
  { key: HVAC.COOLING_LOAD_DENSITY, type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_PER_AREA },
  { key: HVAC.HEATING_LOAD_PEAK, type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT },
  { key: HVAC.HEATING_LOAD_DENSITY, type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_PER_AREA },
  { key: HVAC.ENERGY_USE_INTENSITY, type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_PER_AREA_PER_YEAR },
];

const hideUnitsWithNoValueList = [
  BUILDING_INFO_KEYS.PRIORITY_FIT_SCORE,
  BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS,
];

const hiddenFieldsInIlStandard = [
  BUILDING_INFO_KEYS.BCR,
  BUILDING_INFO_KEYS.NIA,
  BUILDING_INFO_KEYS.GIA,
  BUILDING_INFO_KEYS.NIA_TO_GIA,
  BUILDING_INFO_KEYS.FAR,
];

const getHvacData = (data) => {
  const parsedHvacData = {};
  const energyStudy = lodashGet(data, 'energyStudy.metadata');
  if (energyStudy) {
    parsedHvacData[HVAC.COOLING_LOAD_PEAK] = { value: energyStudy[HVAC.COOLING_LOAD_PEAK] };
    parsedHvacData[HVAC.COOLING_LOAD_DENSITY] = { value: energyStudy[HVAC.COOLING_LOAD_PEAK] };
    parsedHvacData[HVAC.HEATING_LOAD_PEAK] = { value: energyStudy[HVAC.HEATING_LOAD_PEAK] };
    parsedHvacData[HVAC.HEATING_LOAD_DENSITY] = { value: energyStudy[HVAC.HEATING_LOAD_PEAK] };
    parsedHvacData[HVAC.ENERGY_USE_INTENSITY] = { value: energyStudy[HVAC.ENERGY_USE_INTENSITY] };
  }

  return { ...data, study: { ...data.study, buildingInfo: { ...data.study.buildingInfo, ...parsedHvacData } } };
};

const setTableItems = (data, isImperial, unitList) => unitList.map((unit) => {
  let currentUnit = lodashGet(data, `study.buildingInfo[${unit.key}]`);
  const unitsStandard = lodashGet(data, 'singleOptionMassingOptions.requirements.units_standard', STANDARDS.PREFAB.key).toUpperCase();

  if (unitsStandard === STANDARDS.IL.key && lodashIncludes(hiddenFieldsInIlStandard, unit.key)) {
    return;
  }

  // add custom data
  if (data.study.buildingInfo[BUILDING_INFO_KEYS.NIA] && data.lotArea && unit.key === BUILDING_INFO_KEYS.FAR) {
    currentUnit = { value: data.study.buildingInfo[BUILDING_INFO_KEYS.GIA].value / data.lotArea };
  }

  if (lodashIsEmpty(currentUnit) || (lodashIncludes(hideUnitsWithNoValueList, unit.key) && !lodashGet(currentUnit, 'value'))) {
    return;
  }

  const displayName = lodashIncludes(unit.key, 'OTHER') && (lodashGet(currentUnit, 'displayName') || 'OTHER');

  return (
    {
      key: unit.key,
      highlight: unit.highlight,
      name: displayName ? T.translate(displayName) : T.translate(unit.key),
      ...currentUnit,
      colorPill: (!lodashIncludes(excludeFilterableKeys, unit.key) && lodashGet(currentUnit, 'colors[0]'))
          || (lodashIncludes(includeFilterableKeys, unit.key) && '#b0b0b0')
          || unit.colorPillColor,
      value: getValueByType(data, currentUnit.value, unit, isImperial),
      percentage: lodashIncludes(excludePercentage, unit.key) || getValueByType(data, currentUnit.percentage, { type: BUILDING_INFO_TYPES.PERCENTAGE }),
      unitsOf: lodashGet(currentUnit, 'model') && `${lodashGet(currentUnit, 'model')} / ${lodashGet(currentUnit, 'target')}`,
      unitCount: unit.isUnit && (lodashGet(currentUnit, 'model') || lodashGet(currentUnit, 'value')) && ` - ${lodashGet(currentUnit, 'model') || lodashGet(currentUnit, 'value')} ${T.translate('UNITS')}`,
      type: unit.type,
      toolTip: lodashIncludes(toolTipNames, unit.key) && T.translate(`FEASIBILITY_RESULT_DATA_TOOLTIP_${unit.key}`),
      percentageTooltip: lodashIncludes(percentageToolTipNames, unit.key) ? T.translate(`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${unit.key}`) : T.translate(STANDARDS[unitsStandard].unitPercentageTooltipKey),
      valueTooltip: lodashIncludes(valueToolTipNames, unit.key) && T.translate(`FEASIBILITY_RESULT_DATA_VALUE_TOOLTIP_${unit.key}`),
      percentageOfAllUnits: lodashGet(currentUnit, 'percentageOfAllUnits') && numberWithComma(lodashGet(currentUnit, 'percentageOfAllUnits') * 100, { fixed: 1, suffix: '%' }),
      selectionCategory: unit.selectionCategory,
    });
}).filter((e) => e);

const setModelAnalysisArray = (data, keyInfo) => keyInfo.map((unit) => {
  if (lodashIsEmpty(lodashGet(data, `study.modelAnalysis[${unit.key}]`))) {
    return;
  }
  return (
    {
      key: unit.key,
      name: T.translate(unit.key),
      toolTip: unit.key === MODEL_ANALYSIS_TYPES.VSC_ANALYSIS ? T.translate(`${unit.key}_TOOLTIP`) : '',
      ...data.study.modelAnalysis[unit.key],
    });
}).filter((e) => e);

export const useActiveProfileBuildingInfo = () => useActiveProfileEnrichmentUpdate('buildingInfo', getBuildingInfo);

export const getBuildingInfo = (data) => {
  const sitePolygon = lodashGet(data, 'sitePolygon');
  const masses = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].masses', []);
  const generalPolygons = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].extra_features.planar_features', []);
  const parkingMasses = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].parking_masses', []);
  const environmentUrl = lodashGet(data, 'environmentURL');
  const legendColors = getLegendColors(data);
  const parsedMasses = {};
  const parkingInfo = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].parking_info', {});
  const constLineDistance = lodashGet(data, 'singleOptionMassingOptions.requirements.sublot_requirements[0].construction_setback_line', {});
  const groundFloorType = lodashGet(data, 'singleOptionMassingOptions.requirements.sublot_requirements[0].ground_floor_type', 'na');
  const typology = lodashGet(data, 'study.typology');

  let swpProject = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].created_from');
  let swpBuildings = [];
  if (swpProject) {
    try {
      swpProject = SwpProject.loadFromSimpleObject(swpProject);
      enrichProjectProperties(swpProject);
      swpBuildings = getSwpProjectBuildings(swpProject);
    } catch (e) {
      console.log(`Found SwpProject but could not parse it: ${e}`);
      swpProject = undefined;
    }
  }

  masses.forEach((mass, index) => {
    const name = `Mass${index}`;
    const swpBuilding = swpBuildings.find((building) => building.id === mass.created_from) || swpBuildings[index];
    try {
      parsedMasses[name] = {
        name,
        polygon: getPolygonsUnion(mass.floor_groups.map((fg) => fg.envelope)),
        pivotPoint: mass.pivot_point,
        massDto: mass,
        unitColors: legendColors,
        massIndex: index,
        type: FEASIBILITY_MASS_TYPES.APARTMENTS,
        swpBuilding,
      };
    } catch (e) {
      console.log('Could not generate mass polygon ', mass.floor_groups.map((fg) => fg.envelope, e));
    }
  });

  parkingMasses.forEach((parkingMass, index) => {
    const name = `ParkingMass${index}`;
    parsedMasses[name] = {
      name,
      polygon: getPolygonsUnion(parkingMass.floor_groups.map((fg) => fg.envelope)),
      pivotPoint: parkingMass.pivot_point,
      massDto: parkingMass,
      unitColors: legendColors,
      massIndex: masses.length + index,
      type: FEASIBILITY_MASS_TYPES.PARKING,
    };
  });

  const poly = lodashGet(sitePolygon, 'boundary', []);
  const constructionLine = constLineDistance > 0 ? extendPolygon(poly, -constLineDistance) : [];
  const handleSwpProjectPatch = (patch) => patch.applyToProject(swpProject);

  return ({
    massingOptionVersion: lodashGet(data, 'singleOptionMassingOptions.version', 0),
    [MODEL_ANALYSIS_TYPES.AREA_TYPE]: lodashGet(data, 'study.modelAnalysis.AREA_TYPE.url'),
    [MODEL_ANALYSIS_TYPES.SUN_ANALYSIS]: lodashGet(data, 'study.modelAnalysis.SUN_ANALYSIS.url'),
    [MODEL_ANALYSIS_TYPES.VIEWS_ANALYSIS]: lodashGet(data, 'study.modelAnalysis.VIEWS_ANALYSIS.url'),
    [MODEL_ANALYSIS_TYPES.WIND_ANALYSIS]: lodashGet(data, 'study.modelAnalysis.WIND_ANALYSIS.url'),
    [MODEL_ANALYSIS_TYPES.VSC_ANALYSIS]: lodashGet(data, 'study.modelAnalysis.VSC_ANALYSIS.url'),
    environmentUrl,
    generalPolygons,
    groundFloorType,
    typology,
    sitePolygon: {
      polygon: lodashGet(sitePolygon, 'boundary', []),
      polygonHoles: lodashGet(sitePolygon, 'holes', []),
    },
    constructionLine: {
      polygon: constructionLine,
    },
    masses: parsedMasses,
    parkingInfo,
    swpProject,
    handleSwpProjectPatch,
  });
};

const getLegendColors = (data) => {
  const unitsData = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].metadata.building_info.unit_info', {});
  const units = [];
  Object.keys(unitsData).forEach((key) => {
    if (lodashGet(unitsData[key], 'colors')) {
      units.push({
        key,
        ...unitsData[key],
      });
    }
  });
  const hasColors = !!units.find((unit) => unit.colors);
  const colorSets = { ...LEGEND_COLOR_SETS };

  if (hasColors) {
    const filteredUnits = units.filter((unit) => !lodashIncludes([COMMUNAL_SPACES.OTHER, COMMUNAL_SPACES.CORE, 'DEMO_OPEN'], unit.key));
    const sortedUnits = lodashSortBy(filteredUnits, [(unit) => UNIT_SORT_LIST[lodashStartsWith(unit.key, COMMUNAL_SPACES.OTHER) ? COMMUNAL_SPACES.OTHER : unit.key]]);

    const renderName = (unit) => (lodashIncludes(unit.key, 'OTHER') ? T.translate(unit.display_name) || T.translate('OTHER') : T.translate(unit.key));

    colorSets[MODEL_ANALYSIS_TYPES.AREA_TYPE] = [
      [
        ...sortedUnits.map((unit) => ({ value: renderName(unit), color: unit.colors[0] })),
      ],
      // [
      //   ...sortedUnits.map((unit) => ({ value: renderName(unit.key), color: lodashGet(unit, 'colors[1]', unit.colors[0]) })),
      //   { value: 'Multi Asp.', color: '#FFFFFF' },
      // ],
    ];
  }

  return colorSets;
};

const getOtherUnitsList = (data) => {
  const allUnits = lodashGet(data, 'study.buildingInfo');
  const units = [];
  Object.keys(allUnits).forEach((key) => units.push(key));
  const otherUnits = units.filter((unitKey) => lodashStartsWith(unitKey, COMMUNAL_SPACES.OTHER));
  const otherUnitsList = lodashIsEmpty(otherUnits) ? [] : otherUnits.map((unitKey) => (
    { key: unitKey, type: BUILDING_INFO_TYPES.AREA, isUnit: true }
  ));

  return otherUnitsList;
};

// should this function (and functions similar to it) be refactored to a "MassingOptionsUtils" class?
const getMassingOptionParkingArea = (data) => {
  const buildingInfo = lodashGet(data, 'study.buildingInfo');
  const massingOption = lodashGet(data, 'singleOptionMassingOptions.massing_options[0]');
  const surfaceParkingCount = lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NUM_OF_SURFACE_PARKING}]`);
  const parkingMasses = lodashGet(massingOption, 'parking_masses');

  if (!massingOption || !parkingMasses) {
    return 0;
  }

  let parkingArea = 0;
  parkingMasses.forEach((massDto) => {
    const floorGroups = massDto.floor_groups.filter((fg) => fg.count > 0);
    floorGroups.forEach((floorGroupDto) => {
      parkingArea += getPolygonArea(floorGroupDto.envelope);
    });
  });
  parkingArea += surfaceParkingCount * 35; // 35 SQM to each parking on the ground

  return parkingArea;
};

const getCoreAndMechanicalArea = (massingOption, buildingInfo) => {
  if (!massingOption) {
    return;
  }

  const coreAndCorridorArea = lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.CORE}].value`);
  const corridorArea = lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.CORRIDOR}].value`);
  const coreArea = coreAndCorridorArea - corridorArea;
  const mechanicalArea = 0; // TODO: Add mechanical calculation - based on the 'massingOption'

  return coreArea + mechanicalArea;
};

const getFloorCountByKey = (data, key) => {
  const parkingMasses = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].parking_masses');

  if (!parkingMasses || parkingMasses.length === 0) {
    return;
  }

  return lodashSum(parkingMasses.map((mass) => lodashSum(mass.floor_groups.map((item) => (item.type === key && !lodashIsEmpty(item.parking_units) ? item.count : 0)))));
};

const getFormData = (data) => {
  const buildingInfo = lodashGet(data, 'study.buildingInfo');
  const massingOption = lodashGet(data, 'singleOptionMassingOptions.massing_options[0]');
  const parkingArea = getMassingOptionParkingArea(data);
  const coreAndMechanicalArea = getCoreAndMechanicalArea(massingOption, buildingInfo);

  return {
    totalParking: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NUM_OF_PARKING}].value`),
    totalApartments: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.TOTAL_APARTMENTS}].model`),
    NIA: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NIA}].value`),
    parking: [
      { key: BUILDING_INFO_KEYS.NUM_OF_BELOW_GROUND_PARKING, name: 'FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_BELOW_GROUND_PARKING', default: 50000, value: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NUM_OF_BELOW_GROUND_PARKING}]`) },
      { key: BUILDING_INFO_KEYS.NUM_OF_PRECAST_PARKING, name: 'FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_PRECAST_PARKING', floors: getFloorCountByKey(data, 'precast'), default: 20000, value: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NUM_OF_PRECAST_PARKING}]`) },
      { key: BUILDING_INFO_KEYS.NUM_OF_PODIUM_PARKING, name: 'FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_PODIUM_PARKING', floors: getFloorCountByKey(data, 'podium'), default: 35000, value: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NUM_OF_PODIUM_PARKING}]`) },
      { key: BUILDING_INFO_KEYS.NUM_OF_SURFACE_PARKING, name: 'FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_SURFACE_PARKING', default: 3000, value: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NUM_OF_SURFACE_PARKING}]`) },
    ],
    expenditure: [ // TODO - remove NIA by value and stay only with area
      { key: BUILDING_INFO_KEYS.NIA, name: 'FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_NIA', area: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.TOTAL_APARTMENTS}].value`) || lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NIA}].area`) || lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.NIA}].value`), default: 2315 },
      { key: BUILDING_INFO_KEYS.COMMUNAL_AREA, name: 'FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_COMMUNAL_AREA', area: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.COMMUNAL_AREA}].value`), default: 2600 },
      { key: BUILDING_INFO_KEYS.CORRIDOR, name: 'FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_CORRIDOR', area: lodashGet(buildingInfo, `[${BUILDING_INFO_KEYS.CORRIDOR}].value`), default: 2200 },
      { key: BUILDING_INFO_KEYS.CORE_AND_MECHANICAL, name: 'FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_CORE_AND_MECHANICAL', area: coreAndMechanicalArea, default: 3000 },
      { key: BUILDING_INFO_KEYS.PARKING_AREA, name: 'FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_PARKING_AREA', area: parkingArea },
    ],
  };
};

const getNumUnitsPassingPredicate = (massingOptionDto, predicate) => {
  let numUnits = 0;
  massingOptionDto.masses.forEach((massDto) => {
    massDto.floor_groups.forEach((floorGroupDto) => {
      floorGroupDto.units.forEach((unit) => {
        if (predicate(unit)) {
          numUnits += floorGroupDto.count;
        }
      });
    });
  });
  return numUnits;
};

export const isUnitEnhanced = (unit) => {
  const numModifications = Object.keys(lodashGet(unit, 'modifications', {})).length;
  return numModifications > 0;
};

const getEnergyAnalysisData = (data, isImperial) => {
  const { energyAnalyses } = data;
  const GIA = lodashGet(data, `study.buildingInfo[${BUILDING_INFO_KEYS.IL_PRIMARY_AREA}].value`) || data.study.buildingInfo[BUILDING_INFO_KEYS.GIA].value;

  if (!energyAnalyses) {
    return null;
  }

  return energyAnalyses.map((scenario) => ({
    windowToWallRatio: scenario.windowToWallRatio,
    annualEnergyUse: getValueByType(data, scenario.euiTotal, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_TIMES_AREA }, isImperial),
    annualEnergyUseNumber: getValueByType(data, scenario.euiTotal, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_TIMES_AREA, isRaw: true }, isImperial),
    annualEnergyCostGas: scenario.totalGas * GIA,
    annualEnergyCostElectric: scenario.totalElectric * GIA,
    energyUseIntensity: getValueByType(data, scenario.euiTotal, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_PER_AREA_PER_YEAR }, isImperial),
    cooling: getValueByType(data, scenario.energySourcesData.cooling, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
    heating: getValueByType(data, scenario.energySourcesData.heating, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
    lighting: getValueByType(data, scenario.energySourcesData.lighting, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
    equipment: getValueByType(data, scenario.energySourcesData.equipment, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
    pumps: getValueByType(data, (scenario.energySourcesData.pumps), { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
    fans: getValueByType(data, (scenario.energySourcesData.fans), { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
    hotWater: getValueByType(data, scenario.energySourcesData.hotWater, { type: BUILDING_INFO_TYPES.ENERGY, subType: BUILDING_INFO_SUB_TYPES.ENERGY.AMOUNT_RAW }, isImperial),
  }));
};

const enrichFeasibilityResultData = (data) => {
  const massingOptionDto = lodashGet(data, 'singleOptionMassingOptions.massing_options[0]');
  const numEnrichedUnits = getNumUnitsPassingPredicate(massingOptionDto, isUnitEnhanced);
  const totalApartments = data.study.buildingInfo.TOTAL_APARTMENTS.model;
  data = setIn(`study.buildingInfo.${BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS}`, { value: numEnrichedUnits, percentage: numEnrichedUnits / totalApartments }, data);
  return data;
};

const createEnhancedApartmentTableInfo = (data) => {
  const tableInfo = [...enhancedApartmentsTableInfo];
  const tagStatistics = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].metadata.building_info.tag_statistics', {});
  const unitTags = Object.keys(tagStatistics);
  unitTags.forEach((tag) => {
    tableInfo.push({ key: tag, type: BUILDING_INFO_TYPES, colorPillColor: '#b0b0b0', selectionCategory: SELECTION_CATEGORY_TYPES.UNIT_POSITION });
  });
  return tableInfo;
};

export const feasibilityResultModel = (data, isImperial, buildingInfo) => {
  if (!data || !lodashGet(data, 'study.buildingInfo') || !lodashGet(data, 'singleOptionMassingOptions.massing_options')) {
    return;
  }

  data = enrichFeasibilityResultData(data);

  const buildingInfoATable = setTableItems(data, isImperial, buildingInfoATableInfo);
  const buildingInfoBTable = setTableItems(data, isImperial, buildingInfoBTableInfo);
  const communalSpaceInfoTable = [...setTableItems(data, isImperial, communalSpaceTableInfo), ...setTableItems(data, isImperial, getOtherUnitsList(data))];
  const enhancedApartmentsTable = setTableItems(data, isImperial, createEnhancedApartmentTableInfo(data));
  const apartmentOrientationTable = setTableItems(data, isImperial, apartmentOrientationTableInfo);
  const efficiencyScoreTable = setTableItems(data, isImperial, efficiencyScoreTableInfo);
  const passiveHouseTable = setTableItems(data, isImperial, passiveHouseTableInfo);
  const parkingTable = setTableItems(data, isImperial, parkingTableInfo);
  const efficiencyScore = setTableItems(data, isImperial, [{ key: BUILDING_INFO_KEYS.PRIORITY_FIT_SCORE, type: BUILDING_INFO_TYPES.SCORE }]);
  const siteId = lodashGet(data, 'study.buildingInfo.SITE_ID');
  const modelAnalysisArray = setModelAnalysisArray(data, modelAnalysisItemsOrder);
  const legendColors = getLegendColors(data);
  const formData = getFormData(data);
  const hvacData = setTableItems(getHvacData(data), isImperial, hvacItemList);
  const energyAnalysisData = getEnergyAnalysisData(data, isImperial);
  const hbEnergyAnalysisData = getHbEnergyAnalysisData(data);

  return {
    buildingInfoATable,
    buildingInfoBTable,
    communalSpaceInfoTable,
    enhancedApartmentsTable,
    apartmentOrientationTable,
    efficiencyScoreTable,
    efficiencyScore,
    modelAnalysisArray,
    passiveHouseTable,
    parkingTable,
    siteId,
    buildingInfo,
    legendColors,
    formData,
    hvacData,
    energyAnalysisData,
    hbEnergyAnalysisData,
  };
};

export const feasibilityResultCardModel = (data, isImperial) => {
  if (!data || !lodashGet(data, 'study.buildingInfo') || !lodashGet(data, 'singleOptionMassingOptions.massing_options')) {
    return;
  }

  const buildingInfoA = setTableItems(data, isImperial, buildingInfoCardA);
  const buildingInfoB = setTableItems(data, isImperial, buildingInfoCardB);
  const unitsInfo = setTableItems(data, isImperial, unitsInfoCard);
  const totalUnitsInfo = setTableItems(data, isImperial, [{ key: BUILDING_INFO_KEYS.TOTAL_APARTMENTS, type: BUILDING_INFO_TYPES.UNIT }]);
  const efficiencyScore = setTableItems(data, isImperial, [{ key: BUILDING_INFO_KEYS.PRIORITY_FIT_SCORE, type: BUILDING_INFO_TYPES.SCORE }]);
  const hvacData = setTableItems(getHvacData(data), isImperial, hvacItemList);
  const formData = getFormData(data);
  const isEmptyLot = lodashGet(data, 'singleOptionMassingOptions.massing_options[0].masses', []).length === 0;

  return {
    buildingInfoA,
    buildingInfoB,
    unitsInfo,
    totalUnitsInfo,
    efficiencyScore,
    formData,
    hvacData,
    isEmptyLot,
  };
};

export const FloorGroupType = { // enums are coming from `massing_options.py`
  TYPICAL_FLOOR: 'typical_floor',
  OPEN_GROUND_FLOOR: 'open_ground_floor',
  COMMUNAL_TOP_FLOOR: 'communal_top_floor',
  COMMUNAL_GROUND_FLOOR: 'communal_ground_floor',
  COMMUNAL_OPEN_GROUND_FLOOR: 'communal_open_ground_floor',
  COMMUNAL_OPEN_GROUND_FLOOR_TEMPLATE: 'communal_open_ground_floor_template',
  COMMUNAL_GROUND_FLOOR_TEMPLATE: 'communal_ground_floor_template',

  // Retail related
  RETAIL_GROUND_FLOOR: 'retail_ground_floor',
  RETAIL_GROUND_FLOOR_TEMPLATE: 'retail_ground_floor_template',
  COMMUNAL_RETAIL_GROUND_FLOOR: 'communal_retail_ground_floor',
  COMMUNAL_RETAIL_GROUND_FLOOR_TEMPLATE: 'communal_retail_ground_floor_template',
  ABOVE_RETAIL: 'above_retail_floor',
  ABOVE_RETAIL_TEMPLATE: 'above_retail_template',

  // parking floor group types
  PRECAST: 'precast',
  PODIUM: 'podium',
  BELOW_GROUND_PARKING: 'below_ground_parking',
};
