var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "three", "./Utils"], function (require, exports, THREE, Utils_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    THREE = __importStar(THREE);
    class GeometryFactory {
        static extrudeFromSwpPolygon(polygon, depth, bottom) {
            const shape = (0, Utils_1.geomToThreeShape)(polygon);
            if (!shape) {
                return;
            }
            const geom = new THREE.ExtrudeBufferGeometry(shape, {
                depth,
                bevelEnabled: false,
                bevelSegments: 0,
                steps: 1,
                bevelSize: 0,
                bevelThickness: 0
            });
            geom.translate(0, 0, bottom);
            return geom;
        }
        static fromSwpExtrudedArea(geometry) {
            var _a, _b;
            // TODO: make it work on all pathes directions:
            const pathData = (_a = geometry.path) === null || _a === void 0 ? void 0 : _a.saveToObject();
            const bottom = pathData === null || pathData === void 0 ? void 0 : pathData.data[0][2];
            const height = (pathData === null || pathData === void 0 ? void 0 : pathData.data[1][2]) - bottom;
            if (!((_b = geometry.area) === null || _b === void 0 ? void 0 : _b.data)) {
                return;
            }
            return GeometryFactory.extrudeFromSwpPolygon(geometry.area.data, height, bottom);
        }
        static prepareMesh(geometry) {
            const geometryObject = geometry.saveToObject();
            const vertices = [];
            const faces = [];
            const normals = [];
            geometryObject.faces.forEach((face) => {
                const newFace = [];
                const threePoints = face.map((v) => new THREE.Vector3(...geometryObject.vertices[v]));
                const normal = new THREE.Vector3().crossVectors(threePoints[1].sub(threePoints[0]), threePoints[2].sub(threePoints[0])).normalize();
                const faceNormal = normal.toArray().map((p) => +p.toPrecision(10));
                face.forEach((vIndex, index) => {
                    newFace[index] = vertices.length;
                    vertices.push([...geometryObject.vertices[vIndex]]);
                    normals.push(faceNormal);
                });
                faces.push(newFace);
            });
            return { faces, vertices, normals };
        }
        static fromSwpMesh(geometry) {
            const { faces, vertices, normals } = GeometryFactory.prepareMesh(geometry);
            const geom = new THREE.BufferGeometry();
            geom.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices.flatMap((point) => [...point])), 3));
            geom.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(normals.flatMap((norm) => [...norm])), 3));
            const indices = faces.flatMap((face) => [...face]);
            geom.setIndex(indices);
            // geom.computeVertexNormals();
            return geom;
        }
        static flatFromSwpPolygon(geometry) {
            const shape = (0, Utils_1.geomToThreeShape)(geometry);
            if (!shape) {
                return;
            }
            return new THREE.ShapeBufferGeometry(shape);
        }
        static verticalLinesGeometries(geometry, depth, bottom) {
            const geomShape = (0, Utils_1.geomToThreeShape)(geometry);
            if (!geomShape) {
                return;
            }
            const shape = new THREE.ShapeGeometry(geomShape);
            const points = shape === null || shape === void 0 ? void 0 : shape.vertices;
            const verticalLines = points.flatMap((point) => [point, new THREE.Vector3(point.x, point.y, depth)]);
            const geom = new THREE.BufferGeometry().setFromPoints(verticalLines);
            const indices = Array.from(Array(points.length).keys());
            geom.setIndex(indices);
            geom.translate(0, 0, bottom);
            return geom;
        }
        static linesGeometries(geometry, depth, bottom, isExtruded) {
            const shape = (0, Utils_1.geomToThreeShape)(geometry);
            if (!shape) {
                return [];
            }
            const points = shape.getPoints();
            const holes = shape.getPointsHoles(0);
            const geometries = [this.pointsToBufferGeometry(points, depth, bottom, isExtruded)];
            holes === null || holes === void 0 ? void 0 : holes.forEach((hole) => geometries.push(this.pointsToBufferGeometry(hole, depth, bottom, isExtruded)));
            return geometries;
        }
    }
    exports.default = GeometryFactory;
    GeometryFactory.pointsToBufferGeometry = (points, depth, bottom, isExtruded) => {
        if (!points) {
            return;
        }
        const indices = points.flatMap((_, index) => [index, (index + 1) % points.length]);
        const geom = new THREE.BufferGeometry().setFromPoints(points);
        geom.setIndex(indices);
        if (isExtruded) {
            geom.translate(0, 0, depth + bottom);
        }
        return geom;
    };
});
