import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const EditorContext = createContext(null);

export const EditorContextProvider = ({ children }) => {
  const [editor, setEditor] = React.useState();
  // const [, forceUpdate] = React.useState();

  // const setEditor = (editor) => {
  //   editorRef.current = editor;
  //   console.log('🍔 setEditor', editorRef.current);
  //   forceUpdate();
  // };

  return (
    <EditorContext.Provider value={{ setEditor, editor }}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = () => {
  const bb = useContext(EditorContext);
  return bb;
};

EditorContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};
