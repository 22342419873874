import React from 'react';
import { NavLink } from 'react-router-dom';
import T from 'i18n-react';
import { useDispatch } from 'react-redux';
import { SecondaryButton } from 'styles/commonComponents.styles';
import { UI_UNAUTHORIZED_PATH } from 'constants/routes/ui';
import { useHistory } from 'react-router';
import { signUpAction } from 'store/auth/actions/authActions';
import { SingUpTitle, BrakeLine } from './SignUpContainer.styles';
import SingUpForm from './SignUpForm';

const SignUpContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSingUp = () => {
    dispatch(signUpAction());
  };

  return (
    <React.Fragment>
      <SingUpTitle>{T.translate('AUTH_FORM_SIGN_UP')}</SingUpTitle>
      <SingUpForm onSubmit={handleSingUp} history={history} />
      <BrakeLine />
      <NavLink to={UI_UNAUTHORIZED_PATH.login}>
        <SecondaryButton center width={256}>{T.translate('AUTH_FORM_LOG_IN')}</SecondaryButton>
      </NavLink>
    </React.Fragment>
  );
};

export default SignUpContainer;
