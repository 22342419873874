import React, { useEffect } from 'react';
import Editor from '@swapp/swappcommonjs/dist/editor/Editor';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import lodashSortBy from 'lodash/sortBy';
import SwpFloor from '@swapp/swappcommonjs/dist/swpProject/physicalProducts/SwpFloor';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import SwpWallSegment from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpWallSegment';
import SwpRoof from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpRoof';
import SwpWindow from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpWindow';
import SwpRailing from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpRailing';
import SwpCorridor from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpCorridor';
import SwpRoom from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpRoom';
import SwpWall from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpWall';
import SwpApartment from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpApartment';
import { getDistanceBetweenCoordinates, pointFromDistance } from 'components/projectSettings/setup/ArcGis/ArcGisMapHelpers';
import EDITOR_EVENTS from '@swapp/swappcommonjs/dist/editor/EditorEvents';
import { normalizeAngle } from 'utils/algorithms/algorithmHelpers';
import { useEditor } from './EditorContext';
import { selectedStorySelector } from '../../../../../store/BuildingStoriesStore';

const VIEWER_PRESETS = {
  DEFAULT: [
    [SwpBuildingStory, { hasFill: false, isExtruded: false, hasLines: false, hasVerticalLines: false, color: '#f5f6fa' }],
    [SwpRoof, { hasFill: true, isExtruded: true, hasLines: false, color: '#f5f6fa', receiveShadow: true }],
    [SwpFloor, { hasFill: true, isExtruded: true, hasLines: false, color: '#f5f6fa', receiveShadow: true }],
    [SwpWallSegment, { hasFill: true, hasLines: false, isExtruded: true, color: '#f5f6fa', hasVerticalLines: false }],
    [SwpWall, { hasFill: true, hasLines: false, isExtruded: true, hasVerticalLines: false, color: '#f5f6fa' }],
    [SwpRailing, { hasFill: true, hasLines: false, isExtruded: true, hasVerticalLines: false, color: '#6699CC' }],
    [SwpWindow, { hasFill: true, isExtruded: true, hasLines: false, color: '#b6c17e', hasVerticalLines: false }],
  ],
  SELECTION: [
    [SwpWallSegment, { hasFill: true, hasLines: false, isExtruded: true, color: '#f5f6fa', hasVerticalLines: false }],
    [SwpRailing, { hasFill: true, hasLines: false, isExtruded: true, hasVerticalLines: false, color: '#6699CC' }],
    [SwpApartment, { hasFill: true, hasMeshLines: false, isExtruded: false, color: '#d1d2d7', receiveShadow: true }],
    [SwpRoom, { hasFill: false, isExtruded: false, hasLines: true, color: '#f5f6fa' }],
    [SwpCorridor, { hasFill: false, isExtruded: false, hasLines: true, color: '#c9c05b' }],
    [SwpWindow, { hasFill: true, isExtruded: true, hasLines: false, color: '#b6c17e', hasVerticalLines: false }],
  ],
  TECHNICAL: [
    [SwpBuildingStory, { hasFill: false, isExtruded: false, hasLines: true, hasVerticalLines: false, color: '#ffffff' }],
    [SwpRoof, { hasFill: true, isExtruded: true, hasLines: true, color: '#ffffff', receiveShadow: true }],
    [SwpFloor, { hasFill: true, isExtruded: true, hasLines: false, color: '#f5f6fa', receiveShadow: true }],
    [SwpApartment, { hasFill: false, hasMeshLines: true, isExtruded: false, color: '#ffffff' }],
    [SwpRoom, { hasFill: false, isExtruded: false, hasLines: true, color: '#ffffff' }],
    [SwpWallSegment, { hasFill: true, hasLines: true, isExtruded: true, color: '#ffffff', hasVerticalLines: true }],
    [SwpWall, { hasFill: true, hasLines: true, isExtruded: true, hasVerticalLines: true, color: '#ffffff' }],
    [SwpRailing, { hasFill: true, hasLines: true, isExtruded: true, hasVerticalLines: true, color: '#6699CC' }],
    [SwpWindow, { hasFill: true, isExtruded: true, hasLines: true, color: '#ffffff', hasVerticalLines: true }],
  ],
};

const ProgramViewer = ({ isOrthographic, isTechnical, enrichedSwpProject, environmentURL, locationData, envData }) => {
  const { setEditor, editor } = useEditor();
  const selectedStory = useSelector(selectedStorySelector);

  useEffect(() => {
    if (!editor) {
      setEditor(new Editor(document.getElementById('editor')));
    }
  }, [locationData.projectId]);

  useEffect(() => {
    if (editor) {
      initialize();
    }
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (selectedStory != null) {
      editor.changeView(isTechnical ? VIEWER_PRESETS.TECHNICAL : VIEWER_PRESETS.SELECTION);

      const stories = getStoriesByLevel();
      const storiesIdsToShow = stories.filter((story) => story.level <= selectedStory).map((story) => story.story.id);
      editor.isolate(storiesIdsToShow, true);
    } else {
      editor.changeView(isTechnical ? VIEWER_PRESETS.TECHNICAL : VIEWER_PRESETS.DEFAULT);
      editor.showAll();
    }
  }, [selectedStory, isOrthographic, isTechnical, editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.toggleTechnicalRenderMode(isTechnical);
  }, [isTechnical, editor]);

  const getStoriesByLevel = () => {
    const swpStories = enrichedSwpProject.site.childrenByType(SwpBuildingStory, true);
    const sortedStories = lodashSortBy(swpStories, [(story) => story.transform.position.z]);
    const belowGroundFloors = sortedStories.map((story) => (story.transform.position.z < 0 ? story : null)).filter((e) => e);
    const aboveGroundFloors = sortedStories.map((story) => (story.transform.position.z >= 0 ? story : null)).filter((e) => e);
    const stories = [];
    belowGroundFloors.forEach((story, index) => stories.push({ level: -(belowGroundFloors.length - index), story }));
    aboveGroundFloors.forEach((story, index) => stories.push({ level: index, story }));

    return stories;
  };

  const getSiteGeographicalTransform = async () => {
    const ccwAngleToNorth = enrichedSwpProject.site.getProperty('ccw_angle_to_north') || 0;
    const siteElevation = enrichedSwpProject.site.getProperty('elevation') || 0;
    const latitude = enrichedSwpProject.site.getProperty('latitude');
    const longitude = enrichedSwpProject.site.getProperty('longitude');

    const offset = new THREE.Vector2();
    if (latitude && longitude) {
      const offsetX = await getDistanceBetweenCoordinates({ x: longitude, y: latitude }, { x: longitude, y: envData.lat }) || 0;
      const offsetY = await getDistanceBetweenCoordinates({ x: longitude, y: latitude }, { x: envData.lng, y: latitude }) || 0;
      offset.x = offsetX.distance;
      offset.y = offsetY.distance;
    }

    const translation = new THREE.Vector3(offset.y, -offset.x, siteElevation - (envData.elevation || 0));
    const rotation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), ccwAngleToNorth);

    return { translation, rotation };
  };

  const showCoordinates = async (point) => {
    console.log(point);
    const newElev = point.z + (envData.elevation || 0);
    const azimuth = normalizeAngle(Math.atan2(point.x, point.y) * (180 / Math.PI));
    const dist = new THREE.Vector2(point.x, point.y).length();
    const newPoint = await pointFromDistance({ x: envData.lng, y: envData.lat }, dist, azimuth);
    console.log('Enter this in Revit:');
    console.log(`lat: ${newPoint.y}, lng: ${newPoint.x}, elavation: ${newElev}`);
  };

  const initialize = async () => {
    const { translation, rotation } = await getSiteGeographicalTransform();

    editor.addSwpProject(enrichedSwpProject, { translation, rotation });
    editor.addEnvironment(environmentURL);
    editor.changeView(VIEWER_PRESETS.DEFAULT);
    editor.on(EDITOR_EVENTS.TERRAIN_CLICKED, ({ params }) => {
      showCoordinates(params.intersection.point);
    });
  };

  return (
    <div id="editor" style={{ width: '100%', height: '100%', border: '0 solid', position: 'relative' }} />
  );
};

ProgramViewer.propTypes = {
  isOrthographic: PropTypes.bool,
  isTechnical: PropTypes.bool,
  enrichedSwpProject: PropTypes.object,
  environmentURL: PropTypes.string,
  locationData: PropTypes.object,
  envData: PropTypes.object,
};

export default ProgramViewer;
