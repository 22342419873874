// A collection of general utils to enrich how we work with typescript
define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.filterByType = void 0;
    function filterByType(array, filterType) {
        return array === null || array === void 0 ? void 0 : array.filter((e) => e instanceof filterType);
    }
    exports.filterByType = filterByType;
});
