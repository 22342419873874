// Various methods to help us navigate through the SwpProject hierarchy
import { Matrix4, Quaternion, Vector3 } from 'three';
import SwpProduct from '@swapp/swappcommonjs/dist/swpProject/SwpProduct';
import SwpBuilding from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuilding';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import SwpSite from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpSite';
import { filterByType } from '@swapp/swappcommonjs/dist/utils/tsUtils';
import SwpBalcony from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBalcony';
import SwpPoint from '@swapp/swappcommonjs/dist/swpProject/geometries/SwpPoint';
import SwpPolygon from '@swapp/swappcommonjs/dist/swpProject/geometries/SwpPolygon';
import SwpPolyline from '@swapp/swappcommonjs/dist/swpProject/geometries/SwpPolyline';
import SwpQuaternion from '@swapp/swappcommonjs/dist/swpProject/SwpQuaternion';
import SwpPropertySet from '@swapp/swappcommonjs/dist/swpProject/SwpPropertySet';

export const getSwpProjectBuildings = (swpProject) => {
  if (!swpProject) {
    return;
  }
  const site = swpProject.getObjectByType(SwpSite)[0];
  const buildings = filterByType(site.childObjects, SwpBuilding);
  return buildings;
};

const setProductProperties = (swpProduct) => {
  swpProduct.childObjects.forEach((child) => {
    if (child instanceof SwpProduct) {
      child.relationships.forEach((relation) => {
        if (relation.other instanceof SwpPropertySet) {
          relation.other.properties.forEach((prop) => {
            child[prop.key] = prop.value;
          });
        }
      });
      setProductProperties(child);
    }
  });
  return swpProduct;
};

export const enrichProjectProperties = (swpProject) => {
  swpProject.objects.forEach((child) => {
    if (child instanceof SwpProduct) {
      setProductProperties(child);
    }
  });
};

export const extractBalconiesFromBuilding = (swpBuilding, storyIndex) => {
  if (!swpBuilding) {
    return [];
  }
  const stories = filterByType(swpBuilding.childObjects, SwpBuildingStory);
  const story = stories[storyIndex];
  const balconies = story.childrenByType(SwpBalcony, true);
  return balconies;
};

export const getStory = (swpBuilding, storyIndex) => {
  const stories = filterByType(swpBuilding.childObjects, SwpBuildingStory);
  const story = stories[storyIndex];
  return story;
};

export const getChildById = (swpProduct, swpId) => {
  const child = swpProduct.childObjects.find((swpRoot) => swpRoot.id === swpId);
  return child;
};

export const getStoryHeight = (swpBuilding, storyIndex) => {
  const story = getStory(swpBuilding, storyIndex);
  return story.grossHeight;
};

export const isStoryUtilityStory = (swpBuildingStory) => swpBuildingStory.name.toLowerCase().includes('utility');

export const buildTransformationMatrix = (transform) => {
  const translate = new Vector3();
  const rotate = new Quaternion();
  const scale = new Vector3();

  if (transform.position) {
    translate.set(transform.position.x, transform.position.y, transform.position.z);
  }

  if (transform.rotation) {
    rotate.set(transform.rotation.x, transform.rotation.y, transform.rotation.z, transform.rotation.w);
  } else {
    rotate.identity();
  }

  if (transform.scale) {
    scale.set(transform.scale.x, transform.scale.y, transform.scale.z);
  } else {
    scale.set(1, 1, 1);
  }

  const composedMatrix = new Matrix4();
  composedMatrix.compose(translate, rotate, scale);
  return composedMatrix;
};

export const composeTransformationMatrices = (mat1, mat2) => {
  const mat = new Matrix4();
  mat.multiplyMatrices(mat2, mat1);
  return mat;
};

export const buildLocalToGlobalMatrix = (product) => {
  const totalMatrix = new Matrix4();
  while (product) {
    if (product.transform) {
      const transformMat = buildTransformationMatrix(product.transform);
      totalMatrix.premultiply(transformMat);
    }
    product = product.parent;
  }
  return totalMatrix;
};

export const quaternionAboutAxis = (axis, radians) => {
  const quaternion = new Quaternion();
  quaternion.setFromAxisAngle(new Vector3(axis.x, axis.y, axis.z), radians);
  return new SwpQuaternion([quaternion.w, quaternion.x, quaternion.y, quaternion.z]);
};

export const transformPoint = (matrix, point) => {
  const v = new Vector3(point.x, point.y, point.z);
  v.applyMatrix4(matrix);
  return new SwpPoint([v.x, v.y, v.z]);
};

export const transformGeometry = (matrix, geometry) => {
  const poly = new SwpPolygon();
  if (geometry instanceof SwpPoint) {
    return transformPoint(matrix, geometry);
  }
  if (geometry instanceof SwpPolyline) {
    // TODO: Why does geometry.map() fail?
    const transformedPoints = [];
    geometry.data.forEach((p) => transformedPoints.push(transformPoint(matrix, p)));
    return new SwpPolyline(transformedPoints);
  }
  if (geometry instanceof SwpPolygon) {
    if (geometry.boundary) {
      poly.boundary = transformGeometry(matrix, geometry.boundary);
    }
    if (geometry.holes) {
      poly.holes = geometry.holes.map((hole) => transformGeometry(matrix, hole));
    }
    return poly;
  }
  throw new Error(`transformGeometry unsupported for type ${typeof geometry}`);
};

export const getProductBoundaryPoints = (product) => {
  const toGlobalMatrix = buildLocalToGlobalMatrix(product);
  const geometry = transformGeometry(toGlobalMatrix, product.geom.data);
  if (geometry.boundary) {
    return geometry.boundary.data;
  }
  return [];
};

export const iterateChildrenByType = (swpProduct, type, callback) => {
  if (!swpProduct) {
    return;
  }
  swpProduct.childObjects.forEach((child) => {
    const matrix = buildLocalToGlobalMatrix(child);
    let transformedData;
    if (child.geom?.data) {
      transformedData = transformGeometry(matrix, child.geom.data);
    }

    if (child instanceof type) {
      callback(child, transformedData);
    }
  });
};
