var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "three", "three/examples/jsm/loaders/GLTFLoader.js"], function (require, exports, THREE, GLTFLoader_js_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    THREE = __importStar(THREE);
    THREE.Cache.enabled = true;
    class EditorLoader extends GLTFLoader_js_1.GLTFLoader {
        constructor() {
            super();
            this.cache = {};
        }
        async loadAsync(url, onProgress) {
            if (this.cache[url]) {
                return this.cache[url];
            }
            const gltf = await super.loadAsync(url, onProgress);
            this.cache[url] = gltf;
            return gltf;
        }
        load(url, onLoad, onProgress, onError) {
            if (this.cache[url]) {
                onLoad(this.cache[url]);
                return;
            }
            super.load(url, (gltf) => {
                this.cache[url] = gltf;
                onLoad(gltf);
            }, onProgress, onError);
        }
    }
    exports.default = EditorLoader;
});
