var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "three", "./MaterialsManager", "../utils/MergeBufferGeometries"], function (require, exports, THREE, MaterialsManager_1, MergeBufferGeometries_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    THREE = __importStar(THREE);
    class MergingManager {
        constructor() {
            this._geometryDictionary = {};
            this._lineGeometryDictionary = {};
            if (MergingManager._instance) {
                throw new Error("Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.");
            }
            MergingManager._instance = this;
        }
        static getInstance() {
            return MergingManager._instance;
        }
        reset() {
            this._geometryDictionary = {};
            this._lineGeometryDictionary = {};
        }
        storeGeometryToAncestor(geometry, ancestryId, materialId) {
            if (!this._geometryDictionary[ancestryId]) {
                this._geometryDictionary[ancestryId] = {};
            }
            if (!this._geometryDictionary[ancestryId][materialId]) {
                this._geometryDictionary[ancestryId][materialId] = [geometry];
            }
            else {
                this._geometryDictionary[ancestryId][materialId].push(geometry);
            }
        }
        storeLineGeometryToAncestor(geometry, ancestryId) {
            if (!this._lineGeometryDictionary[ancestryId]) {
                this._lineGeometryDictionary[ancestryId] = [geometry];
            }
            else {
                this._lineGeometryDictionary[ancestryId].push(geometry);
            }
        }
        renderGeometryDictionary(swpIdToObject) {
            Object.keys(this._geometryDictionary).forEach((swpId) => {
                const productDict = this._geometryDictionary[swpId];
                Object.keys(productDict).forEach((materialId) => {
                    const splitMat = materialId.split(':');
                    const parsedMaterialId = splitMat[0];
                    const isReceiveShadow = !!splitMat[1];
                    const geomArray = productDict[materialId];
                    const geom = (0, MergeBufferGeometries_1.mergeBufferGeometries)(geomArray);
                    const material = (0, MaterialsManager_1.getMaterialByMaterialId)(parsedMaterialId);
                    const ances = swpIdToObject[swpId];
                    const mesh = new THREE.Mesh(geom, material);
                    if (ances) {
                        ances.addVisuals(mesh, isReceiveShadow);
                    }
                });
            });
        }
        renderLineGeometryDictionary(swpIdToObject) {
            Object.keys(this._lineGeometryDictionary).forEach((swpId) => {
                const geomArray = this._lineGeometryDictionary[swpId];
                const geom = (0, MergeBufferGeometries_1.mergeBufferGeometries)(geomArray);
                const ances = swpIdToObject[swpId];
                const line = new THREE.LineSegments(geom, (0, MaterialsManager_1.getLineMaterial)());
                if (ances) {
                    ances.addLineVisuals(line);
                }
            });
        }
        renderDictionaries(swpIdToObject) {
            this.renderGeometryDictionary(swpIdToObject);
            this.renderLineGeometryDictionary(swpIdToObject);
        }
    }
    exports.default = MergingManager;
    MergingManager._instance = new MergingManager();
});
