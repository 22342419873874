import React, { useState, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import Measure from 'react-measure';
import lodashIsEqual from 'lodash/isEqual';
import lodashIncludes from 'lodash/includes';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import { API_BASE_URL } from '../../config';
import { agent } from '../helpers';
import { StyledForgeViewer } from './ForgeViewer.styles';
import { filterLayersByRelevance, getOtherLayers, cleanLayerName } from '../helpers/forgeHelpers';
import MarkupsContainer from './MarkupsContainer';
import { LAYER_NAMES_TO_KEYS } from '../../constants/autocadConsts';
import { TEST_FIT_OFFICE_TYPES } from '../../constants/dropdownKeys';

const ForgeViewer = forwardRef((props, ref) => {
  const { urn, projectId, isImperial, version = '7.56.0', hideUi, height, heightOffset = 2, hideType,
    backgroundColor = [245, 246, 250, 245, 246, 250], onCameraChange, bordered, sheetView, toggleIsMarkupsOpen, isExternal, sliceAmount } = props;
  const viewerDiv = useRef();
  let resizeHandling = null;
  let docs = [];

  const [viewer, setViewer] = useState(null);
  const [isDoneLoading, useIsDoneLoading] = useState(false);
  const [isMarkupsOpen, useIsMarkupsOpen] = useState(false);
  const selectedRowKeys = useSelector(({ editor }) => editor.selectedRowKeys);
  const selectedCategoryRowKeys = useSelector(({ editor }) => editor.selectedCategoryRowKeys);
  if (typeof urn !== 'undefined' && urn !== '') {
    docs.push(urn);
  }

  useEffect(() => {
    setLayerVisibility();
  }, [selectedRowKeys.length]);

  useEffect(() => {
    setHideTypeVisibility();
  }, [selectedCategoryRowKeys.length]);

  useEffect(() => {
    unHideAllLayers();
  }, [selectedCategoryRowKeys, selectedRowKeys]);

  useEffect(() => {
    if (viewer) {
      setTextLayersVisibility();
    }
  }, [isImperial]);

  useEffect(() => {
    if (viewer && urn) {
      const markupsExtension = viewer.getExtension('Autodesk.Viewing.MarkupsCore');
      toggleMarkups(useIsMarkupsOpen, false);
      markupsExtension.clear();
      markupsExtension.unloadMarkupsAllLayers();

      docs = [urn];
      viewer.tearDown();
      viewer.finish();
      reviewDocuments();
    }
  }, [urn]);

  // componentWillUnmount
  useEffect(() => () => {
    if (viewer) {
      viewer.removeEventListener(window.Autodesk.Viewing.CAMERA_CHANGE_EVENT, () => onCameraChange(getPosition(viewer)));
      viewer.tearDown();
      viewer.finish();
      setViewer(null);
    }
  }, []);

  const handleError = (errorCode) => {
    console.error(errorCode);
  };

  const handleScriptLoad = () => {
    agent.get(`${API_BASE_URL}/project-profile/${projectId}/forge-token`)
      .then((response) => {
        const token = response.data;

        const options = {
          env: 'AutodeskProduction',
          getAccessToken: (onAccessToken) => onAccessToken(token.access_token, token.expires_in),
        };

        window.Autodesk.Viewing.Initializer(options, reviewDocuments);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const handleLoadDocumentSuccess = (doc) => {
    const currentViews = doc.getRoot().search({ type: 'geometry' });
    // Choose any of the available geometries
    const initGeom = currentViews[0];

    // and prepare config for the viewer application
    const config = {
      extensions: [
        'Autodesk.Viewing.MarkupsCore',
        'Autodesk.Section',
      ],
    };

    if (initGeom.data.role === '3d') {
      config.extensions.push('Autodesk.DocumentBrowser');
    }

    const currentViewer = new window.Autodesk.Viewing.Private.GuiViewer3D(viewerDiv.current, config);
    setViewer(currentViewer);

    // currentViewer.addEventListener(window.Autodesk.Viewing.FULLSCREEN_MODE_EVENT, (e) => onFullScreen(e, currentViewer));
    currentViewer.addEventListener(window.Autodesk.Viewing.EXTENSION_LOADED_EVENT, (e) => onToolbarCreated(e, currentViewer));
    currentViewer.addEventListener(window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, (e) => {
      setTimeout(() => handleSuccess(e.model, currentViewer, useIsDoneLoading), 0);
    });

    // start the viewer
    currentViewer.start();

    const modelOptions = {
      sharedPropertyDbPath: doc.getRoot().findPropertyDbPath(),
    };

    // load a node in the fetched document
    currentViewer.loadDocumentNode(doc.getRoot().lmvDocument, currentViews[0], modelOptions);
    currentViewer.setTheme('light-theme');
  };

  const onToolbarCreated = (e, currentViewer) => {
    if (currentViewer.toolbar) {
      const settingsTools = currentViewer.toolbar.getControl('settingsTools');
      settingsTools.removeControl('toolbar-settingsTool');

      if (sheetView) {
        const modelTools = currentViewer.toolbar.getControl('modelTools');
        modelTools.removeControl('toolbar-documentModels');
        modelTools.removeControl('toolbar-explodeTool');
      }

      currentViewer.removeEventListener(window.Autodesk.Viewing.EXTENSION_LOADED_EVENT, onToolbarCreated);
    }
  };

  // const onFullScreen = (e, currentViewer) => {
  //   if (sheetView) {
  //     const documentBrowserExtension = currentViewer.getExtension('Autodesk.DocumentBrowser');
  //     if (documentBrowserExtension && documentBrowserExtension.ui) {
  //       documentBrowserExtension.ui.togglePanel();
  //     }
  //   }
  // };

  const reviewDocuments = () => {
    docs.forEach((currentUrn) => {
      loadDocument(currentUrn);
    });
  };

  const loadDocument = (currentUrn) => {
    const documentId = `urn:${currentUrn}`;

    window.Autodesk.Viewing.Document.load(
      documentId, handleLoadDocumentSuccess, handleError,
    );
  };

  const handleSuccess = (model, currentViewer, updateIsDoneLoading) => {
    if (sheetView) {
      const documentBrowserExtension = currentViewer.getExtension('Autodesk.DocumentBrowser');
      // documentBrowserExtension.ui will load only at the end of the render flow so by adding setTimeout we toggle the ui on the very end of the render flow
      setTimeout(() => {
        if (documentBrowserExtension && !documentBrowserExtension?.ui?.panel?.shown) {
          documentBrowserExtension?.ui?.togglePanel();
        }
      }, 0);
    }

    if (sliceAmount) {
      const sectionExtension = currentViewer.getExtension('Autodesk.Section');
      if (sectionExtension) {
        // sectionExtension.setSectionStyle('Z');
        sectionExtension.setSectionPlane(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 0, sliceAmount), true);
      }
    }

    updateIsDoneLoading(true);

    setTimeout(() => {
      if (hideType && currentViewer.impl) {
        currentViewer.setLayerVisible(getOtherLayers(currentViewer.impl.getLayersRoot().children, hideType), false, false);
      }
      setTextLayersVisibility(currentViewer);
    }, 0);

    if (model.is2d()) { // zoom in to the model
      const boundingBox = model.getBoundingBox();
      // const zoomInAmount = (boundingBox.max.y - boundingBox.max.x) - (boundingBox.max.y / 6);
      // boundingBox.max.y -= zoomInAmount;
      // boundingBox.min.y += zoomInAmount;
      currentViewer.navigation.fitBounds(false, boundingBox, false);
      startListeningToCameraChangeEvent(currentViewer);
    } else {
      const viewerState = currentViewer.getState();
      viewerState.renderOptions.ambientOcclusion.radius = 2;
      viewerState.renderOptions.ambientOcclusion.intensity = 0.3;
      currentViewer.restoreState(viewerState, null, false);
      currentViewer.impl.toggleGroundShadow(false);
      currentViewer.navigation.toPerspective();

      currentViewer.loadExtension('Autodesk.ViewCubeUi')
        .then((res) => {
          res.setViewCube('front top right');
          currentViewer.navigation.toPerspective();
        });
    }
    currentViewer.setLightPreset(isExternal ? 4 : 17);
    currentViewer.setEnvMapBackground(false);
    currentViewer.setBackgroundColor(...backgroundColor);
  };

  const getLayers = () => {
    if (!viewer || !isDoneLoading || !viewer.impl) {
      return;
    }

    const layers = viewer.impl.getLayersRoot();
    return (layers && layers.children) || [];
  };

  const unHideAllLayers = () => {
    if (!viewer) {
      return;
    }

    const layers = getLayers();
    if (lodashIsEmpty(layers)) {
      return;
    }

    const relevantLayers = filterLayersByRelevance(layers);

    if (lodashIsEmpty([...selectedRowKeys, ...selectedCategoryRowKeys])) {
      viewer.setLayerVisible(relevantLayers, true); // show all layers if modifiedSelectedRowKeys is empty
    }
  };

  const setHideTypeVisibility = () => {
    if (!viewer || lodashIsEmpty(selectedCategoryRowKeys)) {
      return;
    }

    const layers = getLayers();
    const relevantLayers = filterLayersByRelevance(layers);
    const filteredRelevantLayers = relevantLayers.filter((layer) => !lodashIncludes(selectedCategoryRowKeys, layer.split(' ')[1]));

    viewer.setLayerVisible(relevantLayers, true); // makes all layers visible
    viewer.setLayerVisible(filteredRelevantLayers, false); // hide all relevant layers that are not selected
  };

  const setLayerVisibility = () => {
    if (!viewer || lodashIsEmpty(selectedRowKeys)) {
      return;
    }

    const layers = getLayers();
    const modifiedSelectedRowKeys = [...selectedRowKeys];

    // when filtering LOBBY_LOUNGE we need to show CORRIDOR as well
    if (lodashIncludes(selectedRowKeys, TEST_FIT_OFFICE_TYPES.ASF.LOBBY_LOUNGE)) {
      modifiedSelectedRowKeys.push(TEST_FIT_OFFICE_TYPES.ASF.CORRIDOR);
    }

    const translatedSelectedRowKeys = modifiedSelectedRowKeys.map((name) => cleanLayerName(LAYER_NAMES_TO_KEYS[name]) || name);
    const relevantLayers = filterLayersByRelevance(layers);
    const notSelectedRelevantLayers = relevantLayers.filter((name) => !lodashIncludes(translatedSelectedRowKeys, cleanLayerName(name)));

    viewer.setLayerVisible(relevantLayers, true); // makes all layers visible
    viewer.setLayerVisible(notSelectedRelevantLayers, false); // hide all relevant layers that are not selected
  };

  const setTextLayersVisibility = (newViewer) => {
    const currentViewer = newViewer || viewer;
    if (!currentViewer || !currentViewer.impl) {
      return;
    }

    const rootLayers = currentViewer.impl.getLayersRoot();
    const layers = lodashGet(rootLayers, 'children');

    if (layers) {
      if (lodashIncludes(layers.map((layer) => layer.name).join(','), '0-BO Text Metric')) {
        currentViewer.setLayerVisible(['0-BO Text Metric'], !isImperial);
        currentViewer.setLayerVisible(['0-BO Text'], isImperial);
      }
    }
  };

  const getPosition = (newViewer) => {
    const currentViewer = newViewer || viewer;
    if (!currentViewer && !currentViewer.navigation && !currentViewer.navigation.getPosition()) {
      return;
    }

    const position = currentViewer.navigation.getPosition();
    const target = currentViewer.navigation.getTarget();
    return { position, target };
  };

  const setCameraPosition = (cord) => {
    if (!viewer || !cord) {
      return;
    }

    const currentPosition = getPosition();
    if (lodashIsEqual(currentPosition, cord)) {
      return;
    }

    viewer.navigation.setView(cord.position, cord.target);
  };

  const startListeningToCameraChangeEvent = (currentViewer) => {
    if (currentViewer && onCameraChange) {
      currentViewer.addEventListener(window.Autodesk.Viewing.CAMERA_CHANGE_EVENT, () => onCameraChange(getPosition(currentViewer)));
    }
  };

  const toggleMarkups = (update, value) => {
    if (!viewer || !isDoneLoading) {
      return;
    }

    const markupsExtension = viewer.getExtension('Autodesk.Viewing.MarkupsCore');
    const position = viewer.navigation.getPosition();
    const target = viewer.navigation.getTarget();

    const zoomPosition = { ...position, z: 10000 };
    viewer.navigation.setView(zoomPosition, target);

    if (!value) {
      markupsExtension.leaveEditMode();
      window.Autodesk.Extensions.Markup.Core.Utils.showLmvToolsAndPanels(markupsExtension.viewer);
      update(value);
    } else {
      markupsExtension.enterEditMode();
      window.Autodesk.Extensions.Markup.Core.Utils.hideLmvToolsAndPanels(markupsExtension.viewer);
      update(value);
    }
    viewer.navigation.setView(position, target);
  };

  const hasViewerDoneLoading = () => isDoneLoading;

  if (ref) {
    ref.current = { hasViewerDoneLoading, setCameraPosition, toggleMarkups: (e) => toggleMarkups(useIsMarkupsOpen, e) };
  }

  const handleResize = () => {
    // cancel any previous handlers that were dispatched
    if (resizeHandling) {
      clearTimeout(resizeHandling);
    }

    // defer handling until resizing stops
    resizeHandling = setTimeout(() => {
      if (viewer) {
        viewer.resize();
      }
    }, 100);
  };

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <StyledForgeViewer height={height} heightOffset={heightOffset} hideUi={hideUi} ref={measureRef} bordered={bordered} sheetView={sheetView}>
          {viewer && viewer.getExtension('Autodesk.Viewing.MarkupsCore') && <MarkupsContainer isMarkupsOpen={isMarkupsOpen} markupsExtension={viewer.getExtension('Autodesk.Viewing.MarkupsCore')} toggleIsMarkupsOpen={toggleIsMarkupsOpen} />}
          <div ref={viewerDiv} />
          <link rel="stylesheet" type="text/css" href={`https://developer.api.autodesk.com/modelderivative/v2/viewers/${version}/style.min.css`} />
          <Script
            url={`https://developer.api.autodesk.com/modelderivative/v2/viewers/${version}/viewer3D.min.js`}
            onLoad={handleScriptLoad}
            onError={handleError}
          />
        </StyledForgeViewer>
      )}
    </Measure>
  );
});

ForgeViewer.propTypes = {
  urn: PropTypes.string,
  projectId: PropTypes.string,
  version: PropTypes.string,
  height: PropTypes.number,
  heightOffset: PropTypes.number,
  hideUi: PropTypes.bool,
  hideType: PropTypes.string,
  backgroundColor: PropTypes.array,
  onCameraChange: PropTypes.func,
  toggleIsMarkupsOpen: PropTypes.func,
  isImperial: PropTypes.bool,
  bordered: PropTypes.bool,
  sheetView: PropTypes.bool,
  isExternal: PropTypes.bool,
  sliceAmount: PropTypes.number,
};

export default ForgeViewer;
