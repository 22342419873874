define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    class SwpRelationshipLink {
        constructor(_rel, _other) {
            this.other = _other;
            this.rel = _rel;
        }
        setRelationShip(_rel, _other) {
            this.other = _other;
            this.rel = _rel;
        }
    }
    exports.default = SwpRelationshipLink;
});
