import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashSortBy from 'lodash/sortBy';
import lodashIncludes from 'lodash/includes';
import lodashFlatten from 'lodash/flatten';
import lodashSum from 'lodash/sum';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsStartsWith from 'lodash/startsWith';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import { getPolygonArea } from 'utils/algorithms/algorithmHelpers';
import { FILTER_TYPES } from 'constants/testFitConsts';
import lodashUniq from 'lodash/uniq';
import { numberWithComma } from '../helpers/dataDisplay';
import { uuidv4 } from '../helpers/uuidv4';
import {
  getLsfModalArea,
  roomSizeToSQM,
  sqfToSqm,
  sqfToSqmText,
  metricDivideValue,
} from '../helpers/unitsHelpers';
import { floorPlanParse } from './testFitEnricher';

const sortRooms = (list, standard) => {
  const sortList = {};
  standard.forEach((unit) => {
    sortList[unit.key] = unit.index;
  });

  return lodashSortBy(list, [(item) => lodashGet(sortList, `[${item.name}]`), 'name']);
};

const tagOtherRooms = (list, standard) => {
  let otherIndex = 0;
  const standardKeys = standard.map((unit) => unit.key);
  const roomList = list.map((item) => {
    if (lodashIncludes(standardKeys, item.name) && !lodashIsStartsWith(item.name, 'OTHER')) {
      return item;
    }

    const newIem = { ...item, otherIndex };
    otherIndex += 1;
    return newIem;
  });

  return roomList;
};

const getRoomColor = (list, category, theme) => {
  const colorsArrayByCategory = theme.colors.layers[category];
  return list.map((item) => {
    if (item.otherIndex || item.otherIndex === 0) {
      const otherColorsArray = colorsArrayByCategory.OTHER;
      return { ...item, color: otherColorsArray[item.otherIndex % otherColorsArray.length] };
    }

    return { ...item, color: colorsArrayByCategory[item.name] };
  });
};

const prepareRoomList = (list, totalArea, roomRequirements, category, theme, testFitStandard) => {
  const standard = lodashGet(getTestFitStandards(), `standards[${testFitStandard}].roomDefinitions[${category}]`);
  const sortedList = sortRooms(list, standard);

  const sortedListWithSubCategory = sortedList.map((unit) => {
    const unitInRoomRequirements = roomRequirements?.find((room) => (Array.isArray(room) ? room[1] === unit.name : room.room_type === unit.name));
    const getTargetRoom = () => {
      if (lodashGet(unit, 'targetRooms')) {
        return lodashGet(unit, 'targetRooms');
      }

      if (unitInRoomRequirements) {
        if (Array.isArray(unitInRoomRequirements)) {
          return unitInRoomRequirements[3];
        }

        const isRooms = lodashGet(unitInRoomRequirements, 'target_area_type') === 'Rooms';
        if (isRooms) {
          return lodashGet(unitInRoomRequirements, 'target_area_value');
        }
        const averageRange = (lodashGet(unitInRoomRequirements, 'min_sqft') + lodashGet(unitInRoomRequirements, 'max_sqft')) / 2;
        const estimateSF = Math.round(lodashGet(unitInRoomRequirements, 'target_area_value') * totalArea);
        return Math.round(estimateSF / averageRange);
      }

      return 0;
    };

    return ({ // from testFitStandard file
      ...unit,
      subCategory: lodashGet(standard?.find((standardUnit) => standardUnit.key === unit.name || standardUnit.key === unitInRoomRequirements?.room_key), 'subCategory'),
      targetRooms: getTargetRoom(),
    });
  });
  const listWithTagsOtherRooms = tagOtherRooms(sortedListWithSubCategory, standard);
  return getRoomColor(listWithTagsOtherRooms, category, theme);
};

const getChartColors = (list) => list.map((item) => item.color).filter((color) => color != null);

export const testFitModel = (data, isImperial, highlight, theme, testFitStandard) => {
  if (!data) {
    return;
  }

  const roomRequirements = lodashGet(data, 'multiBoundaryFile.requirements.rooms_requirements');

  const modelRatioStatus = (target, model) => {
    if (highlight && target < model) {
      return 'max';
    }
  };

  const colorsByRsfType = {
    'LSF:RSF': { key: 'LSF', color: theme.colors.layers.LSF.MAIN },
    'PSF:RSF': { key: 'PSF', color: theme.colors.layers.PSF.MAIN },
    'ASF:RSF': { key: 'ASF', color: theme.colors.layers.ASF.MAIN },
    'FSF:RSF': { key: 'FSF', color: theme.colors.layers.FSF.MAIN },
    Leftover: { key: 'Leftover', color: theme.colors.layers.EMPTY.MAIN },
    EMPTY: { key: 'Leftover', color: theme.colors.layers.EMPTY.MAIN },
  };

  const getTileAmount = (area, roomsCount) => {
    const averageRoomArea = (isImperial ? area : sqfToSqm(area)) / (roomsCount || 1);
    return Math.round(isImperial ? averageRoomArea / 48.43 : averageRoomArea / 4.5); // 48.43/4.5 are average square area of a tile
  };

  const developmentAppraisalData = data.RSF.sections.map((item) => {
    if (!item.modelArea) {
      return;
    }

    return ({
      key: uuidv4(),
      name: item.name === 'Leftover' ? 'Other' : item.name,
      modelArea: item.modelArea / metricDivideValue,
    });
  }).filter((e) => e);

  const rsfTableData = data.RSF.sections.map((item) => {
    if (!item.modelArea) {
      return;
    }

    return ({
      key: uuidv4(),
      name: item.name,
      color: item.modelArea ? colorsByRsfType[item.name]?.color : undefined,
      category: colorsByRsfType[item.name]?.key,
      filterType: FILTER_TYPES.CATEGORY,
      targetRatio: item.targetRatio && numberWithComma(item.targetRatio * 100, { suffix: '%', fixed: 2 }),
      modelRatio: numberWithComma((item.modelArea * 100) / data.RSF.totalArea, { suffix: '%', fixed: 2 }),
      modelArea: sqfToSqmText(item.modelArea, isImperial),
      modelRatioStatus: modelRatioStatus(item.targetRatio * 100, (item.modelArea * 100) / data.RSF.totalArea),
    });
  }).filter((e) => e);

  const rsfTotalData = [
    {
      key: 'TOTAL',
      name: T.translate('OVERALL_CONTAINER_TOTAL_RSF_AREA'),
      targetRatio: '',
      modelRatio: numberWithComma(data.RSF.sections.reduce((total, current) => (
        { modelRatio: (total.modelRatio || total.modelArea / data.RSF.totalArea) + current.modelArea / data.RSF.totalArea })).modelRatio * 100, { suffix: '%', fixed: 0 }),
      modelArea: sqfToSqmText(data.RSF.totalArea, isImperial),
    },
  ];

  const totalRsf = lodashGet(data, 'RSF.totalRsf');
  if (totalRsf) {
    const rsfItem = {
      key: 'LSF:USF_TOTAL',
      name: 'RSF',
      targetRatio: '',
      modelRatio: '',
      modelArea: sqfToSqmText(totalRsf, isImperial),
      modelRatioStatus: '',
    };

    rsfTableData.push(rsfItem);
  }

  if (data.RSF.totalUsf) {
    const usfTotal = {
      key: 'LSF:USF2_TOTAL',
      name: T.translate('OVERALL_CONTAINER_TOTAL_USF_AREA'),
      targetRatio: '',
      modelRatio: '',
      modelArea: sqfToSqmText(data.RSF.totalUsf, isImperial),
    };

    rsfTotalData.push(usfTotal);
  }

  const rsfChartData = data.RSF.sections.map((item) => ({
    label: item.name,
    value: Number(numberWithComma((item.modelArea * 100) / data.RSF.totalArea)),
  }));
  const lsfSortedData = prepareRoomList(data.LSF.sections, data.LSF.totalArea, roomRequirements, 'LSF', theme, testFitStandard);
  const lsfCategoryTypes = lodashUniq(lsfSortedData.map((room) => room.subCategory)).filter((e) => e);

  const getLsfTableData = (item) => ({
    key: item.key || uuidv4(),
    name: T.translate(item.name),
    color: item.color,
    officeKey: item.name,
    category: 'LSF',
    subCategory: item.subCategory,
    filterType: FILTER_TYPES.ROOM,
    targetSizeRange: roomSizeToSQM(item.targetSizeRange, isImperial),
    targetRatio: numberWithComma((item.targetRatio * 100), { fixed: 1 }),
    targetRooms: numberWithComma(item.targetRooms),
    modelRatio: numberWithComma((item.modelArea / data.LSF.totalArea) * 100, { fixed: 1 }),
    modelAverage: !item.key && getLsfModalArea(item.modelArea, item.modelRooms, isImperial),
    modelArea: sqfToSqmText(item.modelArea, isImperial),
    tileAmount: getTileAmount(item.modelArea, item.modelRooms),
    modelRooms: numberWithComma(item.modelRooms),
    modelMembers: numberWithComma(item.modelMembers),
    modelRatioStatus: !item.key && modelRatioStatus(item.targetRatio * 100, (item.modelArea / data.LSF.totalArea) * 100),
  });

  const allLsfTableData = lsfSortedData.map((item) => getLsfTableData(item));

  const getSplitLsfTAbleData = () => {
    const lsfLists = [[], []];
    lsfCategoryTypes.forEach((type, index) => {
      lsfSortedData.forEach((item) => {
        if (item.subCategory === type) {
          lsfLists[index].push(item);
        }
        if (!item.subCategory && index === 0) { // for old "other" rooms that dont have "room_key" in the REQ file
          lsfLists[0].push(item);
        }
      });
    });

    const totals = lsfLists.map((list) => {
      const totalObject = {
        key: 'SUB_TOTAL',
        name: T.translate(list[0].subCategory),
        color: null,
        subCategory: list[0].subCategory,
        filterType: FILTER_TYPES.ROOM,
        targetSizeRange: '',
        targetRatio: lodashSum(list.map((item) => item.targetRatio)),
        targetRooms: lodashSum(list.map((item) => item.targetRooms)),
        modelRatio: lodashSum(list.map((item) => item.modelRatio)),
        modelAverage: lodashSum(list.map((item) => item.modelAverage)),
        modelArea: lodashSum(list.map((item) => item.modelArea)),
        modelRooms: lodashSum(list.map((item) => item.modelRooms)),
        modelMembers: lodashSum(list.map((item) => item.modelMembers)),
      };
      return getLsfTableData(totalObject);
    });
    const lsfListsParsed = lsfLists.map((list) => list.map((item) => getLsfTableData(item)));

    return [...lsfListsParsed[0], totals[0], ...lsfListsParsed[1], totals[1]];
  };

  const lsfTableData = lsfCategoryTypes.length >= 2 ? getSplitLsfTAbleData() : allLsfTableData;

  const lsfTotalData = [
    {
      key: 'TOTAL',
      name: T.translate('OVERALL_CONTAINER_TOTAL'),
      targetSizeRange: '',
      targetRatio: numberWithComma(lsfSortedData.reduce((total, current) => ({ targetRatio: total.targetRatio + current.targetRatio })).targetRatio * 100, { fixed: 0 }),
      targetRooms: numberWithComma(lsfSortedData.reduce((total, current) => ({ targetRooms: total.targetRooms + current.targetRooms })).targetRooms),
      modelRatio: numberWithComma(lsfSortedData[0].modelArea === data.LSF.totalArea ? 100 : lsfSortedData.reduce((total, current) => (
        { modelRatio: (total.modelRatio || total.modelArea / data.LSF.totalArea) + current.modelArea / data.LSF.totalArea })).modelRatio * 100, { fixed: 0 }),
      modelArea: sqfToSqmText(data.LSF.totalArea, isImperial),
      modelAverage: '',
      modelRooms: numberWithComma(data.LSF.totalRooms),
      modelMembers: numberWithComma(data.LSF.totalMembers),
    },
  ];

  const lsfChartData = lsfSortedData.map((item) => ({
    label: T.translate(item.name),
    value: Number(numberWithComma((item.modelArea * 100) / data.LSF.totalArea)),
  }));

  const preparePsfRoom = (item) => {
    let targetRatio;
    switch (item.targetRatioType) {
      case 'Members':
        targetRatio = Math.round(data.LSF.totalMembers / item.targetRatio);
        break;
      case 'Offices':
        targetRatio = Math.round(data.LSF.totalRooms / item.targetRatio);
        break;
      case 'Rooms':
        targetRatio = item.targetRooms;
        break;
      default:
        targetRatio = item.targetRooms;
        break;
    }

    return (
      {
        key: uuidv4(),
        name: T.translate(item.name),
        category: 'PSF',
        color: item.color,
        officeKey: item.name,
        filterType: FILTER_TYPES.ROOM,
        targetSeats: numberWithComma(parseInt(item.targetSeats * targetRatio, 10), { fixed: 0, suffix: '' }),
        targetRoomSize: roomSizeToSQM(item.targetRoomSize, isImperial),
        targetRatio,
        modelRooms: numberWithComma(item.modelRooms),
        modelSeats: numberWithComma(item.modelSeats),
        modelArea: sqfToSqmText(item.modelArea, isImperial),
        tileAmount: getTileAmount(item.modelArea, item.modelRooms),
      }
    );
  };

  const allPsfSortedData = prepareRoomList([...data.PSFA.sections, ...data.PSFB.sections], data.PSFA.totalArea, roomRequirements, 'PSF', theme, testFitStandard);
  const psfASortedData = [...allPsfSortedData.filter((room) => room.subCategory === 'PSFA'), ...allPsfSortedData.filter((room) => !room.subCategory)];
  const psfBSortedData = allPsfSortedData.filter((room) => room.subCategory === 'PSFB');

  const psfATableData = psfASortedData.map((room) => preparePsfRoom(room));
  const psfBTableData = psfBSortedData.map((room) => preparePsfRoom(room));

  const psfEmptyTotalRows = {
    targetSeats: '',
    targetRoomSize: '',
    modelRooms: '',
    modelSeats: '',
  };

  const psfATotalData = [
    {
      key: 'TOTAL',
      name: T.translate('OVERALL_CONTAINER_TOTAL'),
      category: 'PSF',
      ...psfEmptyTotalRows,
      targetRatio: lodashSum(psfATableData.map((room) => Number(room.targetRatio))),
      targetSeats: lodashSum(psfATableData.map((room) => Number(room.targetSeats))),
      modelRooms: lodashSum(psfASortedData.map((room) => room.modelRooms)),
      modelSeats: lodashSum(psfASortedData.map((room) => room.modelSeats)),
      modelArea: sqfToSqmText(lodashSum(psfASortedData.map((room) => room.modelArea)), isImperial),
    },
    { key: 'SEATS_PER_MEMBER',
      name: T.translate('OVERALL_CONTAINER_PSF_SEATS_PER_MEMBER'),
      ...psfEmptyTotalRows,
      modelArea: numberWithComma(data.LSF.totalMembers / lodashSum(psfASortedData.map((room) => room.modelSeats)), { fixed: 1, suffix: '' }),
    },
    {
      key: 'SEATS_PER_OFFICE',
      name: T.translate('OVERALL_CONTAINER_PSF_SEATS_PER_OFFICE'),
      ...psfEmptyTotalRows,
      modelArea: numberWithComma(data.LSF.totalRooms / lodashSum(psfASortedData.map((room) => room.modelSeats)), { fixed: 1, suffix: '' }),
    },
  ];

  const psfBTotalData = lodashIsEmpty(psfBSortedData) ? [] : [
    {
      key: 'TOTAL',
      name: T.translate('OVERALL_CONTAINER_TOTAL'),
      ...psfEmptyTotalRows,
      targetRatio: lodashSum(psfBTableData.map((room) => Number(room.targetRatio))),
      targetSeats: lodashSum(psfBTableData.map((room) => Number(room.targetSeats))),
      modelRooms: lodashSum(psfBSortedData.map((room) => room.modelRooms)),
      modelSeats: lodashSum(psfBSortedData.map((room) => room.modelSeats)),
      modelArea: sqfToSqmText(lodashSum(psfBSortedData.map((room) => room.modelArea)), isImperial),
    },
  ];

  const asfSortedData = prepareRoomList(data.ASF.sections, data.ASF.totalArea, roomRequirements, 'ASF', theme, testFitStandard);

  const asfTableData = asfSortedData.map((item) => ({
    key: uuidv4(),
    category: 'ASF',
    name: T.translate(item.name),
    color: item.color,
    officeKey: item.name,
    filterType: FILTER_TYPES.ROOM,
    targetArea: sqfToSqmText(item.targetArea, isImperial),
    tileAmount: getTileAmount(item.modelArea, item.modelRooms),
    modelArea: sqfToSqmText(item.modelArea, isImperial),
    modelRatioStatus: modelRatioStatus(item.targetArea, item.modelArea),
  }));

  const asfTotalData = [
    { key: 'TOTAL', name: T.translate('OVERALL_CONTAINER_TOTAL'), targetArea: sqfToSqmText(lodashSum(asfTableData.map((e) => Number(e.targetArea))), isImperial), modelArea: sqfToSqmText(data.ASF.totalArea, isImperial) },
    {
      key: '02',
      name: T.translate('OVERALL_CONTAINER_ASF_PER_MEMBER'),
      targetArea: '',
      modelArea: numberWithComma((isImperial ? data.ASF.totalArea : sqfToSqm(data.ASF.totalArea)) / Number(data.LSF.totalMembers), { fixed: isImperial ? 1 : 2, suffix: '' }),
    },
  ];

  const fsfSortedData = prepareRoomList(data.FSF.sections, data.FSF.totalArea, roomRequirements, 'FSF', theme, testFitStandard);
  const fsfTableData = fsfSortedData.map((item) => ({
    key: uuidv4(),
    category: 'FSF',
    name: T.translate(item.name),
    color: item.color,
    officeKey: item.name,
    filterType: FILTER_TYPES.ROOM,
    targetRooms: numberWithComma(item.targetRooms),
    targetArea: sqfToSqmText(item.targetArea, isImperial),
    modelRooms: numberWithComma(item.modelRooms),
    modelArea: sqfToSqmText(item.modelArea, isImperial),
    tileAmount: getTileAmount(item.modelArea, item.modelRooms),
  }));

  const fsfTotalData = [
    { key: 'TOTAL',
      name: T.translate('OVERALL_CONTAINER_TOTAL'),
      targetRooms: lodashSum(fsfTableData.map((e) => Number(e.targetRooms))),
      targetArea: sqfToSqmText(lodashSum(fsfTableData.map((e) => Number(e.targetArea))), isImperial),
      modelRooms: data.FSF.totalRooms,
      modelArea: sqfToSqmText(data.FSF.totalArea, isImperial) },
  ];

  const bathroomsTableData = [
    { key: '01', name: T.translate('OVERALL_CONTAINER_EXISTING_M_BATHROOMS'), noOfWaterCloset: data.Bathrooms.existingMaleBathroomWaterClosets },
    { key: '02', name: T.translate('OVERALL_CONTAINER_EXISTING_F_BATHROOMS'), noOfWaterCloset: data.Bathrooms.existingFemaleBathroomWaterClosets },
    { key: '03', name: T.translate('OVERALL_CONTAINER_REQUIRED_M_BATHROOMS'), noOfWaterCloset: data.Bathrooms.additionalMaleBathroomWaterClosets },
    { key: '04', name: T.translate('OVERALL_CONTAINER_REQUIRED_F_BATHROOMS'), noOfWaterCloset: data.Bathrooms.additionalFemaleBathroomWaterClosets },
  ];

  const getAllRooms = () => {
    const floors = lodashGet(data, 'multiBoundaryFile.floors', []);
    return lodashFlatten(floors.map((floor) => floor.rooms));
  };

  const allRoomsWithDepartments = getAllRooms().filter((room) => room.department_id);

  const allRooms = [
    ...lsfTableData,
    ...psfATableData,
    ...psfBTableData,
    ...asfTableData,
    ...fsfTableData,
  ];
  const categories = ['LSF', 'PSF', 'ASF', 'FSF'];

  const colorIndex = (sortedRoomObject) => {
    const index = {
      LSF: {},
      PSF: {},
      ASF: {},
      FSF: {},
    };

    /* eslint-disable no-return-assign */
    sortedRoomObject.LSF.forEach((type) => index.LSF[type.name] = type.color);
    sortedRoomObject.PSFA.forEach((type) => index.PSF[type.name] = type.color);
    sortedRoomObject.PSFB.forEach((type) => index.PSF[type.name] = type.color);
    sortedRoomObject.ASF.forEach((type) => index.ASF[type.name] = type.color);
    sortedRoomObject.FSF.forEach((type) => index.FSF[type.name] = type.color);
    /* eslint-enable no-return-assign */

    return index;
  };

  const departments = lodashGet(data, 'multiBoundaryFile.departments', []).map((department) => {
    const roomsInDepartment = allRoomsWithDepartments.filter((room) => room.department_id === department.id);
    const isRoomRequirementsArray = Array.isArray(roomRequirements);

    let numberOfMembers = 0;
    roomsInDepartment.forEach((room) => {
      if (isRoomRequirementsArray) {
        numberOfMembers += lodashGet(roomRequirements.find((roomReq) => roomReq[1] === room.type), '[9]', 0);
      } else {
        numberOfMembers += lodashGet(roomRequirements.find((roomReq) => roomReq.room_type === room.type), 'seats_per_room', 0);
      }
    });

    const roomTypes = {};
    // eslint-disable-next-line no-return-assign
    roomsInDepartment.forEach((room) => roomTypes[room.type] = (roomTypes[room.type] || 0) + 1);

    const roomsInFloor = [];
    Object.keys(roomTypes).forEach((roomKey) => {
      const currentRoom = allRooms.find((room) => room.name === roomKey);
      if (currentRoom) {
        roomsInFloor.push({ ...currentRoom, modelRooms: roomTypes[roomKey], targetRooms: null });
      }
    });

    const roomsInFloorByCategory = {};
    // eslint-disable-next-line no-return-assign
    categories.forEach((category) => roomsInFloorByCategory[category] = roomsInFloor.filter((room) => room.category === category));

    return ({
      key: department.id,
      departmentsKey: department.id,
      filterType: FILTER_TYPES.DEPARTMENT,
      name: department.name,
      color: department.color,
      modelArea: lodashSum(roomsInDepartment.map((room) => (getPolygonArea(room.points)) / 144)),
      modelRooms: roomsInDepartment.length,
      modelMembers: Math.floor(numberOfMembers),
      rooms: roomsInFloorByCategory,
    });
  });

  const getFloorData = () => {
    const { multiBoundaryFile } = data;
    if (multiBoundaryFile && multiBoundaryFile.version >= 6) {
      return multiBoundaryFile.floors.map((floor) => {
        const roomTypes = {};
        // eslint-disable-next-line no-return-assign
        floor.rooms.forEach((room) => roomTypes[room.type] = (roomTypes[room.type] || 0) + 1);

        const roomsInFloor = [];
        Object.keys(roomTypes).forEach((roomKey) => {
          const currentRoom = allRooms.find((room) => room.officeKey === roomKey);
          if (currentRoom) {
            roomsInFloor.push({ ...currentRoom, modelRooms: roomTypes[roomKey] });
          }
        });

        const roomsInFloorByCategory = {};
        // eslint-disable-next-line no-return-assign
        categories.forEach((category) => roomsInFloorByCategory[category] = roomsInFloor.filter((room) => room.category === category));
        return roomsInFloorByCategory;
      });
    }
    const roomsInFloorByCategory = {};
    // eslint-disable-next-line no-return-assign
    categories.forEach((category) => roomsInFloorByCategory[category] = allRooms.filter((room) => room.category === category));
    return [roomsInFloorByCategory];
  };

  const colors = colorIndex({ LSF: lsfSortedData, PSFA: psfASortedData, PSFB: psfBSortedData, ASF: asfSortedData, FSF: fsfSortedData });

  return {
    RSF: { tableData: [...rsfTableData, ...rsfTotalData], totalData: rsfTotalData, chartData: rsfChartData },
    LSF: { tableData: [...lsfTableData, ...lsfTotalData], totalData: lsfTotalData, chartData: lsfChartData, chartDataColors: getChartColors(lsfSortedData) },
    PSF_A: { tableData: [...psfATableData, ...psfATotalData], totalData: psfATotalData, chartDataColors: getChartColors(psfASortedData) },
    PSF_B: { tableData: [...psfBTableData, ...psfBTotalData], totalData: psfBTotalData, chartDataColors: getChartColors(psfBSortedData) },
    ASF: { tableData: [...asfTableData, ...asfTotalData], totalData: asfTotalData, chartDataColors: getChartColors(asfSortedData) },
    FSF: { tableData: [...fsfTableData, ...fsfTotalData], totalData: fsfTotalData, chartDataColors: getChartColors(fsfSortedData) },
    departments,
    floors: getFloorData(),
    bathrooms: { tableData: bathroomsTableData },
    forgeURN: data.forgeURN,
    pdfURL: data.pdfURL,
    threeDURN: data.threeDURN,
    renders: data.renders,
    regulationURN: data.regulationURN,
    noiseURN: data.noiseURN,
    circulationURN: data.circulationURN,
    flexibilityURN: data.flexibilityURN,
    multiBoundaryFile: floorPlanParse(data, colors, theme, testFitStandard),
    pdfScreenshots: data.pdfScreenshots,
    waitingFor: data.waitingFor,
    sliceAmount: data.sliceAmount,
    originalData: data,
    threeDW: data['3DW'],
    developmentAppraisalData,
  };
};
