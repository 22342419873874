import lodashFlatten from 'lodash/flatten';
import lodashKeys from 'lodash/keys';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import standardsIcons from '../styles/static/icons/feasibilityIcons/standards';
import typologiesIcons from '../styles/static/icons/feasibilityIcons/typologies';

export const MODEL_ANALYSIS_TYPES = {
  AREA_TYPE: 'AREA_TYPE',
  SUN_ANALYSIS: 'SUN_ANALYSIS',
  VIEWS_ANALYSIS: 'VIEWS_ANALYSIS',
  WIND_ANALYSIS: 'WIND_ANALYSIS',
  VSC_ANALYSIS: 'VSC_ANALYSIS',
};

export const BUILDING_INFO_TYPES = {
  AREA: 'AREA',
  PERCENTAGE: 'PERCENTAGE',
  ROW_PERCENTAGE: 'ROW_PERCENTAGE',
  LIST: 'LIST',
  UNIT: 'UNIT',
  SCORE: 'SCORE',
  ENERGY: 'ENERGY',
  EMPTY: 'EMPTY',
};

export const GROUND_FLOOR_TYPES = {
  OPEN_GROUND_FLOOR: 'open_ground_floor',
  RETAIL: 'retail',
  NA: 'na',
};

export const BUILDING_INFO_SUB_TYPES = {
  ENERGY: {
    AMOUNT: 'AMOUNT',
    AMOUNT_RAW: 'AMOUNT_RAW',
    AMOUNT_PER_AREA: 'AMOUNT_PER_AREA',
    AMOUNT_PER_AREA_PER_YEAR: 'AMOUNT_PER_AREA_PER_YEAR',
    AMOUNT_TIMES_AREA: 'AMOUNT_TIMES_AREA',
  },
};

export const GENERATE_OPTIONS_TYPES = {
  GENERATE_ARCHITECTURE: 'architecture',
  GENERATE_HVAC_MODEL: 'hvacModel',
  GENERATE_PLBG_MODEL: 'plbgModel',
  GENERATE_STRUCTURAL_MODEL: 'structuralModel',
  GENERATE_SHEETS: 'sheets',
};

export const STANDARDS = {
  PREFAB: {
    key: 'PREFAB',
    image: standardsIcons.prefabStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_PREFAB',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_PREFAB_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/prefabStandard.png',
    requiredUserSettingsKey: undefined,
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE],
    units: {
      STUDIO: { key: 'STUDIO', value: 'STUDIO', size: 47, sort: 0, occupancies: 1, unitCost: 6025 },
      ONE_BED: { key: 'ONE_BED', value: 'ONE_BED', size: 52, sort: 1, occupancies: 2, unitCost: 5950 },
      TWO_BED: { key: 'TWO_BED', value: 'TWO_BED', size: 71, sort: 2, occupancies: 3, unitCost: 6460 },
      THREE_BED: { key: 'THREE_BED', value: 'THREE_BED', size: 81, sort: 3, occupancies: 4, unitCost: 6500 },
      FOUR_BED: { key: 'FOUR_BED', value: 'FOUR_BED', size: 92, sort: 4, occupancies: 5, unitCost: 6380 },
      FOUR_BED_B: { key: 'FOUR_BED_B', value: 'FOUR_BED_B', size: 100, sort: 5, occupancies: 6, unitCost: 6260 },
    },
    communalSpaces: {
      LOBBY_LOUNGE: { key: 'LOBBY_LOUNGE', value: 'LOBBY_LOUNGE', sort: 10, type: 'NIA' },
      MAILROOM: { key: 'MAILROOM', value: 'MAILROOM', sort: 11, type: 'GIA' },
      TENNANT_CLUB: { key: 'TENNANT_CLUB', value: 'TENNANT_CLUB', sort: 12, type: 'NIA' },
      FITNESS: { key: 'FITNESS', value: 'FITNESS', sort: 13, type: 'NIA' },
      LAUNDRY: { key: 'LAUNDRY', value: 'LAUNDRY', sort: 14, type: 'GIA' },
      GAME_ROOM: { key: 'GAME_ROOM', value: 'GAME_ROOM', sort: 15, type: 'NIA' },
      LIBRARY: { key: 'LIBRARY', value: 'LIBRARY', sort: 16, type: 'NIA' },
      COMPUTER_ROOM: { key: 'COMPUTER_ROOM', value: 'COMPUTER_ROOM', sort: 17, type: 'NIA' },
      COMMON_GOOD: { key: 'COMMON_GOOD', value: 'COMMON_GOOD', sort: 18, type: 'GIA' },
      PETS_SHOWER_ROOM: { key: 'PETS_SHOWER_ROOM', value: 'PETS_SHOWER_ROOM', sort: 19, type: 'GIA' },
      COWORKING_OFFICE: { key: 'COWORKING_OFFICE', value: 'COWORKING_OFFICE', sort: 20, type: 'NIA' },
    },
    extraCommunalSpaces: {
      OTHER: { key: 'OTHER', value: 'FEASIBILITY_COMMUNAL_SPACE_FORM_OTHER', type: 'NIA' },
    },
    buildingTypologies: {
      MEGA_BLOCK: { key: 'MEGA_BLOCK', image: typologiesIcons.megablockIcon, value: 'MEGA_BLOCK', description: 'FEASIBILITY_BUILDING_FORM_MEGA_BLOCK_DESCRIPTION' },
      L_SHAPE: { key: 'L_SHAPE', image: typologiesIcons.lShapeIcon, value: 'L_SHAPE', description: 'FEASIBILITY_BUILDING_FORM_L_SHAPE_DESCRIPTION' },
      NUM_OF_MASSES: { key: 'NUM_OF_MASSES', image: typologiesIcons.mixedTypologiesIcon, value: 'NUM_OF_MASSES', description: 'FEASIBILITY_BUILDING_FORM_NUM_OF_MASSES_DESCRIPTION' },
      U_SHAPE: { key: 'U_SHAPE', image: typologiesIcons.uShapeIcon, value: 'U_SHAPE', description: 'FEASIBILITY_BUILDING_FORM_U_COURTYARD_DESCRIPTION' },
      COURTYARD: { key: 'COURTYARD', image: typologiesIcons.courtyardIcon, value: 'COURTYARD', description: 'FEASIBILITY_BUILDING_FORM_COURTYARD_DESCRIPTION' },
      CAMPUS: { key: 'CAMPUS', image: typologiesIcons.campusIcon, value: 'CAMPUS', description: 'FEASIBILITY_BUILDING_FORM_CAMPUS_DESCRIPTION' },
      WRAP: { key: 'WRAP', image: typologiesIcons.wrapIcon, value: 'WRAP', description: 'FEASIBILITY_BUILDING_FORM_WRAP_DESCRIPTION' },
      // FINGER_BLOCK: { key: 'FINGER_BLOCK', image: buildingTypologiesImages.yShape, value: 'FINGER_BLOCK', description: 'FEASIBILITY_BUILDING_FORM_FINGER_BLOCK_DESCRIPTION', disabled: true },
    },
  },
  DORMITORIES: {
    key: 'DORMITORIES',
    image: standardsIcons.dormitoriesStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_DORMITORIES',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_DORMITORIES_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/dormitoriesStandard.png',
    requiredUserSettingsKey: undefined,
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE],
    units: {
      BRONZE: { key: 'BRONZE', value: 'BRONZE', size: 15, sort: 0, occupancies: 1, unitCost: 2500 },
      SILVER: { key: 'SILVER', value: 'SILVER', size: 16, sort: 1, occupancies: 1, unitCost: 2800 },
      GOLD: { key: 'GOLD', value: 'GOLD', size: 23, sort: 2, occupancies: 1, unitCost: 3000 },
      ACCESSIBLE: { key: 'ACCESSIBLE', value: 'ACCESSIBLE', size: 23, sort: 3, occupancies: 1, unitCost: 2600 },
    },
    communalSpaces: {
      SHARED_KITCHEN: { key: 'SHARED_KITCHEN', value: 'SHARED_KITCHEN', sort: 21, type: 'NIA' },
      SHARED_AMENITY: { key: 'SHARED_AMENITY', value: 'SHARED_AMENITY', sort: 22, type: 'NIA' },
    },
  },
  UK: {
    key: 'UK',
    image: standardsIcons.ukStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_UK',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_UK_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/ukStandard.png',
    requiredUserSettingsKey: undefined,
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE],
    units: {
      WDC_STUDIO: { key: 'WDC_STUDIO', value: 'WDC_STUDIO', size: 45.2, sort: 0, occupancies: 1, unitCost: 2600 },
      WDC_1B2P: { key: 'WDC_1B2P', value: 'WDC_1B2P', size: 56.8, sort: 1, occupancies: 2, unitCost: 6000 },
      WDC_2B3P: { key: 'WDC_2B3P', value: 'WDC_2B3P', size: 71.0, sort: 2, occupancies: 3, unitCost: 5950 },
      WDC_2B4P: { key: 'WDC_2B4P', value: 'WDC_2B4P', size: 80.5, sort: 3, occupancies: 4, unitCost: 6450 },
      WDC_3B4P: { key: 'WDC_3B4P', value: 'WDC_3B4P', size: 85.2, sort: 4, occupancies: 4, unitCost: 6450 },
      WDC_3B5P: { key: 'WDC_3B5P', value: 'WDC_3B5P', size: 99.4, sort: 5, occupancies: 5, unitCost: 6450 },
      WDC_3B6P: { key: 'WDC_3B6P', value: 'WDC_3B6P', size: 108.9, sort: 6, occupancies: 6, unitCost: 6500 },
    },
    formSettings: {
      [FEASIBILITY_TARGET_FORM.FLEXIBLE]: { visible: true },
    },
    communalSpaces: {},
  },
  RYAN: {
    key: 'RYAN',
    image: standardsIcons.ryanStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_RYAN',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_RYAN_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/ryanStandard.png',
    requiredUserSettingsKey: 'ryanStandard',
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE],
    units: { // TODO - add real unitCost
      RYAN_STUDIO: { key: 'RYAN_STUDIO', value: 'RYAN_STUDIO', size: 61, sort: 0, occupancies: 1, unitCost: 1000 },
      RYAN_ONE_BED: { key: 'RYAN_ONE_BED', value: 'RYAN_ONE_BED', size: 71, sort: 1, occupancies: 1, unitCost: 1000 },
      RYAN_ONE_BED_B: { key: 'RYAN_ONE_BED_B', value: 'RYAN_ONE_BED_B', size: 79, sort: 2, occupancies: 2, unitCost: 1000 },
      RYAN_TWO_BED: { key: 'RYAN_TWO_BED', value: 'RYAN_TWO_BED', size: 108, sort: 3, occupancies: 3, unitCost: 1000 },
      RYAN_THREE_BED: { key: 'RYAN_THREE_BED', value: 'RYAN_THREE_BED', size: 123, sort: 4, occupancies: 4, unitCost: 1000 },
    },
    communalSpaces: {},
  },
  DEMO: {
    key: 'DEMO',
    image: standardsIcons.demoStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_DEMO',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_DEMO_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/demoStandardIcon.png',
    requiredUserSettingsKey: 'demoStandard',
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE],
    units: { // TODO - add real unitCost
      DEMO_ONE_BED: { key: 'DEMO_ONE_BED', value: 'DEMO_ONE_BED', size: 92, sort: 0, occupancies: 10, unitCost: 1000 },
      DEMO_TWO_BED: { key: 'DEMO_TWO_BED', value: 'DEMO_TWO_BED', size: 92, sort: 1, occupancies: 10, unitCost: 1000 },
      DEMO_THREE_BED: { key: 'DEMO_THREE_BED', value: 'DEMO_THREE_BED', size: 92, sort: 2, occupancies: 10, unitCost: 1000 },
      DEMO_FOUR_BED: { key: 'DEMO_FOUR_BED', value: 'DEMO_FOUR_BED', size: 92, sort: 3, occupancies: 10, unitCost: 1000 },
      DEMO_FIVE_BED: { key: 'DEMO_FIVE_BED', value: 'DEMO_FIVE_BED', size: 92, sort: 4, occupancies: 10, unitCost: 1000 },
      DEMO_SIX_BED: { key: 'DEMO_SIX_BED', value: 'DEMO_SIX_BED', size: 92, sort: 5, occupancies: 10, unitCost: 1000 },
      DEMO_HANAYA: { key: 'DEMO_HANAYA', value: 'DEMO_HANAYA', size: 92, sort: 6, occupancies: 10, unitCost: 1000 },
      DEMO_HANUT: { key: 'DEMO_HANUT', value: 'DEMO_HANUT', size: 92, sort: 7, occupancies: 10, unitCost: 1000 },
      DEMO_PUBLIC: { key: 'DEMO_PUBLIC', value: 'DEMO_PUBLIC', size: 92, sort: 8, occupancies: 10, unitCost: 1000 },
      DEMO_CLASSROOM: { key: 'DEMO_CLASSROOM', value: 'DEMO_CLASSROOM', size: 92, sort: 9, occupancies: 10, unitCost: 1000 },
      DEMO_CLASSROOM_SMALL: { key: 'DEMO_CLASSROOM_SMALL', value: 'DEMO_CLASSROOM_SMALL', size: 92, sort: 10, occupancies: 10, unitCost: 1000 },
      DEMO_CLASSROOM_MEDIUM: { key: 'DEMO_CLASSROOM_MEDIUM', value: 'DEMO_CLASSROOM_MEDIUM', size: 92, sort: 11, occupancies: 10, unitCost: 1000 },
      DEMO_CLASSROOM_LARGE: { key: 'DEMO_CLASSROOM_LARGE', value: 'DEMO_CLASSROOM_LARGE', size: 92, sort: 12, occupancies: 10, unitCost: 1000 },
      DEMO_SE_CLASSROOM: { key: 'DEMO_SE_CLASSROOM', value: 'DEMO_SE_CLASSROOM', size: 92, sort: 13, occupancies: 10, unitCost: 1000 },
      DEMO_PERSONAL_TEACHING: { key: 'DEMO_PERSONAL_TEACHING', value: 'DEMO_PERSONAL_TEACHING', size: 92, sort: 14, occupancies: 10, unitCost: 1000 },
      DEMO_SCIENCE_LAB: { key: 'DEMO_SCIENCE_LAB', value: 'DEMO_SCIENCE_LAB', size: 92, sort: 15, occupancies: 10, unitCost: 1000 },
      DEMO_COMPUTER_ROOM: { key: 'DEMO_COMPUTER_ROOM', value: 'DEMO_COMPUTER_ROOM', size: 92, sort: 16, occupancies: 10, unitCost: 1000 },
      DEMO_CLASSROOM_STUDIO: { key: 'DEMO_CLASSROOM_STUDIO', value: 'DEMO_CLASSROOM_STUDIO', size: 92, sort: 17, occupancies: 10, unitCost: 1000 },
      DEMO_ENTRY_HALL: { key: 'DEMO_ENTRY_HALL', value: 'DEMO_ENTRY_HALL', size: 92, sort: 18, occupancies: 10, unitCost: 1000 },
      DEMO_STUDY_AREA: { key: 'DEMO_STUDY_AREA', value: 'DEMO_STUDY_AREA', size: 92, sort: 19, occupancies: 10, unitCost: 1000 },
      DEMO_SMALL_STUDY: { key: 'DEMO_SMALL_STUDY', value: 'DEMO_SMALL_STUDY', size: 92, sort: 20, occupancies: 10, unitCost: 1000 },
      DEMO_LARGE_STUDY: { key: 'DEMO_LARGE_STUDY', value: 'DEMO_LARGE_STUDY', size: 92, sort: 21, occupancies: 10, unitCost: 1000 },
      DEMO_AUDITORIUM: { key: 'DEMO_AUDITORIUM', value: 'DEMO_AUDITORIUM', size: 92, sort: 22, occupancies: 10, unitCost: 1000 },
      DEMO_ACTIVITY_STUDIO: { key: 'DEMO_ACTIVITY_STUDIO', value: 'DEMO_ACTIVITY_STUDIO', size: 92, sort: 23, occupancies: 10, unitCost: 1000 },
      DEMO_LOUNGE: { key: 'DEMO_LOUNGE', value: 'DEMO_LOUNGE', size: 92, sort: 24, occupancies: 10, unitCost: 1000 },
      DEMO_LIBRARY: { key: 'DEMO_LIBRARY', value: 'DEMO_LIBRARY', size: 92, sort: 25, occupancies: 10, unitCost: 1000 },
      DEMO_CAFETERIA: { key: 'DEMO_CAFETERIA', value: 'DEMO_CAFETERIA', size: 92, sort: 26, occupancies: 10, unitCost: 1000 },
      DEMO_SPORT_HALL: { key: 'DEMO_SPORT_HALL', value: 'DEMO_SPORT_HALL', size: 92, sort: 27, occupancies: 10, unitCost: 1000 },
      DEMO_ADMINISTRATION: { key: 'DEMO_ADMINISTRATION', value: 'DEMO_ADMINISTRATION', size: 92, sort: 28, occupancies: 10, unitCost: 1000 },
      DEMO_DIRECTOR: { key: 'DEMO_DIRECTOR', value: 'DEMO_DIRECTOR', size: 92, sort: 29, occupancies: 10, unitCost: 1000 },
      DEMO_MEETING_ROOM: { key: 'DEMO_MEETING_ROOM', value: 'DEMO_MEETING_ROOM', size: 92, sort: 30, occupancies: 10, unitCost: 1000 },
      DEMO_STUDENT_COUNCIL: { key: 'DEMO_STUDENT_COUNCIL', value: 'DEMO_STUDENT_COUNCIL', size: 92, sort: 31, occupancies: 10, unitCost: 1000 },
      DEMO_TEACHER_OFFICE: { key: 'DEMO_TEACHER_OFFICE', value: 'DEMO_TEACHER_OFFICE', size: 92, sort: 32, occupancies: 10, unitCost: 1000 },
      DEMO_OFFICE: { key: 'DEMO_OFFICE', value: 'DEMO_OFFICE', size: 92, sort: 33, occupancies: 10, unitCost: 1000 },
      DEMO_SHARED_OFFICE: { key: 'DEMO_SHARED_OFFICE', value: 'DEMO_SHARED_OFFICE', size: 92, sort: 34, occupancies: 10, unitCost: 1000 },
      DEMO_TEACHERS_LOUNGE: { key: 'DEMO_TEACHERS_LOUNGE', value: 'DEMO_TEACHERS_LOUNGE', size: 92, sort: 35, occupancies: 10, unitCost: 1000 },
      DEMO_GENERAL_OFFICE: { key: 'DEMO_GENERAL_OFFICE', value: 'DEMO_GENERAL_OFFICE', size: 92, sort: 36, occupancies: 10, unitCost: 1000 },
      DEMO_ARCHIVES: { key: 'DEMO_ARCHIVES', value: 'DEMO_ARCHIVES', size: 92, sort: 37, occupancies: 10, unitCost: 1000 },
      DEMO_PREP_ROOM: { key: 'DEMO_PREP_ROOM', value: 'DEMO_PREP_ROOM', size: 92, sort: 38, occupancies: 10, unitCost: 1000 },
      DEMO_GUARD: { key: 'DEMO_GUARD', value: 'DEMO_GUARD', size: 92, sort: 39, occupancies: 10, unitCost: 1000 },
      DEMO_SERVICE: { key: 'DEMO_SERVICE', value: 'DEMO_SERVICE', size: 92, sort: 40, occupancies: 10, unitCost: 1000 },
      DEMO_STORAGE: { key: 'DEMO_STORAGE', value: 'DEMO_STORAGE', size: 92, sort: 41, occupancies: 10, unitCost: 1000 },
      DEMO_RESTROOM: { key: 'DEMO_RESTROOM', value: 'DEMO_RESTROOM', size: 92, sort: 42, occupancies: 10, unitCost: 1000 },
      DEMO_LOCKERS_ROOM: { key: 'DEMO_LOCKERS_ROOM', value: 'DEMO_LOCKERS_ROOM', size: 92, sort: 43, occupancies: 10, unitCost: 1000 },
      DEMO_IT: { key: 'DEMO_IT', value: 'DEMO_IT', size: 92, sort: 44, occupancies: 10, unitCost: 1000 },
      DEMO_KITCHEN: { key: 'DEMO_KITCHEN', value: 'DEMO_KITCHEN', size: 92, sort: 45, occupancies: 10, unitCost: 1000 },
    },
    communalSpaces: {},
  },
  IL: {
    key: 'IL',
    image: standardsIcons.ilStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_IL',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_IL_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/ilStandard.png',
    requiredUserSettingsKey: 'ilStandard',
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_PRIMARY_AREA',
    constructionDocumentsOptions: [
      GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE,
      GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_SHEETS,
    ],
    units: { // TODO - add real unitCost
      IL_ONE_BED: { key: 'IL_ONE_BED', value: 'IL_ONE_BED', size: [1, 1], range: [1, 1], sort: 0, occupancies: 2, unitCost: 1000, isNotSelectable: true },
      IL_TWO_BED: { key: 'IL_TWO_BED', value: 'IL_TWO_BED', size: [60, 80], range: [64, 96], sort: 0, occupancies: 3, unitCost: 1000 },
      IL_THREE_BED: { key: 'IL_THREE_BED', value: 'IL_THREE_BED', size: [80, 100], range: [79, 123], sort: 1, occupancies: 4, unitCost: 1000 },
      IL_FOUR_BED: { key: 'IL_FOUR_BED', value: 'IL_FOUR_BED', size: [95, 120], range: [92, 148], sort: 2, occupancies: 5, unitCost: 1000 },
      IL_FIVE_BED: { key: 'IL_FIVE_BED', value: 'IL_FIVE_BED', size: [115, 150], range: [111, 176], sort: 3, occupancies: 6, unitCost: 1000 },
      IL_SIX_BED: { key: 'IL_SIX_BED', value: 'IL_SIX_BED', size: [1, 1], range: [1, 1], sort: 4, occupancies: 7, unitCost: 1000, isNotSelectable: true },
      IL_HANAYA: { key: 'IL_HANAYA', value: 'IL_HANAYA', size: [1, 1], range: [1, 1], sort: 6, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      IL_HANUT: { key: 'IL_HANUT', value: 'IL_HANUT', size: [1, 1], range: [1, 1], sort: 6, occupancies: 0, unitCost: 1000, isNotSelectable: true },
    },
    formSettings: {
      [FEASIBILITY_TARGET_FORM.LOT_DATA]: {
        openGroundFloorOptions: [
          { key: GROUND_FLOOR_TYPES.NA, value: 'FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_NA' },
        ],
      },
    },
    formsToHide: ['DESIGN_PRIORITY', 'BUILDING_TYPOLOGIES', 'DESIGN_REQUIREMENTS'],
    formsToShow: ['DESIGN_STANDARD', 'MECHANICAL_ELECTRIC_PLUMBING'],
    communalSpaces: {},
    buildingTypologies: {
      TOWER: { key: 'TOWER', image: typologiesIcons.towerIcon, value: 'TOWER', description: 'FEASIBILITY_BUILDING_FORM_TOWER_DESCRIPTION' },
    },
  },
  WV1: {
    key: 'WV1',
    image: standardsIcons.demoStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_WV',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_WV_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/ryanStandard.png',
    requiredUserSettingsKey: 'wv1Standard',
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [
      GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE,
      GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_SHEETS,
    ],
    units: { // TODO - add real unitCost
      WV1_A1: { key: 'WV1_A1', value: 'WV1_A1', size: 42, sort: 0, occupancies: 2, unitCost: 1000 },
      WV1_B1: { key: 'WV1_B1', value: 'WV1_B1', size: 61, sort: 1, occupancies: 3, unitCost: 1000 },
      WV1_C1: { key: 'WV1_C1', value: 'WV1_C1', size: 78, sort: 2, occupancies: 4, unitCost: 1000 },
      // WV1_STAIRCASE_CORE: { key: 'WV1_STAIRCASE_CORE', value: 'WV1_STAIRCASE_CORE', size: 22, sort: 3, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      // WV1_2_ELEVATORS_CORE: { key: 'WV1_2_ELEVATORS_CORE', value: 'WV1_2_ELEVATORS_CORE', size: 38, sort: 4, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      // WV1_3_ELEVATORS_CORE: { key: 'WV1_3_ELEVATORS_CORE', value: 'WV1_3_ELEVATORS_CORE', size: 60, sort: 5, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      // WV1_4_ELEVATORS_CORE: { key: 'WV1_4_ELEVATORS_CORE', value: 'WV1_4_ELEVATORS_CORE', size: 121, sort: 6, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      // WV1_CORRIDOR: { key: 'WV1_CORRIDOR', value: 'WV1_CORRIDOR', size: 0, sort: 7, occupancies: 0, unitCost: 1000, isNotSelectable: true },
    },
    communalSpaces: {},
  },
  PBK: {
    key: 'PBK',
    image: standardsIcons.demoStandardIcon,
    value: 'FEASIBILITY_APARTMENT_STANDARD_FORM_PBK',
    description: 'FEASIBILITY_APARTMENT_STANDARD_FORM_PBK_DESCRIPTION',
    descriptionImage: 'https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/ryanStandard.png',
    requiredUserSettingsKey: 'pbkStandard',
    unitPercentageTooltipKey: 'PERCENTAGE_FROM_GIA',
    constructionDocumentsOptions: [
      GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE,
      GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL,
      GENERATE_OPTIONS_TYPES.GENERATE_SHEETS,
    ],
    units: { // TODO - add real unitCost
      PBK_COMMUN_HALL: { key: 'PBK_COMMUN_HALL', value: 'PBK_COMMUN_HALL', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_STUDY: { key: 'PBK_COMMUN_STUDY', value: 'PBK_COMMUN_STUDY', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_AUDITORIUM: { key: 'PBK_COMMUN_AUDITORIUM', value: 'PBK_COMMUN_AUDITORIUM', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_STUDIO: { key: 'PBK_COMMUN_STUDIO', value: 'PBK_COMMUN_STUDIO', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_LOUNGE: { key: 'PBK_COMMUN_LOUNGE', value: 'PBK_COMMUN_LOUNGE', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_LIBRARY: { key: 'PBK_COMMUN_LIBRARY', value: 'PBK_COMMUN_LIBRARY', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_CAFETERIA: { key: 'PBK_COMMUN_CAFETERIA', value: 'PBK_COMMUN_CAFETERIA', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMMUN_SPORT: { key: 'PBK_COMMUN_SPORT', value: 'PBK_COMMUN_SPORT', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_RR: { key: 'PBK_ADMIN_RR', value: 'PBK_ADMIN_RR', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_SEC: { key: 'PBK_ADMIN_SEC', value: 'PBK_ADMIN_SEC', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_PRINCIPAL: { key: 'PBK_ADMIN_PRINCIPAL', value: 'PBK_ADMIN_PRINCIPAL', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_MEETING: { key: 'PBK_ADMIN_MEETING', value: 'PBK_ADMIN_MEETING', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_LOUNGE: { key: 'PBK_ADMIN_LOUNGE', value: 'PBK_ADMIN_LOUNGE', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_SPEECH: { key: 'PBK_ADMIN_SPEECH', value: 'PBK_ADMIN_SPEECH', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_OFF: { key: 'PBK_ADMIN_OFF', value: 'PBK_ADMIN_OFF', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_STORAGE: { key: 'PBK_ADMIN_STORAGE', value: 'PBK_ADMIN_STORAGE', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_DIAG: { key: 'PBK_ADMIN_DIAG', value: 'PBK_ADMIN_DIAG', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_ADMIN_CLINIC: { key: 'PBK_ADMIN_CLINIC', value: 'PBK_ADMIN_CLINIC', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CIRCULATION_ELEV: { key: 'PBK_CIRCULATION_ELEV', value: 'PBK_CIRCULATION_ELEV', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CIRCULATION_STAIRS: { key: 'PBK_CIRCULATION_STAIRS', value: 'PBK_CIRCULATION_STAIRS', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CIRCULATION_HALLWAY: { key: 'PBK_CIRCULATION_HALLWAY', value: 'PBK_CIRCULATION_HALLWAY', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_SHAFT: { key: 'PBK_SUPPORT_SHAFT', value: 'PBK_SUPPORT_SHAFT', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_ARCHIVES: { key: 'PBK_SUPPORT_ARCHIVES', value: 'PBK_SUPPORT_ARCHIVES', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_PREP: { key: 'PBK_SUPPORT_PREP', value: 'PBK_SUPPORT_PREP', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_GUARD: { key: 'PBK_SUPPORT_GUARD', value: 'PBK_SUPPORT_GUARD', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_SERVICE: { key: 'PBK_SUPPORT_SERVICE', value: 'PBK_SUPPORT_SERVICE', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_STORAGE: { key: 'PBK_SUPPORT_STORAGE', value: 'PBK_SUPPORT_STORAGE', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_RR: { key: 'PBK_SUPPORT_RR', value: 'PBK_SUPPORT_RR', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SUPPORT_LOCKERS: { key: 'PBK_SUPPORT_LOCKERS', value: 'PBK_SUPPORT_LOCKERS', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CLASSROOM_PRE_K: { key: 'PBK_CLASSROOM_PRE_K', value: 'PBK_CLASSROOM_PRE_K', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CLASSROOM_K: { key: 'PBK_CLASSROOM_K', value: 'PBK_CLASSROOM_K', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CLASSROOM_MUSIC: { key: 'PBK_CLASSROOM_MUSIC', value: 'PBK_CLASSROOM_MUSIC', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CLASSROOM_1ST: { key: 'PBK_CLASSROOM_1ST', value: 'PBK_CLASSROOM_1ST', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CLASSROOM_2ND: { key: 'PBK_CLASSROOM_2ND', value: 'PBK_CLASSROOM_2ND', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_CLASSROOM_ART: { key: 'PBK_CLASSROOM_ART', value: 'PBK_CLASSROOM_ART', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_PERSONAL_TEACHING: { key: 'PBK_PERSONAL_TEACHING', value: 'PBK_PERSONAL_TEACHING', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_SCIENCE_LAB: { key: 'PBK_SCIENCE_LAB', value: 'PBK_SCIENCE_LAB', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
      PBK_COMPUTER_ROOM: { key: 'PBK_COMPUTER_ROOM', value: 'PBK_COMPUTER_ROOM', size: 30, sort: 0, occupancies: 0, unitCost: 1000, isNotSelectable: true },
    },
    communalSpaces: {},
  },
};

export const SKETCH_SETTINGS = {
  wv: {
    tubeWidth: 11,
    snappingAngle: 90,
    isDefaultSnappingAngle: true,
    isSingleLoadCorridor: false,
  },
  default: {
    tubeWidth: 8,
    snappingAngle: 15,
    isDefaultSnappingAngle: false,
  },
};

export const ALL_APARTMENT_TYPES_ARRAY = lodashFlatten(lodashKeys(STANDARDS)
  .map((standardKey) => lodashKeys(STANDARDS[standardKey].units)
    .map((unitKey) => ({ ...STANDARDS[standardKey].units[unitKey], type: STANDARDS[standardKey].key }))));

export const getApartmentsByUnitType = (unitType) => (unitType ? ALL_APARTMENT_TYPES_ARRAY.filter((unit) => unit.type === unitType) : ALL_APARTMENT_TYPES_ARRAY).filter((e) => !e.isNotSelectable);

export const ALL_COMMUNAL_SPACES_TYPES_ARRAY = lodashFlatten(lodashKeys(STANDARDS)
  .map((standardKey) => lodashKeys(STANDARDS[standardKey].communalSpaces)
    .map((unitKey) => ({ ...STANDARDS[standardKey].communalSpaces[unitKey], type: STANDARDS[standardKey].key }))));

export const APARTMENT_TYPES = ALL_APARTMENT_TYPES_ARRAY.map((item) => item.key);
export const COMMUNAL_SPACES_TYPES = ALL_COMMUNAL_SPACES_TYPES_ARRAY.map((item) => item.key);

const allSortItemsArray = [...ALL_APARTMENT_TYPES_ARRAY, ...ALL_COMMUNAL_SPACES_TYPES_ARRAY, { key: 'DUAL_ASPECT', sort: 22 }];
export const UNIT_SORT_LIST = allSortItemsArray.reduce((a, v) => ({ ...a, [v.key]: v.sort }), {});

const allSystemUnitKeyTypes = [...ALL_APARTMENT_TYPES_ARRAY, ...ALL_COMMUNAL_SPACES_TYPES_ARRAY].map((unit) => unit.key);
export const allUnitKeyTypes = [
  ...allSystemUnitKeyTypes,
  'OTHER_0', 'OTHER_1', 'OTHER_2', 'OTHER_3', 'OTHER_4', 'OTHER_5',
  'OTHER_6', 'OTHER_7', 'OTHER_8', 'OTHER_9', 'OTHER_10', 'OTHER_11',
  'OTHER_12', 'OTHER_13', 'OTHER_14', 'OTHER_15', 'OTHER_16', 'OTHER_17',
];

export const BUILDING_INFO_KEYS = {
  BCR: 'BCR',
  GEA: 'GEA',
  NIA: 'NIA',
  GIA: 'GIA',
  NIA_TO_GIA: 'NIA_TO_GIA',
  FAR: 'FAR',
  STORIES: 'STORIES',
  COMMUNAL_AREA: 'COMMUNAL_AREA',
  CORE: 'CORE',
  CORE_AND_MECHANICAL: 'CORE_AND_MECHANICAL',
  CORRIDOR: 'CORRIDOR',
  CIRCULATION: 'CIRCULATION',
  ENVELOPE_AREA: 'ENVELOPE_AREA',
  NO_OF_MASSES: 'NO_OF_MASSES',
  FULL_CORE: 'FULL_CORE',
  ELEVATORS_CORE: 'ELEVATORS_CORE',
  APARTMENT_PER_FLOOR: 'APARTMENT_PER_FLOOR',
  APARTMENT_PER_BUILDING: 'APARTMENT_PER_BUILDING',
  ONE_FLAT_ASPECTS: 'ONE_FLAT_ASPECTS',
  TWO_FLAT_ASPECTS: 'TWO_FLAT_ASPECTS',
  THREE_FLAT_ASPECTS: 'THREE_FLAT_ASPECTS',
  ROOF_APARTMENT: 'ROOF_APARTMENT',
  GARDEN_APARTMENT: 'GARDEN_APARTMENT',
  NON_TYPICAL_APARTMENTS: 'NON_TYPICAL_APARTMENTS',
  NORTH: 'NORTH',
  SOUTH: 'SOUTH',
  EAST: 'EAST',
  WEST: 'WEST',
  PRIORITY_FIT_SCORE: 'PRIORITY_FIT_SCORE',
  CONST_COST_SCORE: 'CONST_COST_SCORE',
  MARKETABILITY_SCORE: 'MARKETABILITY_SCORE',
  WELLBING_SCORE: 'WELLBING_SCORE',
  TOTAL_APARTMENTS: 'TOTAL_APARTMENTS',
  SUFFICIENT_DAYLIGHT: 'SUFFICIENT_DAYLIGHT',
  SUFFICIENT_VIEW: 'SUFFICIENT_VIEW',
  SUFFICIENT_WIND: 'SUFFICIENT_WIND',
  SUFFICIENT_VSC: 'SUFFICIENT_VSC',
  PH_HLFF: 'PH_HLFF',
  PH_SVR: 'PH_SVR',
  PARKING_AREA: 'PARKING_AREA',
  PARKING_PERCENTAGE: 'PARKING_PERCENTAGE',
  NUM_OF_PARKING: 'NUM_OF_PARKING',
  NUM_OF_SURFACE_PARKING: 'NUM_OF_SURFACE_PARKING',
  NUM_OF_PODIUM_PARKING: 'NUM_OF_PODIUM_PARKING',
  NUM_OF_PRECAST_PARKING: 'NUM_OF_PRECAST_PARKING',
  NUM_OF_BELOW_GROUND_PARKING: 'NUM_OF_BELOW_GROUND_PARKING',
  PARKING_RATIO: 'PARKING_RATIO',
  FINANCIAL_DATA: 'FINANCIAL_DATA',
  ENERGY_GAS_COST: 'ENERGY_GAS_COST',
  ENERGY_PV_COST: 'ENERGY_PV_COST',
  ENERGY_PANEL_CAPACITY: 'ENERGY_PANEL_CAPACITY',
  ENERGY_PV_ROOF_COVERAGE: 'ENERGY_PV_ROOF_COVERAGE',
  ENERGY_ELECTRIC_COST: 'ENERGY_ELECTRIC_COST',
  ENERGY_FORM_MIN_WWR: 'ENERGY_FORM_MIN_WWR',
  ENERGY_FORM_MAX_WWR: 'ENERGY_FORM_MAX_WWR',
  IL_PRIMARY_AREA: 'IL_PRIMARY_AREA',
  IL_MMD_AREA: 'IL_MMD_AREA',
  IL_COMMON_AREA: 'IL_COMMON_AREA',
  IL_NET_TO_GROSS: 'IL_NET_TO_GROSS',
  IL_GROSS_BALCONY_AREA: 'IL_GROSS_BALCONY_AREA',
  RETAIL: 'RETAIL',
  MECHANICAL_ROOM: 'MECHANICAL_ROOM',
  PARKING_FINANCIAL: 'PARKING_FINANCIAL',
};

export const COMMUNAL_SPACES = {
  FULL_CORE: 'FULL_CORE',
  ELEVATORS_CORE: 'ELEVATORS_CORE',
  STAIRCASE_CORE: 'STAIRCASE_CORE',
  SHAFT: 'SHAFT',
  CORE: 'CORE',
  CORRIDOR: 'CORRIDOR',
  RETAIL: 'RETAIL',
  OTHER: 'OTHER',
  // TODO TECH DEBT : Is there a cleaner way?
  OTHER_0: 'OTHER_0',
  OTHER_1: 'OTHER_1',
  OTHER_2: 'OTHER_2',
  OTHER_3: 'OTHER_3',
  OTHER_4: 'OTHER_4',
  OTHER_5: 'OTHER_5',
  OTHER_6: 'OTHER_6',
  OTHER_7: 'OTHER_7',
  OTHER_8: 'OTHER_8',
  OTHER_9: 'OTHER_9',
};

// The empty units type in "dumb" masses.
const EMPTY_TYPE = 'empty';

export const NIA_UNIT_TYPES = [
  ...APARTMENT_TYPES,
  ...COMMUNAL_SPACES_TYPES,
  COMMUNAL_SPACES.OTHER,
  EMPTY_TYPE,
];

export const COMMUNAL_SPACES_RATIO_TYPE = {
  PER_APARTMENT: 'PER_APARTMENT',
  ROOM_AREA: 'ROOM_AREA',
};

export const COMMUNAL_SPACES_SPREAD = {
  PER_BUILDING: 'PER_BUILDING',
  PER_FLOOR: 'PER_FLOOR',
  ENTIRE_PROJECT: 'ENTIRE_PROJECT',
};

export const LEGEND_COLOR_SETS = {
  [MODEL_ANALYSIS_TYPES.AREA_TYPE]: [
    [
      { value: 'Studio', color: '#D45AFF' },
      { value: '1 Bed', color: '#61DF93' },
      { value: '2 Bed', color: '#6B8DFF' },
      { value: '3 Bed', color: '#36D8CE' },
      { value: '4 Bed', color: '#FE7F49' },
      { value: '4 Bed Large', color: '#F36084' },
      { value: 'Communal', color: '#F9C247' },
    ],
  ],
  [MODEL_ANALYSIS_TYPES.SUN_ANALYSIS]: [
    [
      { value: '0H', color: '#FCFC99' },
      { value: '1H', color: '#FFFF66' },
      { value: '2H', color: '#FFFF00' },
      { value: '3H', color: '#FFE100' },
      { value: '4H', color: '#FEC000' },
      { value: '5H', color: '#FE9E00' },
      { value: '6H', color: '#FC7800' },
      { value: '7H', color: '#F94800' },
      { value: '8H', color: '#E71000' },
      { value: '9H', color: '#C30800' },
      { value: '10H', color: '#9D0400' },
      { value: '11H', color: '#7A0200' },
      { value: '12H', color: '#5A0100' },
    ],
  ],
  [MODEL_ANALYSIS_TYPES.VIEWS_ANALYSIS]: [
    [
      { value: '25m', color: '#b0006c' },
      { value: '50m', color: '#bf0088' },
      { value: '75m', color: '#ef00e1' },
      { value: '100m', color: '#fb02fe' },
      { value: '200m', color: '#d718fb' },
      { value: '300m', color: '#ac4efe' },
      { value: '400m', color: '#7578f9' },
      { value: '600m', color: '#34bcfa' },
      { value: '800m', color: '#0be3fc' },
      { value: '1000+m', color: '#03ecf3' },
    ],
  ],
  [MODEL_ANALYSIS_TYPES.WIND_ANALYSIS]: [
    [
      { value: '0', color: '#045a01' },
      { value: '10', color: '#1f8201' },
      { value: '20', color: '#5fa101' },
      { value: '30', color: '#a5c500' },
      { value: '40', color: '#dde601' },
      { value: '50', color: '#e5e606' },
      { value: '60', color: '#e4ee1e' },
      { value: '70', color: '#d2e847' },
      { value: '80', color: '#c6ed80' },
      { value: '90', color: '#abe8c7' },
      { value: '100', color: '#90d2ce' },
    ],
  ],
  [MODEL_ANALYSIS_TYPES.VSC_ANALYSIS]: [
    [
      { value: '0°', color: '#1B3D6D' },
      { value: '5°', color: '#17548A' },
      { value: '10°', color: '#286FAD' },
      { value: '15°', color: '#25C0D5' },
      { value: '20°', color: '#54C7DD' },
      { value: '25°', color: '#90CFE6' },
      { value: '27°', color: '#97D5C9' },
      { value: '30°', color: '#BAE1CF' },
      { value: '40°', color: '#D2EBDF' },
    ],
  ],
};

export const TARGET_STORIES_TYPES = {
  TARGET_STORIES: 'TARGET_STORIES',
  MAX_STORIES: 'MAX_STORIES',
};

export const FEASIBILITY_EDITOR_BUTTON_KEYS = {
  TRANSFORM: 'TRANSFORM',
  CREATE: 'CREATE',
  PARKING: 'PARKING',
  CREATE_PARKING: 'CREATE_PARKING',
  CREATE_UNDERGROUND_PARKING: 'CREATE_UNDERGROUND_PARKING',
  CREATE_BUILDING: 'CREATE_BUILDING',
  EDIT_BUILDING: 'EDIT_BUILDING',
  CREATE_SURFACE_PARKING: 'CREATE_SURFACE_PARKING',
  EDIT_SURFACE_PARKING: 'EDIT_SURFACE_PARKING',
  WAITING_FOR_SKETCH: 'WAITING_FOR_SKETCH',
  ROTATE: 'ROTATE',
  MIRROR: 'MIRROR',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  SETBACK: 'SETBACK',
  GROUND_FLOOR_TYPE: 'GROUND_FLOOR_TYPE',
  FLOOR: 'FLOOR',
  FLOOR_DUPLICATE: 'FLOOR_DUPLICATE',
  FLOOR_DELETE: 'FLOOR_DELETE',
  UNDO: 'UNDO',
  CLOSE: 'CLOSE',
};

export const WAITING_FOR_KEYS = {
  ANALYSIS: 'ANALYSIS',
  EDIT: 'EDIT',
  REPORT: 'REPORT',
  REVIT_FILE: 'REVIT_FILE',
  FORGE_URN: 'FORGE_URN',
  TEST_FIT_SCREENSHOTS: 'TEST_FIT_SCREENSHOTS',
  COVE_TOOL: 'COVE_TOOL',
  HB_ENERGY: 'HB_ENERGY',
  OCB_PARKING: 'OCB_PARKING',
};

export const FEASIBILITY_MASS_TYPES = {
  NONE: 'NONE',
  APARTMENTS: 'APARTMENTS',
  PARKING: 'PARKING',
};

export const INCOME_KEYS = {
  SALE: 'SALE',
  RENT: 'RENT',
};

export const CARD_INFO_TYPE = {
  APARTMENT_MIXTURE: 'APARTMENT_MIXTURE',
  FINANCIAL: 'FINANCIAL',
  HVAC: 'HVAC',
};

export const HVAC = {
  COOLING_LOAD_PEAK: 'COOLING_LOAD_PEAK',
  COOLING_LOAD_DENSITY: 'COOLING_LOAD_DENSITY',
  HEATING_LOAD_PEAK: 'HEATING_LOAD_PEAK',
  HEATING_LOAD_DENSITY: 'HEATING_LOAD_DENSITY',
  ENERGY_USE_INTENSITY: 'ENERGY_USE_INTENSITY',
};

export const BALCONY_AREA_TYPES = {
  PER_APARTMENT: 'PER_APARTMENT',
  ENTIRE_PROJECT: 'ENTIRE_PROJECT',
};

export const ilsMmdAreaSize = 13;
