import { TEST_FIT_OFFICE_TYPES, TEST_FIT_GENERAL } from 'constants/dropdownKeys';
import { PROJECT_STATUS, PROFILE_STATUS } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { MARKUPS_TOOLS_NAME } from 'constants/markupsConts';
import {
  BUILDING_INFO_KEYS,
  COMMUNAL_SPACES,
  COMMUNAL_SPACES_RATIO_TYPE,
  COMMUNAL_SPACES_SPREAD,
  HVAC,
  MODEL_ANALYSIS_TYPES,
} from 'constants/feasibilityConts';
import { getRoomDisplayNamesByRoomKeys } from 'utils/helpers/parseTestFitStandards';

export default {
  // ===============Global===============
  SWAPP_UPPER_CASE: 'SWAPP',
  SWAPP_LOWER_CASE: 'swapp',
  BACK: 'Back',
  FRONT: 'Front',
  LOADING: 'Loading...',
  UPDATE: 'Update',
  BETA: 'Beta.',
  NO_MOBILE_SUPPORT: 'SWAPP works best on web browsers. We\'re currently optimizing the experience for mobile devices',
  SQF: 'ft²',
  SQM: 'm²',
  ALL: 'All',
  INCHES: 'inches',
  METERS: 'meters',
  UNITS: 'Units',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  EMPTY: 'Empty',
  OTHER: 'Other',
  DUPLICATE: 'Duplicate',
  SHARE: 'Share',
  DELETE: 'Delete',
  LEFTOVERS: 'Leftovers',
  LSFA: 'LSF A',
  LSFB: 'LSF B',
  UNTITLED: 'Untitled',

  // ===============Small Components===============
  // ImperialMetricToggle
  IMPERIAL_METRIC_TOGGLE_SWITCH_TO_SQF: 'View Project in Square Feet',
  IMPERIAL_METRIC_TOGGLE_SWITCH_TO_SQM: 'View Project in Square Meter',

  STAGE_NOT_READY: 'Please complete preceding stages before proceeding to',

  // ProfileRename
  PROFILE_RENAME_PLACEHOLDER: 'Enter layout name',
  PROFILE_RENAME_ERROR_EMPTY: 'Name must not be empty',
  PROFILE_RENAME_RENAME_LAYOUT: 'Rename Option',
  PROFILE_RENAME_RENAME_DESIGN: 'Rename Design',
  PROFILE_RENAME_RENAME_RESULT: 'Rename Option',
  PROFILE_RENAME_ERROR_NAME_TAKEN: (_key, context) => `The name "${context.currentName}" is already taken`,

  // MultiImageSelect
  MULTI_IMAGE_SELECT_CANT_SELECT_LESS: (_key, context) => `Select at least ${context.min} option`,
  MULTI_IMAGE_SELECT_CANT_SELECT_MORE: (_key, context) => `Select up to ${context.max} options`,
  MULTI_IMAGE_SELECT_SELECT_TEXT: 'Selected Items',

  // BlockLotTable
  BLOCK_LOT_TABLE_ADD: 'Add block',
  BLOCK_LOT_TABLE_BLOCK_LABEL: 'Block',
  BLOCK_LOT_TABLE_BLOCK_PLACEHOLDER: 'Enter block',
  BLOCK_LOT_TABLE_LOT_LABEL: 'Lot',
  BLOCK_LOT_TABLE_LOT_PLACEHOLDER: 'Enter lot. 100, 101, etc..',

  // PdfExport
  PDF_EXPORT_TEXT: 'PDF',
  PDF_EXPORT_POWERED_BY: 'Powered By',
  PDF_EXPORT_TEST_FIT: 'Test Fit',
  PDF_EXPORT_PROJECT: 'project',

  // MarkupsContainer
  MARKUPS_CONTAINER_TOOLTIP: 'Open Markups',
  MARKUPS_CONTAINER_DELETE: 'Delete',
  MARKUPS_CONTAINER_DELETE_All: 'Delete all',
  MARKUPS_CONTAINER_DUPLICATE: 'Duplicate',
  MARKUPS_CONTAINER_UNDO: 'Undo',
  MARKUPS_CONTAINER_SHAPES: 'Shapes',
  MARKUPS_CONTAINER_COLORS: 'Colors',
  MARKUPS_CONTAINER_WEIGHT: 'Weight',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.ARROW}`]: 'Arrow',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.CALLOUT}`]: 'Callout',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.TEXT}`]: 'Text',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.CIRCLE}`]: 'Circle',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.CLOUD}`]: 'Cloud',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.FREEHAND}`]: 'Freehand',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.HIGHLIGHT}`]: 'Highlight',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.POLYCLOUD}`]: 'Polycloud',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.POLYLINE}`]: 'Polyline',
  [`MARKUPS_CONTAINER_${MARKUPS_TOOLS_NAME.RECTANGLE}`]: 'Rectangle',
  MARKUPS_CONTAINER_COLOR_RED: 'Red',
  MARKUPS_CONTAINER_COLOR_GREEN: 'Green',
  MARKUPS_CONTAINER_COLOR_BLUE: 'Blue',
  MARKUPS_CONTAINER_COLOR_YELLOW: 'Yellow',
  MARKUPS_CONTAINER_WIDTH_THIN: 'Thin',
  MARKUPS_CONTAINER_WIDTH_NORMAL: 'Normal',
  MARKUPS_CONTAINER_WIDTH_THICK: 'Thick',
  MARKUPS_CONTAINER_FONT_SIZE: 'Size',
  MARKUPS_CONTAINER_FONT_SIZE_BIG: 'expand',
  MARKUPS_CONTAINER_FONT_SIZE_SMALL: 'shrink',

  MARKUPS_CONTAINER_EXIT_BUTTON: 'Exit Markups',
  MARKUPS_CONTAINER_EXIT_MODAL_TITLE: 'Exit Markups',
  MARKUPS_CONTAINER_EXIT_MODAL_OK_TEXT: 'Save and exit',
  MARKUPS_CONTAINER_EXIT_MODAL_CANCEL_TEXT: 'Exit',
  MARKUPS_CONTAINER_EXIT_MODAL_CONTENT: 'Save will keep the markups only at your profile. Exit will delete all current markups from the drawing.',
  MARKUPS_CONTAINER_SEND_BUTTON: 'Send',
  MARKUPS_CONTAINER_REVISION_BUTTON: 'Send to SWAPP',
  MARKUPS_CONTAINER_REVISION_MODAL_TITLE: 'Send to SWAPP',
  MARKUPS_CONTAINER_REVISION_MODAL_OK_TEXT: 'Send for revision',
  MARKUPS_CONTAINER_REVISION_MODAL_CANCEL_TEXT: 'Cancel',
  MARKUPS_CONTAINER_REVISION_MODAL_CONTENT: 'By sending a revision request, Swapp will generate a new layout based on the plan markups.',

  // WellbeingAnalysisButton
  WELLBEING_ANALYSIS_BUTTON_TOOLTIP_RUNNING: 'Wellbeing analysis in progress',
  WELLBEING_ANALYSIS_BUTTON_TOOLTIP_RUN: 'Run wellbeing analysis',
  WELLBEING_ANALYSIS_BUTTON_TOOLTIP_HAS_RESULT: 'Wellbeing analysis is ready',
  WELLBEING_ANALYSIS_BUTTON_MODAL_TITLE: 'Occupant Wellbeing Analysis',
  WELLBEING_ANALYSIS_BUTTON_MODAL_CONTENT: 'The analysis may take a few minutes. The algorithm will generate environmental studies, checking Daylight, View, and Wind for the selected design option.',
  WELLBEING_ANALYSIS_BUTTON_MODAL_OK_TEXT: 'Continue',

  // DownloadRevitButton
  DOWNLOAD_REVIT_BUTTON_TOOLTIP_RUNNING: 'Generating Revit Massing Model',
  DOWNLOAD_REVIT_BUTTON_TOOLTIP_RUN: 'Generate Revit Massing Model',
  DOWNLOAD_REVIT_BUTTON_TOOLTIP_HAS_RESULT: 'Download Revit Massing Model',
  DOWNLOAD_REVIT_BUTTON_MODAL_TITLE: 'Generate Revit Massing Model',
  DOWNLOAD_REVIT_BUTTON_MODAL_CONTENT: 'The generation may take a few minutes',
  DOWNLOAD_REVIT_BUTTON_MODAL_OK_TEXT: 'Continue',

  // GenerateEnergyAnalysis
  GENERATE_ENERGY_ANALYSIS_TITLE: 'Window-to-wall ratio',
  GENERATE_ENERGY_ANALYSIS_MAX: 'Maximum WWR',
  GENERATE_ENERGY_ANALYSIS_MIN: 'Minimum WWR',
  GENERATE_ENERGY_ANALYSIS_GENERATE: 'Generate',

  // GenerateRevitForm
  GENERATE_REVIT_FORM_ARCHITECTURE: 'Architecture',
  GENERATE_REVIT_FORM_STRUCTURE: 'Structure',
  GENERATE_REVIT_FORM_PLUMBING: 'Plumbing',
  GENERATE_REVIT_FORM_HVAC: 'HVAC',
  GENERATE_REVIT_FORM_SHEETS: 'Sheets',
  GENERATE_REVIT_FORM_DOWNLOAD_FILES: 'DOWNLOAD FILES',
  GENERATE_REVIT_FORM_GENERATE: 'GENERATE',

  // GenerateParking
  GENERATE_PARKING_TOOLTIP_RUNNING: 'Generating Parking',
  GENERATE_PARKING_TOOLTIP_RUN: 'Generate Parking',
  GENERATE_PARKING_TOOLTIP_HAS_RESULT: 'Parking are ready',
  GENERATE_PARKING_GENERATE: 'Generate',
  GENERATE_PARKING_FORM_STALLS_PER_APARTMENT: 'Stalls per apartment',
  GENERATE_PARKING_FORM_MIN: 'Min.',
  GENERATE_PARKING_FORM_MAX: 'Max.',
  GENERATE_PARKING_FORM_TARGET: 'Target',
  GENERATE_PARKING_FORM_APARTMENTS_AMOUNT_TITLE: 'Apt. no.',
  GENERATE_PARKING_FORM_STALLS_TITLE: 'Stalls',
  GENERATE_PARKING_FORM_BELOW_GRADE: 'Below Grade',
  GENERATE_PARKING_FORM_MAXIMUM_LEVELS: 'Maximum levels',
  GENERATE_PARKING_FORM_PERCENTAGE_TARGET: 'Percentage target',
  GENERATE_PARKING_FORM_RAINWATER_INFILTRATION_AREA: 'Rainwater infiltration area',
  GENERATE_PARKING_FORM_STALLS: 'Stalls',
  GENERATE_PARKING_FORM_GUESTS: 'Guests',
  GENERATE_PARKING_FORM_ACCESSIBLE: 'Accessible',
  GENERATE_PARKING_FORM_TALL_ACCESSIBLE: 'Tall Accessible',
  GENERATE_PARKING_FORM_TOTAL: 'Total - car parking stalls',
  GENERATE_PARKING_FORM_MOTORCYCLE: 'Motorcycle',
  GENERATE_PARKING_FORM_BICYCLE: 'Bicycle',
  GENERATE_PARKING_FORM_LOCATE_ENTRANCE: 'Entrance',
  GENERATE_PARKING_FORM_LOCATE_ENTRANCE_DESCRIPTION: 'To set the parking lot entrance-\n Select one or more of the lot edges to set a range in which the entrance will be placed,\nor select a specific location',
  GENERATE_PARKING_FORM_CHOOSE_EDGE: 'Edge',
  GENERATE_PARKING_FORM_CHOOSE_LOCATION: 'Location',
  GENERATE_PARKING_FORM_WARNING_MESSAGE: 'Please select the parking lot entrance location',

  // FeasibilityResultEnergyAnalysis
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ANNUAL_ENERGY_USE: 'Annual Energy Use',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ANNUAL_ENERGY_COST: 'Annual Energy Cost',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_USE_INTENSITY: 'Energy Use Intensity',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_COOLING: 'Cooling',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_HEATING: 'Heating',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_LIGHTING: 'Lighting',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_EQUIPMENT: 'Equipment',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_PUMP: 'Pump',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_FANS: 'Fans',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_HOT_WATER: 'Hot Water',

  FEASIBILITY_RESULT_ENERGY_ANALYSIS_GAS_COST: 'Gas Cost',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_PV_COST: 'PV Cost',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_PANEL_CAPACITY: 'Panel Capacity',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_PV_ROOF_COVERAGE: 'PV Roof Coverage',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ELECTRIC_COST: 'Electric Cost',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT: '/kWh',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT_W: '/w',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT_KW: '/kw',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_SCENARIO: 'Scenario',

  FEASIBILITY_RESULT_ENERGY_ANALYSIS_PV_TITLE: 'PV Solar System',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_SIZE_OF_THE_SYSTEM: 'System Capacity',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_ANNUAL_ENERGY_PRODUCTION: 'Annual Energy Production',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_RENEWABLE_ENERGY: 'Annual Renewable Energy Production',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_INITIAL_INVESTMENT: 'Initial Investment',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_RECOUP: 'Recoup',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_IRR: 'IRR-25 yr',
  FEASIBILITY_RESULT_ENERGY_ANALYSIS_TILT: 'Tilt Angle',

  // GenerateReportButton
  GENERATE_REPORT_BUTTON: 'Generate Report',
  GENERATE_REPORT_BUTTON_MODAL_TITLE: 'Occupant Report Generation',
  GENERATE_REPORT_BUTTON_MODAL_CONTENT: 'The generation may take a few minutes',
  GENERATE_REPORT_BUTTON_MODAL_OK_TEXT: 'Continue',

  // NoReportNotification
  NO_REPORT_NOTIFICATION_TITLE: 'No Reports',
  NO_REPORT_NOTIFICATION_HINT: 'go back to Study and create a report from at least one of the options',

  // FavoriteButton
  FAVORITE_BUTTON_TOOLTIP_REMOVE: 'Remove from Favorites',
  FAVORITE_BUTTON_TOOLTIP_ADD: 'Add to Favorites',
  FAVORITE_BUTTON_MODAL_TITLE: 'Remove from Favorites?',
  FAVORITE_BUTTON_MODAL_CONTENT: 'The result will be deleted',
  FAVORITE_BUTTON_MODAL_OK_TEXT: 'Continue',

  // ResultSplitView
  RESULT_SPLIT_VIEW_RESULT: 'Results',
  RESULT_SPLIT_VIEW_FAVORITE: 'Favorites',
  RESULT_SPLIT_VIEW_RESULT_EMPTY_TITLE: 'Let\'s create some options',
  RESULT_SPLIT_VIEW_RESULT_EMPTY_HINT: 'Please revise the form on the left bar and press ‘Update’',
  RESULT_SPLIT_VIEW_FAVORITE_EMPTY_TITLE: 'No Favorites',

  // FeasibilityViewerContainer
  FEASIBILITY_VIEWER_CONTAINER_TWO_D_TOGGLE_TOOLTIP: '2D/3D Toggle',

  // ForgeToggleButton
  FORGE_TOGGLE_BUTTON_TOOLTIP: 'Show Revit model',

  // ViewsUi
  VIEWS_UI_BUILDINGS: 'Buildings',
  VIEWS_UI_BUILDING: 'Building',
  VIEWS_UI_SECTION: 'Section',
  VIEWS_UI_SCOPE_BOX: 'ScopeBox',
  VIEWS_UI_DELETE: 'Delete',
  VIEWS_UI_DUPLICATE: 'Duplicate',
  VIEWS_UI_DELETE_TOOLTIP: '(Delete)',
  VIEWS_UI_UNDO: 'Undo',
  VIEWS_UI_UNDO_TOOLTIP: '(ctrl+Z)',
  VIEWS_UI_DUPLICATE_TOOLTIP: '(ctrl+D)',
  VIEWS_UI_SCOPE_BOX_SIZE_1: '1:50',
  VIEWS_UI_SCOPE_BOX_SIZE_2: '1:100',
  VIEWS_UI_SCOPE_BOX_SIZE_3: '1:200',

  // FeasibilityEditorOverlay
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_TITLE: 'Your Model has been modified',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CONTENT: 'What would you like to do?',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_OK_BUTTON: 'Discard',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CANCEL_BUTTON: 'Continue editing',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_BUTTON: 'Save',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_AS_BUTTON: 'Save a Copy',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_ERROR_TITLE: 'Wait a second',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_ERROR_CONTENT: 'The buildings are located outside the site polyline or overlap each other.',
  FEASIBILITY_EDITOR_OVERLAY_SELECT: 'Select',
  FEASIBILITY_EDITOR_OVERLAY_CREATE: 'Create',
  FEASIBILITY_EDITOR_OVERLAY_PARKING: 'Parking',
  FEASIBILITY_EDITOR_OVERLAY_PRECAST_PARKING: 'Precast',
  FEASIBILITY_EDITOR_OVERLAY_UNDERGROUND_PARKING: 'Below',
  FEASIBILITY_EDITOR_OVERLAY_BUILDING: 'Building',
  FEASIBILITY_EDITOR_OVERLAY_SURFACE_PARKING: 'Surface',
  FEASIBILITY_EDITOR_OVERLAY_EDIT: 'Edit',
  FEASIBILITY_EDITOR_OVERLAY_MIRROR: 'Mirror',
  FEASIBILITY_EDITOR_OVERLAY_DUPLICATE: 'Duplicate',
  FEASIBILITY_EDITOR_OVERLAY_DELETE: 'Delete',
  FEASIBILITY_EDITOR_OVERLAY_OPEN_GF: 'Open GF',
  FEASIBILITY_EDITOR_OVERLAY_RETAIL: 'Retail',
  FEASIBILITY_EDITOR_OVERLAY_SETBACK: 'Setback',
  FEASIBILITY_EDITOR_OVERLAY_FLOOR: 'Floor',
  FEASIBILITY_EDITOR_OVERLAY_UNDO: 'Undo',
  FEASIBILITY_EDITOR_OVERLAY_CLOSE: 'Close',
  FEASIBILITY_EDITOR_OVERLAY_ERROR_SERVER_PROCESSING: 'Processing... Please wait a few seconds and try again.',

  // FeasibilityDesignStandard
  FEASIBILITY_DESIGN_STANDARD_BASIC: 'Basic Design',
  FEASIBILITY_DESIGN_STANDARD_BASIC_DESCRIPTION: 'Exterior porcelain cladding systems with gray stucco at the ground and penthouse floors. \nStandard window size with laminated glass and side HPL frame.\nBasic interior design with Ytong block walls, stucco wall finish, laminate doors, Granite Porcelain floor tiles, MDF and laminated finish kitchen, etc. ',
  FEASIBILITY_DESIGN_STANDARD_HIGH_END: 'High-End Design',
  FEASIBILITY_DESIGN_STANDARD_HIGH_END_DESCRIPTION: 'Exterior aluminum cladding systems with HPL cladding at the ground and penthouse floors.\nFloor to ceiling windows with insulated glass and decorative facade design.\nLuxurious interior with Ytong block walls and drywall level 5 finish, acoustic wood doors, stone floor tiles, acrylic or glazed finish kitchen, etc.',

  // EditorRoomsPanel
  EDITOR_ROOMS_PANEL_ROOM_TYPE: 'Room Type',
  EDITOR_ROOMS_PANEL_ROOM_TYPE_TILES: '(Tiles)',
  EDITOR_ROOMS_PANEL_ROOM_TYPE_TILES_TOOLTIP: 'Number of tiles required by the user',
  EDITOR_ROOMS_PANEL_TILES_REQUIRED: 'Tiles Required',
  EDITOR_ROOMS_PANEL_MODEL: 'Model',
  EDITOR_ROOMS_PANEL_NO: 'No.',
  EDITOR_ROOMS_PANEL_EMPTY_TILES: 'Empty Area',
  EDITOR_ROOMS_PANEL_WARNINGS: 'Warnings',
  EDITOR_ROOMS_PANEL_NO_DEPARTMENTS: 'Departments tab is empty',
  EDITOR_ROOMS_PANEL_ROOMS: 'Program',
  EDITOR_ROOMS_PANEL_FLOORS: 'Floors',
  EDITOR_ROOMS_PANEL_FLOOR: 'Floor',
  EDITOR_ROOMS_PANEL_DEPARTMENTS: 'Departments',
  EDITOR_ROOMS_PANEL_FILTER_TITLE_SORT: 'Sort by',
  EDITOR_ROOMS_PANEL_FILTER_TITLE_DISPLAY: 'Display',
  EDITOR_ROOMS_PANEL_GRID_VIEW: 'Grid view',
  EDITOR_ROOMS_PANEL_LIST_VIEW: 'List view',

  // TestFitViewerContainerUi
  TEST_FIT_VIEWER_CONTAINER_UI_SWAP: 'swap',
  TEST_FIT_VIEWER_CONTAINER_UI_UNASSIGN: 'Remove',
  TEST_FIT_VIEWER_CONTAINER_UI_LOCK: 'Lock',
  TEST_FIT_VIEWER_CONTAINER_UI_UNLOCK: 'Unlock',
  TEST_FIT_VIEWER_CONTAINER_UI_ROOM: 'Rooms',
  TEST_FIT_VIEWER_CONTAINER_UI_ROOM_TOOLTIP: 'Room Edit mode',
  TEST_FIT_VIEWER_CONTAINER_UI_TILES: 'Tiles',
  TEST_FIT_VIEWER_CONTAINER_UI_TILES_TOOLTIP: 'Tiles Edit mode',
  TEST_FIT_VIEWER_CONTAINER_UI_UNDO: 'Undo',
  TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS: 'depts',
  TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS_TOOLTIP: 'Departments Edit mode',
  TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS_EMPTY: 'Departments tab is empty',

  // ===============Project Status===============
  STATUS: {
    [PROJECT_STATUS.OPEN]: 'Open',
    [PROJECT_STATUS.ARCHIVED]: 'Archived',
    [PROJECT_STATUS.REPOSITORY]: 'Repository',
    [PROJECT_STATUS.DELETE]: 'Delete',
  },

  // ===============Profile Status===============
  PROFILE_STATUS: {
    [PROFILE_STATUS.WAITING_FOR_SUBMISSION]: 'Not submitted',
    [PROFILE_STATUS.WAITING_FOR_DATA]: 'In process',
    [PROFILE_STATUS.READY]: 'Completed',
    [PROFILE_STATUS.APPROVED]: 'Approved',
  },

  // ===============Errors===============
  ERROR_SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
  ERROR: 'Error',

  // ===============Auth Forms===============
  AUTH_FORM_LOG_IN: 'Log in',
  AUTH_FORM_LOG_IN_ERROR: 'Invalid email or password. Please try again or click "Forgot Password" to continue.',
  AUTH_FORM_SIGN_UP: 'Sign up',
  AUTH_FORM_CREAT_ACCOUNT: 'Create account',
  AUTH_FORM_CREATE_YOUR_ACCOUNT: 'Create your account',
  AUTH_FORM_CONTINUE: 'Continue',
  AUTH_FORM_EMAIL: 'Email address',
  AUTH_FORM_SEND_EMAIL: 'Send email',
  AUTH_FORM_ERROR_NO_EMAIL: 'Please enter your email address!',
  AUTH_FORM_EMAIL_PLACEHOLDER: 'Enter your email address',
  AUTH_FORM_PASSWORD: 'Password',
  AUTH_FORM_PASSWORD_RESET: 'Password Reset',
  AUTH_FORM_ENTER_NEW_PASSWORD_PLACEHOLDER: 'Enter new password',
  AUTH_FORM_ERROR_NO_PASSWORD: 'Please enter your password!',
  AUTH_FORM_PASSWORD_PLACEHOLDER: 'Enter your password',
  AUTH_FORM_ERROR_INCONSISTENT_PASSWORD: 'The passwords don’t match. Try again?',
  AUTH_FORM_CONFIRM_PASSWORD: 'Confirm password',
  AUTH_FORM_CONFIRM_NEW_PASSWORD_PLACEHOLDER: 'Confirm new password',
  AUTH_FORM_CONFIRM_PASSWORD_AGAIN_PLACEHOLDER: 'Enter your password again',
  AUTH_FORM_ERROR_CONFIRM_PASSWORD: 'Please confirm password!',

  // ===============Auth===============

  // ConfirmPasswordResetContainer
  CONFIRM_PASSWORD_RESET_CONTAINER_CHOOSE_NEW_PASSWORD: 'Choose new password for your account',

  // LoginContainer
  LOGIN_CONTAINER_FORGOT_PASSWORD: 'Forgot your password?',

  // PasswordResetContainer
  PASSWORD_RESET_CONTAINER_VIA_MAIL: 'Don’t worry!\n'
      + 'We\'ll send you a reset link via mail.',

  // ResetPasswordForm
  RESET_PASSWORD_FORM_CHECK_EMAIL: 'Please check your email',
  RESET_PASSWORD_FORM_EMAIL_NOT_FOUND: 'email not found',

  // ===============Navigation===============
  NAVIGATION_PROJECT_INFO: 'Project Settings',
  NAVIGATION_PROGRAM: 'Program',
  NAVIGATION_OVERALL: 'Overall',
  NAVIGATION_CIRCULATION: 'Circulation',
  NAVIGATION_REGULATION: 'Regulation',
  NAVIGATION_NOISE: 'Noise',
  NAVIGATION_BUDGET: 'Budget',
  NAVIGATION_FLEXIBILITY: 'Flexibility',
  NAVIGATION_DAYLIGHT: 'Daylight',
  NAVIGATION_VIEW: 'View',
  NAVIGATION_MODEL: '3D Model',
  [UI_AUTHORIZE_PATH.TEST_FIT]: 'Test',
  [UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]: 'Feasibility',
  [UI_AUTHORIZE_PATH.PROJECT_SETTINGS]: 'Project Settings',
  NAVIGATION_EMPTY: '',
  NAVIGATION_SETUP: 'Setup',
  NAVIGATION_EXPLORE: 'Explore',
  NAVIGATION_REPORT: 'Report',
  NAVIGATION_STUDIES: 'Study',
  NAVIGATION_COMPARE: 'Compare',
  NAVIGATION_COMPARE_OVERALL: 'Overall',
  NAVIGATION_COMPARE_PLANS: 'Plans',
  NAVIGATION_COMPARE_SCHEDULE: 'Schedule',
  NAVIGATION_COMPARE_BUDGET: 'Budget',
  NAVIGATION_COMPARE_APPROVAL: 'Approval',
  NAVIGATION_COMPARE_ARCHITECTURE: 'Architecture',
  NAVIGATION_COMPARE_MECHANICAL: 'Mechanical',
  NAVIGATION_COMPARE_ELECTRICAL: 'Electrical',
  NAVIGATION_COMPARE_PLUMBING: 'Plumbing',
  NAVIGATION_COMPARE_VIEWS: 'Views',
  NAVIGATION_COMPARE_DATA: 'Data',
  NAVIGATION_COMPARE_EFFICIENCY: 'Efficiency',
  NAVIGATION_COMPARE_TYPE: 'Apt. Type',
  NAVIGATION_COMPARE_STUDIES: 'Studies',
  NAVIGATION_COST: 'Cons. Cost',
  NAVIGATION_MARKETABILITY: 'Marketability',
  NAVIGATION_OCCUPANT_WELLBEING: 'Wellbeing',
  NAVIGATION_URBAN_CONTEXT: 'Urbanism',
  NAVIGATION_SUSTAINABILITY: 'Sustainability',
  NAVIGATION_ENERGY_EFFICIENT: 'Energy Efficient',
  NAVIGATION_SUMMARY: 'Summary',
  NAVIGATION_EXISTING_SITE: 'Existing Site',
  NAVIGATION_FLOODS: 'Floods',
  NAVIGATION_SITE: 'Site Info',
  NAVIGATION_SITE_LOCATION: 'Site Location',
  NAVIGATION_AERIAL_VIEW: 'Aerial View',
  NAVIGATION_EXISTING_STORE: 'Existing Store',
  NAVIGATION_STREET_VIEW: 'Street View',
  NAVIGATION_SURROUNDING: 'Surrounding',
  NAVIGATION_STRATEGY: 'Site Strategy',
  NAVIGATION_SITE_CONSTRAINTS: 'Site Constraints',
  NAVIGATION_OPPORTUNITIES: 'Opportunities',
  NAVIGATION_PHASING: 'Phasing',
  NAVIGATION_DESIGN: 'Design',
  NAVIGATION_OVERALL_FE: 'Overall Design',
  NAVIGATION_APARTMENTS_MIX: 'Apartments Mix',
  NAVIGATION_COMMUNAL: 'Communal',
  NAVIGATION_PLANS_FE: 'Plans',
  NAVIGATION_SECTION: 'Sections',
  NAVIGATION_WELLBEING: 'Wellbeing',
  NAVIGATION_ENVIRONMENT: 'Analysis',
  NAVIGATION_SHADOWS: 'Shadows',
  NAVIGATION_RIGHT_OF_LIGHT: 'Right Of Light',
  NAVIGATION_APARTMENTS: 'Typical Apartments',
  NAVIGATION_FINANCIAL: 'Financial',
  NAVIGATION_DEVELOPMENT_APPRAISAL: 'Development Appraisal',
  NAVIGATION_CONSTRUCTION_COST: 'Construction Cost',

  // ===============projectsLibrary===============
  // Header
  HEADER_AVATAR_LOG_OUT: 'Log Out',

  // shareButton
  SHARE_BUTTON_TEXT: (_key, context) => `Send this URL to your colleagues in order to allow them to explore ${context.shareName}`,
  SHARE_BUTTON_ERROR: (_key, context) => `When project ${context.shareName} will have completed layouts, you will be able to share it with your colleagues.`,
  SHARE_BUTTON_TITLE: (_key, context) => `Share ${context.shareName}`,
  SHARE_BUTTON_COPY_LINK: 'Copy link',
  SHARE_BUTTON_PROJECT: 'Project',
  SHARE_BUTTON_LINK_COPIED: 'Link copied to clipboard.',

  // UploadThumbnail
  UPLOAD_THUMBNAIL_UPLOAD: 'Upload Project \nPhoto',
  UPLOAD_THUMBNAIL_UPLOAD_EDIT: 'Edit',
  UPLOAD_THUMBNAIL_FILE_TYPE_ERROR: 'You may only upload JPG/PNG files!',
  UPLOAD_THUMBNAIL_LARGE_IMAGE_ERROR: 'Image must smaller than 2MB!',

  // FloorList
  FLOOR_LIST_NEW_FLOOR_BUTTON: 'Create New Project',
  FLOOR_LIST_FILTER_STATUS_PLACEHOLDER: 'Please select status',
  FLOOR_LIST_FILTER_STAGE_PLACEHOLDER: 'Please select stage',
  FLOOR_LIST_FILTER_STATUS_LABEL: 'Status',
  FLOOR_LIST_FILTER_SEARCH_LABEL: 'Search',
  FLOOR_LIST_FILTER_STAGE_LABEL: 'Stage',
  FLOOR_LIST_SEARCH_PLACEHOLDER: 'Search projects by name or location',

  // EmptyProjectList
  EMPTY_PROJECT_LIST_NOTIFICATION: 'LET’S ADD SOME PROJECTS.',
  EMPTY_PROJECT_LIST_HINTS: 'Create your project in just 1 step.',
  EMPTY_PROJECT_LIST_BUTTON: 'Create a project',

  // SwappCartList
  SWAPP_CART_LIST_FLOOR: 'Floor',
  SWAPP_CART_LIST_CREATED: 'Created',
  SWAPP_CART_LIST_LAST_MODIFIED: 'Last Modified',
  SWAPP_CART_LIST_ARCHIVE: 'Archive Project',
  SWAPP_CART_LIST_STAGE: 'Stage',
  SWAPP_CART_LIST_CREATE_NEW_PROJECT: 'Create a new project',
  SWAPP_CART_LIST_RENOVATION: 'Renovation',
  SWAPP_CART_LIST_GROUND_UP: 'Ground-up',
  SWAPP_CART_LIST_MODAL_DELETE_PROFILE: (_key, context) => `Are you sure you want to delete this ${context}?`,

  // ===============newFloor===============

  // AreaTargetFormContainer
  'LSF:RSF': 'LSF:RSF',
  'LSF:USF': 'LSF:USF',
  'PSF:RSF': 'PSF:RSF',
  'ASF:RSF': 'ASF:RSF',
  'FSF:RSF': 'FSF:RSF',
  AREA_TARGET_FORM_CONTAINER_OFFICE_REQUIREMENTS: 'Office Requirements',
  AREA_TARGET_FORM_CONTAINER_OFFICE_REQUIREMENTS_DESCRIPTION: 'General Requirements of different office types',
  AREA_TARGET_FORM_CONTAINER_RSF_LSF: 'RSF / LSF',
  AREA_TARGET_FORM_CONTAINER_RATABLE_LEASABLE: 'Rentable / Leasable',
  AREA_TARGET_FORM_CONTAINER_LSF: 'LSF',
  AREA_TARGET_FORM_CONTAINER_LEASABLE_AREA: 'Leasable area',
  AREA_TARGET_FORM_CONTAINER_PSF: 'PSF',
  AREA_TARGET_FORM_CONTAINER_PRODUCTIVE_AREA: 'Productive area',
  AREA_TARGET_FORM_CONTAINER_ASF: 'ASF',
  AREA_TARGET_FORM_CONTAINER_AMENITY_SPACES: 'Amenity spaces',
  AREA_TARGET_FORM_CONTAINER_FSF: 'FSF',
  AREA_TARGET_FORM_CONTAINER_FACILITY_SPACES: 'Facility spaces',
  AREA_TARGET_FORM_CONTAINER_BATHROOMS: 'Restrooms',
  AREA_TARGET_FORM_CONTAINER_DEVELOPMENT_APPRAISAL: 'Development Appraisal',
  AREA_TARGET_FORM_CONTAINER_DEVELOPMENT_APPRAISAL_DESCRIPTION: 'Estimated cost per area',
  AREA_TARGET_FORM_CONTAINER_ROOMS_DATA: 'Rooms Data',
  AREA_TARGET_FORM_CONTAINER_BATHROOMS_DESCRIPTION: 'Water closets',
  AREA_TARGET_FORM_CONTAINER_GENERATE_SWAPPS: 'Generate Test Fit',
  AREA_TARGET_FORM_CONTAINER_MODAL_TITLE: 'Swapp is excited to receive your project information and generate a Test Fit plan',
  AREA_TARGET_FORM_CONTAINER_MODAL_TITLE_FEASIBILITY: 'Swapp is excited to receive your Project Information and generate Feasibility Study.',
  AREA_TARGET_FORM_CONTAINER_MODAL_TITLE_WARNINGS: 'Do you want to proceed?',
  AREA_TARGET_FORM_CONTAINER_MODAL_TITLE_ERROR: 'There are errors in the SWAPP Definition Form.\nPlease fix them to continue',
  AREA_TARGET_FORM_CONTAINER_MODAL_CONTENT: 'Any last revision?',
  AREA_TARGET_FORM_CONTAINER_MODAL_OK_TEXT: 'Submit',
  AREA_TARGET_FORM_CONTAINER_MODAL_CANCEL_TEXT: 'Revise',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_PSF: 'PSF area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_ASF: 'ASF area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_FSF: 'FSF area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_M_BATHROOMS: 'No Existing Male Restrooms was Specified',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_F_BATHROOMS: 'No Existing Female Restrooms was Specified',
  AREA_TARGET_FORM_CONTAINER_LAYOUT_ERROR: 'You may not create more then 10 layouts',
  AREA_TARGET_FORM_CONTAINER_CIRCULATION: 'Circulation',
  AREA_TARGET_FORM_CONTAINER_CIRCULATION_DESCRIPTION: 'Corridors and dead-end paths',
  AREA_TARGET_FORM_CONTAINER_POSITIONING: 'Positioning',
  AREA_TARGET_FORM_CONTAINER_POSITIONING_DESCRIPTION: 'Room adjacency and proximity',
  AREA_TARGET_FORM_CONTAINER_DEPARTMENTS: 'Departments',
  AREA_TARGET_FORM_CONTAINER_DEPARTMENTS_DESCRIPTION: 'Cluster of rooms',
  AREA_TARGET_FORM_CONTAINER_COMMENTS: 'Comments',
  AREA_TARGET_FORM_CONTAINER_COMMENTS_DESCRIPTION: 'Additional Information',
  AREA_TARGET_FORM_CONTAINER_ADVANCED: 'Advanced Mode',
  AREA_TARGET_FORM_CONTAINER_COVID: 'Covid-19',
  AREA_TARGET_FORM_CONTAINER_NEW_LAYOUT: 'New Layout',
  AREA_TARGET_FORM_CONTAINER_DUPLICATE: 'Duplicate',
  AREA_TARGET_FORM_CONTAINER_DELETE: 'Delete',
  AREA_TARGET_FORM_CONTAINER_DELETE_MODAL_TITLE: 'Are you sure you want to delete this layout?',
  AREA_TARGET_FORM_CONTAINER_READ_ONLY_MESSAGE: 'Read Only Mode',
  AREA_TARGET_FORM_CONTAINER_READ_ONLY_DESCRIPTION: 'This project has already concluded the Test Fit stage.',
  AREA_TARGET_FORM_CONTAINER_SUBMITTED_MESSAGE: 'Read Only Mode',
  AREA_TARGET_FORM_CONTAINER_SUBMITTED_DESCRIPTION: 'Edit mode is not available after submitting.\n You can click on “Duplicate” in order to make an editable copy of this layout',

  TEST_FIT_TARGET_FORM_CONTAINER_PROGRAM: 'program',
  TEST_FIT_TARGET_FORM_CONTAINER_MARKUPS: 'markups',
  TEST_FIT_TARGET_FORM_CONTAINER_FINANCIAL: 'financial',
  TEST_FIT_TARGET_FORM_CONTAINER_DESIGN: 'design',
  TEST_FIT_TARGET_FORM_CONTAINER_BUILD: 'build',
  TEST_FIT_TARGET_FORM_CONTAINER_EXPORT_FILES: 'export',

  // AreaTargetFormRsfLsf
  AREA_TARGET_FORM_RSF_LSF_TOTAL_AREA_LABEL: 'RSF',
  AREA_TARGET_FORM_RSF_LSF_TOTAL_AREA_ERROR: 'Please enter total area',
  AREA_TARGET_FORM_RSF_LSF_RSF_LSF_LABEL: 'LSF / RSF',
  AREA_TARGET_FORM_RSF_LSF_RSF_LSF_ERROR: 'Please enter target',
  AREA_TARGET_FORM_RSF_LSF_LSF_TOTAL_LABEL: 'LSF:',
  AREA_TARGET_FORM_RSF_LSF_LSF_PERCENTAGE_TARGET: 'Percentage Target',
  AREA_TARGET_FORM_RSF_LSF_LSF_ROOM_COUNT: 'Room Count',

  // ===================== Old key just for default ===================== //
  // Test fit dropdowns names
  [TEST_FIT_OFFICE_TYPES.OTHER]: 'Other',
  [TEST_FIT_OFFICE_TYPES.NONE]: 'None',
  // ===================== Old key just for default ===================== //

  // ===================== New keys from TF standards ===================== //
  ...getRoomDisplayNamesByRoomKeys(),
  // ===================== New keys from TF standards ===================== //

  AREA_TARGET_FORM_DROPDOWNS: {
    [TEST_FIT_OFFICE_TYPES.NONE]: 'None',
    [TEST_FIT_GENERAL.POSITIONING.CLOSE_TO]: 'Should be close to',
    [TEST_FIT_GENERAL.POSITIONING.ADJACENCY]: 'Should be adjacent to',
    [TEST_FIT_GENERAL.POSITIONING.EXTERIOR_WINDOWS]: 'Exterior Windows',
    [TEST_FIT_GENERAL.POSITIONING.FLOOR_CORNERS]: 'Floor Corners',
    [TEST_FIT_GENERAL.POSITIONING.BUILDING_CORE]: 'Building Core',
    [TEST_FIT_GENERAL.POSITIONING.ELEVATOR_ENTRANCE]: 'Elevator / Entrance',
    [TEST_FIT_GENERAL.POSITIONING.AMENITY_AREA]: 'Amenity Area',
    [TEST_FIT_GENERAL.POSITIONING.RESTROOMS]: 'Restrooms',
    [TEST_FIT_GENERAL.POSITIONING.FIRE_STAIRS]: 'Fire Escape',
    [TEST_FIT_GENERAL.POSITIONING.LOCATION]: 'Should be located in',
    [TEST_FIT_GENERAL.POSITIONING.NORTH]: 'North',
    [TEST_FIT_GENERAL.POSITIONING.SOUTH]: 'South',
    [TEST_FIT_GENERAL.POSITIONING.EAST]: 'East',
    [TEST_FIT_GENERAL.POSITIONING.WEST]: 'West',
    [TEST_FIT_GENERAL.POSITIONING.SPREAD]: 'Should be Spread',
    [TEST_FIT_GENERAL.POSITIONING.EQUALLY]: 'Equally throughout the floor',
    [TEST_FIT_GENERAL.POSITIONING.TWO_SIDES]: 'On both sides of the core',
    [TEST_FIT_GENERAL.POSITIONING.CENTER]: 'Center of the floor',
    [TEST_FIT_GENERAL.POSITIONING.OUTER_RING]: 'Outer ring',
    [TEST_FIT_GENERAL.POSITIONING.FLOOR_SIDE]: 'Opposite floor side',
  },

  // AreaTargetFormLsf
  AREA_TARGET_FORM_LSF_OFFICE_TYPE: 'Office type',
  AREA_TARGET_FORM_LSF_OFFICE_SIZE: 'Office size',
  AREA_TARGET_FORM_LSF_TARGET: 'Target',
  AREA_TARGET_FORM_LSF_PERCENTAGE: 'Percentage',
  AREA_TARGET_FORM_LSF_ESTIMATE_ROOMS: 'Offices',
  AREA_TARGET_FORM_LSF_ESTIMATE_MEMBERS: 'Members',
  AREA_TARGET_FORM_LSF_ESTIMATE: '',
  AREA_TARGET_FORM_LSF_TOTAL_TOOLTIP: 'Total LSF target must be 100%',
  AREA_TARGET_FORM_LSF_TOTAL: 'Total',
  AREA_TARGET_FORM_LSF_ADD_OFFICE_BUTTON: 'Add another office type',

  // AreaTargetFormPsf
  AREA_TARGET_FORM_PSF_ROOM_TYPE: 'Room type',
  AREA_TARGET_FORM_PSF_ROOM_SIZE: 'Room size',
  AREA_TARGET_FORM_PSF_ROOM_COUNT: 'Ratio type',
  AREA_TARGET_FORM_PSF_RATIO: 'Ratio',
  AREA_TARGET_FORM_PSF_ESTIMATED_ROOMS: 'Rooms',
  AREA_TARGET_FORM_PSF_ESTIMATED_SEATS: 'Seats',
  AREA_TARGET_FORM_PSF_TOTAL: 'Total',
  AREA_TARGET_FORM_PSF_TOTAL_SF: 'Estimated ft²',
  AREA_TARGET_FORM_PSF_TOTAL_SM: 'Estimated M²',
  AREA_TARGET_FORM_PSF_PSF_PER_MEMBER: 'Members per Productivity Seat',
  AREA_TARGET_FORM_PSF_PSF_PER_OFFICE: 'Offices per Productivity Seat',
  AREA_TARGET_FORM_PSF_ADD_ROOM_BUTTON: 'Add another room type',
  AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_MEMBERS: 'Members per Room',
  AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_OFFICES: 'Offices per Room',
  AREA_TARGET_FORM_PSF_ERROR: 'It is advised that PSF total area estimated area does not exceed 20% of the total building area',

  // AreaTargetFormAsf
  AREA_TARGET_FORM_ASF_PER_MEMBER: 'ASF per member',
  AREA_TARGET_FORM_ASF_ROOM_SIZE: 'Room size',
  AREA_TARGET_FORM_ASF_SF_COUNT_RATIO_SQF: 'ft² to Members',
  AREA_TARGET_FORM_ASF_SF_COUNT_RATIO_SQM: 'M² to Members',
  AREA_TARGET_FORM_ASF_SF_COUNT_TOTAL_SQF: 'Room ft²',
  AREA_TARGET_FORM_ASF_SF_COUNT_TOTAL_SQM: 'Room M²',
  AREA_TARGET_FORM_ASF_AMENITY_TYPE: 'Space type',
  AREA_TARGET_FORM_ASF_TYPE: 'Ratio Type',
  AREA_TARGET_FORM_ASF_RATIO: 'Ratio',
  AREA_TARGET_FORM_ASF_SQM_COUNT: 'M² Count / Ratio',
  AREA_TARGET_FORM_ASF_ESTIMATE_SF: 'ft²',
  AREA_TARGET_FORM_ASF_ESTIMATE_SQM: 'M²',
  AREA_TARGET_FORM_ASF_ADD_OFFICE_BUTTON: 'Add another space type',
  AREA_TARGET_FORM_ASF_ERROR: 'It is advised that ASF total area estimated area does not exceed 20% of the total building area',

  // AreaTargetFormFsf
  AREA_TARGET_FORM_FSF_ROOM_TYPE: 'Room type',
  AREA_TARGET_FORM_FSF_NUMBER_OF_ROOMS: 'Rooms',
  AREA_TARGET_FORM_FSF_ROOM_SIZE: 'Room size',

  // AreaTargetFormBathrooms
  AREA_TARGET_FORM_BATHROOMS_EXISTING_M_BATHROOMS: 'Existing male restrooms',
  AREA_TARGET_FORM_BATHROOMS_EXISTING_F_BATHROOMS: 'Existing female restrooms',
  AREA_TARGET_FORM_BATHROOMS_REQUIRED_M_BATHROOMS: 'Required additional male restrooms',
  AREA_TARGET_FORM_BATHROOMS_REQUIRED_F_BATHROOMS: 'Required additional female restrooms',
  AREA_TARGET_FORM_BATHROOMS_REQUIRED_TOOLTIP: 'By the USA International Building Code, 2018 Edition',

  // AreaTargetFormCirculation
  AREA_TARGET_FORM_CIRCULATION_MAIN_AISLES: 'Main Aisles',
  AREA_TARGET_FORM_CIRCULATION_SECONDARY: 'Secondary',

  // AreaTargetFormPositioning
  AREA_TARGET_FORM_POSITIONING_ROOM_TYPE: 'Room type',
  AREA_TARGET_FORM_POSITIONING_REQUIREMENT_TYPE: 'Requirement type',
  AREA_TARGET_FORM_POSITIONING_OFFICE_BUTTON: 'Add another position requirement',
  AREA_TARGET_FORM_POSITIONING_PLACEHOLDER: 'Please select room to position',
  AREA_TARGET_FORM_POSITIONING_EMPTY_ROOM: 'Area type has been removed',

  // AreaTargetFormDepartments
  AREA_TARGET_FORM_DEPARTMENTS_SELECT_ROOM_TYPE: 'Select Room Type',
  AREA_TARGET_FORM_DEPARTMENTS_OFFICE_BUTTON: 'Add another department',
  AREA_TARGET_FORM_DEPARTMENTS_OFFICE_DEPARTMENT_NAME: 'Department name',
  AREA_TARGET_FORM_DEPARTMENTS_OFFICE_Departments_NUMBER: 'Departments No.',
  AREA_TARGET_FORM_DEPARTMENTS_OFFICE_ROOM_TYPE: 'Room type',
  AREA_TARGET_FORM_DEPARTMENTS_OFFICE_ROOMS_NUMBER: 'Rooms No.',

  // AreaTargetFormComments
  AREA_TARGET_FORM_COMMENTS_PLACEHOLDER: 'Add any information you wish to be considered',

  // AreaTargetFormSimpleForm
  AREA_TARGET_FORM_SIMPLE_FORM_LAYOUT_INFO: 'Layout Info',
  AREA_TARGET_FORM_SIMPLE_FORM_TIGHT: 'Tight',
  AREA_TARGET_FORM_SIMPLE_FORM_BASIC: 'Comfort',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACIOUS: 'Spacious',
  AREA_TARGET_FORM_SIMPLE_FORM_EMPLOYEE_NUMBER: 'Number of Employees',
  AREA_TARGET_FORM_SIMPLE_FORM_TYPE: 'Type',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY: 'Room Comfort Level',
  AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH: 'Hi-Tech',
  AREA_TARGET_FORM_SIMPLE_FORM_COWORKING: 'Coworking',
  AREA_TARGET_FORM_SIMPLE_FORM_FINANCE: 'Finance',
  AREA_TARGET_FORM_SIMPLE_FORM_BANKING: 'Banking',
  AREA_TARGET_FORM_SIMPLE_FORM_LAW: 'Law',
  AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE: 'Open Space',
  AREA_TARGET_FORM_SIMPLE_FORM_OTHER: 'Other',
  AREA_TARGET_FORM_SIMPLE_FORM_OTHER_PLACEHOLDER: 'Office Type (e.g. Insurance, Education etc)',
  AREA_TARGET_FORM_SIMPLE_FORM_MODAL_TITLE: 'Resetting advanced data',
  AREA_TARGET_FORM_SIMPLE_FORM_MODAL_DESCRIPTION: 'Changing office types will reset advanced data to default',
  AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_A: 'Enclosed offices: 40% (team offices for 5-8 persons).',
  AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_B: 'Open floor plan: 60% (workspaces with minimal benching for visitors).',
  AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_C: 'Amenity area: Large.',
  AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_D: 'Productive area: Standard conference and collaboration spaces.',
  AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_A: 'Enclosed offices: 60% (office size for 1-8 persons).',
  AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_B: 'Open floor plan: 40% (workspaces and bench-style seating).',
  AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_C: 'Amenity area: Medium-sized.',
  AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_D: 'Productive area: Standard conference and collaboration spaces.',
  AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_A: 'Enclosed offices: 50% (small offices and executive suites).',
  AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_B: 'Open floor plan: 50% (workspaces and no bench space).',
  AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_C: 'Amenity area: Medium-sized.',
  AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_D: 'Productive area: Mix of conference rooms and collaboration spaces.',
  AREA_TARGET_FORM_SIMPLE_FORM_BANKING_DESCRIPTION_A: 'Enclosed offices: 100% (office size for 1-10 persons and executive suites).',
  AREA_TARGET_FORM_SIMPLE_FORM_BANKING_DESCRIPTION_B: 'Amenity area: Small with library.',
  AREA_TARGET_FORM_SIMPLE_FORM_BANKING_DESCRIPTION_C: 'Productive area: Conference rooms (for 4 to 14 persons).',
  AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_A: 'Enclosed offices: 80% (small offices and executive suites).',
  AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_B: 'Open floor plan: 20% (Large cubicle style seating with no bench space).',
  AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_C: 'Amenity area: Small-size with libraries/archives.',
  AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_D: 'Productive area: Conference rooms (for 4 to 14 persons).',
  AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE_DESCRIPTION_A: 'Open floor plan: 100% (bench-style seating and standard size workstations).',
  AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE_DESCRIPTION_B: 'Amenity area: Large.',
  AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE_DESCRIPTION_C: 'Productive area: Varieties conference and collaboration spaces.',
  AREA_TARGET_FORM_SIMPLE_FORM_OTHER_DESCRIPTION_A: 'Custom your own needs; offices type and number of rooms, productive area, conference rooms, amenity spaces, facility rooms, define a cluster of rooms, adjacency and proximity of rooms, etc.',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_TIGHT_A: 'Minimum space required',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_TIGHT_B_SQF: '48" X 24" desk',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_TIGHT_B_SQM: '120cm X 60cm desk',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_A: 'Standard office size',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_B_SQF: 'Basic 48" X 30" desk',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_B_SQM: 'Basic 120cm X 75cm desk',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_C: 'Shelves and drawers',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_A: 'Roomy office',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_B_SQF: 'Extended 60" X 30" desk',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_B_SQM: 'Extended 152cm X 75cm desk',
  AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_C: 'Additional storage area and small huddle/setting area',

  // AddButton
  ADD_BUTTON_ADD_ANOTHER_SWAPP_PROFILE: 'Add another Swapp Profile',

  // FloorCol
  FLOOR_COL_WORKSTATION_SEAT: 'Employee : Workstation seat',
  FLOOR_COL_ENTER_FIELD: 'Please enter this field.',
  FLOOR_COL_ENTER_INPUT: 'Please enter your input.',

  // EditDepartmentsPanel
  EDIT_DEPARTMENTS_PANEL_NEW_ROW: 'Add Department',
  EDIT_DEPARTMENTS_PANEL_DEALLOCATE_ROOM: 'Unassigned Rooms',
  EDIT_DEPARTMENTS_PANEL_DEPARTMENT_NAME_PLACEHOLDER: 'Add Department Name',
  EDIT_DEPARTMENTS_PANEL_ASSIGN_ROOMS_TOOLTIP: 'Add selected rooms to Department',
  EDIT_DEPARTMENTS_PANEL_DEALLOCATE_ROOMS_TOOLTIP: 'Unassign rooms from Departments',
  EDIT_DEPARTMENTS_PANEL_DELETE_DEPARTMENT_TOOLTIP: 'Delete Department',
  EDIT_DEPARTMENTS_PANEL_DELETE_MODAL_TITLE: 'Delete Department',
  EDIT_DEPARTMENTS_PANEL_DELETE_MODAL_CONTENT: 'Are you sure you want to delete this Department?',
  EDIT_DEPARTMENTS_PANEL_DELETE_MODAL_CANCEL_TEXT: 'Cancel',
  EDIT_DEPARTMENTS_PANEL_NO_SELECTED_ROOMS_MESSAGE: 'Please select one or more Rooms',

  // ProfileDevelopmentAppraisalPanel
  PROFILE_DEVELOPMENT_APPRAISAL_PANEL_AREA_TYPE: 'Area Type',
  PROFILE_DEVELOPMENT_APPRAISAL_PANEL_TOTAL: 'Total',
  PROFILE_DEVELOPMENT_APPRAISAL_PANEL_PRICE_PER_AREA: (_key, context) => `price per ${context.areaType} (Est.)`,
  // TestFitEditPanelContainer
  TEST_FIT_EDIT_PANEL_CONTAINER_EDIT_ROOMS_TITLE: 'Edit Room',
  TEST_FIT_EDIT_PANEL_CONTAINER_EDIT_TILES_TITLE: 'Edit Tiles',
  TEST_FIT_EDIT_PANEL_CONTAINER_EDIT_DEPARTMENTS_TITLE: 'Edit Departments',

  // RoomActionButton
  ROOM_ACTION_BUTTON_LOCK_ROOMS_TOOLTIP: 'Lock selected Room',
  ROOM_ACTION_BUTTON_UNLOCK_ROOMS_TOOLTIP: 'Unlock selected Room',
  ROOM_ACTION_BUTTON_EDIT_ROOMS_TOOLTIP: 'Swap selected Room',
  ROOM_ACTION_BUTTON_EDIT_TILES_TOOLTIP: 'Swap selected Tiles',
  ROOM_ACTION_BUTTON_EDIT_DEPARTMENTS_TOOLTIP: 'Swap selected Departments',
  ROOM_ACTION_BUTTON_DELETE_ROOMS_TOOLTIP: 'Unassign selected room',
  ROOM_ACTION_BUTTON_DELETE_TILES_TOOLTIP: 'Unassign selected Tiles',
  ROOM_ACTION_BUTTON_DELETE_DEPARTMENTS_TOOLTIP: 'Unassign selected rooms from Departments',

  // GenerateSwappContainer
  GENERATE_SWAPP_CONTAINER_TITLE: 'CREATE A PROJECT',
  GENERATE_SWAPP_CONTAINER_SUBTITLE: 'Enter property info',
  GENERATE_SWAPP_CONTAINER_PROJECT_INFO: 'PROJECT INFO',
  GENERATE_SWAPP_CONTAINER_UPLOADING_FILES: 'Uploading files',
  GENERATE_SWAPP_CONTAINER_RENOVATION_TAB: 'Renovation',
  GENERATE_SWAPP_CONTAINER_GROUND_UP_TAB: 'Ground-up',
  GENERATE_SWAPP_CONTAINER_PREFERRED_FLOORS_LABEL: 'Preferred Number of Floors',
  GENERATE_SWAPP_CONTAINER_PREFERRED_FLOORS_ERROR: 'Enter Preferred Number of Floors',
  GENERATE_SWAPP_CONTAINER_PREFERRED_FLOORS_PLACEHOLDER: 'Enter Preferred Number of Floors, 0 for n/a',

  // ProjectInfoContainer
  PROJECT_INFO_CONTAINER_TITLE: 'Project Info',
  PROJECT_INFO_CONTAINER_SUBTITLE: 'Edit property info',

  // MapComponent
  FLOOR_COL_TENANT_TYPE_PLACEHOLDER: 'Tenant type',
  MAP_COMPONENT_LOCATION_LABEL: 'Location',
  MAP_COMPONENT_SEARCH_PLACEHOLDER: 'Enter the location',
  MAP_COMPONENT_SEARCH_ERROR: 'Enter Location',

  // PlanUpload
  PLAN_UPLOAD_CREATE_PROJECT: 'CREATE A PROJECT',
  PLAN_UPLOAD_START_TEXT: 'Upload your floor plans',
  PLAN_UPLOAD_START_ERROR: 'Upload plans',
  PLAN_UPLOAD_BUTTON: 'Upload',
  PLAN_UPLOAD_NEXT_BUTTON: 'NEXT',

  // SetLocation
  SET_LOCATION_SELECT_LOCATION: 'Please select a location for your future office',
  SET_LOCATION_PROJECT_NAME_LABEL: 'Project name',
  SET_LOCATION_PROJECT_NAME_PLACEHOLDER: 'Enter a project name',
  SET_LOCATION_PROJECT_NAME_ERROR: 'Enter a project name',
  SET_LOCATION_FLOOR_LABEL: 'Floor',
  SET_LOCATION_FLOOR_TYPE: 'Type',
  SET_LOCATION_FLOOR_PLACEHOLDER: 'Enter floor',
  SET_LOCATION_FLOOR_ERROR: 'Enter floor',
  SET_LOCATION_TYPE_PLACEHOLDER: 'Enter Type name',
  SET_LOCATION_TYPE_ERROR: 'Type can not be empty',
  SET_LOCATION_SELECT_SWAPP_CREATED: 'Swapp Created',
  SET_LOCATION_SELECT_BLOCK_NOT_FOUND: 'Block was not found. Try another one.',
  SET_LOCATION_STATUS: 'Status',
  SET_LOCATION_ARCHIVE_MODAL_OK: 'Yes',
  SET_LOCATION_ARCHIVE_MODAL_CANCEL: 'No',
  SET_LOCATION_ARCHIVE_MODAL_TITLE: 'Are you sure you want to archive this project?',
  SET_LOCATION_ARCHIVE_MODAL_DESCRIPTION: 'Archived projects can be accessed later by filtering the project list',
  SET_LOCATION_DELETE_MODAL_TITLE: 'Are you sure you want to Delete this project?',

  NEW_PROJECT_ADRESS: 'Address',
  NEW_PROJECT_BLOCK: 'Block',
  NEW_PROJECT_ADRESS_LOWER_CASE: 'address',
  NEW_PROJECT_BLOCK_LOWER_CASE: 'block',
  NEW_PROJECT_SEARCH_BY: (_key, context) => `Search by ${context}`,
  NEW_PROJECT_SHOW_BLOCKS_AND_LOTS: 'Show block and lots',
  NEW_PROJECT_SEARCH_FOR: 'Search for:',
  NEW_PROJECT_LOTS: 'Lots',
  NEW_PROJECT_SEARCH_A_LOT: 'Search a lot',
  NEW_LOCATION_SELECT_LOT_NOT_FOUND: (_key, { lot, block }) => `Lot ${lot} was not found for block ${block}. Try another one.`,
  NEW_PROJECT_ERROR_UNABLE_TO_GENERATE_POLYGON: 'Unable to generate polygon',
  NEW_PROJECT_ERROR_MULTIPOLYGON: 'Please make sure lots are adjacent to each other',
  NEW_PROJECT_ERROR_NO_POLYGON: 'Please make a polygon',

  // WaitingGeneration
  WAITING_GENERATION_NOTIFICATION: 'Your floor plan is being generated',
  WAITING_GENERATION_NOTIFICATION_MARKUPS: 'Your markups have been submitted.',
  WAITING_GENERATION_NOTIFICATION_QUEUE: 'Your request was submitted and is now awaiting processing.',
  WAITING_GENERATION_HINTS: 'We will notify you by email when it\'s ready',
  WAITING_GENERATION_HINTS_MARKUPS: 'A corrected version will be ready shortly',
  WAITING_GENERATION_HINTS_QUEUE: 'We will notify you when processing begins.',
  WAITING_GENERATION_QUESTION: 'Any questions? don’t hesitate to',
  WAITING_GENERATION_CONTACT_US: 'contact us',
  WAITING_GENERATION_STEPS_ONE_TITLE: 'QUEUING',
  WAITING_GENERATION_STEPS_ONE_DESCRIPTION: 'In Queue',
  WAITING_GENERATION_STEPS_TWO_TITLE: 'REGULATION',
  WAITING_GENERATION_STEPS_TWO_DESCRIPTION: 'Examining local regulation',
  WAITING_GENERATION_STEPS_THREE_TITLE: 'GENERATE',
  WAITING_GENERATION_STEPS_THREE_DESCRIPTION: 'Generating a plan',
  WAITING_GENERATION_STEPS_FOUR_TITLE: 'FINISH OFF',
  WAITING_GENERATION_STEPS_FOUR_DESCRIPTION: 'Wrapping up',

  // WaitingForSubmit
  WAITING_FOR_SUBMIT_NOTIFICATION: 'Program Layout Not Submitted',
  WAITING_FOR_SUBMIT_HINTS: 'Please go back to Setup and complete the form',
  WAITING_FOR_SUBMIT_BUTTON: 'Go to Setup',

  // LayoutsMissing
  LAYOUTS_MISSING_NOTIFICATION: 'Not Available',
  LAYOUTS_MISSING_HINTS: 'A comparison is available only with 2 or more processed layouts',

  // ===============Test-Fit===============

  // SwappsDetails
  SWAPPS_DETAILS_OPEN_VIEWER: 'Open viewer',
  SWAPPS_DETAILS_SWAPP_PROFILE: 'Swapp profile #',
  SWAPPS_DETAILS_MORE_OPEN_SPACE: 'More open space, max',
  SWAPPS_DETAILS_REFINE_YOUR_TENANT_PROFILE_TO_SUIT_THEIR_NEEDS: 'Refine your tenant profile to suit their needs',
  SWAPPS_DETAILS_TOTAL_EMPLOYEE_SEATS: 'Total employee seats',
  SWAPPS_DETAILS_TOTAL_COLLABORATION_SEATS: 'Total collaboration seats',

  // SwappsSelection
  SWAPPS_SELECTION_SWAPP_READY: 'SWAPPS PROFILES READY FOR YOUR REVIEW',
  SWAPPS_SELECTION_SELECT_LAYOUT: 'Select between the different options below, your desired layout',
  SWAPPS_SELECTION_VIEW_DETAILS: 'View details',

  // OverallContainer
  OVERALL_CONTAINER_PROCEED_SD: 'Proceed to Schematic Design',
  OVERALL_CONTAINER_RSF: 'RSF',
  OVERALL_CONTAINER_RSF_COLUMN: 'model / target',
  OVERALL_CONTAINER_RENTABLE: 'Rentable area',
  OVERALL_CONTAINER_LSF: 'LSF',
  OVERALL_CONTAINER_LEASABLE_AREA: 'Leasable area',
  OVERALL_CONTAINER_PSF: 'PSF',
  OVERALL_CONTAINER_PRODUCTIVE_AREA: 'Productive area',
  OVERALL_CONTAINER_ASF: 'ASF',
  OVERALL_CONTAINER_AMENITY_SPACES: 'Amenity spaces',
  OVERALL_CONTAINER_FSF: 'FSF',
  OVERALL_CONTAINER_FACILITY_SPACES: 'Facility spaces',
  OVERALL_CONTAINER_BATHROOMS: 'RESTROOMS',
  OVERALL_CONTAINER_NAME: 'Name',
  OVERALL_CONTAINER_USER_REQUIREMENT: 'User Requirement',
  OVERALL_CONTAINER_SWAPP_MODEL: 'Rooms\nmodel / target',
  OVERALL_CONTAINER_SWAPP_ESTIMATIONS: 'Estimations',
  OVERALL_CONTAINER_SQFT_IN_MODEL: 'ft² \n in model',
  OVERALL_CONTAINER_SQM_IN_MODEL: 'M² \n in model',
  OVERALL_CONTAINER_OFFICES: 'Offices',
  OVERALL_CONTAINER_DEPARTMENTS: 'Departments',
  OVERALL_CONTAINER_OFFICE_SIZE: 'Office Size',
  OVERALL_CONTAINER_ROOM_SIZE: 'Room Size',
  OVERALL_CONTAINER_TARGET: 'Target',
  OVERALL_CONTAINER_AREA: 'Area',
  OVERALL_CONTAINER_ROOMS: 'Rooms',
  OVERALL_CONTAINER_TARGET_IN_MODAL: 'Percentage\n model / target',
  OVERALL_CONTAINER_AVERAGE_ROOM_SIZE: 'Average \n Room Size',
  OVERALL_CONTAINER_OFFICE_NUM: 'No. Offices',
  OVERALL_CONTAINER_ROOM_NUM: 'Rooms\nmodel / target',
  OVERALL_CONTAINER_AREA_FSF: '\nmodel / target',
  OVERALL_CONTAINER_MEMBERS_NUM: 'No. \nMembers',
  OVERALL_CONTAINER_SEATS_NUM: 'Seats\nmodel / target',
  OVERALL_CONTAINER_SEATS: 'Seats',
  OVERALL_CONTAINER_TOTAL_AREA: 'Total Area',
  OVERALL_CONTAINER_TOTAL_RSF_AREA: 'Total Floor Area',
  OVERALL_CONTAINER_TOTAL_USF_AREA: 'Total USF Area',
  OVERALL_CONTAINER_TOTAL_SF: 'Total ft²',
  OVERALL_CONTAINER_TOTAL_SQM: 'Total M²',
  OVERALL_CONTAINER_COAST_SQF: 'Cost Per ft²',
  OVERALL_CONTAINER_COAST_SQM: 'Cost Per M²',
  OVERALL_CONTAINER_TOTAL: 'Total',
  OVERALL_CONTAINER_PSF_SEATS_PER_MEMBER: 'Members per Productivity Seat',
  OVERALL_CONTAINER_PSF_SEATS_PER_OFFICE: 'Offices per Productivity Seat',
  OVERALL_CONTAINER_ASF_PER_MEMBER: 'ASF per member',
  OVERALL_CONTAINER_SF_PER_MEMBER: 'ft² per member',
  OVERALL_CONTAINER_SQM_PER_MEMBER: 'M² per member',
  OVERALL_CONTAINER_EXISTING_M_BATHROOMS: 'Existing Male Restrooms',
  OVERALL_CONTAINER_EXISTING_F_BATHROOMS: 'Existing Female Restrooms',
  OVERALL_CONTAINER_REQUIRED_M_BATHROOMS: 'Required additional Male Restrooms',
  OVERALL_CONTAINER_REQUIRED_F_BATHROOMS: 'Required additional Female Restrooms',
  OVERALL_CONTAINER_MODAL_TITLE: 'Proceed to Schematic Design?',
  OVERALL_CONTAINER_MODAL_CONTENT: 'This will approve and finalize this Test Fit, and will lock it from future changes. Are you sure?',
  // TODO: use correct const instead of 'Rooms'
  OVERALL_CONTAINER_PER_ROOM: (_key, context) => `${context.number} ${context.type}${context.type === 'Rooms' ? '' : ' per room'}`,
  OVERALL_CONTAINER_MODAL_MEMBER: 'Member',
  OVERALL_CONTAINER_MODAL_OFFICE: 'Office',
  OVERALL_CONTAINER_MODAL_PLEASE_SELECT: 'Please Select Layout',

  // RegulationContainer
  REGULATION_CONTAINER_BUILDING_INFORMATION: 'Building Information',
  REGULATION_CONTAINER_BUILDING_CODES: 'Building Codes',
  REGULATION_CONTAINER_DISTANCE_CHART: 'Distance Chart',
  REGULATION_CONTAINER_SITE_INFORMATION: 'Site Information',
  REGULATION_CONTAINER_SITE_DESIGN: 'Design',
  REGULATION_CONTAINER_REGULATION_LEGEND: 'Regulation',
  REGULATION_CONTAINER_PRECEDENTS_LEGEND: 'Precedents',

  REGULATION_CONTAINER_ADDRESS: 'Address',
  REGULATION_CONTAINER_BUILDING_AREA: 'Building Area',
  REGULATION_CONTAINER_LOT_AREA: 'Lot Area',
  REGULATION_CONTAINER_BUILDING_HEIGHT: 'Building Height',
  REGULATION_CONTAINER_OCCUPANCY_CLASSIFICATION: 'Occupancy Classification',
  REGULATION_CONTAINER_CONSTRUCTION_TYPE: 'Construction Type',
  REGULATION_CONTAINER_CONSTRUCTION_MATERIALS: 'Construction Materials',
  REGULATION_CONTAINER_JURISDICTION: 'Jurisdiction',
  REGULATION_CONTAINER_BUILDING_GROSS_FOOTPRINT: 'Building Gross footprint',
  REGULATION_CONTAINER_TOTAL_CONSTRUCTION: 'Total Construction',
  REGULATION_CONTAINER_FLAT_SIZE: 'Flat size',

  REGULATION_CONTAINER_OCCUPANCY_LOAD: 'Occupancy Load',
  REGULATION_CONTAINER_TRAVEL_DISTANCE: 'Travel Distance',
  REGULATION_CONTAINER_PRIMARY_MAXIMUM: 'Primary Maximum Egress path',
  REGULATION_CONTAINER_SECONDARY_MAXIMUM: 'Secondary Maximum Egress path',
  REGULATION_CONTAINER_MAXIMUM_DEAD_END: 'Maximum Dead End Corridor',
  REGULATION_CONTAINER_NUMBER_OF_STAIRS: 'Number of stairs per occupancy',
  REGULATION_CONTAINER_MAXIMUM_COMMON_PATH: 'Maximum Common Path',
  REGULATION_CONTAINER_MAXIMUM_BUILDING_DISTANCE: 'Maximum Building Distance',
  REGULATION_CONTAINER_MINIMUM_CORRIDOR_WIDTH: 'Minimum Corridor Width',
  REGULATION_CONTAINER_DOOR_CAPACITY: 'Door Capacity',
  REGULATION_CONTAINER_STAIR_CAPACITY: 'Stair Capacity',
  REGULATION_CONTAINER_TOTAL_EXIT_CAPACITY: 'Total Exit capacity from floor',
  REGULATION_CONTAINER_ADA_PHONE_ROOMS: 'Accessible Phone Room',
  REGULATION_CONTAINER_ACCESSIBLE_ADA_OFFICES: 'ADA Accessible office percent',
  REGULATION_CONTAINER_ACCESSIBLE_ADA_CONFERENCE_ROOMS: 'ADA Accessible conference rooms percent',

  // NoiseContainer
  NOISE_CONTAINER_DISTANCE_NOISE: 'noise',
  NOISE_CONTAINER_DISTANCE_NOISE_SUBTITLE: 'Noise mapping of the public areas',
  NOISE_CONTAINER_RECOMMENDED_ACOUSTIC_WALLS: 'Recommended Acoustic Walls',
  NOISE_CONTAINER_NOISE_RESOURCE: 'Noise Resource',
  NOISE_CONTAINER_NOISE_AVOIDING_PERCENTAGE: 'Offices not Facing Noise Hazard Zones',

  // PricingContainer
  PRICING_CONTAINER_AREA_TITLE: 'Cost Per Area',
  PRICING_CONTAINER_AREA_DESCRIPTION: 'Estimate cost per sf by area type',
  PRICING_CONTAINER_ITEM_TITLE: 'Cost Per Item',
  PRICING_CONTAINER_ITEM_DESCRIPTION: 'Breakdown of construction & FF&E Items',
  PRICING_CONTAINER_AREA_TYPE: 'Area Type',
  PRICING_CONTAINER_PRICE_PER_SQF: 'Price per ft² (Est.)',
  PRICING_CONTAINER_PRICE_PER_SQM: 'Price per M² (Est.)',
  PRICING_CONTAINER_TOTAL: 'Total',
  PRICING_CONTAINER_TYPE: 'Type',
  PRICING_CONTAINER_ITEM: 'Item',
  PRICING_CONTAINER_COUNT_SQF: 'Count / ft²',
  PRICING_CONTAINER_COUNT_SQM: 'Count / M²',
  PRICING_CONTAINER_UNIT_SQF: 'Unit/ft²',
  PRICING_CONTAINER_UNIT_SQM: 'Unit/M²',
  PRICING_CONTAINER_PRICE: 'Price',
  PRICING_CONTAINER_UNIT: 'Unit',
  PRICING_CONTAINER_SF: 'ft²',
  PRICING_CONTAINER_ERROR: 'No Budget Information',
  PRICING_CONTAINER_ERROR_DESCRIPTION: 'This layout does not contain budget information.',

  // CirculationContainer
  CIRCULATION_CONTAINER_CIRCULATION: 'circulation',
  CIRCULATION_CONTAINER_CIRCULATION_SUBTITLE: 'The movement density on the floor',
  CIRCULATION_CONTAINER_RECOMMENDED_ACOUSTIC_WALLS: 'Recommended Acoustic Walls',
  CIRCULATION_CONTAINER_NOISE_RESOURCE: 'Noise Resource',
  CIRCULATION_CONTAINER_NOISE_AVOIDING_PERCENTAGE: 'Offices not Facing Noise Hazard Zones',
  CIRCULATION_CONTAINER_AVERAGE_ELEVATOR_OFFICE_DISTANCE: 'Average distance from elevator to an office',
  CIRCULATION_CONTAINER_MAX_ELEVATOR_OFFICE_DISTANCE: 'Longest distance from elevator to an office',
  CIRCULATION_CONTAINER_AVERAGE_BATHROOMS_OFFICE_DISTANCE: 'Average distance from restrooms to an office',
  CIRCULATION_CONTAINER_AVERAGE_AMENITY_OFFICE_DISTANCE: 'Average distance from amenity area to an office',
  CIRCULATION_CONTAINER_AVERAGE_CONFERENCE_OFFICE_DISTANCE: 'Average distance from conference room to an office',
  CIRCULATION_CONTAINER_AVERAGE_PHONE_OFFICE_DISTANCE: 'Average distance from phone room to an office',
  CIRCULATION_CONTAINER_HIGH_TRAFFIC_CIRCULATION: 'Number of offices facing high traffic circulation',

  // OfficeProportions
  OFFICE_PROPORTIONS_MIN: 'Small',
  OFFICE_PROPORTIONS_AVG: 'Standard',
  OFFICE_PROPORTIONS_MAX: 'Large',
  OFFICE_PROPORTIONS_OFFICES: 'Offices',
  OFFICE_PROPORTIONS_OFFICE: 'Office',
  OFFICE_PROPORTIONS_ROOMS: 'Rooms',
  OFFICE_PROPORTIONS_ROOM: 'Room',

  // LsfContainer
  LSF_CONTAINER_OFFICE_PROPORTIONS: 'Office Proportions',

  // PsfContainer
  PSF_CONTAINER_ROOM_PROPORTIONS: 'Room Proportions',

  // FlexibilityContainer
  FLEXIBILITY_CONTAINER_FLEXIBILITY: 'Flexibility',
  FLEXIBILITY_CONTAINER_SUBTITLE: 'Re-work comparison between modular to conventional design & build',
  FLEXIBILITY_CONTAINER_MODULAR: 'Modular\nDesign & Build',
  FLEXIBILITY_CONTAINER_CONVENTIONAL: 'Conventional\nDesign & Build',

  // VisualProgram
  VISUAL_PROGRAM_ERROR_NO_SF: 'Please enter a target Total Area',
  VISUAL_PROGRAM_ERROR_NO_ROOMS: 'Please add rooms and offices at the program form',
  VISUAL_PROGRAM_ERROR_PROGRAM_EXCEED: 'The area of the required rooms\nexceeds the total available space.',
  VISUAL_PROGRAM_TITLE: 'Visual Program',
  VISUAL_PROGRAM_DESCRIPTION: 'Room and offices area diagram',
  VISUAL_PROGRAM_CLOSE: 'Close Visual program',
  VISUAL_PROGRAM_OPEN: 'Open Visual program',

  // ForgeDisplayToggle
  FORGE_DISPLAY_TOGGLE_2D: 'Move to 2D View',
  FORGE_DISPLAY_TOGGLE_3D: 'Move to 3D View',

  // ===============Pages===============
  SUCCESS_NOTIFICATION_PAGE_NOTIFICATION: 'Account created',
  SUCCESS_NOTIFICATION_PAGE_HINTS: 'Log in to your account',

  SUCCESS_PASSWORD_RESET_PAGE_NOTIFICATION: 'Password Updated',
  SUCCESS_PASSWORD_RESET_PAGE_HINTS: 'Log in to your account with your\n'
      + 'new password.',

  // ===============Utils===============

  // ForgeViewer
  FORGE_VIEWER_STARTING_VIEWER: 'Starting Viewer...',
  FORGE_VIEWER_NO_TOKEN: 'Could not find token',
  FORGE_VIEWER_NO_DOCUMENT: 'Could not find document',

  // ===============Feasibility===============

  // StudiesContainer
  STUDIES_CONTAINER_OPTION: 'Design',
  STUDIES_CONTAINER_POINTS: 'Points',
  STUDIES_CONTAINER_STUDY: 'Study',
  STUDIES_CONTAINER_STUDY_DESCRIPTION: 'Masses scheme',

  // unitTypes:
  ONE_BED: '1 Bed',
  TWO_BED: '2 Bed',
  THREE_BED: '3 Bed',
  FOUR_BED: '4 Bed',
  FOUR_BED_B: '4 Bed Large',
  STUDIO: 'Studio',
  RYAN_STUDIO: 'Studio',
  RYAN_ONE_BED: '1 Bed',
  RYAN_ONE_BED_B: '1 Bed B',
  RYAN_TWO_BED: '2 Bed',
  RYAN_THREE_BED: '3 Bed',
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  ACCESSIBLE: 'Accessible',
  WDC_STUDIO: 'Studio',
  WDC_1B2P: '1B2P',
  WDC_2B3P: '2B3P',
  WDC_2B4P: '2B4P',
  WDC_3B4P: '3B4P',
  WDC_3B5P: '3B5P',
  WDC_3B6P: '3B6P',

  IL_ONE_BED: '1 Room',
  IL_TWO_BED: '2 Rooms',
  IL_THREE_BED: '3 Rooms',
  IL_FOUR_BED: '4 Rooms',
  IL_FIVE_BED: '5 Rooms',
  IL_SIX_BED: '6 Rooms',
  IL_HANAYA: 'P',
  IL_HANUT: 'Retail',

  DEMO_CLASSROOM: 'Classroom',
  DEMO_SE_CLASSROOM: 'SE Classroom',
  DEMO_TEACHER_OFFICE: 'Teacher Office',
  DEMO_AUDITORIUM: 'Auditorium',
  DEMO_ARCHIVES: 'Archives',
  DEMO_GUARD: 'Guard',
  DEMO_SERVICE: 'Service',
  DEMO_OFFICE: 'Office',
  DEMO_PERSONAL_TEACHING: 'Personal Teaching',
  DEMO_TEACHERS_LOUNGE: 'Teachers Lounge',
  DEMO_ADMINISTRATION: 'Administration',
  DEMO_DIRECTOR: 'Director',
  DEMO_MEETING_ROOM: 'Meeting Room',
  DEMO_LOUNGE: 'Lounge',
  DEMO_STUDENT_COUNCIL: 'Student Council',
  DEMO_STORAGE: 'Storage',
  DEMO_LIBRARY: 'Library',
  DEMO_CAFETERIA: 'Cafeteria',
  DEMO_SCIENCE_LAB: 'Science Lab',
  DEMO_COMPUTER_ROOM: 'Computer Room',
  DEMO_RESTROOM: 'Restroom',
  DEMO_STUDY_AREA: 'Communal Study Area',
  DEMO_ONE_BED: '1 Room',
  DEMO_TWO_BED: '2 Rooms',
  DEMO_THREE_BED: '3 Rooms',
  DEMO_FOUR_BED: '4 Rooms',
  DEMO_FIVE_BED: '5 Rooms',
  DEMO_SIX_BED: '6 Rooms',
  DEMO_HANAYA: 'P',
  DEMO_HANUT: 'Retail',
  DEMO_PUBLIC: 'Public',
  DEMO_PREP_ROOM: 'Science Prep Room',
  DEMO_CLASSROOM_LARGE: 'Large Classroom',
  DEMO_LARGE_STUDY: 'Large Study Area',
  DEMO_SHARED_OFFICE: 'Shared Office',
  DEMO_LOCKERS_ROOM: 'Lockers Room',
  DEMO_CLASSROOM_MEDIUM: 'Medium Classroom',
  DEMO_CLASSROOM_SMALL: 'Small Classroom',
  DEMO_GENERAL_OFFICE: 'General Office',
  DEMO_SPORT_HALL: 'Sport Hall',
  DEMO_IT: 'IT Room',
  DEMO_CLASSROOM_STUDIO: 'Studio',
  DEMO_ENTRY_HALL: 'Entry Hall',
  DEMO_ACTIVITY_STUDIO: 'Activity Studio',
  DEMO_SMALL_STUDY: 'Small Study Area',
  DEMO_KITCHEN: 'Kitchen',

  LOBBY_LOUNGE: 'Lobby / Lounge',
  MAILROOM: 'Mailroom',
  TENNANT_CLUB: 'Tenant Club',
  FITNESS: 'Fitness',
  LAUNDRY: 'Laundry',
  GAME_ROOM: 'Game room',
  LIBRARY: 'Library',
  COMPUTER_ROOM: 'Computer room',
  COMMON_GOOD: 'Common Good',
  PETS_SHOWER_ROOM: 'Pets shower',
  SHARED_KITCHEN: 'Shared Kitchen',
  SHARED_AMENITY: 'Shared Amenity',
  COWORKING_OFFICE: 'Coworking Office',
  MECHANICAL_ROOM: 'Mechanical Area',

  WV1_A1: 'One Bed Apt.',
  WV1_B1: 'Two Bed Apt.',
  WV1_C1: 'Three Bed Apt.',
  WV1_STAIRCASE_CORE: 'Only stairs',
  WV1_2_ELEVATORS_CORE: '2 Elevators',
  WV1_3_ELEVATORS_CORE: '3 Elevators',
  WV1_4_ELEVATORS_CORE: '4 Elevators',
  WV1_CORRIDOR: 'Corridor',

  PBK_COMMUN_HALL: 'Entry Hall',
  PBK_COMMUN_STUDY: 'Large Study Area',
  PBK_COMMUN_AUDITORIUM: 'Auditorium',
  PBK_COMMUN_STUDIO: 'Activity Studio',
  PBK_COMMUN_LOUNGE: 'Lounge',
  PBK_COMMUN_LIBRARY: 'Library',
  PBK_COMMUN_CAFETERIA: 'Cafeteria',
  PBK_COMMUN_SPORT: 'Sport Hall',
  PBK_ADMIN_RR: 'Restroom',
  PBK_ADMIN_SEC: 'Secretary',
  PBK_ADMIN_PRINCIPAL: 'Principal',
  PBK_ADMIN_MEETING: 'Meeting Room',
  PBK_ADMIN_LOUNGE: 'Lounge',
  PBK_ADMIN_SPEECH: 'Speech',
  PBK_ADMIN_OFF: 'Office',
  PBK_ADMIN_STORAGE: 'Storage',
  PBK_ADMIN_DIAG: 'Diag',
  PBK_ADMIN_CLINIC: 'Clinic',
  PBK_CIRCULATION_ELEV: 'Elevator',
  PBK_CIRCULATION_STAIRS: 'Stairs',
  PBK_CIRCULATION_HALLWAY: 'Hallway',
  PBK_SUPPORT_SHAFT: 'Shaft',
  PBK_SUPPORT_ARCHIVES: 'Archives',
  PBK_SUPPORT_PREP: 'Prep Room',
  PBK_SUPPORT_GUARD: 'Guard',
  PBK_SUPPORT_SERVICE: 'Service',
  PBK_SUPPORT_STORAGE: 'Storage',
  PBK_SUPPORT_RR: 'Restroom',
  PBK_SUPPORT_LOCKERS: 'Lockers Room',
  PBK_CLASSROOM_PRE_K: 'Pre-K',
  PBK_CLASSROOM_K: 'Kindergarden',
  PBK_CLASSROOM_MUSIC: 'Music',
  PBK_CLASSROOM_1ST: '1st Grade',
  PBK_CLASSROOM_2ND: '2nd Grade',
  PBK_CLASSROOM_ART: 'SE Classroom',
  PBK_PERSONAL_TEACHING: 'Personal Teaching',
  PBK_SCIENCE_LAB: 'Science Lab',
  PBK_COMPUTER_ROOM: 'Computer Room',

  [BUILDING_INFO_KEYS.CORE]: 'Core',
  [COMMUNAL_SPACES.ELEVATORS_CORE]: 'Elevators Core',
  [COMMUNAL_SPACES.STAIRCASE_CORE]: 'Staircase Core',
  [COMMUNAL_SPACES.SHAFT]: 'Shaft',
  [COMMUNAL_SPACES.FULL_CORE]: 'Full Core',
  [COMMUNAL_SPACES.CORRIDOR]: 'Corridor',
  [COMMUNAL_SPACES.RETAIL]: 'Retail',
  [BUILDING_INFO_KEYS.BCR]: 'BCR',
  [BUILDING_INFO_KEYS.NIA]: 'NIA',
  [BUILDING_INFO_KEYS.GIA]: 'GIA',
  [BUILDING_INFO_KEYS.NIA_TO_GIA]: 'NIA / GIA',
  [BUILDING_INFO_KEYS.FAR]: 'FAR',
  [BUILDING_INFO_KEYS.STORIES]: 'Stories',
  [BUILDING_INFO_KEYS.TOTAL_APARTMENTS]: 'Total Apt.',
  [COMMUNAL_SPACES.CORRIDOR]: 'Corridor',
  [COMMUNAL_SPACES.OTHER]: 'Other',
  [BUILDING_INFO_KEYS.COMMUNAL_AREA]: 'Communal Area',
  [BUILDING_INFO_KEYS.ENVELOPE_AREA]: 'Envelope Area',
  [BUILDING_INFO_KEYS.NO_OF_MASSES]: 'No. of Masses',
  [BUILDING_INFO_KEYS.FULL_CORE]: 'Full Core',
  [BUILDING_INFO_KEYS.APARTMENT_PER_FLOOR]: 'Apt. per floor',
  [BUILDING_INFO_KEYS.APARTMENT_PER_BUILDING]: 'Apt. per Building',
  [BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS]: 'Single Flat Aspects',
  [BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS]: 'Multi Flat Aspect',
  [BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS]: 'Non-Typical Apartments',
  [BUILDING_INFO_KEYS.THREE_FLAT_ASPECTS]: '3+ Flat Aspects',
  [BUILDING_INFO_KEYS.ROOF_APARTMENT]: 'Roof Apt.',
  [BUILDING_INFO_KEYS.GARDEN_APARTMENT]: 'Garden Apt.',
  [BUILDING_INFO_KEYS.NORTH]: 'North',
  [BUILDING_INFO_KEYS.SOUTH]: 'South',
  [BUILDING_INFO_KEYS.EAST]: 'East',
  [BUILDING_INFO_KEYS.WEST]: 'West',
  [BUILDING_INFO_KEYS.PRIORITY_FIT_SCORE]: 'Priority-Fit Score',
  [BUILDING_INFO_KEYS.CONST_COST_SCORE]: 'Const. Cost',
  [BUILDING_INFO_KEYS.MARKETABILITY_SCORE]: 'Marketability',
  [BUILDING_INFO_KEYS.WELLBING_SCORE]: 'Occupant Wellbeing',
  [BUILDING_INFO_KEYS.SUFFICIENT_DAYLIGHT]: 'Apt. with sufficient daylight',
  [BUILDING_INFO_KEYS.SUFFICIENT_VIEW]: 'Apt. with sufficient view',
  [BUILDING_INFO_KEYS.SUFFICIENT_WIND]: 'Apt. with sufficient wind',
  [BUILDING_INFO_KEYS.SUFFICIENT_VSC]: 'Apt. with sufficient VSC',
  [BUILDING_INFO_KEYS.PH_HLFF]: 'Heat Loss Form Factor (HLFF)',
  [BUILDING_INFO_KEYS.PH_SVR]: 'Surface to Volume Ratio (SVR)',
  [BUILDING_INFO_KEYS.PARKING_AREA]: 'Parking Area',
  [BUILDING_INFO_KEYS.PARKING_PERCENTAGE]: 'Parking Percentage',
  [BUILDING_INFO_KEYS.NUM_OF_PARKING]: 'No. of parking spots',
  [BUILDING_INFO_KEYS.PARKING_RATIO]: 'Parking spots per apartment',
  [BUILDING_INFO_KEYS.IL_PRIMARY_AREA]: 'Primary Area',
  [BUILDING_INFO_KEYS.IL_COMMON_AREA]: 'Common Area',
  [BUILDING_INFO_KEYS.IL_MMD_AREA]: 'MMD Area',
  [BUILDING_INFO_KEYS.IL_NET_TO_GROSS]: 'Net/Gross',
  [BUILDING_INFO_KEYS.IL_GROSS_BALCONY_AREA]: 'Balcony Area',
  [MODEL_ANALYSIS_TYPES.AREA_TYPE]: 'Area Type',
  [MODEL_ANALYSIS_TYPES.SUN_ANALYSIS]: 'Sun Analysis',
  [MODEL_ANALYSIS_TYPES.VIEWS_ANALYSIS]: 'Views Analysis',
  [MODEL_ANALYSIS_TYPES.WIND_ANALYSIS]: 'Wind Analysis',
  [MODEL_ANALYSIS_TYPES.VSC_ANALYSIS]: 'VSC Analysis',
  [`${MODEL_ANALYSIS_TYPES.VSC_ANALYSIS}_TOOLTIP`]: 'Vertical Sky Component',
  [HVAC.COOLING_LOAD_PEAK]: 'Cooling load peak',
  [HVAC.COOLING_LOAD_DENSITY]: 'Cooling load density',
  [HVAC.HEATING_LOAD_PEAK]: 'Heating load peak',
  [HVAC.HEATING_LOAD_DENSITY]: 'Heating load density',
  [HVAC.ENERGY_USE_INTENSITY]: 'Energy use intensity (EUI)',
  DUAL_ASPECT: 'Dual Aspect',
  PERCENTAGE_FROM_GIA: 'Percentage from GIA',
  PERCENTAGE_FROM_PRIMARY_AREA: 'Percentage from Primary Area',

  // ApartmentTypeContainer
  APARTMENT_TYPE_CONTAINER_APARTMENT_TYPE: 'Type',
  APARTMENT_TYPE_CONTAINER_APARTMENT_TYPE_DESCRIPTION: 'Typical Units Information',
  APARTMENT_TYPE_CONTAINER_NAME: 'Name',
  APARTMENT_TYPE_CONTAINER_AREA: 'Net Area',
  APARTMENT_TYPE_CONTAINER_ROOMS: 'Rooms',
  APARTMENT_TYPE_CONTAINER_BATHROOMS: 'Bath',
  APARTMENT_TYPE_CONTAINER_MAX_OCCUPANCY: 'Max. Occupancy',
  APARTMENT_TYPE_CONTAINER_APARTMENT_COUNT: 'Units',
  APARTMENT_TYPE_CONTAINER_BED_ONE: 'Bed. 1',
  APARTMENT_TYPE_CONTAINER_BED_TWO: 'Bed. 2',
  APARTMENT_TYPE_CONTAINER_LIVING_ROOM: 'Living Room',
  APARTMENT_TYPE_CONTAINER_KITCHEN: 'Kitchen',
  APARTMENT_TYPE_CONTAINER_BATHROOM: 'Bath',

  // DataContainer
  DATA_CONTAINER_OCCUPANCY: 'Occupancy',
  DATA_CONTAINER_NET_AREA: 'Net Area',
  DATA_CONTAINER_GROUND_FLOOR: 'L00',
  DATA_CONTAINER_FLOOR_A: 'L01',
  DATA_CONTAINER_FLOOR_B: 'L02',
  DATA_CONTAINER_FLOOR_C: 'L03',
  DATA_CONTAINER_FLOOR_D: 'L04',
  DATA_CONTAINER_TOTAL: 'Total',
  DATA_CONTAINER_SOUTH: 'South',
  DATA_CONTAINER_EAST: 'East',
  DATA_CONTAINER_WEST: 'West',
  DATA_CONTAINER_NORTH_EAST: 'North-East',
  DATA_CONTAINER_NORTH_WEST: 'North-West',
  DATA_CONTAINER_SOUTH_EAST: 'South-East',
  DATA_CONTAINER_STANDARD: 'Standard',
  DATA_CONTAINER_GARDEN: 'Garden',
  DATA_CONTAINER_ROOF: 'Roof',
  DATA_CONTAINER_FACADE_A: '1 Facade',
  DATA_CONTAINER_FACADE_B: '2 Facade',
  DATA_CONTAINER_APARTMENT_CHART: 'Apartment Chart',
  DATA_CONTAINER_APARTMENT_CHART_DESCRIPTION: 'Typical Apartment units',
  DATA_CONTAINER_APARTMENT_FACING: 'Apartment Facing',
  DATA_CONTAINER_APARTMENT_FACING_DESCRIPTION: 'Flat facing direction',
  DATA_CONTAINER_APARTMENT_TYPE: 'Apartment Type',
  DATA_CONTAINER_APARTMENT_TYPE_DESCRIPTION: 'Apartments extra feature',
  DATA_CONTAINER_LIGHT_AND_CLIMATE: 'Number of Flat Aspects',
  DATA_CONTAINER_LIGHT_AND_CLIMATE_DESCRIPTION: 'Exterior wall access',

  // EfficiencyContainer
  EFFICIENCY_CONTAINER_BUDGET: 'Budget',
  EFFICIENCY_CONTAINER_CONSTRUCTION: 'Construction',
  EFFICIENCY_CONTAINER_MEP: 'Arc. & MEP',
  EFFICIENCY_CONTAINER_OPERATION: 'Operation',
  EFFICIENCY_CONTAINER_MARKETABILITY: 'Marketability',
  EFFICIENCY_CONTAINER_EFFICIENCY: 'Efficiency',
  EFFICIENCY_CONTAINER_EFFICIENCY_DESCRIPTION: 'Efficiency score by divisions',
  EFFICIENCY_CONTAINER_BUILDING: 'Building',
  EFFICIENCY_CONTAINER_APARTMENTS: 'Apartments',

  // FeasibilitySimpleForm
  FEASIBILITY_SIMPLE_FORM_LAYOUT_INFO: 'Layout Info',
  FEASIBILITY_SIMPLE_FORM_TOTAL_LOT_AREA: 'Total Lot Area',
  FEASIBILITY_SIMPLE_FORM_GENERATE: 'Generate Feasibility',
  FEASIBILITY_SIMPLE_FORM_NUMBER_OF_APARTMENTS: 'Number of Apartments',
  FEASIBILITY_SIMPLE_FORM_APARTMENT_COMFORT_LEVEL: 'Apartment Comfort Level',
  FEASIBILITY_SIMPLE_FORM_TIGHT: 'Tight',
  FEASIBILITY_SIMPLE_FORM_TIGHT_DESCRIPTION: '',
  FEASIBILITY_SIMPLE_FORM_COMFORT: 'Comfort',
  FEASIBILITY_SIMPLE_FORM_COMFORT_DESCRIPTION: '',
  FEASIBILITY_SIMPLE_FORM_SPACIOUS: 'Spacious',
  FEASIBILITY_SIMPLE_FORM_SPACIOUS_DESCRIPTION: '',
  FEASIBILITY_SIMPLE_FORM_TYPE: 'Type',
  FEASIBILITY_SIMPLE_FORM_MULTIFAMILY: 'Multifamily',
  FEASIBILITY_SIMPLE_FORM_MULTIFAMILY_DESCRIPTION: 'Multifamily housing refers to a building that contains more than one dwelling unit - A building that houses more than one family at a time. Apartments, condos, townhouses, duplexes, and quadruplexes are all examples of multifamily housing options.',
  FEASIBILITY_SIMPLE_FORM_SMALL_APARTMENT: 'Small Apartment',
  FEASIBILITY_SIMPLE_FORM_SMALL_APARTMENT_DESCRIPTION: 'An apartment between 400-850 sf.',
  FEASIBILITY_SIMPLE_FORM_CO_LIVING: 'Co-Living',
  FEASIBILITY_SIMPLE_FORM_CO_LIVING_DESCRIPTION: 'Wellbeing living in which residents get a private bedroom in a furnished home with shared common areas.',
  FEASIBILITY_SIMPLE_FORM_ELDERLY_HOME: 'Elderly Home',
  FEASIBILITY_SIMPLE_FORM_ELDERLY_HOME_DESCRIPTION: 'A multi-residence housing facility intended for the elderly . Typically, each person or couple in the home has an apartment -style room or suite of rooms.',
  FEASIBILITY_SIMPLE_FORM_DORMITORIES: 'Dormitories',
  FEASIBILITY_SIMPLE_FORM_DORMITORIES_DESCRIPTION: 'A building primarily providing sleeping and residential quarters for large numbers of people.',
  FEASIBILITY_SIMPLE_FORM_AFFORDABLE_HOUSING: 'Affordable Housing',
  FEASIBILITY_SIMPLE_FORM_AFFORDABLE_HOUSING_DESCRIPTION: 'Housing for lower or middle income households, as rated by the national government.',
  FEASIBILITY_SIMPLE_FORM_OTHER: 'Other',
  FEASIBILITY_SIMPLE_FORM_OTHER_PLACEHOLDER: 'Apartment Type (e.g. Insurance, Education etc)',
  FEASIBILITY_SIMPLE_FORM_OTHER_DESCRIPTION: '',
  FEASIBILITY_SIMPLE_FORM_ADVANCED_MODE: 'Advanced Mode',

  // FeasibilityZoningFarForm
  FEASIBILITY_ZONING_FAR_FORM_TITLE: 'Zoning / FAR',
  FEASIBILITY_ZONING_FAR_FORM_DESCRIPTION: 'Buildable area by local regulation',
  FEASIBILITY_ZONING_FAR_FORM_ZONING: 'Zoning',
  FEASIBILITY_ZONING_FAR_FORM_FAR: 'FAR',
  FEASIBILITY_ZONING_FAR_FORM_BUILDABLE_AREA: 'Buildable Area',
  FEASIBILITY_ZONING_FAR_FORM_CIRCULATION: 'Circulation',
  FEASIBILITY_ZONING_FAR_FORM_RESIDENCE: 'Residence',
  FEASIBILITY_ZONING_FAR_FORM_COMMERCIAL: 'Commercial',
  FEASIBILITY_ZONING_FAR_FORM_MIX_USE: 'Mix-use',

  // FeasibilityApartmentsForm
  FEASIBILITY_APARTMENTS_FORM_TITLE: 'Apartments',
  FEASIBILITY_APARTMENTS_FORM_DESCRIPTION: 'Type and size of the required apartments',
  FEASIBILITY_APARTMENTS_FORM_ADD_ANOTHER: 'Add another Apartment type',
  FEASIBILITY_APARTMENTS_FORM_APARTMENT_TYPE: 'Apartment Type',
  FEASIBILITY_APARTMENTS_FORM_APARTMENT_SIZE: 'Apt.',
  FEASIBILITY_APARTMENTS_FORM_TARGET: 'Target',
  FEASIBILITY_APARTMENTS_FORM_TARGET_TOOLTIP: 'Ratio of NIA',
  FEASIBILITY_APARTMENTS_FORM_APT: 'Apt.',
  FEASIBILITY_APARTMENTS_FORM_APT_TOOLTIP: 'Number of apartments',
  FEASIBILITY_APARTMENTS_FORM_EST: '(est.)',
  FEASIBILITY_APARTMENTS_FORM_TOTAL: 'Total',
  FEASIBILITY_APARTMENTS_FORM_NIA: 'NIA',
  FEASIBILITY_APARTMENTS_FORM_NIA_TOOLTIP: 'Net Internal Area',
  FEASIBILITY_APARTMENTS_FORM_GIA: 'GIA',
  FEASIBILITY_APARTMENTS_FORM_GIA_TOOLTIP: 'Gross Internal Area',
  FEASIBILITY_APARTMENTS_FORM_NIA_TO_GIA: 'NIA / GIA (Target)',
  FEASIBILITY_APARTMENTS_FORM_CIRCULATION: 'Circulation (Est.)',

  // FeasibilityParameterForm
  FEASIBILITY_PARAMETER_FORM_TITLE: 'Parameter Value',
  FEASIBILITY_PARAMETER_FORM_DESCRIPTION: 'Efficiency parameters score value',
  FEASIBILITY_PARAMETER_FORM_CONSTRUCTION: 'Construction Budget',
  FEASIBILITY_PARAMETER_FORM_MARKETABILITY: 'Marketability',
  FEASIBILITY_PARAMETER_FORM_WELLBEING: 'Occupant Wellbeing',
  FEASIBILITY_PARAMETER_FORM_SUSTAINABILITY: 'Sustainability',
  FEASIBILITY_PARAMETER_FORM_ARCHITECTURE: 'Architecture & Urban Context',
  FEASIBILITY_PARAMETER_FORM_ENERGY_EFFICIENCY: 'Energy Efficiency',
  FEASIBILITY_PARAMETER_FORM_ENVIRONMENTAL: 'Environmental Conditions\n (daylight, wind, view)',
  FEASIBILITY_PARAMETER_FORM_ERROR: 'Design Priority should target only one category with a 3 or 4 score.',

  // FeasibilityBuildingForm
  FEASIBILITY_BUILDING_FORM_TITLE: 'Building Typologies',
  FEASIBILITY_BUILDING_FORM_DESCRIPTION: 'Preferred building typologies to test',
  MEGA_BLOCK: 'Megablock',
  FEASIBILITY_BUILDING_FORM_MEGA_BLOCK_DESCRIPTION: 'Rectangle mass with apartments on both sides of a corridor.',
  COURTYARD: 'Courtyard',
  FEASIBILITY_BUILDING_FORM_COURTYARD_DESCRIPTION: 'Circumscribed area open to the sky, surrounded by a building with apartments on both sides of a corridor.',
  U_SHAPE: 'U-Shape',
  FEASIBILITY_BUILDING_FORM_U_COURTYARD_DESCRIPTION: 'U-Shape mass with apartments on both sides of a corridor and interior courtyard.',
  NUM_OF_MASSES: 'Mixed Typologies',
  FEASIBILITY_BUILDING_FORM_NUM_OF_MASSES_DESCRIPTION: 'Mixed typologies that can fit the lot',
  CAMPUS: 'Campus',
  FEASIBILITY_BUILDING_FORM_CAMPUS_DESCRIPTION: 'Series of small buildings which spread and cover the entire lot area.',
  L_SHAPE: 'L-Shape',
  FEASIBILITY_BUILDING_FORM_L_SHAPE_DESCRIPTION: 'L-Shape mass with apartments on both sides of a corridor.',
  FINGER_BLOCK: 'Finger Block',
  FEASIBILITY_BUILDING_FORM_FINGER_BLOCK_DESCRIPTION: 'Mass with more than two wings, center intersection, and apartments on both sides of a corridor.',
  WRAP: 'Wrap',
  FEASIBILITY_BUILDING_FORM_WRAP_DESCRIPTION: 'Above-grade parking structure surrounded by a building\nwith apartments on one side of a corridor.',
  TOWER: 'H-Shape',
  FEASIBILITY_BUILDING_FORM_TOWER_DESCRIPTION: 'Four to Six apartments per floor in an H-Shape layout, surrounding an interior core.',
  FEASIBILITY_BUILDING_FORM_COUNT_TEXT: 'Select maximum three preferred typologies to study',

  // FeasibilityAmenityForm
  FEASIBILITY_AMENITY_FORM_TITLE: 'Amenity Spaces',
  FEASIBILITY_AMENITY_FORM_DESCRIPTION: 'Shared Amenity Rooms',
  FEASIBILITY_AMENITY_FORM_LOBBY_LOUNGE: 'Lobby / Lounge',
  FEASIBILITY_AMENITY_FORM_LAUNDRY_ROOM: 'Laundry Room',
  FEASIBILITY_AMENITY_FORM_FITNESS_ROOM: 'Fitness Room',
  FEASIBILITY_AMENITY_FORM_RESIDENCE_CLUB: 'Residence Club',
  FEASIBILITY_AMENITY_FORM_OFFICE: 'Office',
  FEASIBILITY_AMENITY_FORM_MAILBOX_ROOM: 'Mailbox room',
  FEASIBILITY_AMENITY_FORM_PACKAGE_ROOM: 'Package room',
  FEASIBILITY_AMENITY_FORM_JANITOR: 'Janitor',
  FEASIBILITY_AMENITY_FORM_STORAGE: 'Storage',
  FEASIBILITY_AMENITY_FORM_OTHER: 'Other',
  FEASIBILITY_AMENITY_FORM_TOTAL_SF: 'Total ft²',
  FEASIBILITY_AMENITY_FORM_TOTAL_SM: 'Total M²',
  FEASIBILITY_AMENITY_FORM_TOTAL_RATIO_SF: 'Ratio ft² to Apartments',
  FEASIBILITY_AMENITY_FORM_TOTAL_RATIO_SM: 'Ratio M² to Apartments',
  FEASIBILITY_AMENITY_FORM_ADD_SPACE: 'Add Another Space',
  FEASIBILITY_AMENITY_FORM_AMENITY_TYPE: 'Amenity Type',
  FEASIBILITY_AMENITY_FORM_RATIO_TYPE_SF: 'ft² Count / Ratio',
  FEASIBILITY_AMENITY_FORM_RATIO_TYPE_SM: 'M² Count / Ratio',
  FEASIBILITY_AMENITY_FORM_ESTIMATE_SM: 'ft² (est.)',
  FEASIBILITY_AMENITY_FORM_ESTIMATE_SF: 'M² (est.)',
  FEASIBILITY_AMENITY_FORM_ROOM_NUM: 'Number of rooms',
  FEASIBILITY_AMENITY_FORM_RATIO: 'Ratio',

  // FeasibilityTargetFormContainer
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_BUILDING: 'building',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_PARKING: 'parking',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_ENV: 'env.',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_ENERGY: 'energy',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_HB_ENERGY: 'hb-energy',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_FINANCIAL: 'financial',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_DESIGN: 'design',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_CD: 'CD',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_VIEWS: 'Views',
  FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_REPORT: 'report',
  FEASIBILITY_TARGET_FORM_CONTAINER_APARTMENT_STANDARD: 'Apartment Standard',
  FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_RIGHTS: 'Building Rights',
  FEASIBILITY_TARGET_FORM_CONTAINER_COMMUNAL_SPACE: 'Communal Space',
  FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_PRIORITY: 'Design Priority',
  FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_TYPOLOGIES: 'Building Typologies',
  FEASIBILITY_TARGET_FORM_CONTAINER_SITES: 'Site',
  FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_PROPERTIES: 'Building Properties',
  FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_REQUIREMENTS: 'Design Requirements',
  FEASIBILITY_TARGET_FORM_CONTAINER_MECHANICAL_ELECTRIC_PLUMBING: 'Mechanical | Electric | Plumbing',
  FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_STANDARD: 'Design Standard',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_ONE: 'Calculating Building Rights',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_TWO: 'Arranging Apartments Mixture',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_THREE: 'Checking Typologies',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_FOUR: 'Placing on site',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_FIVE: 'Optimizing Results',
  FEASIBILITY_TARGET_FORM_CONTAINER_TF_STEP_ONE: 'Arranging Layout Information',
  FEASIBILITY_TARGET_FORM_CONTAINER_TF_STEP_TWO: 'Positioning and Adjacencies',
  FEASIBILITY_TARGET_FORM_CONTAINER_TF_STEP_THREE: 'Optimizing Results',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_GENERAL_TITLE: 'Something went wrong',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_GENERAL_DESCRIPTION: 'Please wait a few seconds and try to send a new request by pressing the \'Update\' button.',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_EMPTY_TITLE: 'Something went wrong',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_EMPTY_DESCRIPTION: 'The required program cannot fit the lot.\n Please try to change the Target Stories, the construction line, the NIA, etc., and then press the \'Update\' button again.',
  FEASIBILITY_TARGET_FORM_PARKING_STEP: 'Planning a Parking Lot',
  FEASIBILITY_TARGET_FORM_SKY_STEP: 'Running Sun Analysis',
  FEASIBILITY_TARGET_FORM_SUN_STEP: 'Running VSC Analysis',
  FEASIBILITY_TARGET_FORM_VIEW_STEP: 'Running Views Analysis',
  FEASIBILITY_TARGET_FORM_WIND_STEP: 'Running Wind Analysis',
  FEASIBILITY_TARGET_FORM_REQUEST_GENERATED: 'Your request is being generated',
  FEASIBILITY_TARGET_FORM_CARD_TYPE_APARTMENT_MIXTURE: 'Apartment Mixture',
  FEASIBILITY_TARGET_FORM_CARD_TYPE_FINANCIAL_VIABILITY: 'Financial Viability',
  FEASIBILITY_TARGET_FORM_CARD_TYPE_HVAC: 'HVAC Design',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_COST: 'Total Cost',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_SALE_REVENUE: 'Total Sale Revenue',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_RENT_REVENUE: 'Total Rent Revenue - Annual',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TIME_TO_PROFIT: 'Time to Profit',
  FEASIBILITY_TARGET_FORM_CARD_EMPTY_LOT: 'Use this site to manually create\n a model design with the editor tools',
  FEASIBILITY_TARGET_FORM_CARD_EMPTY_HVAC: 'No HVAC data',

  // FeasibilityApartmentStandardForm
  FEASIBILITY_APARTMENT_STANDARD_FORM_PREFAB: 'Prefab Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_PREFAB_DESCRIPTION: 'Fixed-size apartments (studio apartment to large four bedrooms), with circulation cores (elevators and stairs) adjacent to the exterior facade.',
  FEASIBILITY_APARTMENT_STANDARD_FORM_DORMITORIES: 'Dormitories Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_DORMITORIES_DESCRIPTION: 'Several room types (varied by size with private bath). Layouts of room clusters with shared kitchens, and amenity spaces. Circulation cores (elevators and stairs) adjacent to the exterior facade.',
  FEASIBILITY_APARTMENT_STANDARD_FORM_UK: 'UK Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_UK_DESCRIPTION: 'standard-size apartments (studio apartment to three bedrooms; six-person apartments), with interior circulation cores (elevators and stairs). Maximize the apartment\'s exterior facade.',
  FEASIBILITY_APARTMENT_STANDARD_FORM_RYAN: 'Ryan Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_RYAN_DESCRIPTION: 'Based on the company’s apartments standard (studio apartment to three bedrooms). \nCirculation cores (elevators and stairs) adjacent to the exterior facade.',
  FEASIBILITY_APARTMENT_STANDARD_FORM_DEMO: 'Demo Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_DEMO_DESCRIPTION: 'Demo Description',
  FEASIBILITY_APARTMENT_STANDARD_FORM_IL: 'IL Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_IL_DESCRIPTION: 'Large-size apartments, 2-Room to 5-Room apartments, with interior circulation cores (two elevators and stairs), MMD rooms and penthouse apartments. Maximize the building rights target.',
  FEASIBILITY_APARTMENT_STANDARD_FORM_WV: 'WV Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_WV_DESCRIPTION: 'WV Standard description',
  FEASIBILITY_APARTMENT_STANDARD_FORM_PBK: 'PBK Standard',
  FEASIBILITY_APARTMENT_STANDARD_FORM_PBK_DESCRIPTION: 'PBK Standard description',

  // FeasibilityBuildingRightsForm
  FEASIBILITY_BUILDING_RIGHTS_FORM_PERCENTAGE_TARGET: 'Percentage Target',
  FEASIBILITY_BUILDING_RIGHTS_FORM_APARTMENTS_COUNT: 'Apartments Count',
  FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL: 'Total',
  FEASIBILITY_BUILDING_RIGHTS_FORM_ADD_ANOTHER: 'Add another Apartment type',
  FEASIBILITY_BUILDING_RIGHTS_FORM_ADD_ANOTHER_ERROR: 'All available apartments types were exploited',
  FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL_TOOLTIP: 'Total target must be 100%',
  FEASIBILITY_BUILDING_RIGHTS_FORM_PENTHOUSE: 'Penthouse',
  FEASIBILITY_BUILDING_RIGHTS_FORM_PENTHOUSE_TOOLTIP: 'Select up to two apartments',
  FEASIBILITY_BUILDING_RIGHTS_FORM_PENTHOUSE_WARNING: 'Select up to two apartments',
  FEASIBILITY_BUILDING_RIGHTS_FORM_ILS_NOTE: (_key, context) => `*Not Including ${context.area} ${context.unit} MMD`,

  // FeasibilityBuildingRightsAreaFormIlStandard
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_PRIMARY_AREA: (_key, context) => `Primary Area (${context.unitType})`,
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_COMMON_AREA: (_key, context) => `Common Area (${context.unitType})`,
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_NUMBER_OF_APARTMENTS: 'Number of Apartments',
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_MIN_FLOOR_HEIGHT: (_key, context) => `Minimum floor height - Net (${context.unitType})`,
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_MAX_CONSTRUCTION_HEIGHT: (_key, context) => `Maximum construction height (${context.unitType})`,
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_BALCONY_PER_APARTMENT: (_key, context) => `Balcony (${context.unitType} per apt.)`,
  FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_BALCONY_TOTAL_AREA: (_key, context) => `Balcony (Total ${context.unitType})`,

  // FeasibilityMechanicalElectricPlumbingForm
  FEASIBILITY_MECHANICAL_ELECTRIC_PLUMBING_FORM_HVAC: 'HVAC',
  FEASIBILITY_MECHANICAL_ELECTRIC_PLUMBING_FORM_CENTRAL: 'Heat Pump (Mini central)',
  FEASIBILITY_MECHANICAL_ELECTRIC_PLUMBING_FORM_VRF: 'Variable Refrigerant Flow (VRF)',

  // FeasibilityDesignRequirementsForm
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_FLEXIBLE: 'Flexible',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_SPRINKLERS: 'Sprinklers',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_DEAD_END_CORRIDOR: 'Dead-End Corridor',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_MULTIPLE_FLOOR_GROUP: 'Multiple floor group',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_NATURAL_LIGHT_CORRIDORS: 'Corridors with natural light',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_SINGLE_LOAD_CORRIDOR: 'Single load corridor',

  // FeasibilityLotInfoForm
  FEASIBILITY_LOT_INFO_FORM_AREA: 'Lot Area',
  FEASIBILITY_BLOCK_LOT: 'Block-Lot',
  FEASIBILITY_LOT_INFO_FORM_NIA: 'MP-NIA',
  FEASIBILITY_LOT_INFO_FORM_GIA: 'MP-GIA',
  FEASIBILITY_LOT_INFO_FORM_STORIES: 'Max. Stories',

  // BuildContainer
  BUILD_CONTAINER_NO_REVIT: 'READY TO CREATE A REVIT MODEL?\nPlease revise the form on the left bar and press ‘Generate’',
  BUILD_CONTAINER_NO_REVIT_LOADING: 'GENERATING REVIT MODEL\nThis process may take a few minutes',

  // FeasibilitySitesForm
  FEASIBILITY_SITES_FORM_SELECT: 'R = Residential Area | S = Store Area | P = Parking Area. Please select one variant.',
  FEASIBILITY_SITES_FORM_MIXED_TYPOLOGIES: 'Temporary Swapp cannot generates ‘Mixed Typologies’ with multi-lot zones sites. Please select different Building Typology.',
  FEASIBILITY_SITES_FORM_TARGET_STORIES: 'Target Stories',
  FEASIBILITY_SITES_FORM_MAX_STORIES: 'Max Stories',
  FEASIBILITY_SITES_FORM_MAX_STORIES_COUNT: 'Stories',
  FEASIBILITY_SITES_FORM_OPEN_TERRAIN: 'Minimum Open Terrain',
  FEASIBILITY_SITES_FORM_SETBACK_LINE: 'Construction Line',
  FEASIBILITY_SITES_FORM_OPEN_GROUND_FLOOR: 'Open Ground Floor',
  FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE: 'Ground Floor',
  FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_OPEN_GROUND_FLOOR: 'Open Ground Floor',
  FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_RETAIL: 'Retail Floor',
  FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_NA: 'N/A',
  FEASIBILITY_SITES_FORM_OPEN_GROUND_FLOOR_TOOLTIP: 'Parking',

  // FeasibilityCommunalSpaceForm
  FEASIBILITY_COMMUNAL_SPACE_FORM_SPACE_TYPE: 'Type',
  FEASIBILITY_COMMUNAL_SPACE_FORM_RATIO_TYPE: 'Area',
  FEASIBILITY_COMMUNAL_SPACE_FORM_RATIO: 'Ratio/',
  FEASIBILITY_COMMUNAL_SPACE_FORM_SPREAD: 'Spread',
  FEASIBILITY_COMMUNAL_SPACE_FORM_AREA: 'M²',
  FEASIBILITY_COMMUNAL_SPACE_ADD_ANOTHER: 'Add another communal type',
  FEASIBILITY_COMMUNAL_SPACE_TOTAL: 'Total',
  FEASIBILITY_COMMUNAL_SPACE_TOO_LARGE_WARNING: 'Please recheck the Communal Space Tab, it seems unusually large area space.\n',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES.OTHER}`]: 'Other',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA}`]: 'Space',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_RATIO_TYPE.PER_APARTMENT}`]: 'per Unit',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.PER_BUILDING}`]: 'Building',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.ENTIRE_PROJECT}`]: 'Site',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.PER_FLOOR}`]: 'Floor',

  // FeasibilityResultData
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.BCR]}`]: 'Building Coverage Ratio',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.NIA]}`]: 'Net Internal Area',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.GIA]}`]: 'Gross Internal Area',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.NIA_TO_GIA]}`]: 'Ratio between Net and Gross area.',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.FULL_CORE]}`]: 'Two elevators and one escape stairs',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.BCR]}`]: 'A percentage from lot area',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS]}`]: 'A percentage from the total no. of units',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS]}`]: 'A percentage from the total no. of units',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.THREE_FLAT_ASPECTS]}`]: 'A percentage from the total no. of units',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.ROOF_APARTMENT]}`]: 'A percentage from the total no. of units',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.GARDEN_APARTMENT]}`]: 'A percentage from the total no. of units',
  [`FEASIBILITY_RESULT_DATA_VALUE_TOOLTIP_${[BUILDING_INFO_KEYS.TOTAL_APARTMENTS]}`]: 'Model / Target',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.COMMUNAL_AREA]}`]: 'Percentage from GIA',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.CORE]}`]: 'Percentage from GIA',
  FEASIBILITY_RESULT_DATA_MODELS_TITLE: 'Models',
  FEASIBILITY_RESULT_DATA_MODELS_SUBTITLE: 'Programmatic and occupant wellbeing analysis',
  FEASIBILITY_RESULT_DATA_ENHANCED_APARTMENTS: 'Apartment Properties',
  FEASIBILITY_RESULT_DATA_APARTMENT_ORIENTATION: 'Apartment Orientation',
  FEASIBILITY_RESULT_DATA_EFFICIENCY_SCORE: 'Efficiency Score',
  FEASIBILITY_RESULT_DATA_ENERGY_ANALYSIS: 'Energy Analysis',
  FEASIBILITY_RESULT_DATA_APT_MIX: 'Apt. Mix',
  FEASIBILITY_RESULT_NO_DATA: 'No Data',
  FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP: 'Percentage from GIA',
  FEASIBILITY_RESULT_DATA_PARKING: 'Parking',
  FEASIBILITY_RESULT_DATA_PARKING_EMPTY: 'Parking is Empty',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL: 'Development Appraisal',
  FEASIBILITY_RESULT_DATA_AREA_SUMMARY: 'Area Summary',
  FEASIBILITY_RESULT_DATA_UNITS_COUNT: 'Units Count',
  FEASIBILITY_RESULT_DATA_COMMUNAL_SPACE: 'Communal Space',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_TYPE: 'Type',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_FLOORS: 'Floors',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_STALL: 'Stall',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_STALLS: 'Stalls',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_TOTAL_COST: 'Total Cost',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_EXPENDITURE: 'Expenditure',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_INCOME: 'Income',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_AREA: 'Area',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_BUILD_COST_PER: 'Build Cost per',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_INCOME_PER: 'Income per',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_TOTAL_COST: 'Total Cost',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_TOTAL_INCOME: 'Total Income',
  FEASIBILITY_RESULT_DATA_SALE: 'Sale',
  FEASIBILITY_RESULT_DATA_RENT: 'Rent (Monthly)',
  FEASIBILITY_RESULT_DATA_NUM_OF_PARKING: 'No. of parking stalls',
  FEASIBILITY_RESULT_DATA_PARKING_RATIO: 'Parking stalls per apartment',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_BELOW_GROUND_PARKING: 'Below grade parking',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_PRECAST_PARKING: 'Above grade parking (precast)',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_PODIUM_PARKING: 'Above grade parking (podium)',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_SURFACE_PARKING: 'Surface parking',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_NIA: 'Apartments Area',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_COMMUNAL_AREA: 'Communal',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_CORRIDOR: 'Corridors',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_CORE_AND_MECHANICAL: 'Core & Mechanical',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_PARKING_AREA: 'Parking',

  FEASIBILITY_SELF_SERVE_DRAW_POLYGON: 'Draw',
  FEASIBILITY_SELF_SERVE_CLEAR_POLYGON: 'Clear',
  FEASIBILITY_GENERATE_ERROR_DRAW_POLYGON: 'Please complete drawing a site polygon',
  FEASIBILITY_GENERATE_ERROR_POLYGON_SIZE: 'The polygon site must not exceed 200,000 square meters',

  FEASIBILITY_SWP_STORY_APT_AREA: 'Apt. area: ',
  FEASIBILITY_SWP_STORY_MMD_AREA: 'Mmd. area: ',

  // TimeAndBudget
  TIME_AND_BUDGET_BUDGET_LABEL: 'Choose your budget',
  TIME_AND_BUDGET_DATE_LABEL: 'Move in date',
  TIME_AND_BUDGET_NO_RUSH: 'No Rush',
  TIME_AND_BUDGET_OR: 'Or:',
  TIME_AND_BUDGET_BUDGET: 'Budget',
  TIME_AND_BUDGET_DATE_PICKER_PLACEHOLDER: 'Select move in date',
  TIME_AND_BUDGET_DATE_PICKER_ERROR: 'Select Move in date or No Rush',
  TIME_AND_BUDGET_SITE_CONDITION_LABEL: 'Site Condition',
  TIME_AND_BUDGET_SITE_CONDITION_PLACEHOLDER: 'Select Site Condition',
  TIME_AND_BUDGET_SITE_CONDITION_SHELL_CORE: 'Shell & Core',
  TIME_AND_BUDGET_SITE_CONDITION_CAT_A: 'CAT A (Basic Finish)',
  TIME_AND_BUDGET_SITE_CONDITION_CAT_B: 'CAT B',
  TIME_AND_BUDGET_SITE_CONDITION_DESCRIPTION_A: 'Shell & Core\n In construction the ‘shell and core‘ (or ‘base build’) is the completed outer framework of a building or the concrete and metal frame of the space. From the outside, the building may look complete, but the interior of the building has not yet been installed. This will include aspects such as lobbies, lift shafts, toilets, etc.',
  TIME_AND_BUDGET_SITE_CONDITION_DESCRIPTION_B: 'CAT A\n Cat A refers to a basic landlord fit out where the interior of the building is a blank space for the potential occupier to establish their design and construction. Cat A comprises the installation of the building’s mechanical, electrical and plumbing services, as well as flooring, suspended ceilings and fire detection systems.',
  TIME_AND_BUDGET_SITE_CONDITION_DESCRIPTION_C: 'CAT B\n Cat B is where the office space comes to life when your fit out partner fits out your space with your design specifications. From partitioning, specialist facilities (e.g. meeting rooms, workstations and breakout areas) to the installation of fitting, furniture and equipment.',
  TIME_AND_BUDGET_BUDGET_DESCRIPTION_A: 'Pricing\n All prices have been aligned to show a CAT B construction finish. Includes - internal partitions, M&E reconfiguration, doors and ironmongery, wall, floor and ceiling finishes, specialist joinery, fixtures and fittings, structured cabling and comms room fit-out, internal signage, branding, contractor preliminaries and overhead and profit. Excludes - technology, furniture, professional fees, contingencies and VAT.',

  // ImagesRadioSelection
  IMAGES_RADIO_SELECTION_CHOOSE: 'Choose',
  IMAGES_RADIO_SELECTION_COST: 'Cost Per ft²:',
  IMAGES_RADIO_SELECTION_TOTAL_COST: 'Est. Total Build Cost:',
};
