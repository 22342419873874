import styled from 'styled-components';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';

export const StyledCardsWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  display: grid;
  place-items: start;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
`;

export const StyledCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  height: 375px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray_01};
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.22);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  
  &:hover {
    opacity: 0.8;
  }
`;

export const CardName = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.font.size.xLarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
`;

export const TopPart = styled.div`
  overflow: hidden;
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray_03};
  color: ${({ theme }) => theme.colors.gray_06};
  border-radius: ${({ theme }) => `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`};
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

export const NewProjectImageWrapper = styled.div`
  height: 170px;
  width: 100%;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColor)};
  color: ${({ theme }) => theme.colors.gray_01};
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: ${({ theme }) => `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`};
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 12px 0 12px;
`;

export const NewProjectContentWrapper = styled(CardBody)`
  flex: 1;
  justify-content: start;
  text-align: center;
  margin-top: 50px;
  font-size: ${({ theme }) => theme.font.size.xLarge};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_05 : theme.colors.textColor)};
  
  :hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColor)};
  }
  
  svg {
    font-size: 30px;
    margin-bottom: 20px;
  }
`;

export const CardInfo = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '8px')};
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.font.size.medium};
`;

export const LocationWrapper = styled.a`
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 10px;
  display: flex;
  
  :hover {
    svg {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const LocationTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

export const LocationText = styled.div`
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardInfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.gray_09};
  width: 16px !important;
  margin-right: 14px;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_07};
  font-size: ${({ theme }) => theme.font.size.small};
  padding: 0 12px 12px 12px;
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const PercentageWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Percentage = styled.span`
  color: ${({ theme, isPositive }) => (isPositive ? theme.colors.success : theme.colors.textColor)};
`;

export const ProfileStatus = styled.div`
  display: flex;
  line-height: 1.1;
  margin-bottom: 10px;
  
  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledStatus = styled.div`
  margin-left: 6px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const StatusIconWrapper = styled.div`
  margin-right: 8px;
  font-size: 13px;
`;

export const FooterIcon = styled(InfoCircleOutlined)`
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const StyledArchivedIcon = styled(Icon)`
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  margin-right: 6px;
`;

export const StageWrapper = styled.div`
  display: flex;
`;
