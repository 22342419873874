/* eslint-disable no-restricted-globals */
import React, { useMemo, useLayoutEffect, Suspense, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import { useGLTF, useTexture } from '@react-three/drei';
import lodashGet from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { MODEL_ANALYSIS_TYPES, FEASIBILITY_EDITOR_BUTTON_KEYS } from 'constants/feasibilityConts';
import { currentThemeSelector } from 'store/userSettings';
import { getSwpProjectBuildings } from 'utils/model/swpProjectModel';
import DraggableMesh from './DraggableMesh';
import BuildingStory from './Stories/BuildingStory';
import { ParkingMesh, isParkingInfoDtoSupported } from './ParkingMesh';
import SketchTool from './SketchTool/SketchTool';
import { changeCursor } from '../helpers/SelectionHelpers';
import GeneralPolygons from './GeneralPolygons';
import { MassTransformer } from '../../../store/editor';
import * as SwappEditor from '../../../store/editor';
import MassGroupRotator from './MassGroupRotator';
import images from '../../../styles/static/img/feasibility';
import InteractableLines from './InteractableLines';
import { getRenderPropsByName } from '../helpers/FeasibilityRenderProps';
import DashedShape from './DashedShape';
import SitePolygon from './SitePolygon';

const EDITOR_TYPES_PARKING_CLICKABLE = [
  FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM,
];

const BuildingMeshes = (props) => {
  const { data,
    intractable,
    legendKey,
    castShadow,
    onResolvedHasSurroundingBuildings,
    profileId,
    isSketchToolOn,
    isSketchParkingOn,
    isEditModeOn,
    updateServerReducer,
    editorType,
    selectedObject,
    isOrthographic,
    selectedStory,
    selectedRowKeys,
  } = props;
  const sitePolygon = data.sitePolygon.polygon;
  const constructionPolygon = data.constructionLine.polygon;

  const obj = useGLTF(data[legendKey], true);
  const scene = useMemo(() => obj.scene.clone(true), [obj]);

  // const selectedObject = useSelector((state) => state.editor.selectedObject);
  const dispatch = useDispatch();
  const currentTheme = useSelector(currentThemeSelector);
  const [parkingHoverMap, setParkingHoverMap] = useState(false);

  // For preloading textures:
  useTexture(images.arrow);
  useTexture(images.middlePoint);
  useTexture(images.pointer);
  useTexture(images.parkingEntrance);

  const isParkingClickable = useMemo(() => {
    const noSelected = !selectedObject || (Array.isArray(selectedObject) && !selectedObject.length);
    return EDITOR_TYPES_PARKING_CLICKABLE.includes(editorType) && noSelected;
  }, [editorType, selectedObject]);

  const getMesh = (currentObject) => {
    if (lodashIsEmpty(lodashGet(currentObject, 'children'))) {
      return;
    }

    return lodashGet(currentObject, 'children[0].children');
  };

  const mainMesh = getMesh(scene);

  useLayoutEffect(() => {
    if (editorType !== FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING) {
      dispatch(SwappEditor.editMassInSketch(null));
    }
    dispatch(MassTransformer.initialize(data));
  }, [data]);
  useLayoutEffect(() => {
    dispatch(MassTransformer.updateSelection(selectedObject));
  }, [selectedObject, data]);

  useMemo(() => {
    const hasSurroundingBuildings = mainMesh?.some((child) => child.name.includes('SurroundingBuildings'));
    onResolvedHasSurroundingBuildings(hasSurroundingBuildings);
  }, [mainMesh, onResolvedHasSurroundingBuildings]);

  const getChildrenArray = (child) => {
    const { renderOrder } = getRenderPropsByName('mass');
    const { linesRenderOrder } = getRenderPropsByName('massLines');

    return child.children.map((mesh) => {
      if (mesh.type === 'LineSegments') {
        return (
          <lineSegments
            key={mesh.uuid}
            {...mesh}
            renderOrder={linesRenderOrder}
          >
            <lineBasicMaterial linewidth={3} color="#000000" />
          </lineSegments>
        );
      }

      return (
        <mesh key={mesh.uuid} {...mesh} castShadow={castShadow} renderOrder={renderOrder} />
      );
    });
  };

  const massMeshes = Object.keys(data.masses).map((childName) => (
    <DraggableMesh
      buildingData={data.masses[childName]}
      key={childName}
      intractable={intractable}
      legendKey={legendKey}
      profileId={profileId}
      isOrthographic={isOrthographic}
      selectedObject={selectedObject}
      selectedRowKeys={selectedRowKeys}
    >
      {/* TODO TECH DEBT: Without this empty group, selection doesn't work. Why? */}
      <group />
    </DraggableMesh>
  ));

  const swpBuildings = getSwpProjectBuildings(data.swpProject) || [];
  const massStories = swpBuildings.map((swpBuilding) => (
    <BuildingStory
      swpBuilding={swpBuilding}
      key={swpBuilding.id}
    />
  ));

  const renderChildren = (child) => {
    if (child.name.startsWith('Mass') && data.massingOptionVersion >= 5 && legendKey === MODEL_ANALYSIS_TYPES.AREA_TYPE) {
      return; // This will be covered by massMeshes array.
    }

    if (child.name === 'Parking' || child.name === 'Floor_For_Parking') {
      const parkingInfoDto = data.parkingInfo;
      if (isParkingInfoDtoSupported(parkingInfoDto)) {
        if (child.name === 'Parking') {
          return <ParkingMesh key="parking" parkingInfoDto={parkingInfoDto} />;
        }
      }
      return;
      // return getChildrenArray(child);
    }

    return getChildrenArray(lodashIsEmpty(child.children) ? { children: [child] } : child);
  };

  const sketchType = isSketchParkingOn ? SketchTool.Types.Polygon : SketchTool.Types.BufferedLines;

  useEffect(() => {
    // When starting a new sketch - reset selected parking.
    if (editorType && editorType.includes('CREATE')) {
      dispatch(SwappEditor.selectObject(null));
    }
  }, [editorType]);

  const onParkClick = useCallback((id) => {
    if (isParkingClickable) {
      dispatch(SwappEditor.setEditorTypeAndObject({ editorType: 'EDIT_SURFACE_PARKING', object: { type: 'surfaceParking', id } }));
    }
  }, [isParkingClickable]);

  const onPointerOut = useCallback((id) => {
    if (isParkingClickable) {
      changeCursor('default');
      setParkingHoverMap({ ...parkingHoverMap, [id]: false });
    }
  }, [isParkingClickable, parkingHoverMap]);

  const onPointerEnter = useCallback((id) => {
    if (isParkingClickable) {
      changeCursor('pointer');
      setParkingHoverMap({ ...parkingHoverMap, [id]: true });
    }
  }, [isParkingClickable, parkingHoverMap]);

  const renderParkingsPolygon = () => {
    const { renderOrder, materialProps } = getRenderPropsByName('parkingPolygon');
    return data.parkingInfo?.lots?.map((lot, id) => (
      <InteractableLines
        key={id}
        id={id}
        renderOrder={renderOrder}
        materialProps={materialProps}
        points={lot.boundary.boundary}
        color={isParkingClickable && parkingHoverMap[id] ? currentTheme.colors.primaryColor : 'white'}
        width={0.5}
        height={lot.boundary.boundary[0][2] || 0.5}
        onClick={onParkClick}
        onPointerOut={onPointerOut}
        onPointerEnter={onPointerEnter}
      />
    ));
  };

  return (
    <Suspense fallback={null}>
      <group name="BuildingMeshes">
        <GeneralPolygons list={lodashGet(data, 'generalPolygons')} />
        {(!isNaN(selectedStory) && isOrthographic && !isEditModeOn) ? massStories : massMeshes}
        {mainMesh?.map((child) => renderChildren(child))}
        {isOrthographic && <SitePolygon sitePolygon={sitePolygon} />}
        {isOrthographic && editorType && (
          <mesh position={[0, 0, 20]}>
            <DashedShape
              color="#F47174"
              lineWidth={0.3}
              dashSize={2}
              points={constructionPolygon}
            />
          </mesh>
        )}
        {isOrthographic && <MassGroupRotator />}
        {/* Clickable parking polygons that can be seen in both 2d and 3d */}
        {!isSketchParkingOn && renderParkingsPolygon()}
        {/* The 2D sketch tool for creating new mass or a new parking */}
        {((isSketchToolOn || isSketchParkingOn || editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.WAITING_FOR_SKETCH) && isOrthographic) && (
        <SketchTool
          initialParking={(data.parkingInfo?.lots && !isNaN(selectedObject?.id) && data.parkingInfo?.lots[selectedObject.id]) || {}}
          updateServerReducer={(type, options) => updateServerReducer(type, { ...options, objecdID: selectedObject?.id })}
          type={sketchType}
          sitePolygon={sitePolygon}
          editorType={editorType}
          isOrthographic={isOrthographic}
        />
        )}
      </group>
    </Suspense>
  );
};

BuildingMeshes.propTypes = {
  data: PropTypes.object,
  intractable: PropTypes.bool,
  castShadow: PropTypes.bool,
  legendKey: PropTypes.string,
  onResolvedHasSurroundingBuildings: PropTypes.func,
  profileId: PropTypes.number,
  updateServerReducer: PropTypes.func,
  isSketchParkingOn: PropTypes.bool,
  isSketchToolOn: PropTypes.bool,
  isOrthographic: PropTypes.bool,
  isEditModeOn: PropTypes.bool,
  editorType: PropTypes.string,
  selectedStory: PropTypes.any,
  selectedObject: PropTypes.any,
  selectedRowKeys: PropTypes.any,
};

export default BuildingMeshes;
