import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import lodashSum from 'lodash/sum';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Progress } from 'antd';
import { WAITING_FOR_KEYS, GENERATE_OPTIONS_TYPES, STANDARDS } from 'constants/feasibilityConts';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import icons from 'styles/static/icons/feasibilityIcons/build';
import { activePollingProfileIdsSelector, getProfileByIdSelector } from 'store/swappProfile/selectors';
import { pollSwappProjectAction, generateDownloadFileAction } from 'store/swappProfile/actions/swappProfileActions';
import { PROFILE_STATUS, PROJECT_STAGES } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { PrimaryButton } from 'styles/commonComponents.styles';
import { downloadUrl } from 'utils/helpers/generalHelpers';
import styled, { keyframes } from 'styled-components';
import { currentThemeSelector } from 'store/userSettings';
import { useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';

const STATUS_TYPES = {
  NOT_AVAILABLE: 'notAvailable',
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished',
};

const DEFAULT_OPTIONS_STATUS = {
  [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE]: STATUS_TYPES.NOT_AVAILABLE,
  [GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL]: STATUS_TYPES.NOT_AVAILABLE,
  [GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL]: STATUS_TYPES.NOT_AVAILABLE,
  [GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL]: STATUS_TYPES.NOT_AVAILABLE,
  [GENERATE_OPTIONS_TYPES.GENERATE_SHEETS]: STATUS_TYPES.NOT_AVAILABLE,
};

const TIME_EST_BY_TYPE = { // value in min
  [GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE]: { timePerFloor: 3, timeToStartOperation: 0 },
  [GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL]: { timePerFloor: 0.5, timeToStartOperation: 5 },
  [GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL]: { timePerFloor: 1, timeToStartOperation: 5 },
  [GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL]: { timePerFloor: 0.5, timeToStartOperation: 0 },
  [GENERATE_OPTIONS_TYPES.GENERATE_SHEETS]: { timePerFloor: 0, timeToStartOperation: 20 },
};

const buttons = [
  [
    {
      size: 70,
      selected: true,
      icon: icons.architectureIcon,
      key: GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE,
      value: 'GENERATE_REVIT_FORM_ARCHITECTURE',
    },
    {
      size: 70,
      icon: icons.plumbingIcon,
      key: GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL,
      value: 'GENERATE_REVIT_FORM_PLUMBING',
    },
  ],
  [
    {
      size: 70,
      icon: icons.hvacIcon,
      key: GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL,
      value: 'GENERATE_REVIT_FORM_HVAC',
    },
    {
      size: 70,
      icon: icons.sheetsIcon,
      key: GENERATE_OPTIONS_TYPES.GENERATE_SHEETS,
      value: 'GENERATE_REVIT_FORM_SHEETS',
    },
  ],
  [
    {
      size: 70,
      icon: icons.structureIcon,
      key: GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL,
      value: 'GENERATE_REVIT_FORM_STRUCTURE',
    },
  ],
];

const GenerateRevitFormWrapper = styled.div`
  padding-left: 22px;
  height: 100%;
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 20px;
  //margin-left: 10px;
  text-transform: uppercase;
`;

const OptionsSelectionWrapper = styled.div`
  display: flex;
  height: 220px;
  width: 330px;
  justify-content: space-between;
  margin: 0 auto;
`;

const OptionsSelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 70px;
  justify-content: space-between;
`;

const OptionsSelectionIconWrapper = styled.div`
  position: relative;
  height: ${({ size }) => (size ? `${size}px` : '100%')};
  width: ${({ size }) => (size ? `${size}px` : '100%')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OptionsSelectionText = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const StatusIconWrapper = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spin = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${rotate} 1.7s linear infinite;
`;

const OptionsSelectionCell = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme, selected, disabled }) => {
    if (disabled) {
      return theme.colors.gray_04;
    }

    return selected ? theme.colors.primaryColor : theme.colors.textColor;
  }};
  
  &:hover {
    ${OptionsSelectionIconWrapper} {
      color: ${({ theme, disabled }) => (!disabled && theme.colors.primaryColorHover)};
    }
    ${OptionsSelectionText} {
      color: ${({ theme, disabled }) => (!disabled && theme.colors.primaryColorHover)};
    }
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

const TimeEstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  text-align: center;
`;

const GenerateRevitForm = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const activePollingProfileIds = useSelector((state) => activePollingProfileIdsSelector(state));
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const currentTheme = useSelector(currentThemeSelector);

  const buildingInfo = useActiveProfileBuildingInfo();
  const downloadLink = lodashGet(profile, 'result.revitFile');
  const isProfileLoading = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_EXTRA_DATA;
  const isWaitingFor = lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.REVIT_FILE}]`);
  const revitOptionsStatus = lodashGet(profile, 'result.revitOptionsStatus') || DEFAULT_OPTIONS_STATUS;
  const isLoading = isProfileLoading && isWaitingFor;
  const disabled = isLoading || isSharedUrl();
  const dispatch = useDispatch();
  const isProfileSwpProject = profile.stage === PROJECT_STAGES[UI_AUTHORIZE_PATH.ONE_CLICK_BUILDING];

  const numOfFloors = useMemo(() => lodashSum(Object.values(buildingInfo.masses).map((mass) => lodashSum(mass.massDto.floor_groups.map((floor) => floor.count)))), [locationData.profileId, buildingInfo]);
  const profileStandard = lodashGet(profile, 'result.singleOptionMassingOptions.requirements.units_standard', '').toUpperCase();
  const [selectedOptions, setSelectedOptions] = useState(Object.keys(revitOptionsStatus).map((key) => {
    const status = revitOptionsStatus[key];
    if (key === GENERATE_OPTIONS_TYPES.GENERATE_ARCHITECTURE) {
      return key;
    }

    if (status === STATUS_TYPES.NOT_AVAILABLE) {
      return;
    }
    return key;
  }).filter((e) => e));

  useEffect(() => {
    if (isLoading && locationData.profileId && !lodashIncludes(activePollingProfileIds, locationData.profileId)) {
      dispatch(pollSwappProjectAction(locationData.projectId, locationData.profileId, history, WAITING_FOR_KEYS.REVIT_FILE));
    }
  }, [isLoading, locationData.profileId, activePollingProfileIds]);

  const handleClick = () => {
    const params = {
      exportParameters: {
        [GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL]: lodashIncludes(selectedOptions, GENERATE_OPTIONS_TYPES.GENERATE_HVAC_MODEL),
        [GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL]: lodashIncludes(selectedOptions, GENERATE_OPTIONS_TYPES.GENERATE_PLBG_MODEL),
        [GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL]: lodashIncludes(selectedOptions, GENERATE_OPTIONS_TYPES.GENERATE_STRUCTURAL_MODEL),
        [GENERATE_OPTIONS_TYPES.GENERATE_SHEETS]: lodashIncludes(selectedOptions, GENERATE_OPTIONS_TYPES.GENERATE_SHEETS),
      },
    };

    dispatch(generateDownloadFileAction(locationData.projectId, locationData.profileId, history, WAITING_FOR_KEYS.REVIT_FILE, params));
  };

  const renderCell = (cell) => {
    const selected = cell.selected || lodashIncludes(selectedOptions, cell.key);
    const isDisabled = useMemo(() => {
      if (isProfileSwpProject) {
        return false;
      }
      return !lodashIncludes(STANDARDS[profileStandard]?.constructionDocumentsOptions, cell.key) || disabled;
    }, [profile, profileStandard, disabled]);

    const onSelect = () => {
      if (cell.selected || isDisabled) {
        return;
      }
      let currentSelectedOptions = [...selectedOptions];
      if (selected) {
        currentSelectedOptions = currentSelectedOptions.filter((item) => item !== cell.key);
      } else {
        currentSelectedOptions.push(cell.key);
      }

      setSelectedOptions(currentSelectedOptions);
    };

    const renderStatusIcon = (key) => {
      const statusKey = revitOptionsStatus[key];
      const statusRender = {
        [STATUS_TYPES.IN_PROGRESS]: () => <Spin><Icon component={icons.loadingIcon} spin /></Spin>,
        [STATUS_TYPES.FINISHED]: () => <Icon component={icons.finishedIcon} />,
      };

      const statusToolTip = {
        [STATUS_TYPES.IN_PROGRESS]: 'in progress',
        [STATUS_TYPES.FINISHED]: 'ready to download',
      };

      if (statusKey === STATUS_TYPES.NOT_AVAILABLE) {
        return null;
      }

      return (
        <Tooltip title={T.translate(statusToolTip[statusKey])}>
          <StatusIconWrapper>
            {statusRender[statusKey]()}
          </StatusIconWrapper>
        </Tooltip>
      );
    };

    return (
      <OptionsSelectionCell key={cell.key} selected={selected} onClick={onSelect} disabled={isDisabled}>
        <OptionsSelectionIconWrapper size={cell.size} selected={selected}>
          <Icon component={cell.icon} />
          {renderStatusIcon(cell.key)}
        </OptionsSelectionIconWrapper>
        <OptionsSelectionText>{T.translate(cell.value)}</OptionsSelectionText>
      </OptionsSelectionCell>
    );
  };

  const renderTimeEst = useMemo(() => {
    if (isProfileSwpProject) {
      return null;
    }
    const timeEstForEachType = {};
    Object.values(GENERATE_OPTIONS_TYPES).forEach((key) => {
      const option = TIME_EST_BY_TYPE[key];
      timeEstForEachType[key] = (option.timePerFloor * numOfFloors) + option.timeToStartOperation;
    });
    const totalEstTime = lodashSum(Object.values(GENERATE_OPTIONS_TYPES).map((key) => timeEstForEachType[key]));
    const selectedOptionsTimeEst = lodashSum(selectedOptions.map((key) => timeEstForEachType[key]));

    return (
      <TimeEstWrapper>
        <Progress
          type="dashboard"
          percent={(selectedOptionsTimeEst / totalEstTime) * 100}
          strokeColor={{
            '0%': currentTheme.colors.primaryColor,
            '100%': currentTheme.colors.primaryColor,
          }}
          strokeWidth={10}
          width={100}
          status="normal"
          trailColor={currentTheme.colors.gray_06}
          format={() => `${Math.round(selectedOptionsTimeEst)} ${T.translate('min')}`}
        />
        <div>{T.translate('*Estimated time for files generation')}</div>
      </TimeEstWrapper>

    );
  }, [selectedOptions, numOfFloors]);

  return (
    <GenerateRevitFormWrapper>
      <Title>{T.translate('Construction Documents')}</Title>
      {/* =========== download options =========== */}
      <OptionsSelectionWrapper>
        {buttons.map((column) => (
          <OptionsSelectionColumn>
            {column.map((cell) => renderCell(cell))}
          </OptionsSelectionColumn>
        ))}
      </OptionsSelectionWrapper>

      {/* =========== time est =========== */}
      {renderTimeEst}

      {/* =========== generate button =========== */}
      <PrimaryButton
        disabled={disabled}
        width={300}
        center
        marginBottom={25}
        marginTop={isProfileSwpProject && 25}
        onClick={handleClick}
      >
        {isLoading ? <LoadingOutlined spin /> : T.translate('GENERATE_REVIT_FORM_GENERATE')}
      </PrimaryButton>

      {/* =========== download button =========== */}
      <PrimaryButton
        disabled={!downloadLink}
        center
        width={300}
        marginBottom={25}
        onClick={() => downloadUrl(downloadLink)}
      >
        {T.translate('GENERATE_REVIT_FORM_DOWNLOAD_FILES')}
      </PrimaryButton>
    </GenerateRevitFormWrapper>
  );
};

export default GenerateRevitForm;
