import React from 'react';
import T from 'i18n-react';
import EditorRsfPanel from 'components/testFit/study/result/program/EditorRsfPanel';
import EditorRoomsPanel from 'components/testFit/study/result/program/EditorRoomsPanel';
import styled from 'styled-components';
import EditDepartmentsPanel from 'components/testFit/study/result/program/EditDepartmentsPanel';
import { TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import { useSelector } from 'react-redux';
import { StyledSubMenu, StyledMenu } from 'styles/commonComponents.styles';
import RenderTitle from 'components/common/RenderTitle';

const TestFitEditPanelContainerWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 15px 0 15px;
`;

export const FormSubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.font.size.xLarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 25px;
`;

const editorHeaderByType = {
  [TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS]: 'TEST_FIT_VIEWER_CONTAINER_UI_ROOM_TOOLTIP',
  [TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES]: 'TEST_FIT_VIEWER_CONTAINER_UI_TILES_TOOLTIP',
  [TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS]: 'TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS_TOOLTIP',
};

const TestFitEditPanelContainer = () => {
  const editorType = useSelector(({ editor }) => editor.editorType);
  const showRoomPanel = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS || editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES;
  const showDepartmentsPanel = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS;

  return (
    <TestFitEditPanelContainerWrapper>
      <Header>{T.translate(editorHeaderByType[editorType])}</Header>
      {showRoomPanel && (
        <StyledMenu
          mode="inline"
          overflow
        >
          {/* ============ RSF ============ */}
          <StyledSubMenu
            key="RSF"
            padding={10}
            title={RenderTitle('RSF')}
          >
            <EditorRsfPanel />
          </StyledSubMenu>
        </StyledMenu>
      )}

      {/* ========= Rooms ========= */}
      {showRoomPanel && (
        <FormSubMenuWrapper>
          <EditorRoomsPanel openTabs />
        </FormSubMenuWrapper>
      )}

      {/* ========= Departments ========= */}
      {showDepartmentsPanel && (
        <FormSubMenuWrapper>
          <EditDepartmentsPanel />
        </FormSubMenuWrapper>
      )}
    </TestFitEditPanelContainerWrapper>
  );
};

export default TestFitEditPanelContainer;
