/* eslint-disable camelcase */
// createNextState is immer's produce function: https://immerjs.github.io/immer/produce
import { createNextState } from '@reduxjs/toolkit';
import { lastTestFitResults } from 'store/swappProfile/selectors/swappSelectors';
import { parseId, transformObject } from './editOperationsHelpers';
import { reorderRooms } from './reorderRoomsEditOperation';
import { addEmptyMass, editMass } from './addMassEditOperation';

const deleteUnitsOperation = (operationName, operationParameters, resultObject) => {
  // supports only one unit change
  const { floor_group_index: floorGroupIndex, unit_index: unitIndex, floor_index: floorIndex, mass_index: massIndex } = operationParameters.units[0];

  return createNextState(resultObject, (draft) => {
    const newFloorGroups = [];

    const mass = draft.singleOptionMassingOptions.massing_options[0].masses[massIndex];
    const workFloor = mass.floor_groups[floorGroupIndex];
    const floorsGroupBefore = mass.floor_groups.slice(0, floorGroupIndex);

    newFloorGroups.push(...floorsGroupBefore);
    if (floorIndex > 0) {
      const workFloorLowerPart = JSON.parse(JSON.stringify(workFloor));
      workFloorLowerPart.count = floorIndex;
      newFloorGroups.push(workFloorLowerPart);
    }

    const newFloorGroup = JSON.parse(JSON.stringify(workFloor));
    newFloorGroup.count = 1;
    newFloorGroup.units.splice(unitIndex, 1);
    newFloorGroups.push(newFloorGroup);

    if (floorIndex < workFloor.count - 1) {
      const workFloorHeigherPart = JSON.parse(JSON.stringify(workFloor));
      workFloorHeigherPart.count = workFloor.count - floorIndex - 1;
      newFloorGroups.push(workFloorHeigherPart);
    }
    const floorsGroupAfter = mass.floor_groups.slice(floorGroupIndex + 1);
    newFloorGroups.push(...floorsGroupAfter);
    mass.floor_groups = newFloorGroups;
  });
};

const modifyFloorsOperation = (operationName, operationParameters, resultObject) => {
  const { floor_group_index: floorGroupIndex, new_floor_group_count: newFloorGroupCount, mass_index: massIndex } = operationParameters;
  return createNextState(resultObject, (draft) => {
    const mass = draft.singleOptionMassingOptions.massing_options[0].masses[massIndex];
    const floorGroup = mass && mass.floor_groups[floorGroupIndex];
    const isSwpProject = !!draft.singleOptionMassingOptions.massing_options[0].created_from;

    if (floorGroupIndex === 0 && floorGroup?.count === 1) {
    // Adding a floor to the bottom floor is complicated, let the server do it.
    // https://immerjs.github.io/immer/return
      return null;
    }
    if (floorGroup) {
      if (isSwpProject && newFloorGroupCount > 0) {
        if (newFloorGroupCount === 2 && floorGroup.count === 1) {
        // In SwpProjects, duplicating a floor is duplicating a floor group, not modifying the count
          const newFloorGroup = JSON.parse(JSON.stringify(floorGroup));
          mass.floor_groups.splice(floorGroupIndex, 0, newFloorGroup);
        } else {
        // We don't know what to do here
        // https://immerjs.github.io/immer/return
          return null;
        }
      } else {
        floorGroup.count = newFloorGroupCount;
      }
    }
  });
};

// eslint-disable-next-line camelcase
const duplicateMassing = (operationName, operationParameters, resultObject, extraParams) => {
  // eslint-disable-next-line camelcase
  const { outSelectedObject } = extraParams;
  const { mass_index, delta_x, delta_y } = operationParameters;
  return createNextState(resultObject, (draft) => {
    const mass = draft.singleOptionMassingOptions.massing_options[0].masses[mass_index];
    const duplicatedMass = JSON.parse(JSON.stringify(mass));
    outSelectedObject.selected = { id: draft.singleOptionMassingOptions.massing_options[0].masses.length, type: 'Mass' };
    transformObject(duplicatedMass, delta_x, delta_y);
    draft.singleOptionMassingOptions.massing_options[0].masses.push(duplicatedMass);
  });
};

const addDepartments = (operationName, operationParameters, resultObject) => {
  const { department_id, color, name } = operationParameters;
  return createNextState(resultObject, (draft) => {
    if (!draft.multiBoundaryFile.departments) {
      draft.multiBoundaryFile.departments = [];
    }
    draft.multiBoundaryFile.departments.push({ department_id, color, name });
  });
};

const deleteDepartments = (operationName, operationParameters, resultObject) => {
  const { department_id } = operationParameters;
  const depatmentIndex = resultObject.multiBoundaryFile.departments.findIndex((dep) => dep.id === department_id);
  return createNextState(resultObject, (draft) => {
    draft.multiBoundaryFile.departments.splice(depatmentIndex, 1);
  });
};

const assignRoomToDepartments = (operationName, operationParameters, resultObject, localViewParameters) => {
  const { selectedRooms } = localViewParameters;
  const { department_id } = operationParameters;

  return createNextState(resultObject, (draft) => {
    selectedRooms.forEach((room) => {
      const { floor } = parseId(room.id);
      draft.multiBoundaryFile.floors[floor].rooms.forEach((floorRoom) => {
        if (JSON.stringify(floorRoom.points) === JSON.stringify(room.points)) {
          floorRoom.department_id = department_id;
        }
      });
    });
  });
};

const repurpuseRoom = (operationName, operationParameters, resultObject, localViewParameters) => {
  const { selectedRoom, newRoomCategory } = localViewParameters;
  const { new_room_type, room_id } = operationParameters;

  return createNextState(resultObject, (draft) => {
    const { floor } = parseId(room_id);
    draft.multiBoundaryFile.floors[floor].rooms.forEach((floorRoom) => {
      if (JSON.stringify(floorRoom.points) === JSON.stringify(selectedRoom.points)) {
        floorRoom.type = new_room_type;
        floorRoom.category = newRoomCategory;
      }
    });
  });
};

const editDepartment = (operationName, operationParameters, resultObject) => {
  const { department_id, color, name } = operationParameters;
  const depatmentIndex = resultObject.multiBoundaryFile.departments.findIndex((dep) => dep.id === department_id);
  return createNextState(resultObject, (draft) => {
    if (color) {
      draft.multiBoundaryFile.departments[depatmentIndex].color = color;
    }
    if (name) {
      draft.multiBoundaryFile.departments[depatmentIndex].name = name;
    }
  });
};

// eslint-disable-next-line no-unused-vars
const deallocateRooms = (operationName, operationParameters, resultObject, extraParams) => {
  const { room_id } = operationParameters;
  const { room: roomId, tube: tubeId, floor: floorId } = parseId(room_id);
  const { profileId } = extraParams;
  const { multiBoundaryFile } = lastTestFitResults[profileId];
  const floor = multiBoundaryFile.floors[floorId];
  const tube = floor.tubes[tubeId];
  const roomToChange = tube.rooms[roomId];
  return createNextState(resultObject, (draft) => {
    for (let i = draft.multiBoundaryFile.floors[floorId].rooms.length - 1; i >= 0; i--) {
      const room = draft.multiBoundaryFile.floors[floorId].rooms[i];
      if (JSON.stringify(roomToChange.points) === JSON.stringify(room.points)) {
        console.log(roomToChange);
        draft.multiBoundaryFile.floors[floorId].rooms[i].type = 'EMPTY';
        draft.multiBoundaryFile.floors[floorId].rooms[i].category = 'EMPTY';
      }
    }
  });
};

const updateTags = (operationName, operationParameters, resultObject, extraParams) => {
  const { room_ids, tag, value } = operationParameters;
  const { profileId } = extraParams;
  const { multiBoundaryFile } = lastTestFitResults[profileId];

  return createNextState(resultObject, (draft) => {
    room_ids.forEach((id) => {
      const { room: roomId, tube: tubeId, floor: floorId } = parseId(id);
      const floor = multiBoundaryFile.floors[floorId];
      const tube = floor.tubes[tubeId];
      const roomToChange = tube.rooms[roomId];
      for (let i = draft.multiBoundaryFile.floors[floorId].rooms.length - 1; i >= 0; i--) {
        const room = draft.multiBoundaryFile.floors[floorId].rooms[i];
        if (JSON.stringify(roomToChange.points) === JSON.stringify(room.points)) {
          if (!draft.multiBoundaryFile.floors[floorId].rooms[i].tags) {
            draft.multiBoundaryFile.floors[floorId].rooms[i].tags = {};
          }
          draft.multiBoundaryFile.floors[floorId].rooms[i].tags[tag] = value;
        }
      }
    });
  });
};

export const editOperations = {
  DELETE_UNITS: deleteUnitsOperation,
  MODIFY_FLOOR_GROUP_COUNT: modifyFloorsOperation,
  DUPLICATE_MASSING: duplicateMassing,
  ADD_EMPTY_MASS: addEmptyMass,
  EDIT_MASS_SHAPE: editMass,
  TEST_FIT_ADD_DEPARTMENT: addDepartments,
  TEST_FIT_DELETE_DEPARTMENT: deleteDepartments,
  TEST_FIT_ASSIGN_ROOMS_TO_DEPARTMENT: assignRoomToDepartments,
  TEST_FIT_EDIT_DEPARTMENT: editDepartment,
  TEST_FIT_REPURPOSE_ROOM: repurpuseRoom,
  TEST_FIT_REORDER_TUBE_ROOMS: reorderRooms,
  TEST_FIT_DEALLOCATE_ROOM: deallocateRooms,
  TEST_FIT_UPDATE_ROOMS_TAGS: updateTags,
};
