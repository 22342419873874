define(["require", "exports", "reflect-metadata"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Type = exports.getTypeByProperty = exports.SwpClassMap = exports.registerClass = void 0;
    const TYPE = 'type';
    function registerClass(swpClass, name) {
        exports.SwpClassMap[name || swpClass.name] = swpClass; // Right now not in use.
    }
    exports.registerClass = registerClass;
    exports.SwpClassMap = {};
    function getTypeByProperty(classInstance, propertyType) {
        return Reflect.getMetadata(TYPE, classInstance, propertyType);
    }
    exports.getTypeByProperty = getTypeByProperty;
    function Type(value) {
        return function (target, propertyKey, descriptor) {
            // A temp hack - when a type is not supplied, we will  assume it is a string for now
            Reflect.defineMetadata(TYPE, value || 'string', target, propertyKey);
            // In case there will be a need to check for missing data, this is a beggining of a solution
            // if (!SwpClassTypesMap.has(target)) {
            //   SwpClassTypesMap.set(target, {});
            // }
            // const obj = SwpClassTypesMap.get(target);
            // if (!obj[propertyKey]) {
            //   obj[propertyKey] = value;
            // }
            // For retrive later:
            // let aa3 = SwpClassTypesMap.get(swpClass.prototype);
            // let aa4 = SwpClassTypesMap.get(swpClass.prototype.__proto__);
        };
    }
    exports.Type = Type;
});
