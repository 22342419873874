import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import lodashIncludes from 'lodash/includes';
import styled from 'styled-components';
import { StyledExploreTable } from 'styles/commonComponents.styles';
import { getExploreData, COLUMN_TYPES } from 'constants/exploreData';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { setSelectedRowKeys } from 'store/editor';
import { DISPLAY_TYPES, FILTER_TYPES } from 'constants/testFitConsts';
import GridTile from 'components/common/GridTile';
import { testFitDisplayTypeSelector } from 'store/testFit';
import T from 'i18n-react';

const GridTileWrapper = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const columnsArray = [
  COLUMN_TYPES.NAME,
  COLUMN_TYPES.MODEL,
];

const ProfileDataTablesContainer = (props) => {
  const { data, type, hideHeader, displayTypeProp } = props;
  const dispatch = useDispatch();
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const selectedRowKeys = useSelector(({ editor }) => editor.selectedRowKeys);
  const selectedCategoryRowKeys = useSelector(({ editor }) => editor.selectedCategoryRowKeys);
  const selectedDepartmentRowKeys = useSelector(({ editor }) => editor.selectedDepartmentRowKeys);
  const displayType = useSelector(testFitDisplayTypeSelector);
  const tableData = lodashGet(data, 'tableData', []);

  const onRowClicked = (rowData) => {
    if (!rowData.color) {
      return;
    }

    const list = {
      [FILTER_TYPES.ROOM]: selectedRowKeys,
      [FILTER_TYPES.CATEGORY]: selectedCategoryRowKeys,
      [FILTER_TYPES.DEPARTMENT]: selectedDepartmentRowKeys,
    };
    const keysByType = {
      [FILTER_TYPES.ROOM]: rowData.officeKey,
      [FILTER_TYPES.CATEGORY]: rowData.category,
      [FILTER_TYPES.DEPARTMENT]: rowData.departmentsKey,
    };
    const key = keysByType[rowData.filterType];
    let currentSelectedRowKeys = [...list[rowData.filterType]];

    if (lodashIncludes(currentSelectedRowKeys, key)) {
      currentSelectedRowKeys = currentSelectedRowKeys.filter((item) => item !== key); // deselect layer
    } else {
      currentSelectedRowKeys = [...currentSelectedRowKeys, key]; // add layer
    }

    dispatch(setSelectedRowKeys({ list: currentSelectedRowKeys, filterType: rowData.filterType }));
  };

  const columns = useMemo(() => columnsArray.map((columnType) => (getExploreData(type, columnType, isImperial))), [isImperial]);

  const onRow = (rowData) => (
    {
      onClick: () => onRowClicked(rowData, type),
    }
  );

  const combinedSelectedRowKeys = useMemo(() => ([...selectedRowKeys, ...selectedCategoryRowKeys, ...selectedDepartmentRowKeys]), [selectedRowKeys, selectedCategoryRowKeys, selectedDepartmentRowKeys]);

  const rowClassName = (row) => `
    ${lodashIncludes(combinedSelectedRowKeys, row.officeKey || row.category || row.departmentsKey) && 'ant-table-row-selected'}
    ${row.color && ' ant-table-row-selectable'}
    ${row.key === 'TOTAL' && ' ant-table-total-row'}
    ${row.key === 'SUB_TOTAL' && ' ant-table-sub-total-row'}
  `;

  const renderTable = useMemo(() => (
    <StyledExploreTable
      onRow={onRow}
      rowClassName={rowClassName}
      pagination={false}
      columns={columns}
      showHeader={!hideHeader}
      dataSource={tableData}
    />
  ), [isImperial, tableData, combinedSelectedRowKeys]);

  const renderGrid = useMemo(() => (
    <GridTileWrapper>
      {tableData.map((room) => {
        if (!room.color) {
          return;
        }

        return (
          <GridTile
            key={room.key}
            title={room.name || T.translate('UNTITLED')}
            subtitle={`${room.modelRooms || 1}${room.targetRooms ? `  /  ${room.targetRooms}` : ''} `}
            color={room.color}
            onClick={() => onRowClicked(room, type)}
            size={room.tileAmount < 3 ? 3 : room.tileAmount}
            selected={lodashIncludes(combinedSelectedRowKeys, room.officeKey || room.category || room.departmentsKey)}
            tooltip={room.name}
          />
        );
      }).filter((e) => e)}
    </GridTileWrapper>
  ), [isImperial, tableData, combinedSelectedRowKeys]);

  if (lodashIsEmpty(lodashGet(data, 'tableData'))) {
    return null;
  }

  return displayType === DISPLAY_TYPES.LIST || displayTypeProp === DISPLAY_TYPES.LIST || type === 'RSF' ? renderTable : renderGrid;
  // return renderTable;
};

ProfileDataTablesContainer.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  displayTypeProp: PropTypes.string,
};

export default React.memo(ProfileDataTablesContainer);
