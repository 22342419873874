/* eslint-disable no-restricted-globals */
import React, { Suspense, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { __HistoryContext } from 'react-router';
import { extend } from 'react-three-fiber';
import * as THREE from 'three';
import * as meshline from 'threejs-meshline';
import { ReactReduxContext, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useContextBridge } from '@react-three/drei';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import { clearSelectedRowKeysAction } from 'store/feasibility/actions/actions';
import { FEASIBILITY_EDITOR_BUTTON_KEYS } from 'constants/feasibilityConts';
import { selectedStorySelector } from 'store/BuildingStoriesStore';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Views from 'utils/swappViewer/markups/Views';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { selectedMassSelector } from 'store/views';
// import { useKeyPress } from 'utils/hooks';
import { useOverridableSetting } from 'store/userSettings';
import { EDIT_TYPES } from 'store/editor';
import CompassImage from './components/CompassImage';
import CameraController from './components/CameraController';
import Post from './components/Post';
import Lights from './components/Lights';
import EnvMesh from './components/EnvMesh';
import BuildingMeshes from './components/BuildingMeshes';
import { isValidURL } from '../helpers/dataDisplay';
import * as SwappEditor from '../../store/editor';
import { StyledCanvas } from './FeasibilityViewerContainer.styles';
import { useActiveProfileBuildingInfo } from '../model/feasibilityResultModel';
import { didJustFinishDragging, findAncestor } from './helpers/ThreeHelpers';
import { SwappThreeContextProvider } from '../hooks/useSwappThree';
// import { getAverageFloorCount } from './components/SketchTool/sketchHelpers';

extend(meshline);

const GL = { antialias: true, localClippingEnabled: true };
const CANVAS_CONTEXTS = [ReactReduxContext];

const FeasibilityViewerContainer = (props) => {
  const { profileId, legendKey, isOrthographic, editorType, updateServerReducer, isViewsOpen } = props;
  const [isPostOn, setIsPostOn] = useState(true);
  const [buildingMeshHasSurroundingBuildings, setBuildingMeshHasSurroundingBuildings] = useState(false);

  const buildingInfo = useActiveProfileBuildingInfo();
  const isEditModeOn = useSelector(({ editor }) => editor.editSessionType === EDIT_TYPES.FEASIBILITY);
  const isEditorLoading = useSelector(({ editor }) => editor.isLoading);
  const selectedRowKeys = useSelector((state) => state.feasibility.selectedRowKeys);
  const isEditorInTransformMode = useSelector(({ editor }) => editor.editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM);
  // const editorType = useSelector(({ editor }) => editor.editorType);
  const isSketchToolOn = useSelector(({ editor }) => editor.editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING || editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING);
  const isSketchParkingOn = useSelector(({ editor }) => editor.editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING || editor.editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_SURFACE_PARKING);
  const selectedObject = useSelector((state) => state.editor.selectedObject, shallowEqual);
  const selectedStory = useSelector(selectedStorySelector, shallowEqual);
  const isSketchCentric = useOverridableSetting('isSketchCentric', false);

  const selectedMass = useSelector(selectedMassSelector);
  const isBuildingStoriesOn = buildingInfo.swpProject && !editorType;
  const dispatch = useDispatch();

  THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1); // set Z axis to be top, default is Y

  useEffect(() => {}, [selectedRowKeys]); // TODO - TECH DEBT - remove and trigger canvas render when `selectedRowKeys` in other methods

  useEffect(() => {
    // This useEffect was written to handle cases of getting new masses after creating a new one with the sketch tool
    // It is possible it will change if other cases will meet this criteria and will have to behave differently
    if (isSketchCentric) {
      if (!isEditorLoading && (editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.WAITING_FOR_SKETCH || isSketchParkingOn)) {
        dispatch(SwappEditor.setEditorType(''));
      }
    } else if (!isEditorLoading && (isSketchToolOn || isSketchParkingOn)) {
      dispatch(SwappEditor.setEditorType(''));
    }
  }, [isEditorLoading, buildingInfo]);

  useEffect(() => {
    if (isEditModeOn) {
      dispatch(clearSelectedRowKeysAction());
    }
  }, [isEditModeOn]);

  const togglePost = (value, update) => {
    update(isOrthographic ? true : value);
  };

  const postInstance = useMemo(() => <Post aoPreset={Post.aoPresetsTypes.low} />, []);
  // RouterContext is bad, performance wise, causes a lot of rerenders, so we use HistoryContext instead
  // https://github.com/ReactTraining/react-router/issues/6999
  const ContextBridge = useContextBridge(ReactReduxContext, __HistoryContext);

  const handleEmptyCanvasClick = (e) => {
    if (didJustFinishDragging()) {
      return;
    }
    // Added isOrthographic to keep TRANSFORM edit mode in 3D. Because in these cases the use of the comment below is not needed
    // And it let's us keep the parkings selectable in 3d.
    if (isEditorInTransformMode && isOrthographic && !isEditorLoading) {
      // This event can happen when dragging is enabled, which prevents event capturing (stopPropagation). Therefore to know if
      // we clicked on the ground, we check whether the ray intersected any object from the 'BuildingMeshes' hierarchy.
      if (e === undefined || !e.intersections.some((inter) => findAncestor(inter.object, (node) => node.name === 'BuildingMeshes'))) {
        dispatch(SwappEditor.setEditorType(''));
      }
    }
  };

  const renderSwappViewer = () => (
    <StyledCanvas
      shadowMap
      gl={GL}
      colorManagement={false}
      invalidateFrameloop
      contexts={CANVAS_CONTEXTS}
      orthographic={isOrthographic}
      camera={isOrthographic ? CameraController.DEFAULT_ORTHO_PROPS : CameraController.DEFAULT_PERSPECTIVE_PROPS}
    >
      <ContextBridge>
        <SwappThreeContextProvider>
          <group onPointerDown={() => togglePost(false, setIsPostOn)} onPointerUp={() => togglePost(true, setIsPostOn)}>
            <Lights />
            { !isOrthographic && postInstance }
            <CameraController isOrthographic={isOrthographic} orbitControlsMinMax={[50, 500]} minMaxZoom={[1, 500]} />

            {/* ============== Views ============== */}
            {isViewsOpen && <Views profileId={profileId} isViewOnly={isSharedUrl()} attachmentId={selectedMass} />}

            {/* ========= building mesh ========= */}
            <Suspense fallback={null}>
              {isValidURL(buildingInfo[legendKey]) && (
                <BuildingMeshes
                  data={buildingInfo}
                  castShadow={isPostOn}
                  legendKey={legendKey}
                  intractable={isEditorInTransformMode && !isEditorLoading}
                  onResolvedHasSurroundingBuildings={setBuildingMeshHasSurroundingBuildings}
                  profileId={profileId}
                  selectedStory={isBuildingStoriesOn && selectedStory}
                  isSketchToolOn={isSketchToolOn}
                  isSketchParkingOn={isSketchParkingOn}
                  isOrthographic={isOrthographic}
                  isEditModeOn={isEditModeOn}
                  editorType={editorType}
                  selectedObject={selectedObject}
                  selectedRowKeys={selectedRowKeys}
                  updateServerReducer={updateServerReducer}
                />
              )}
            </Suspense>

            {/* ========= environment mesh ========= */}
            <Suspense fallback={null}>
              {isValidURL(buildingInfo.environmentUrl) && (
                <EnvMesh
                  url={buildingInfo.environmentUrl}
                  hideSurroundingBuildings={buildingMeshHasSurroundingBuildings}
                  castReceiveShadow={isPostOn}
                  onClick={handleEmptyCanvasClick}
                />
              )}
            </Suspense>
          </group>
        </SwappThreeContextProvider>
      </ContextBridge>
    </StyledCanvas>
  );

  return (
    <>
      {/* ========= UI ========= */}
      {isEditorLoading && <LoadingSpinner />}
      <ViewerUiPanelWrapper position="top-right" fitContent>
        <CompassImage />
      </ViewerUiPanelWrapper>

      {/* ========= viewer ========= */}
      {renderSwappViewer()}
    </>
  );
};

FeasibilityViewerContainer.propTypes = {
  profileId: PropTypes.number,
  legendKey: PropTypes.string,
  editorType: PropTypes.string,
  isOrthographic: PropTypes.bool,
  updateServerReducer: PropTypes.func,
  isViewsOpen: PropTypes.bool,
};

export default React.memo(FeasibilityViewerContainer);
