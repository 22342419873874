var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "three", "three.meshline"], function (require, exports, THREE, three_meshline_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.getMaterialByMaterialId = exports.getMeshLineMaterial = exports.getPhongeMaterial = exports.getLineMaterial = exports.getRandomMaterial = exports.materialsDictionary = exports.getMaterial = void 0;
    THREE = __importStar(THREE);
    THREE.Cache.enabled = true;
    // const texturesCache: TextureCache = {};
    // const baseUrl = 'http://swappmedia.s3.amazonaws.com/assets/local/';
    const getMaterial = (materialKey, material) => {
        if (material) {
            exports.materialsDictionary[materialKey] = material;
        }
        return exports.materialsDictionary[materialKey];
    };
    exports.getMaterial = getMaterial;
    // const getTexture = (textureKey: string): THREE.Texture => {
    //   const textureUrl = textureKeyToUrl[textureKey];
    //   if (!texturesCache[textureUrl]) {
    //     texturesCache[textureUrl] = loadTexture(textureUrl);
    //   }
    //   return texturesCache[textureUrl];
    // };
    // const loadTexture = (textureUrl: string) => loader.load(baseUrl + textureUrl, (texture) => {
    //   texture.needsUpdate = true;
    //   texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    //   texture.repeat.set(0.1, 0.1);
    // }, () => {
    //   // console.log('loading texture', progress);
    // }, () => {
    //   // console.error('failed loading texture', error);
    // });
    // const loadTextures = (texturesUrls: string[]) => {
    //   texturesUrls.forEach((textureUrl) => {
    //     texturesCache[textureUrl] = loadTexture(textureUrl);
    //   });
    // };
    // loadTextures(Object.values(textureKeyToUrl).map((url) => baseUrl + url));
    const LINE_MATERIAL = new THREE.LineBasicMaterial({ color: '#000000', linewidth: 3 });
    exports.materialsDictionary = {
        Gpb: new THREE.MeshPhongMaterial({ color: '#ff0000' }),
        Ti: new THREE.MeshPhongMaterial({ color: '#ff00ff' }),
        St: new THREE.MeshPhongMaterial({ color: '#00ff00' }),
        Con: new THREE.MeshPhongMaterial({ color: '#0000ff' }),
        default: new THREE.MeshPhongMaterial({ color: '#777777' }),
    };
    const uuidToMaterial = {};
    const addMaterialToDict = (material) => {
        uuidToMaterial[material.uuid] = material;
        return material;
    };
    const getRandomMaterial = (materialKey, hasLines) => {
        if (!exports.materialsDictionary[materialKey]) {
            if (hasLines) {
                exports.materialsDictionary[materialKey] = addMaterialToDict(new THREE.LineBasicMaterial({ color: '#000000', linewidth: 3 }));
            }
            else {
                exports.materialsDictionary[materialKey] = addMaterialToDict(new THREE.MeshPhongMaterial({ color: Math.random() * 0xffffff }));
            }
        }
        return exports.materialsDictionary[materialKey];
    };
    exports.getRandomMaterial = getRandomMaterial;
    const getLineMaterial = () => {
        return LINE_MATERIAL;
    };
    exports.getLineMaterial = getLineMaterial;
    const getPhongeMaterial = (color, opacity) => {
        let materialKey = `phonge-c:${color}`;
        let transparent = false;
        if (opacity !== undefined) {
            transparent = true;
            materialKey += `-o:${opacity}`;
        }
        if (!exports.materialsDictionary[materialKey]) {
            exports.materialsDictionary[materialKey] = addMaterialToDict(new THREE.MeshPhongMaterial({ color, transparent, opacity }));
        }
        return exports.materialsDictionary[materialKey];
    };
    exports.getPhongeMaterial = getPhongeMaterial;
    const getMeshLineMaterial = (config) => {
        if (!exports.materialsDictionary[config.toString()]) {
            exports.materialsDictionary[config.toString()] = addMaterialToDict(new three_meshline_1.MeshLineMaterial(config));
        }
        return exports.materialsDictionary[config.toString()];
    };
    exports.getMeshLineMaterial = getMeshLineMaterial;
    const getMaterialByMaterialId = (materialId) => {
        return uuidToMaterial[materialId];
    };
    exports.getMaterialByMaterialId = getMaterialByMaterialId;
});
