var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "three", "three/src/math/Vector3", "three"], function (require, exports, three_1, Vector3_1, THREE) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.getAncestorSceneObjectByType = exports.getParentSceneObject = exports.scaleGeometry = exports.geomToThreeShape = exports.swpToThreeTransform = void 0;
    THREE = __importStar(THREE);
    const swpToThreeTransform = (transform) => {
        const translate = new Vector3_1.Vector3();
        const rotate = new three_1.Quaternion();
        const scale = new Vector3_1.Vector3();
        if (transform === null || transform === void 0 ? void 0 : transform.position) {
            translate.set(transform.position.x, transform.position.y, transform.position.z);
        }
        if (transform === null || transform === void 0 ? void 0 : transform.rotation) {
            rotate.set(transform.rotation.x, transform.rotation.y, transform.rotation.z, transform.rotation.w);
        }
        else {
            rotate.identity();
        }
        if (transform === null || transform === void 0 ? void 0 : transform.scale) {
            scale.set(transform.scale.x, transform.scale.y, transform.scale.z);
        }
        else {
            scale.set(1, 1, 1);
        }
        return { translate, rotate, scale };
    };
    exports.swpToThreeTransform = swpToThreeTransform;
    const geomToThreeShape = (swpGeom) => {
        var _a, _b;
        if (!swpGeom) {
            return;
        }
        const geom = swpGeom.saveToObject();
        let shape;
        if ((_a = geom === null || geom === void 0 ? void 0 : geom.boundary) === null || _a === void 0 ? void 0 : _a.length) {
            shape = new THREE.Shape(geom === null || geom === void 0 ? void 0 : geom.boundary.map((boundary) => new THREE.Vector2(...boundary)));
        }
        if (shape && ((_b = geom === null || geom === void 0 ? void 0 : geom.holes) === null || _b === void 0 ? void 0 : _b.length)) {
            geom === null || geom === void 0 ? void 0 : geom.holes.forEach((hole) => {
                shape.holes.push(new THREE.Path(hole.map((boundary) => new THREE.Vector2(...boundary))));
            });
        }
        return shape;
    };
    exports.geomToThreeShape = geomToThreeShape;
    const scaleGeometry = (geometry, scaleFactor) => {
        const vecCenter = new THREE.Vector3();
        const box = new THREE.Box3();
        if (geometry instanceof THREE.BufferGeometry) {
            box.setFromBufferAttribute(geometry.attributes.position);
        }
        else {
            box.setFromPoints(geometry.vertices);
        }
        box.getCenter(vecCenter);
        geometry.translate(-vecCenter.x, -vecCenter.y, -vecCenter.z);
        geometry.scale(scaleFactor, scaleFactor, scaleFactor);
        geometry.translate(vecCenter.x, vecCenter.y, vecCenter.z);
        return geometry;
    };
    exports.scaleGeometry = scaleGeometry;
    const getParentSceneObject = (object) => {
        let parent = object;
        while (!parent.swpProduct) {
            parent = parent.parent;
            if (!parent) {
                break;
            }
        }
        return parent;
    };
    exports.getParentSceneObject = getParentSceneObject;
    const getAncestorSceneObjectByType = (object, type) => {
        let parent = object;
        while (!!parent && !(parent.swpProduct instanceof type)) {
            parent = parent === null || parent === void 0 ? void 0 : parent.parent;
        }
        return parent;
    };
    exports.getAncestorSceneObjectByType = getAncestorSceneObjectByType;
});
