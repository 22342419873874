import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashRange from 'lodash/range';
import { useDispatch, useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import * as SwappEditor from 'store/editor';
import lodashGet from 'lodash/get';
import { ROOM_TAGS } from 'constants/testFitConsts';
import { getRectanglePolygonCenter, vecDistance } from 'utils/algorithms/algorithmHelpers';
import ExtrudeMesh from '../../components/ExtrudeMesh';
import MeshLine from '../../components/MeshLine';
import DnDComp from './DnDComp';
import DndRoom from './DndRoom';

const DraggableTube = (props) => {
  const { tube, visible } = props;

  const currentTheme = useSelector(currentThemeSelector);
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const activeSubTube = lodashGet(tube, 'subTubes', []).find((subTube) => lodashIncludes(subTube?.roomIds, selectedObject?.id));
  const dispatch = useDispatch();
  const points = activeSubTube?.points || tube.points;
  const isSelectedObjectHasLockTag = lodashGet(selectedObject, `tags[${ROOM_TAGS.LOCK}]`);

  const getTubeRoomList = () => tube.roomsPartitions.map((item, index) => {
    const thisRoomLength = tube.size.length * item.positionData.t;
    const nextRoomLength = (tube.roomsPartitions.length - 1) === index ? 0 : tube.size.length * tube.roomsPartitions[index + 1].positionData.t;
    const roomLength = thisRoomLength - nextRoomLength;
    const roomNameKey = item.roomInfo?.type;
    const roomParsedName = T.translate(roomNameKey).split(' ').join('\n');

    const handleRoomClicked = () => {
      dispatch(SwappEditor.selectObject({ ...item.roomInfo, subTubeId: activeSubTube.id }));
    };

    return {
      id: item.roomInfo.id,
      index,
      roomSize: roomLength,
      color: item.roomInfo.color,
      name: roomParsedName,
      position: item.positionData.point,
      roomInfo: item.roomInfo,
      handleRoomClicked,
    };
  }).filter((room) => {
    if (lodashIsEmpty(activeSubTube)) {
      return room;
    }
    return lodashIncludes(activeSubTube.roomIds, room.id); // filter to subTube
  });

  const [rooms, setRooms] = useState(getTubeRoomList());
  const [currentRoomOrder, setCurrentRoomOrder] = useState([]);

  useEffect(() => {
    setRooms(getTubeRoomList());
    saveNewOrder();
  }, [activeSubTube]);

  useEffect(() => { // we save room order globaly in `currentRoomOrder` so we can get the current order in unmount
    if (!rooms || !rooms.length) {
      return;
    }
    const roomOrderSection = rooms.map((room) => room.index);
    const minIndexInTube = Math.min(...rooms.map((e) => e.index));
    const maxIndexInTube = Math.max(...rooms.map((e) => e.index));
    // if subTube we take all indexes before and after the section

    setCurrentRoomOrder([...lodashRange(minIndexInTube), ...roomOrderSection, ...lodashRange(maxIndexInTube + 1, tube.roomsPartitions.length)]);
  }, [rooms]);

  useEffect(() => {
    if (!visible) {
      setCurrentRoomOrder([]);
    }

    saveNewOrder();
  }, [visible]);

  const saveNewOrder = () => {
    const roomsOldOrder = tube.roomsPartitions.map((_, index) => index);
    if (lodashIsEqual(currentRoomOrder, roomsOldOrder) || lodashIsEmpty(currentRoomOrder) || lodashIsEmpty(roomsOldOrder)
        || currentRoomOrder.length <= 1 || roomsOldOrder.length !== currentRoomOrder.length) {
      return;
    }

    const action = [{ name: 'TEST_FIT_REORDER_TUBE_ROOMS', parameters: { tube_id: tube.tubeId, rooms_order: currentRoomOrder } }];
    dispatch(SwappEditor.addOperations(action));
    setCurrentRoomOrder([]);
  };

  // ///////////////////////////////
  const getPos = (index) => {
    let roomPos = 0;
    for (let i = 0; i < rooms.length; i++) {
      if (i === index) {
        const pos = [0, 0, 0];
        pos[0] = roomPos;
        return pos;
      }
      roomPos += rooms[i].roomSize;
    }
  };

  const swappRooms = (currRooms, from, to) => {
    const newRooms = [...currRooms];
    const tmp = newRooms[from];
    newRooms[from] = rooms[to];
    newRooms[to] = tmp;
    return newRooms;
  };

  const onPointMoved = (point, draggedIndex, delta) => {
    const deltaAxis = delta[0];

    // Because we swapp items while looping the order of iteration should be based on the direction
    const iterateRoms = (dx, cb) => {
      if (dx < 0) {
        for (let i = 0; i < rooms.length; i++) {
          cb(i);
        }
      } else {
        for (let i = rooms.length - 1; i >= 0; i--) {
          cb(i);
        }
      }
    };

    iterateRoms(deltaAxis, (i) => {
      const pp = getPos(i);
      if ((draggedIndex < i && point[0] > pp[0]) || (draggedIndex > i && point[0] < pp[0])) {
        setRooms((currRooms) => swappRooms(currRooms, draggedIndex, i));
      }
    });
  };

  const center = getRectanglePolygonCenter(points);
  const length = vecDistance(points[0], points[1]);

  const renderedRooms = rooms.map((room, index) => {
    const position = getPos(index);
    const selected = selectedObject?.id === room.id;
    const roomPosition = {
      isFirst: index === 0,
      isLast: index === rooms.length - 1,
    };

    return (
      <DnDComp
        key={room.id}
        realIndex={index}
        tubeSize={tube.size}
        position={position}
        selected={selected}
        onPointMoved={onPointMoved}
        handleClick={room.handleRoomClicked}
        tubeCenter={center}
        childSize={room.roomSize}
      >
        <DndRoom tube={tube} room={room} roomPosition={roomPosition} />
      </DnDComp>
    );
  });
  // //////////////////////////////

  if (isSelectedObjectHasLockTag || !visible || rooms.length <= 1) {
    return null;
  }

  return (
    <group>
      {/* ============== fill in tube ============== */}
      <ExtrudeMesh unlit envelope={points} color={currentTheme.colors.gray_04} extrudeDepth={2} meshProps={{ position: [0, 0, 200] }} />

      {/* ============== border ============== */}
      <MeshLine vertices={points} color={currentTheme.colors.white} lineWidth={0.8} height={201} />
      <MeshLine vertices={points} color={currentTheme.colors.success} lineWidth={1.6} height={200} />

      {/* ============== rooms ============== */}
      {/* renderedRooms are being rendered from the left-bottom corner of the tube */}
      {/* We rotate the tube around it's center: */}
      <group position={[...center, 0]}>
        <group rotation={[0, 0, tube.tubeAngle]}>
          <group position={[-length / 2, tube.size.depth / 2, 0]}>
            {renderedRooms}
          </group>
        </group>
      </group>
    </group>
  );
};

DraggableTube.propTypes = {
  tube: PropTypes.object,
  visible: PropTypes.bool,
};

export default React.memo(DraggableTube);
