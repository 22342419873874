import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import ProgramContainer from './swpProjectResult/program/ProgramContainer';
import BuildContainer from './result/build/BuildContainer';

const { program, build } = UI_AUTHORIZE_PATH;

const SwpProjectStudiesRoute = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/${program}`} component={ProgramContainer} />
      <Route path={`${match.path}/${build}`} component={BuildContainer} />
      <Redirect to={`${match.path}/${program}`} />
    </Switch>
  );
};

export default SwpProjectStudiesRoute;
