import React, { useMemo, useState } from 'react';
import { Text } from '@react-three/drei';
import { useDispatch, useSelector, useStore } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashSum from 'lodash/sum';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import { currentThemeSelector } from 'store/userSettings';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { getPolygonArea } from 'utils/algorithms/algorithmHelpers';
import { sqfToSqm } from 'utils/helpers/unitsHelpers';
import { ROOM_FILTER_TYPES, ROOM_TAGS, TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import { getUrlParams } from 'utils/helpers/navigationHelpers';
import * as SwappEditor from 'store/editor';
import { isTestFitItemSelectedSelector } from 'store/editor';
import { getProfileByIdSelector } from 'store/swappProfile';
import { testFitRoomsFilterSelector, isMultiClickActiveSelector, multiClickStatusSelector, setMultiClick } from 'store/testFit';
import icons from 'styles/static/icons/testFit/editor';
import swappRoomIcon from 'styles/static/img/swappRoomIcon.png';
import Door from './Door';
import Desk from './Desk';
import CustomFurniture from './CustomFurniture';
import DisplayObject from './DisplayObject';
import ExtrudeMesh from '../../components/ExtrudeMesh';
import StyledHtml from '../../components/StyledHtml';
import MeshLine from '../../components/MeshLine';

const Room = (props) => {
  const { room, isImperial, planLineThickness, planLineColor, profileId, disabled } = props;
  const { id, color, furniture, points, innerPoints, holes, displayObjects,
    type, category, roomCenterPosition, departmentId, tags, localPreview } = room;

  const profile = useSelector((state) => getProfileByIdSelector(state, profileId));
  const editorType = useSelector(({ editor }) => editor.editorType);
  const currentTheme = useSelector(currentThemeSelector);
  const selectedRowKeys = useSelector(({ editor }) => editor.selectedRowKeys);
  const selectedCategoryRowKeys = useSelector(({ editor }) => editor.selectedCategoryRowKeys);
  const selectedDepartmentRowKeys = useSelector(({ editor }) => editor.selectedDepartmentRowKeys);
  const result = useSelector(({ editor }) => editor.result);
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const isSelected = useSelector((state) => (isTestFitItemSelectedSelector(state, id)));
  const roomFilterType = useSelector(testFitRoomsFilterSelector);
  const dispatch = useDispatch();
  const store = useStore();
  const [hovered, setHover] = useState(false);
  const params = getUrlParams();

  const intractable = lodashIncludes([TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS, TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS], editorType) && !(type === 'CORRIDOR' && category === 'ASF');
  const vertices = lodashIsEmpty(innerPoints) ? points : innerPoints;
  const desks = furniture && furniture.filter((f) => f.furniture_type === 'desk');
  const doors = furniture && furniture.filter((f) => f.furniture_type === 'door');
  const customFurniture = furniture && furniture.filter((f) => f.type === 'custom');
  const area = (getPolygonArea(points) - lodashSum(holes.map((hole) => getPolygonArea(hole)))) / 144;
  const isForcedImperial = (lodashGet(params, 'unit') && params.unit === 'imperial') || isImperial;
  const roomParsedName = T.translate(type).split(' ').join('\n'); // DEV NOTE: Change this to roomParsedName=id to visualize room ids
  const roomAreaSize = numberWithComma(isForcedImperial ? area : sqfToSqm(area), { fixed: 0, suffix: ` ${T.translate(isForcedImperial ? 'SQF' : 'SQM')}` });
  const isSelectedRoomHasLockTag = lodashGet(tags, `[${ROOM_TAGS.LOCK}]`);
  const isSelectedObjectHasLockTag = lodashGet(selectedObject, `tags[${ROOM_TAGS.LOCK}]`);
  const canSelectedRoomFitInEmptyRoom = selectedObject?.subTubeId !== room.subTubeId && !isSelectedRoomHasLockTag && !isSelectedObjectHasLockTag && category === 'EMPTY' && !lodashIsEmpty(selectedObject) && selectedObject?.tilesAmount <= room?.tilesAmount;

  const getDepartmentColor = () => {
    const departments = lodashGet(result, 'multiBoundaryFile.departments') || lodashGet(profile, 'result.multiBoundaryFile.departments', []);
    return lodashGet(departments.find((e) => e.id === departmentId), 'color') || currentTheme.colors.layers.EMPTY.MAIN;
  };

  const roomColor = useMemo(() => {
    if (isSelected) {
      return currentTheme.colors.selected3dObject;
    }

    const colorLayers = {
      LSF: currentTheme.colors.layers.LSF.MAIN,
      PSF: currentTheme.colors.layers.PSF.MAIN,
      FSF: currentTheme.colors.layers.FSF.MAIN,
      ASF: currentTheme.colors.layers.ASF.MAIN,
      EMPTY: currentTheme.colors.layers.EMPTY.MAIN,
    };
    const originalColor = color || colorLayers[category];

    let currentColor = originalColor;
    const unfiltered = colorLayers.EMPTY;
    const grayColorLight = '#b9b9b9';
    const grayColorDark = '#868686';

    // when selecting 'LOBBY_LOUNGE' we want to highlight 'CORRIDOR' as well
    const isRoomFilteredByRoom = lodashIncludes(selectedRowKeys, type === 'CORRIDOR' ? 'LOBBY_LOUNGE' : type);
    const isRoomFilteredByCategory = lodashIncludes(selectedCategoryRowKeys, category);
    const isRoomFilteredByDepartment = lodashIncludes(selectedDepartmentRowKeys, departmentId);
    const isDepartmentAllSelected = lodashIncludes(selectedDepartmentRowKeys, 'all');
    const hasFilterByRoom = !lodashIsEmpty(selectedRowKeys);
    const hasFilterByCategory = !lodashIsEmpty(selectedCategoryRowKeys);
    const hasFilterByDepartment = !lodashIsEmpty(selectedDepartmentRowKeys);

    if (hasFilterByCategory) {
      if (isRoomFilteredByDepartment) {
        currentColor = isRoomFilteredByCategory ? getDepartmentColor() : grayColorDark;
      } else if (isRoomFilteredByCategory) {
        currentColor = hasFilterByDepartment ? grayColorLight : originalColor;
      } else {
        currentColor = unfiltered;
      }
    }

    if (hasFilterByRoom) {
      if (departmentId && isDepartmentAllSelected) {
        currentColor = isRoomFilteredByRoom ? originalColor : grayColorDark;
      } else if (isRoomFilteredByDepartment) {
        currentColor = isRoomFilteredByRoom ? originalColor : grayColorDark;
      } else if (isRoomFilteredByRoom) {
        currentColor = hasFilterByDepartment ? grayColorLight : originalColor;
      } else {
        currentColor = unfiltered;
      }
    }

    if (!hasFilterByCategory && !hasFilterByRoom) {
      currentColor = isRoomFilteredByDepartment || isDepartmentAllSelected ? getDepartmentColor() : unfiltered;
    }

    if (params['color-source'] === 'department' || editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS || (roomFilterType === ROOM_FILTER_TYPES.DEPARTMENTS && intractable)) {
      currentColor = getDepartmentColor() || unfiltered;
    } else if (!hasFilterByCategory && !hasFilterByRoom && !hasFilterByDepartment) {
      currentColor = originalColor;
    }

    if (!lodashIncludes([unfiltered, grayColorDark, grayColorLight], currentColor)) {
      dispatch(SwappEditor.addSelectedHighlightedItems(id));
    }

    return currentColor;
  }, [isSelected, editorType, result, profile, type, departmentId, category,
    selectedRowKeys, selectedCategoryRowKeys, selectedDepartmentRowKeys, roomFilterType]);

  const handleRoomClicked = (e) => {
    e.stopPropagation();
    if (!intractable || disabled || editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS) {
      return;
    }

    if (category === 'EMPTY' && !lodashIsEmpty(selectedObject) && canSelectedRoomFitInEmptyRoom) {
      let actions = [];
      if (selectedObject.isSelectedInUi) {
        if (lodashIsEmpty(room.tiles)) {
          actions = [{ name: 'TEST_FIT_REPURPOSE_ROOM', parameters: { room_id: id, new_room_type: selectedObject.type }, localViewParameters: { newRoomCategory: selectedObject.category, selectedRoom: room } }];
        } else {
          actions = [{ name: 'TEST_FIT_ALLOCATE_TILES', parameters: { tile_ids: room.tiles.slice(0, selectedObject.tilesAmount).map((tile) => tile.id), new_room_type: selectedObject.type } }];
        }
      } else {
        const deallocateRoomAction = { name: 'TEST_FIT_DEALLOCATE_ROOM', parameters: { room_id: selectedObject.id }, localViewParameters: { selectedRoom: selectedObject } };

        if (lodashIsEmpty(selectedObject.tiles)) {
          actions = [
            deallocateRoomAction,
            { name: 'TEST_FIT_REPURPOSE_ROOM', parameters: { room_id: id, new_room_type: selectedObject.type }, localViewParameters: { newRoomCategory: selectedObject.category, selectedRoom: selectedObject } },
          ];
        } else {
          actions = [
            { name: 'TEST_FIT_DEALLOCATE_ROOM', parameters: { room_id: selectedObject.id } },
            { name: 'TEST_FIT_ALLOCATE_TILES', parameters: { tile_ids: room.tiles.slice(0, selectedObject.tilesAmount).map((tile) => tile.id), new_room_type: selectedObject.type } },
          ];
        }
      }

      dispatch(SwappEditor.addOperations(actions));
      return;
    }

    if (editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS) {
      dispatch(SwappEditor.selectObject(room));
    }
  };

  const changeCursor = (cursor) => {
    document.body.style.cursor = cursor;
  };

  const onPointer = (isOver) => {
    if (isSelectedRoomHasLockTag && editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS) {
      return;
    }

    if (intractable) {
      setHover(isOver);
      if (!isOver) {
        changeCursor('default');
      } else if (canSelectedRoomFitInEmptyRoom) {
        changeCursor(`url(${swappRoomIcon}), auto`);
      } else {
        changeCursor('pointer');
      }
    }
  };

  const baseOpacity = lodashIsEmpty(innerPoints)
  && !isSelected
  && lodashIsEmpty([...selectedRowKeys, ...selectedCategoryRowKeys, ...selectedDepartmentRowKeys])
  && editorType !== TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS ? 0.25 : 1;

  const renderEmptyRoomBorder = () => {
    if (category !== 'EMPTY' || isSelected || lodashIsEmpty(selectedObject) || editorType !== TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS || selectedObject?.subTubeId === room.subTubeId) {
      return;
    }

    return (
      <MeshLine
        vertices={vertices}
        color={canSelectedRoomFitInEmptyRoom ? currentTheme.colors.success : currentTheme.colors.danger}
        lineWidth={0.3}
        height={210}
      />
    );
  };

  const renderDepartmentRoomBorder = () => {
    const isDepartmentAllSelected = lodashIncludes(selectedDepartmentRowKeys, 'all');
    const hasFilterByRoom = !lodashIsEmpty(selectedRowKeys);
    const isDepartmentSelected = lodashIncludes(selectedDepartmentRowKeys, departmentId);
    if (departmentId && hasFilterByRoom && (isDepartmentSelected || isDepartmentAllSelected)) {
      return (
        <MeshLine
          vertices={vertices}
          color={getDepartmentColor()}
          lineWidth={0.6}
          height={210}
        />
      );
    }
  };

  const toggleDepartment = (e, forceClick) => {
    e.stopPropagation();
    const isMultiClickActive = isMultiClickActiveSelector(store.getState());
    const multiClickStatus = multiClickStatusSelector(store.getState());

    if (multiClickStatus === isSelected && !forceClick) {
      return;
    }

    if ((isMultiClickActive || forceClick) && editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS) {
      dispatch(SwappEditor.toggleObjectSelection(room));
    }
  };

  return (
    <group name="room">
      {/* ============== inside room ============== */}
      <group
        position={[0, 0, -2]}
        onPointerOver={() => onPointer(true)}
        onPointerOut={() => onPointer(false)}
        onClick={(e) => handleRoomClicked(e)}
        onPointerDown={(e) => {
          dispatch(setMultiClick({ active: true, isSelected }));
          toggleDepartment(e, true);
        }}
        onPointerUp={() => dispatch(setMultiClick({ active: false, isSelected: false }))}
        onPointerEnter={(e) => toggleDepartment(e)}
      >
        <ExtrudeMesh
          unlit
          receiveShadow
          envelope={vertices}
          // holes={holes}
          color={roomColor}
          extrudeDepth={4}
          transparent={lodashIsEmpty(innerPoints)}
          opacity={hovered ? (baseOpacity - 0.04) : baseOpacity}
        />
      </group>

      {/* ============== inside room data ============== */}
      {category !== 'Exteriors' && (
      <group position={[0, 0, 70]} name="roomText">
        {/* =========== lock Icon =========== */}
        {editorType && isSelectedRoomHasLockTag && (
          <StyledHtml fixed position={[roomCenterPosition[0], roomCenterPosition[1] + 30, roomCenterPosition[2]]}>
            <Icon component={icons.lockIcon} />
          </StyledHtml>
        )}
        {/* =========== text =========== */}
        <Text
          // onClick={() => console.log('🍔', room)} // used for QA
          textAlign="center"
          position={roomCenterPosition}
          fontSize={area < 40 ? 10 : 13}
          color="black"
        >
          {`${roomParsedName} \n ${roomAreaSize}`}
        </Text>
      </group>
      )}

      {/* ============== EMPTY room border ============== */}
      {!localPreview && renderEmptyRoomBorder()}

      {/* ============== Department room border ============== */}
      {renderDepartmentRoomBorder()}

      {/* ============== Action Buttons ============== */}
      {/* <RoomActionButton show={isSelected && editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS} room={room} /> */}

      {/* ============== custom furniture ============== */}
      {!localPreview && !lodashIsEmpty(customFurniture) && customFurniture.map((item, index) => <CustomFurniture profileId={profileId} key={index} item={item} lineColor={planLineColor} lineThickness={planLineThickness} />)}

      {/* ============== desks ============== */}
      {!localPreview && !lodashIsEmpty(desks) && desks.map((desk, index) => <Desk key={index} desk={desk} lineColor={planLineColor} lineThickness={planLineThickness} />)}

      {/* ============== doors ============== */}
      {!localPreview && !lodashIsEmpty(doors) && doors.map((door, index) => <Door key={index} door={door} lineColor={planLineColor} lineThickness={planLineThickness} />)}

      {/* ============== display objects ============== */}
      {!localPreview && !lodashIsEmpty(displayObjects) && displayObjects.map((item, index) => <DisplayObject key={index} lines={item} lineColor={planLineColor} lineThickness={planLineThickness} />)}

      {/* ============== extraWalls QA render ============== */}
      {/* {room.extraWalls && room.extraWalls.map((wall) => <MeshLine vertices={wall} height={230} lineWidth={0.05} color="hotpink" />)} */}
    </group>
  );
};

Room.propTypes = {
  room: PropTypes.object,
  isImperial: PropTypes.bool,
  disabled: PropTypes.bool,
  planLineThickness: PropTypes.number,
  profileId: PropTypes.number,
  planLineColor: PropTypes.string,
};

export default React.memo(Room, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
