import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { Text } from '@react-three/drei';
import { ROOM_FILTER_TYPES } from 'constants/testFitConsts';
import Icon from '@ant-design/icons';
import { RAD_2_DEG } from 'utils/algorithms/algorithmHelpers';
import { useSelector } from 'react-redux';
import { testFitRoomsFilterSelector } from 'store/testFit';
import { getProfileByIdSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { currentThemeSelector } from 'store/userSettings';
import icons from 'styles/static/icons/testFit/editor';
import StyledHtml from '../../components/StyledHtml';
import MeshLine from '../../components/MeshLine';
import ExtrudeMesh from '../../components/ExtrudeMesh';

const DndRoom = (props) => {
  const { tube, room, roomPosition } = props;

  const locationData = parseLocationUrl(window.location);
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const result = useSelector(({ editor }) => editor.result);
  const roomFilterType = useSelector(testFitRoomsFilterSelector);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const currentTheme = useSelector(currentThemeSelector);

  const { isFirst, isLast } = roomPosition;
  const [hovered, setHover] = useState(false);
  const selected = selectedObject?.id === room.id;
  const vertices = [[0, 0], [room.roomSize, 0], [room.roomSize, -tube.size.depth], [0, -tube.size.depth], [0, 0]];
  const onPointer = (value) => {
    setHover(value);
  };

  const getDepartmentColor = () => {
    const departments = lodashGet(result, 'multiBoundaryFile.departments') || lodashGet(profile, 'result.multiBoundaryFile.departments', []);
    return lodashGet(departments.find((e) => e.id === room.roomInfo.departmentId), 'color');
  };

  const color = () => {
    if (selected) {
      return currentTheme.colors.selected3dObject;
    }
    if (roomFilterType === ROOM_FILTER_TYPES.DEPARTMENTS) {
      return getDepartmentColor();
    }

    return room.color;
  };

  return (
    <group>
      {/* ============== room mesh ============== */}
      <mesh onPointerOver={() => onPointer(true)} onPointerOut={() => onPointer(false)} onClick={room.handleRoomClicked}>
        <ExtrudeMesh
          unlit
          receiveShadow
          envelope={vertices}
          color={color()}
          extrudeDepth={4}
          opacity={hovered ? 0.94 : 1}
        />
      </mesh>
      <MeshLine
        vertices={vertices}
        color={selected ? currentTheme.colors.white : currentTheme.colors.gray_08}
        lineWidth={selected ? 0.4 : 0.1}
        height={selected ? 6 : 5}
      />

      {/* ============== Room Name ============== */}
      <Text textAlign="center" position={[room.roomSize / 2, -tube.size.depth / 2, 10]} fontSize={16} color={selected ? currentTheme.colors.white : currentTheme.colors.black} rotation={[0, 0, -tube.tubeAngle]}>
        {room.name}
      </Text>

      {/* ============== Arrows ============== */}
      {selected && (
      <group>
        {!isLast && (
        <StyledHtml fixed position={[room.roomSize + 20, -tube.size.depth / 2, 10]} rotate={-(tube.tubeAngle * RAD_2_DEG)}>
          <Icon component={icons.dragIcon} style={{ color: currentTheme.colors.white }} />
        </StyledHtml>
        )}
        {!isFirst && (
        <StyledHtml fixed position={[-20, -tube.size.depth / 2, 10]} rotate={-(tube.tubeAngle * RAD_2_DEG)}>
          <Icon component={icons.dragIcon2} style={{ color: currentTheme.colors.white }} />
        </StyledHtml>
        )}
      </group>
      )}
    </group>
  );
};

DndRoom.propTypes = {
  tube: PropTypes.object,
  room: PropTypes.object,
  roomPosition: PropTypes.object,
};

export default DndRoom;
