import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import html2canvas from 'html2canvas';
import lodashIncludes from 'lodash/includes';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { pageWidthInPx, pageHeightInPx, pdfPageIdName } from 'constants/pdfConsts';
import { WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import { usePrevious } from 'utils/hooks';
import { activePollingProfileIdsSelector } from 'store/swappProfile/selectors';
import { SecondaryButton } from 'styles/commonComponents.styles';
import { LoadingOutlined } from '@ant-design/icons';
import { generateDownloadFileAction, pollSwappProjectAction } from 'store/swappProfile/actions/swappProfileActions';
import { TestFitPdfExportContainerWrapper } from './PdfExportContainer.styles';
import TestFitPdf from './TestFitPdf';
import {
  loadingSelector,
  swappProfileResultsSelector,
  swappProjectSelector,
} from '../../store/swappProfile';

const PdfContentWrapper = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray_01};
  
  // css for QA so we will be able to see the pages
  // position: fixed;
  // top: 0;
  // left: 0;
  // overflow-y: scroll;
  // height: 100vh;
  // background-color: ${({ theme }) => theme.colors.gray_01};
`;

const PdfDownloadButtonNew = (props) => {
  const { hide, profileId } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const currentProfileId = profileId || locationData.profileId;
  const isLoading = useSelector(loadingSelector);
  const swappProject = useSelector(swappProjectSelector);
  const activePollingProfileIds = useSelector(activePollingProfileIdsSelector);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, currentProfileId, isImperial));
  const isWaitingFor = lodashGet(swappProfileResults, `waitingFor.[${WAITING_FOR_KEYS.TEST_FIT_SCREENSHOTS}]`);
  const hasResult = !lodashIsEmpty(lodashGet(swappProfileResults, 'pdfScreenshots'));
  const prevIsWaitingFor = usePrevious(isWaitingFor);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => { // start polling
    if (isLoading && currentProfileId && !lodashIncludes(activePollingProfileIds, currentProfileId)) {
      dispatch(pollSwappProjectAction(locationData.projectId, currentProfileId, history, WAITING_FOR_KEYS.TEST_FIT_SCREENSHOTS));
    }
  }, [isLoading, currentProfileId, activePollingProfileIds]);

  useEffect(() => {
    if (isWaitingFor) {
      setIsPdfGenerating(true);
    }
    if (prevIsWaitingFor && !isWaitingFor) {
      handlePdfDownload()
        .catch(() => setIsPdfGenerating(false));
    }
  }, [isWaitingFor]);

  if (hide || !swappProfileResults || !swappProject) {
    return null;
  }

  const startDownload = async () => {
    if (hasResult) {
      setIsPdfGenerating(true);
      handlePdfDownload()
        .catch(() => setIsPdfGenerating(false));
    } else {
      dispatch(generateDownloadFileAction(locationData.projectId, currentProfileId, history, WAITING_FOR_KEYS.TEST_FIT_SCREENSHOTS));
    }
  };

  const handlePdfDownload = async () => {
    const numberOfPages = document.getElementById('pagesWrapper').childElementCount;

    const doc = await PDFDocument.create();
    for (let i = 0; i < numberOfPages; i++) {
      // html2canvas convert each page to canvas then we convert to PNG.
      // 'scale' property controls the quality of the img but make the process slower
      await html2canvas(document.getElementById(`${pdfPageIdName}${i}`), { scale: 2, width: pageWidthInPx })
        .then(async (canvas) => {
          const page = doc.addPage();
          page.setSize(pageWidthInPx, pageHeightInPx);
          const imgData = canvas.toDataURL('image/png');
          const pngImage = await doc.embedPng(imgData); // create a PDF PNG element
          page.drawImage(pngImage, { x: 0, y: 0, width: pageWidthInPx, height: pageHeightInPx });
        });
    }

    const plansPngUrl = lodashGet(swappProfileResults, `pdfScreenshots[${isImperial ? 'imperial' : 'metric'}]`, []);
    const departmentsPngUrl = lodashGet(swappProfileResults, `pdfScreenshots[${isImperial ? 'imperialDepartments' : 'metricDepartments'}]`, []);
    const allPlans = [];
    plansPngUrl.forEach((planImage, index) => {
      allPlans.push({ image: planImage, pageIndex: 3 });

      if (!lodashIsEmpty(departmentsPngUrl) && !lodashIsEmpty(departmentsPngUrl[index])) {
        allPlans.push({ image: departmentsPngUrl[index], pageIndex: 4 });
      }
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const planImageData of allPlans) {
      const [emptyBasePage] = await doc.copyPages(doc, [planImageData.pageIndex]); // copy the empty page (emptyPageIndex)
      const page = doc.addPage(emptyBasePage); // creates a new page with the empty page
      const imageBlob = await fetch(planImageData.image).then((res) => res.arrayBuffer()); // convert image to blob
      const pngImage = await doc.embedPng(imageBlob); // create a PDF PNG element
      page.drawImage(pngImage, { x: 380, y: 220, width: 1350, height: 675 }); // render the image on page (original size is 3200X1600)
    }
    // Delete the empty pages that as used fot the plans
    doc.removePage(3);
    doc.removePage(3); // index 4 but after the first delete it index 3

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await doc.save();

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${swappProject.name}.pdf`, 'application/pdf');
    setIsPdfGenerating(false);
  };

  return (
    <>
      <SecondaryButton disabled={isPdfGenerating} onClick={() => (isPdfGenerating ? null : startDownload())} width={90} marginRight={10}>
        {isPdfGenerating ? <LoadingOutlined spin /> : T.translate('PDF_EXPORT_TEXT')}
      </SecondaryButton>

      <PdfContentWrapper>
        <TestFitPdfExportContainerWrapper id="pagesWrapper">
          <TestFitPdf />
        </TestFitPdfExportContainerWrapper>
      </PdfContentWrapper>
    </>
  );
};

PdfDownloadButtonNew.propTypes = {
  profileId: PropTypes.number,
  hide: PropTypes.bool,
};

export default PdfDownloadButtonNew;
