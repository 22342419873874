define(["require", "exports", "./SwpClassMap"], function (require, exports, SwpClassMap_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    // In the SwpQuaternion representation, [0] represents the cos(angle) and [1,2,3] represent sin(angle) * direction
    class SwpQuaternion extends Array {
        constructor(items) {
            super(...items);
            Object.setPrototypeOf(this, Object.create(SwpQuaternion.prototype));
        }
        get x() {
            return this[1];
        }
        set x(val) {
            this[1] = val;
        }
        get y() {
            return this[2];
        }
        set y(val) {
            this[2] = val;
        }
        get z() {
            return this[3];
        }
        set z(val) {
            this[3] = val;
        }
        get w() {
            return this[0];
        }
        set w(val) {
            this[0] = val;
        }
        saveToObject() {
            return this;
        }
    }
    exports.default = SwpQuaternion;
    (0, SwpClassMap_1.registerClass)(SwpQuaternion, 'SwpQuaternion');
});
