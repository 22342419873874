import React from 'react';
import T from 'i18n-react';
import { Radio, Tabs, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTab } from 'styles/commonComponents.styles';
import { setRoomFilter, setDisplayType, testFitRoomsFilterSelector, testFitDisplayTypeSelector } from 'store/testFit';
import { selectObject, clearSelectedRowKeys } from 'store/editor';
import { DISPLAY_TYPES, ROOM_FILTER_TYPES } from 'constants/testFitConsts';
import icons from 'styles/static/icons/testFit/editor';
import PropTypes from 'prop-types';
import { useOverridableSetting } from 'store/userSettings';

const { TabPane } = Tabs;

const TestFitDataFilterHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  line-height: normal;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
`;

const CardTypeIcon = styled(Icon)`
  margin-top: 2px;
`;

const StyleSortIcon = styled(Icon)`
  margin-right: 5px;
`;

const FilterDisplayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterTabsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding-right: 20px;
  margin-bottom: 3px;
`;

const FilterTabsText = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const TestFitDataFilterHeader = (props) => {
  const { hideFilter, marginBottom } = props;

  const dispatch = useDispatch();
  const roomFilterType = useSelector(testFitRoomsFilterSelector);
  const displayType = useSelector(testFitDisplayTypeSelector);
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const isTestFitEditDisabled = useOverridableSetting('disabledTestFitEdit', false);

  const onFilterChange = (key) => {
    dispatch(setRoomFilter(key));
    dispatch(clearSelectedRowKeys());
    if (selectedObject?.isSelectedInUi) {
      dispatch(selectObject(null)); // clean selection
    }
  };

  return (
    <TestFitDataFilterHeaderWrapper marginBottom={marginBottom}>
      <FilterTabsHeader>
        {!hideFilter && (
          <FilterTabsText>
            <StyleSortIcon component={icons.sortIcon} />
            {T.translate('EDITOR_ROOMS_PANEL_FILTER_TITLE_SORT')}
          </FilterTabsText>
        )}
        {/* <FilterTabsText>{T.translate('EDITOR_ROOMS_PANEL_FILTER_TITLE_DISPLAY')}</FilterTabsText> */}
      </FilterTabsHeader>
      {/* ============ Header ============ */}
      <FilterDisplayWrapper>
        {/* ============ Filter Tabs ============ */}
        {!hideFilter && (
          <StyledTab onChange={(key) => onFilterChange(key)} activeKey={roomFilterType}>
            <TabPane tab={T.translate('EDITOR_ROOMS_PANEL_ROOMS')} key={ROOM_FILTER_TYPES.ROOMS} />
            <TabPane tab={T.translate('EDITOR_ROOMS_PANEL_FLOORS')} key={ROOM_FILTER_TYPES.FLOORS} />
            {!isTestFitEditDisabled && <TabPane tab={T.translate('EDITOR_ROOMS_PANEL_DEPARTMENTS')} key={ROOM_FILTER_TYPES.DEPARTMENTS} />}
          </StyledTab>
        )}

        {/* ============ Display Filter ============ */}
        <Radio.Group defaultValue={displayType} buttonStyle="solid" size="small" onChange={(e) => dispatch(setDisplayType(e.target.value))}>
          <Radio.Button value={DISPLAY_TYPES.LIST}>
            <Tooltip title={T.translate('EDITOR_ROOMS_PANEL_LIST_VIEW')} mouseEnterDelay={0.5}>
              <CardTypeIcon component={icons.listIcon} />
            </Tooltip>
          </Radio.Button>
          <Radio.Button value={DISPLAY_TYPES.GRID}>
            <Tooltip title={T.translate('EDITOR_ROOMS_PANEL_GRID_VIEW')} mouseEnterDelay={0.5}>
              <CardTypeIcon component={icons.gridIcon} />
            </Tooltip>
          </Radio.Button>
        </Radio.Group>
      </FilterDisplayWrapper>
    </TestFitDataFilterHeaderWrapper>
  );
};

TestFitDataFilterHeader.propTypes = {
  hideFilter: PropTypes.bool,
  marginBottom: PropTypes.number,
};

export default TestFitDataFilterHeader;
