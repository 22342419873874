export const TEST_FIT_EDITOR_BUTTON_KEYS = {
  CREATE: 'CREATE_ROOM',
  SELECT_ROOMS: 'SELECT_ROOMS',
  SELECT_TILES: 'SELECT_TILES',
  UNDO: 'UNDO',
  CREATE_CORRIDOR_DOOR: 'CREATE_CORRIDOR_DOOR',
  DEPARTMENTS: 'DEPARTMENTS',
  SWAP: 'SWAP',
  DELETE: 'DELETE',
  LOCK: 'LOCK',
};

export const RENDER_TYPES = {
  SWAPP_VIEWER: 'SWAPP_VIEWER',
  FORGE_VIEWER: 'FORGE_VIEWER',
  THREE_D_WALKTHROUGH: 'THREE_D_WALKTHROUGH',
};

export const CURRENT_MULTI_BOUNDARY_FILE_VERSION = 6;

export const FILTER_TYPES = {
  CATEGORY: 'CATEGORY',
  ROOM: 'ROOM',
  DEPARTMENT: 'DEPARTMENT',
};

export const DISPLAY_TYPES = {
  LIST: 'LIST',
  GRID: 'GRID',
};

export const ROOM_FILTER_TYPES = {
  ROOMS: 'ROOMS',
  DEPARTMENTS: 'FLOORS',
  FLOORS: 'DEPARTMENTS',
};

export const ROOM_TAGS = {
  LOCK: 'LOCK',
};
