var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
define(["require", "exports", "humps", "./SwpClassMap"], function (require, exports, humps_1, SwpClassMap_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    class SimpleDataObject {
        constructor() { }
        loadFromObject(object) {
            const keys = Object.keys(object);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const camelizedKey = (0, humps_1.camelize)(key); // SwpProject files are in snake_case, convert to camelCase to match our object keys
                const propType = (0, SwpClassMap_1.getTypeByProperty)(this, camelizedKey);
                if (!propType) {
                    console.warn(`SwpProject: Could not find type for property "${key}" in class "${this.constructor.name}". Ignoring.`);
                    continue;
                }
                if (typeof propType === 'string' || propType.name === 'number') {
                    this[camelizedKey] = object[key];
                }
                else if (propType.name === 'Array') {
                    this[camelizedKey] = [...object[key]];
                }
                else if (propType) {
                    const swpClass = new propType(object[key]);
                    this[camelizedKey] = swpClass;
                    // This is the way we check if the class implements an interface with "loadObject" function
                    // Since in Typescript interfaces do not exist in runtime.
                    if (swpClass.loadFromObject) {
                        swpClass.loadFromObject.bind(swpClass);
                        swpClass.loadFromObject(object[key]);
                    }
                }
                else {
                    // console.log('else > ', key, this[key]);
                }
            }
        }
        static initFromObject(obj) {
            const { type } = obj;
            const swpClass = SwpClassMap_1.SwpClassMap[type];
            if (!swpClass) {
                // debugger;
                throw new Error(`Could not find class with name "${type}". Do you need to run "npm run generate ${type}" in swappcommonjs project?`);
            }
            const instance = new swpClass(obj);
            instance.loadFromObject.bind(instance);
            instance.loadFromObject(obj);
            return instance;
        }
        static _saveToObjectOrSelf(obj) {
            if (obj.saveToObject) {
                obj.saveToObject.bind(obj);
                return obj.saveToObject();
            }
            return obj;
        }
        saveToObject() {
            const keys = Object.keys(this);
            const planeObject = {};
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const decamelizedKey = (0, humps_1.decamelize)(key);
                const propType = (0, SwpClassMap_1.getTypeByProperty)(this, key);
                if (key.indexOf('_') !== 0 && !!propType && !!this[key]) {
                    if (typeof proptype === 'string' || propType.name === 'number') {
                        planeObject[decamelizedKey] = this[key];
                    }
                    else if (propType.name === 'Array') {
                        planeObject[decamelizedKey] = this[key].map(SimpleDataObject._saveToObjectOrSelf);
                    }
                    else {
                        planeObject[decamelizedKey] = SimpleDataObject._saveToObjectOrSelf(this[key]);
                    }
                }
            }
            return planeObject; // JSON.parse(JSON.stringify(this))
        }
        clone() {
            return SimpleDataObject.initFromObject(this.saveToObject());
        }
    }
    __decorate([
        (0, SwpClassMap_1.Type)(),
        __metadata("design:type", String)
    ], SimpleDataObject.prototype, "type", void 0);
    exports.default = SimpleDataObject;
});
