import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import lodashIsEqual from 'lodash/isEqual';
import lodashIncludes from 'lodash/includes';
import lodashOmit from 'lodash/omit';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { COMMUNAL_SPACES } from 'constants/feasibilityConts';
import SwpBalcony from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBalcony';
import { collectFloorGroupLineSegments, extrudePolygon } from '../../algorithms/geometryBuilders';
import { uuidv4 } from '../../helpers/uuidv4';
import { selectionProps, unselectableSelectionProps } from '../helpers/SelectionHelpers';
import { isUnitEnhanced } from '../../model/feasibilityResultModel';
import { getRenderPropsByName } from '../helpers/FeasibilityRenderProps';
import { getProductBoundaryPoints, isStoryUtilityStory, getChildById } from '../../model/swpProjectModel';
// import SwpBalcony from '../../../swappcommonjs/dist/spatialProducts/SwpBalcony';
import { getBuildingStoriesData, getLastIndexOfStoryGroupWithUnits, getStoryEnvelope, getStoryHeight, getStoryParkingUnits, getStoryUnits, isStoryGroupAnOpenGroundFloor, isBuildingSupported } from '../../model/feasibilityDataExtractors';

export const MASS_MESH_SELECTION_TYPES = {
  NONE: 0,
  FLOOR: 1,
  UNIT: 2,
};

// TODO TECH DEBT: We should pass transparency information through the unit color.
const TRANSPARENT_UNIT_NAMES = ['DEMO_OPEN'];

const prepareBalconyMap = (swpBuilding) => {
  const balconies = swpBuilding.childrenByType(SwpBalcony, true);
  const apartmentIdToBalconyMap = {};
  balconies.forEach((balcony) => {
    const swpPoints = getProductBoundaryPoints(balcony);
    const points2D = swpPoints.map((p) => [p.x, p.y]);
    const apartmentId = balcony.parent.id;
    apartmentIdToBalconyMap[apartmentId] = points2D;
  });
  return apartmentIdToBalconyMap;
};

const generateMeshes = (props) => {
  const { buildingData, colorCallback, selectionType, onUnitSelected, selectedObject, invalidate } = props;

  const { massIndex, swpBuilding } = buildingData;
  const enableUnitSelection = selectionType === MASS_MESH_SELECTION_TYPES.UNIT;
  const unitGeometries = [];
  const transparentGeometries = [];
  const lineSegmentsPointList = [];
  const parkingUnitsGeometries = [];

  const balconyMap = swpBuilding ? prepareBalconyMap(swpBuilding) : {};

  const massRenderOrder = useMemo(() => {
    const { renderOrder } = getRenderPropsByName('mass');
    return renderOrder;
  }, []);

  const linesRenderOrder = useMemo(() => {
    const { renderOrder } = getRenderPropsByName('massLines');
    return renderOrder;
  }, []);

  const { storyGroups } = getBuildingStoriesData(buildingData);
  const lastIndexOfFloorGroupWithUnits = getLastIndexOfStoryGroupWithUnits(storyGroups);

  storyGroups.forEach((storyGroup, storyGroupIdx) => {
    const { startHeight: currentHeight, height, typicalStory } = storyGroup;
    collectFloorGroupLineSegments(storyGroup, currentHeight, lineSegmentsPointList);
    const isLastFloorGroup = storyGroupIdx === lastIndexOfFloorGroupWithUnits;
    const storyHeight = getStoryHeight(typicalStory);
    const units = getStoryUnits(typicalStory);
    if (isStoryGroupAnOpenGroundFloor(storyGroup) || units.length === 0) {
      const envelopeColor = new THREE.Color('#969696');
      if (selectionType === MASS_MESH_SELECTION_TYPES.FLOOR) {
        for (let floorGroupFloorNum = 0; floorGroupFloorNum < storyGroup.numStories; floorGroupFloorNum++) {
          const startHeight = storyGroup.startHeight + floorGroupFloorNum * storyHeight;
          const storyIndex = floorGroupFloorNum + storyGroup.firstStoryIndex;
          const floorSelectionId = { type: 'Floor', massIndex, floorIndex: storyIndex, floorGroupIndex: storyGroupIdx };
          const isSelected = lodashIsEqual(floorSelectionId, selectedObject);
          const color = isSelected ? new THREE.Color('#0000ff') : envelopeColor;
          const floorProps = floorSelectionId;
          const envelopeGeometry = extrudePolygon(getStoryEnvelope(typicalStory), undefined, storyHeight, color);
          envelopeGeometry.translate(0, 0, startHeight);
          transparentGeometries.push([floorProps, envelopeGeometry]);
        }
      } else {
        const envelopeGeometry = extrudePolygon(getStoryEnvelope(typicalStory), undefined, height, envelopeColor);
        envelopeGeometry.translate(0, 0, currentHeight);
        transparentGeometries.push([null, envelopeGeometry]);
      }

      // An assumption - only in open ground floors there are parking units
      const parkingUnits = getStoryParkingUnits(typicalStory) || [];
      for (let i = 0; i < parkingUnits.length; i++) {
        const parkingUnit = parkingUnits[i];
        if (parkingUnit.parking_type === 'column') {
          const parkingPolygon = parkingUnit.polygon;
          const columnGeometry = extrudePolygon(parkingPolygon.boundary, parkingPolygon.holes, storyHeight, envelopeColor);
          columnGeometry.translate(0, 0, currentHeight);
          parkingUnitsGeometries.push(columnGeometry);
        }
      }
    }

    for (let floorGroupFloorNum = 0; floorGroupFloorNum < storyGroup.numStories; floorGroupFloorNum++) {
      const isGroundFloor = storyGroupIdx === 0 && floorGroupFloorNum === 0;
      const isTopFloor = isLastFloorGroup && floorGroupFloorNum === storyGroup.numStories - 1;

      const z = currentHeight + floorGroupFloorNum * storyHeight;
      getStoryUnits(typicalStory).forEach((unit, unitIndex) => {
        // TODO TECH DEBT: Code duplication from getLegendColors
        const unitName = unit.displayName ? unit.displayName : unit.name;
        const unitType = lodashIncludes(COMMUNAL_SPACES, unit.name) ? 'COMMUNAL' : 'UNIT';

        const unitProps = {
          key: unit.name,
          name: unitName,
          aspects: unit.aspects,
          isGroundFloor,
          isTopFloor,
          unitType,
          isCore: unit.name.indexOf('CORE') > -1,
          floorGroupIndex: storyGroupIdx,
          floorIndex: floorGroupFloorNum,
          unitIndex,
          massIndex,
          isEnhanced: isUnitEnhanced(unit),
          floorSwpId: storyGroup.swpId,
          tags: unit.tags ? Object.keys(unit.tags) : undefined,
        };
        let color = colorCallback(unitProps);
        if (selectionType === MASS_MESH_SELECTION_TYPES.FLOOR) {
          const floorSelectionId = { type: 'Floor', massIndex, floorGroupIndex: storyGroupIdx, floorIndex: floorGroupFloorNum };
          if (lodashIsEqual(floorSelectionId, selectedObject)) {
            color = new THREE.Color('#0000ff');
          }
        }
        const extrudeColor = enableUnitSelection ? undefined : color;
        if (enableUnitSelection) {
          unitProps.color = color;
        }
        const unitGeometry = extrudePolygon(unit.polygon.boundary, unit.polygon.holes, storyHeight, extrudeColor);
        unitGeometry.translate(0, 0, z);
        unitGeometries.push([unitProps, unitGeometry]);

        if (unit.swpId && balconyMap[unit.swpId]) {
          const balconyPoints = balconyMap[unit.swpId];
          const balconyGeometry = extrudePolygon(balconyPoints, null, 0.3, extrudeColor);
          balconyGeometry.translate(0, 0, z);
          unitGeometries.push([unitProps, balconyGeometry]);
        }
      });
    }
  });

  const resultingMeshes = [];
  if (unitGeometries.length > 0) {
    if (selectionType === MASS_MESH_SELECTION_TYPES.NONE) {
      const isUnitPairTransparent = (unitPair) => TRANSPARENT_UNIT_NAMES.includes(unitPair[0].key);
      const solidPairs = unitGeometries.filter((pair) => !isUnitPairTransparent(pair));
      const transparentPairs = unitGeometries.filter((pair) => isUnitPairTransparent(pair));

      if (solidPairs.length > 0) {
        const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(solidPairs.map((pair) => pair[1]));
        resultingMeshes.push(
          <mesh key="solidUnitsGeom" geometry={mergedGeometry} castShadow receiveShadow {...unselectableSelectionProps} renderOrder={massRenderOrder}>
            <meshBasicMaterial name="solidMaterial" vertexColors />
          </mesh>,
        );
      }

      if (transparentPairs.length > 0) {
        const mergedTransparentGeometry = BufferGeometryUtils.mergeBufferGeometries(transparentPairs.map((pair) => pair[1]));
        resultingMeshes.push(
          <mesh key="transparentUnitsGeom" geometry={mergedTransparentGeometry} receiveShadow {...unselectableSelectionProps} renderOrder={massRenderOrder}>
            <meshBasicMaterial name="transparentMaterial" vertexColors transparent opacity={0.4} />
          </mesh>,
        );
      }
    } else if (selectionType === MASS_MESH_SELECTION_TYPES.FLOOR) {
      // We are in floor selection mode - merge each floor as a single objcet
      const floorGeometries = [];
      unitGeometries.forEach(([unitProps, unitGeometry]) => {
        // This iteration assumes that all floorProps are adjacent in the unitGeometries array.
        const floorProps = { floorGroupIndex: unitProps.floorGroupIndex, floorIndex: unitProps.floorIndex, massIndex, swpId: unitProps.floorSwpId };
        if (floorGeometries.length === 0 || !lodashIsEqual(floorGeometries[floorGeometries.length - 1][0], floorProps)) {
          floorGeometries.push([floorProps, []]);
        }
        floorGeometries[floorGeometries.length - 1][1].push(unitGeometry);
      });
      floorGeometries.forEach(([floorProps, floorGeometry], idx) => {
        let isFloorSelectable = true;
        if (floorProps.swpId && swpBuilding) {
          const swpBuildingStory = getChildById(swpBuilding, floorProps.swpId);
          isFloorSelectable = !isStoryUtilityStory(swpBuildingStory);
        }
        floorProps = lodashOmit(floorProps, 'swpId'); // Shouldn't pass into selectionProps, will break selection
        const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(floorGeometry);
        const name = `floorGeom${idx}`;
        resultingMeshes.push(
          <mesh
            renderOrder={massRenderOrder}
            key={name}
            geometry={mergedGeometry}
            castShadow
            receiveShadow
            {...selectionProps(floorProps, isFloorSelectable, '#7777ff', invalidate, onUnitSelected)}
          >
            <meshBasicMaterial name="solidMaterial" vertexColors />
          </mesh>,
        );
      });
    } else if (selectionType === MASS_MESH_SELECTION_TYPES.UNIT) {
      unitGeometries.forEach(([unitProps, unitGeometry], idx) => {
        const isSelectable = !(unitProps.name === 'CORRIDOR' || unitProps.name.endsWith('_CORE'));
        const name = `unitGeom${idx}`;
        resultingMeshes.push(
          <mesh
            renderOrder={massRenderOrder}
            key={name}
            geometry={unitGeometry}
            castShadow
            {...selectionProps(unitProps, isSelectable, '#3333ff', invalidate, onUnitSelected)}
          >
            <meshBasicMaterial name="solidMaterial" color={unitProps.color} />
          </mesh>,
        );
      });
    }
  }
  if (transparentGeometries.length > 0) {
    const hasUnitInfo = transparentGeometries.findIndex((pair) => pair[0] !== null) >= 0;
    if (hasUnitInfo) {
      transparentGeometries.forEach(([unitProps, unitGeometry], idx) => {
        resultingMeshes.push(
          <mesh
            renderOrder={massRenderOrder}
            key={`transparentGeom${idx}`}
            geometry={unitGeometry}
            receiveShadow
            {...selectionProps(unitProps, true, '#7777ff', invalidate, onUnitSelected)}
          >
            <meshBasicMaterial name="transparentMaterial" vertexColors transparent opacity={0.4} />
          </mesh>,
        );
      });
    } else {
      const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(transparentGeometries.map((pair) => pair[1]));
      resultingMeshes.push(
        <mesh key="transparentGeom" geometry={mergedGeometry} receiveShadow renderOrder={massRenderOrder}>
          <meshBasicMaterial name="transparentMaterial" vertexColors transparent opacity={0.4} />
        </mesh>,
      );
    }
  }
  if (parkingUnitsGeometries.length > 0) {
    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(parkingUnitsGeometries);
    resultingMeshes.push(
      <mesh key="parkingGeom" geometry={mergedGeometry} castShadow receiveShadow {...unselectableSelectionProps} renderOrder={massRenderOrder}>
        <meshBasicMaterial name="solidMaterial" vertexColors />
      </mesh>,
    );
  }
  if (lineSegmentsPointList.length > 0) {
    const vertices = lineSegmentsPointList.map((v) => new THREE.Vector3(...v));

    // Note: our 3D stack somewhere uses key as a refresh identifier. If we do not change from generation to generation,
    // geometry might not update
    resultingMeshes.push(
      <lineSegments key={`linesGeom${uuidv4()}`} renderOrder={linesRenderOrder}>
        <geometry attach="geometry" vertices={vertices} />
        <lineBasicMaterial attach="material" color="black" />
      </lineSegments>,
    );
  }
  return resultingMeshes;
};

// Build all meshes required to render a logic mass (MassDTO). Receives a colorCallback function that receives
// a unit model and returns its render color
const MassMesh = (props) => {
  const { buildingData, colorCallback, selectionType, onUnitSelected, selectedObject } = props;
  const { invalidate } = useThree();

  // export const buildMassGeometry = (massDto, colorCallback, mergeSolidGeometry = true) => {
  if (!isBuildingSupported(buildingData)) {
    return null;
  }

  const resultingMeshes = generateMeshes({
    buildingData,
    colorCallback,
    selectionType,
    onUnitSelected,
    selectedObject,
    invalidate,
  });

  return resultingMeshes;
};

MassMesh.propTypes = {
  selectionType: PropTypes.number,
  buildingData: PropTypes.object,
  selectedObject: PropTypes.object,
  colorCallback: PropTypes.func,
  onUnitSelected: PropTypes.func,
};

export default MassMesh;
