import React, { useMemo, useState, useEffect } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import lodashGet from 'lodash/get';
import lodashKeys from 'lodash/keys';
import lodashFlattenDepth from 'lodash/flattenDepth';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashFilter from 'lodash/filter';
import lodashSortBy from 'lodash/sortBy';
import { currentThemeSelector, getUnitSystemByProjectIdSelector, useOverridableSetting } from 'store/userSettings';
import { swappProfileResultsSelector } from 'store/swappProfile';
import { StyledSubMenu, StyledMenu } from 'styles/commonComponents.styles';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { metricDivideValue } from 'utils/helpers/unitsHelpers';
import { TEST_FIT_EDITOR_BUTTON_KEYS, DISPLAY_TYPES, ROOM_FILTER_TYPES, ROOM_TAGS } from 'constants/testFitConsts';
import { splitPolygonsToGroupsByAdjacency } from 'utils/swappViewer/testFit/helpers';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import { testFitRoomsFilterSelector, setRoomFilter, testFitDisplayTypeSelector } from 'store/testFit';
import GridTile from 'components/common/GridTile';
import TestFitDataFilterHeader from 'components/testFit/TestFitDataFilterHeader';
import EmptyMessage from 'components/common/EmptyMessage';
import * as SwappEditor from 'store/editor';
import {
  EditorRoomsPanelWrapper, StyledName, ColorPill, PanelContentWrapper,
  GridTileWrapper, IconText, IconWrapper, MenuRowWrapper,
  StyledNameWrapper, MenuItem, IconsHeader,
} from './EditorRoomsPanel.styles';

const sortByCategory = {
  LSF: 0,
  PSF: 1,
  FSF: 2,
  ASF: 3,
  EMPTY: 4,
};

const EditorRoomsPanel = (props) => {
  const { openTabs } = props;

  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const selectedObject = useSelector((state) => state.editor.selectedObject);
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, match.params.profileId, isImperial));
  const editorType = useSelector(({ editor }) => editor.editorType);
  const currentTheme = useSelector(currentThemeSelector);
  const filterType = useSelector(testFitRoomsFilterSelector);
  const displayType = useSelector(testFitDisplayTypeSelector);

  const dispatch = useDispatch();

  const [sortedRoomDefinitions, setSortedRoomDefinitions] = useState({});

  const isTileSelected = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const multiBoundaryFile = lodashGet(swappProfileResults, 'multiBoundaryFile');
  const roomDefinitions = lodashGet(multiBoundaryFile, 'roomDefinitions');
  const floorDefinitions = lodashGet(multiBoundaryFile, 'floorDefinitions');
  const departmentDefinition = lodashGet(multiBoundaryFile, 'departmentDefinition');
  const isSelectedObjectHasLockTag = lodashGet(selectedObject, `tags[${ROOM_TAGS.LOCK}]`);

  const filterDataArrays = {
    [ROOM_FILTER_TYPES.ROOMS]: roomDefinitions,
    [ROOM_FILTER_TYPES.FLOORS]: floorDefinitions,
    [ROOM_FILTER_TYPES.DEPARTMENTS]: departmentDefinition,
  };

  const colorsByCategory = {
    LSF: currentTheme.colors.layers.LSF.MAIN,
    PSF: currentTheme.colors.layers.PSF.MAIN,
    FSF: currentTheme.colors.layers.FSF.MAIN,
    ASF: currentTheme.colors.layers.ASF.MAIN,
    EMPTY: currentTheme.colors.layers.EMPTY.MAIN,
  };

  // unmount
  useEffect(() => () => {
    dispatch(setRoomFilter(ROOM_FILTER_TYPES.ROOMS));
  }, []);

  useMemo(() => {
    const allCombinedRooms = [
      ...filterDataArrays[filterType],
    ];
    const standardTypes = lodashGet(getTestFitStandards(), `standards[${testFitStandard}].roomDefinitions`, {});
    const sortList = {};
    lodashKeys(standardTypes).forEach((type) => standardTypes[type].forEach((unit) => {
      sortList[unit.key] = unit.index;
    }));

    const allCombinedRoomsSorted = lodashSortBy(allCombinedRooms, [
      (room) => lodashGet(sortByCategory, `[${room.roomCategory}]`),
      (room) => lodashGet(sortList, `[${room.id}]`),
      'name',
    ]);
    const clonedSortedRoomDefinitions = {};
    allCombinedRoomsSorted.forEach((room) => {
      const { roomsRequirements } = multiBoundaryFile;
      const isRoomReqArray = Array.isArray(roomsRequirements[0]);
      const roomTargetAmount = isRoomReqArray ? lodashGet(roomsRequirements.find((item) => item[1] === room.id), '[3]', 0) : lodashGet(roomsRequirements.find((item) => item.room_type === room.id), 'target_area_value', 0);
      clonedSortedRoomDefinitions[room.category] = {
        totalTilesAmount: lodashGet(clonedSortedRoomDefinitions, `[${room.category}].totalTilesAmount`, 0) + (room.tilesAmount || 0),
        totalArea: lodashGet(clonedSortedRoomDefinitions, `[${room.category}].totalArea`, 0) + room.area,
        totalAmount: lodashGet(clonedSortedRoomDefinitions, `[${room.category}].totalAmount`, 0) + room.amount,
        categoryColor: room.categoryColor,
        rooms: [
          ...lodashGet(clonedSortedRoomDefinitions, `[${room.category}].rooms`, []),
          { ...room, amount: `${room.amount}${filterType === ROOM_FILTER_TYPES.ROOMS ? ` / ${roomTargetAmount}` : ''}`, color: room.color || lodashGet(currentTheme, `colors.layers[${room.roomCategory}][${room.id}]`, currentTheme.colors.layers[room.roomCategory]?.MAIN) },
        ],
      };
    });
    const sortedCategoryKeys = lodashSortBy(lodashKeys(clonedSortedRoomDefinitions), [(key) => lodashGet(sortByCategory, `[${key}]`), (key) => key]);
    const data = {};
    sortedCategoryKeys.forEach((key) => {
      data[key] = clonedSortedRoomDefinitions[key];
    });
    setSortedRoomDefinitions(data);
  }, [multiBoundaryFile, editorType, filterType]);

  const handleRoomAction = (room) => {
    // if the user select a room from the ui we letting him to assign it to an empty room
    if (lodashIsEmpty(selectedObject)) {
      const roomObject = {
        category: room.category,
        id: room.id,
        tilesAmount: room.tilesAmount,
        area: room.area,
        isSelectedInUi: true,
        type: room.id,
      };
      dispatch(SwappEditor.selectObject(roomObject));
      return;
    }

    if (isSelectedObjectHasLockTag) {
      return;
    }

    // if the user selected an room from the UI and click it again we clear the selection
    if (selectedObject?.isSelectedInUi) {
      dispatch(SwappEditor.selectObject(null));
      return;
    }

    const { id, category } = room;
    let action = [];
    const selectedObjectsArray = Array.isArray(selectedObject) ? selectedObject : [selectedObject];

    if (editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS) {
      if (selectedObject.tiles) {
        const currentRoomTiles = [...selectedObject.tiles];
        const numberOfRoomsThatCanFitInSpace = selectedObject.tiles.length % room.tilesAmount;
        for (let i = 1; i <= numberOfRoomsThatCanFitInSpace; i++) {
          action.push({ name: 'TEST_FIT_ALLOCATE_TILES', parameters: { tile_ids: currentRoomTiles.splice(0, room.tilesAmount).map((tile) => tile.id), new_room_type: id } });
        }
        if (!lodashIsEmpty(currentRoomTiles)) {
          action.push({ name: 'TEST_FIT_DEALLOCATE_TILES', parameters: { tile_ids: currentRoomTiles.map((tile) => tile.id) } });
        }
      } else {
        action = selectedObjectsArray.map((object) => ({ name: 'TEST_FIT_REPURPOSE_ROOM', parameters: { room_id: object.id, new_room_type: id }, localViewParameters: { newRoomCategory: category, selectedRoom: selectedObject } }));
      }
    } else if (editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES) {
      const allTiles = lodashFlattenDepth(multiBoundaryFile.floors.map((floor) => floor.tubes.map((tube) => tube.tiles)), 2); // get oll tiles in project
      const selectedTileObjects = lodashFilter(allTiles, (tile) => lodashIncludes(selectedObjectsArray.map((object) => object.id), tile.id)); // take only the selected tile objects
      const tilesByGroups = splitPolygonsToGroupsByAdjacency(selectedTileObjects);

      action = id === 'DEALLOCATE'
        ? tilesByGroups.map((group) => ({ name: 'TEST_FIT_DEALLOCATE_TILES', parameters: { tile_ids: group.map((tile) => tile.id) } }))
        : tilesByGroups.map((group) => ({ name: 'TEST_FIT_ALLOCATE_TILES', parameters: { tile_ids: group.map((tile) => tile.id), new_room_type: id } }));
    }

    dispatch(SwappEditor.addOperations(action));
  };

  const isDisabled = (room) => {
    if (isSelectedObjectHasLockTag) {
      return true;
    }

    if (selectedObject?.isSelectedInUi && selectedObject?.id !== room.id) {
      return true;
    }

    if (editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS || editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES) {
      if (lodashIsEmpty(selectedObject)) {
        return false;
      }
      return selectedObject?.tilesAmount < room?.tilesAmount;
    }

    return true;
  };

  const renderGridTile = (room) => {
    const disabled = isDisabled(room);
    const color = room.color || colorsByCategory[room.roomCategory];

    return (
      <GridTile
        key={room.id}
        title={room.name}
        subtitle={room.amount}
        color={color}
        onClick={() => handleRoomAction(room)}
        disabled={disabled}
        size={room.tilesAmount}
        selected={room.id === selectedObject?.id && selectedObject?.isSelectedInUi}
        tooltip={disabled && !selectedObject?.isSelectedInUi ? `${T.translate(room.name)} ${T.translate('is too big to replace the selected room')}` : T.translate(room.name)}
      />
    );
  };

  const renderMenuRow = (room, isTitle, totalArea, index = 0) => {
    const disabled = isDisabled(room);

    return (
      <MenuRowWrapper
        key={`${room.id}_${index}`}
        selected={room.id === selectedObject?.id && selectedObject?.isSelectedInUi}
        isTitle={isTitle}
        disabled={disabled}
        onClick={() => !isTitle && !disabled && handleRoomAction(room)}
      >
        {/* =============Name============= */}
        <MenuItem width={90}>
          <ColorPill color={room.color} />
          <Tooltip placement="topLeft" title={isTitle ? null : T.translate(room.name)}>
            <StyledNameWrapper>
              <StyledName bold={isTitle}>{T.translate(room.name)}</StyledName>
              {isTileSelected && Number(room.tilesAmount) && !isTitle ? <span>{` (${room.tilesAmount})`}</span> : ''}
            </StyledNameWrapper>
          </Tooltip>
        </MenuItem>

        {/* =============Target============= */}
        <MenuItem width={20}>{isTitle || !totalArea ? '' : numberWithComma((room.area * 100) / totalArea, { fixed: 1, suffix: '%' })}</MenuItem>

        {/* =============Area============= */}
        <MenuItem width={60}>
          {numberWithComma(room.area * (isImperial ? metricDivideValue : 1), { fixed: 0, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` })}
        </MenuItem>

        {/* =============Amount============= */}
        <MenuItem bold={isTitle}>{room.amount}</MenuItem>
      </MenuRowWrapper>
    );
  };

  const getTitleOptions = (key) => ({
    name: key,
    color: sortedRoomDefinitions[key].categoryColor || colorsByCategory[key],
    tilesAmount: sortedRoomDefinitions[key].totalTilesAmount,
    area: sortedRoomDefinitions[key].totalArea,
    amount: sortedRoomDefinitions[key].totalAmount,
  });

  const renderSubMenuByKey = (key) => sortedRoomDefinitions[key] && (
    <StyledSubMenu key={key} title={renderMenuRow(getTitleOptions(key), true)}>
      {displayType === DISPLAY_TYPES.LIST
        ? sortedRoomDefinitions[key].rooms.map((room, index) => (renderMenuRow(room, false, sortedRoomDefinitions[key].totalArea, index)))
        : <GridTileWrapper>{sortedRoomDefinitions[key].rooms.map((room) => renderGridTile(room))}</GridTileWrapper>}
    </StyledSubMenu>
  );

  const renderTableView = () => (lodashIsEmpty(sortedRoomDefinitions) ? <EmptyMessage description="EDITOR_ROOMS_PANEL_NO_DEPARTMENTS" /> : (
    <>
      {/* ============== Header ============== */}
      <IconsHeader>
        {/* ========== room type ========== */}
        <Tooltip title={isTileSelected ? T.translate('EDITOR_ROOMS_PANEL_ROOM_TYPE_TILES_TOOLTIP') : ''} placement="topLeft">
          <IconWrapper>
            <IconText>
              {`${T.translate('EDITOR_ROOMS_PANEL_ROOM_TYPE')} ${isTileSelected ? T.translate('EDITOR_ROOMS_PANEL_ROOM_TYPE_TILES') : ''}`}
            </IconText>
          </IconWrapper>
        </Tooltip>
        {/* ========== % modal ========== */}
        <IconWrapper><IconText>{T.translate('EDITOR_ROOMS_PANEL_MODEL')}</IconText></IconWrapper>
        {/* ========== area ========== */}
        <IconWrapper><IconText>{T.translate(isImperial ? 'SQF' : 'SQM')}</IconText></IconWrapper>
        {/* ========== amount ========== */}
        <IconWrapper><IconText>{T.translate('EDITOR_ROOMS_PANEL_NO')}</IconText></IconWrapper>
      </IconsHeader>

      {/* ============== Tables ============== */}
      <PanelContentWrapper>
        <StyledMenu mode="inline" overflow openKeys={openTabs && lodashKeys(sortedRoomDefinitions).map((key) => key)}>
          {lodashKeys(sortedRoomDefinitions).map((key) => renderSubMenuByKey(key))}
        </StyledMenu>
      </PanelContentWrapper>
    </>
  ));

  const renderGridView = () => (lodashIsEmpty(sortedRoomDefinitions) ? <EmptyMessage description="EDITOR_ROOMS_PANEL_NO_DEPARTMENTS" /> : (
    <>
      {/* ============== Tables ============== */}
      <PanelContentWrapper>
        <StyledMenu mode="inline" overflow defaultOpenKeys={openTabs && lodashKeys(sortedRoomDefinitions).map((key) => key)}>
          {lodashKeys(sortedRoomDefinitions).map((key) => renderSubMenuByKey(key))}
        </StyledMenu>
      </PanelContentWrapper>
    </>
  ));

  const renderDisplayTypes = {
    [DISPLAY_TYPES.LIST]: renderTableView(),
    [DISPLAY_TYPES.GRID]: renderGridView(),
  };

  return (
    <EditorRoomsPanelWrapper>
      {/* ============ Header ============ */}
      <TestFitDataFilterHeader />

      {/* ============ tables render ============ */}
      {renderDisplayTypes[displayType]}
    </EditorRoomsPanelWrapper>
  );
};

EditorRoomsPanel.propTypes = {
  openTabs: PropTypes.bool,
};

export default EditorRoomsPanel;
