/* eslint-disable camelcase */
import lodashGet from 'lodash/get';
import lodashKeys from 'lodash/keys';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFlattenDepth from 'lodash/flattenDepth';
import lodashFilter from 'lodash/filter';
import lodashSum from 'lodash/sum';
import lodashIsEqual from 'lodash/isEqual';
import lodashStartsWith from 'lodash/startsWith';
import lodashIncludes from 'lodash/includes';
import lodashUniq from 'lodash/uniq';
import lodashLast from 'lodash/last';
import lodashSortBy from 'lodash/sortBy';
import lodashValues from 'lodash/values';
import T from 'i18n-react';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import { CURRENT_MULTI_BOUNDARY_FILE_VERSION, ROOM_TAGS } from 'constants/testFitConsts';
import { TEST_FIT_SIMPLE_FORM } from 'constants/dropdownKeys';
import { uuidv4 } from 'utils/helpers/uuidv4';
import {
  rotatePolygon,
  getRectanglePolygonCenter,
  getOrientedBoundingBox,
  getPolygonLongestEdge,
  vecDistance,
  getPolygonArea,
  vecAngle,
  vecSub,
  vecAdd,
  vecMoveTowards,
  polygonIterEdges,
  vecLength,
  getCosAngleBetweenSegments,
  vecNormalize,
  extrudeEdge,
  vecLerp,
  projectPointToLine,
} from '../algorithms/algorithmHelpers';
import {
  morphologyOpen,
  morphologyClose,
  pointsToJSTSPolygon,
  getPolygonCentroid,
  arePointsCCW,
} from '../algorithms/jstsHelpers';
import { sqfToSqm } from '../helpers/unitsHelpers';

const deskDepthByType = {
  [TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.TIGHT]: 24,
  [TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.BASIC]: 30,
  [TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.SPACIOUS]: 30,
};

const doorTypeSizes = { // TODO - move to consts file
  default: { openToSide: 40, nonClearanceSide: 40 },
};

const tileLength = 0.9 * (100 / 2.54 / 12); // 90 cm in feet (2.95275591 feet)

const orderArray = (points, nonClearanceSide) => {
  // reordering the array so nonClearanceSide[0] will be the first vertex and the second will be nonClearanceSide[1] in boundingBox
  const boundingBox = [...points];
  // removing the last vertex because it the same as the first
  if (boundingBox.length === 5) { // TODO - delete IF when the data will return in a consistent way (4 vertex or 5)
    boundingBox.pop();
  }

  // if the boundingBox order is reversed we make sure to reverse it back
  const nonClearanceSideFirstVertexIndex = boundingBox.findIndex((vertex) => lodashIsEqual(vertex, nonClearanceSide[0]));
  if (!lodashIsEqual(boundingBox[(nonClearanceSideFirstVertexIndex + 1) % 4], nonClearanceSide[1])) {
    boundingBox.reverse();
  }

  // poping the last item and putting in fist until boundingBox[0] === nonClearanceSide[0]
  while (!lodashIsEqual(boundingBox[0], nonClearanceSide[0])) {
    const last = lodashLast(boundingBox);
    boundingBox.pop();
    boundingBox.unshift(last);
  }

  // adding back the first vertex as the last to close the polygon
  return [...boundingBox, boundingBox[0]];
};

const setFurniture = (furniture) => furniture?.map((item) => {
  if (item.furniture_type === 'door') {
    return setDoor(item);
  }
  if (item.furniture_type === 'desk') {
    return setDesk(item);
  }
  if (lodashStartsWith(item.furniture_type, 'CUSTOM_FURNITURE_')) {
    return setCustomFurniture(item);
  }

  return { ...item, boundingBox: [] };
});

const setCustomFurniture = (item) => ({
  ...item,
  type: 'custom',
  furnitureType: item.furniture_type,
  // boundingBox: orderArray(item.points, item.non_clearance_side),
  boundingBox: item.points,
  isCCW: arePointsCCW(item.points),
});

const setDesk = (desk) => {
  const stringVertexArray = desk.points.map((vertex) => vertex.join(','));
  const nonClearanceSide = desk.non_clearance_side;

  if (!nonClearanceSide) {
    return desk;
  }

  // some desks dont have the same nonClearanceSide[0] vertex as one pf the vertex in the polygon on 'points'
  if (!lodashIncludes(stringVertexArray, nonClearanceSide[0].join(','))) {
    return {
      ...desk,
      boundingBox: desk.points,
      isFalse: true, // a flag that something went wrong
    };
  }

  const chairWidth = 25.72; // number in inches
  const boundingBox = orderArray(desk.points, nonClearanceSide);
  const clearanceDirection = vecNormalize(vecSub(boundingBox[2], boundingBox[1]));
  const tablePolygon = extrudeEdge(nonClearanceSide, clearanceDirection, deskDepthByType[lodashGet(desk, 'comfort_level', TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.BASIC)]);
  const clearanceSide = [tablePolygon[2], tablePolygon[3]];
  const clearanceSideCenter = vecLerp(clearanceSide[0], clearanceSide[1], 0.5);
  const getNonClearanceChairVertexFromCenter = (vertex) => vecMoveTowards(clearanceSideCenter, vertex, chairWidth / 2);
  const nonClearanceChairEdge = [getNonClearanceChairVertexFromCenter(clearanceSide[0]), getNonClearanceChairVertexFromCenter(clearanceSide[1])];
  const chairPolygon = extrudeEdge(nonClearanceChairEdge, clearanceDirection, chairWidth);

  return {
    ...desk,
    tablePolygon,
    chairPolygon,
    clearanceDirection,
    boundingBox,
  };
};

const setDoor = (door) => {
  const doorType = door.door_type || 'default';

  const getDoorBoundingBox = (doorData) => {
    // Making sure that nonClearanceEdge[0] === openToEdge[0]
    const nonClearanceSide = doorData.non_clearance_side;
    const openToSide = doorData.open_to_side;

    const nonClearanceEdge = lodashIsEqual(nonClearanceSide[0], openToSide[0])
      ? [...nonClearanceSide]
      : [nonClearanceSide[1], nonClearanceSide[0]];
    const openToEdge = lodashIsEqual(nonClearanceEdge[0], openToSide[0])
      ? [...openToSide]
      : [openToSide[1], openToSide[0]];

    nonClearanceEdge[1] = vecMoveTowards(nonClearanceEdge[0], nonClearanceEdge[1], lodashGet(doorTypeSizes, `[${doorType}].nonClearanceSide`, 40));
    openToEdge[1] = vecMoveTowards(openToEdge[0], openToEdge[1], lodashGet(doorTypeSizes, `[${doorType}].openToSide`, 40));

    // Assuming openToEdge[0] === nonClearanceSide[0]
    const otherCorner = vecAdd(vecAdd(openToEdge[0], vecSub(openToEdge[1], openToEdge[0])), vecSub(nonClearanceEdge[1], nonClearanceEdge[0]));
    return [
      nonClearanceEdge[0],
      nonClearanceEdge[1],
      otherCorner,
      openToEdge[1],
      nonClearanceEdge[0],
    ];
  };

  return {
    ...door,
    boundingBox: getDoorBoundingBox(door),
  };
};

const setRooms = (rooms, enclosingId, holes, tubeDepth, colors, tiles) => {
  if (lodashIsEmpty(rooms)) return [];
  let tubeId = null;
  if (enclosingId.includes('tube')) {
    tubeId = enclosingId;
  }

  return rooms.map((room, index) => {
    const id = `room_${index}_${enclosingId}`;
    const roomBoundingBox = getOrientedBoundingBox(room.points || room.inner_points);
    let roomLength = 0;

    for (let i = 0; i < roomBoundingBox.length; i++) {
      if (roomBoundingBox[i + 1]) {
        const distance = vecDistance(roomBoundingBox[i], roomBoundingBox[i + 1]);
        if (Math.floor(distance) !== Math.floor(tubeDepth)) {
          roomLength = distance / 12; // we divide from inches to feet
          break;
        }
      }
    }

    let tilesInRoom = null;

    if (tiles) {
      const roomPolygon = pointsToJSTSPolygon(room.points).buffer(-2.0);
      tilesInRoom = (tiles.filter((tile) => {
        const tilePolygon = pointsToJSTSPolygon(tile.points);

        return roomPolygon.intersects(tilePolygon);
      }));
    }
    const newRoom = {
      id,
      tubeId, // if room in a Tube pass Tube id
      category: room.category === 'Leftovers' ? 'EMPTY' : room.category,
      type: room.category === 'Leftovers' ? 'EMPTY' : room.type,
      extraWalls: room.extra_walls,
      tilesAmount: tubeId && Math.round(roomLength / tileLength),
      furniture: setFurniture(room.furniture),
      displayObjects: room.display_objects,
      roomCenterPosition: getPolygonCentroid(room.points || room.inner_points),
      innerPoints: room.inner_points,
      departmentId: room.department_id,
      points: room.points,
      color: lodashGet(colors, `[${room.category}][${room.type}]`),
      holes: getRoomHoles(room, holes),
      tags: room.tags,
      localPreview: room.localPreview,
      tiles: tilesInRoom,
    };
    if (tubeId) {
      newRoom.indexInTube = index;
    }

    return newRoom;
  });
};

const setExtraRooms = (floor, floorId, colors) => {
  const { structures: holes } = floor;

  if (lodashIsEmpty(floor.tubes)) {
    return setRooms(floor.rooms, floorId, holes, null, colors);
  }

  if (!floor || !floorId) {
    return [];
  }

  let tubesPolygon = null;
  floor.tubes.map((tube) => {
    if (!tubesPolygon) tubesPolygon = pointsToJSTSPolygon(tube.points);
    else tubesPolygon = tubesPolygon.union(pointsToJSTSPolygon(tube.points));
  });
  tubesPolygon = tubesPolygon.buffer(-2.0);

  const extraRooms = floor.rooms.filter((room) => {
    const roomPolygon = pointsToJSTSPolygon(room.points);

    return !tubesPolygon.intersects(roomPolygon);
  });

  return setRooms(extraRooms, floorId, holes, null, colors);
};

const divideToSubTubes = (tubeRooms, roomsPartitions, tube, tubeLengthAndDepth) => {
  if (!roomsPartitions.length) {
    return [];
  }
  const subTubes = [
    {
      id: uuidv4(),
      points: [roomsPartitions[0].positionData.point],
      roomIds: [],
    },
  ];

  const getPoints = (first, roomIndex, t) => {
    const second = vecLerp(tube.principal_segment[1], tube.principal_segment[0], t);
    const dir = vecNormalize(vecSub(...tube.principal_segment));
    const perpen = [dir[1], -dir[0]];
    const third = vecMoveTowards(second, vecSub(second, perpen), -tubeLengthAndDepth[1]);
    const fourth = vecMoveTowards(first, vecSub(first, perpen), -tubeLengthAndDepth[1]);
    return [second, third, fourth, first];
  };

  tubeRooms.forEach((room, roomIndex) => {
    const isLast = roomIndex === tubeRooms.length - 1;
    const lastSubTube = subTubes[subTubes.length - 1];
    if (lodashGet(room, `tags[${ROOM_TAGS.LOCK}]`)) {
      const { t } = roomsPartitions[roomIndex].positionData;
      const points = getPoints(lastSubTube.points[0], roomIndex, t);
      lastSubTube.points.push(...points);

      if (!isLast) {
        subTubes.push({
          id: uuidv4(),
          roomIds: [tubeRooms[roomIndex + 1].id],
          points: [roomsPartitions[roomIndex + 1].positionData.point],
        });
      }
    } else {
      lastSubTube.roomIds.push(room.id);
      if (isLast) {
        const points = getPoints(lastSubTube.points[0], roomIndex, 0);
        lastSubTube.points.push(...points);
      }
    }
  });

  return subTubes;
};

const getRoomsByTube = (kwargs) => {
  const { tubePoints, rooms, tubeId, holes = [], principalSegment, tubeDepth, colors, tiles } = kwargs;
  if (!tubePoints || lodashIsEmpty(rooms) || !tubeId) {
    return [];
  }
  const jstsTubePolygon = pointsToJSTSPolygon(tubePoints).buffer(-2.0);

  let arrayOfRooms = rooms.filter((room) => {
    const roomPolygon = pointsToJSTSPolygon(room.points);

    return jstsTubePolygon.intersects(roomPolygon);
  });

  const squaredDistanceToPrincipalSegment = (room) => {
    const roomPolygon = pointsToJSTSPolygon(room.points);
    const roomCentroid = roomPolygon.getCentroid();
    const distX = roomCentroid.getX() - principalSegment[0][0];
    const distY = roomCentroid.getY() - principalSegment[0][1];
    return distX * distX + distY * distY;
  };

  // Sort by distance to principal segment start, this is the "axis" of a tube's rooms.
  arrayOfRooms = lodashSortBy(arrayOfRooms, squaredDistanceToPrincipalSegment);

  return setRooms(arrayOfRooms, tubeId, holes, tubeDepth, colors, tiles);
};

const getTubeLengthAndDepth = (tubePoints, principalSegment) => {
  let lengthAndDepth;
  polygonIterEdges(tubePoints, (edgeV1, edgeV2) => {
    const tubeEdge = [edgeV1, edgeV2];
    const cosAngle = getCosAngleBetweenSegments(tubeEdge, principalSegment);
    const isOrtho = Math.abs(cosAngle) < 0.1;
    // A segment is either perpendicular to the principal segment or orthogonal to it.
    // The orthogonal is the depth.
    if (isOrtho) {
      const tubeDepth = vecLength(vecSub(tubeEdge[1], tubeEdge[0]));
      const tubeLength = vecLength(vecSub(principalSegment[1], principalSegment[0]));
      lengthAndDepth = [tubeLength, tubeDepth];
    }
  });
  return lengthAndDepth;
};

const getTubeTiles = (kwargs) => {
  // create tiles objects of a tube according to its tilePartitions
  const { tubePoints, tilePartitions, principalSegment, tubeAngle, tubeId, floorPoints, nooks = [] } = kwargs;
  if (!tubePoints || lodashIsEmpty(tilePartitions) || !principalSegment || tubeAngle === null || !floorPoints) {
    return [];
  }

  const isTubeContainedInFloor = pointsToJSTSPolygon(floorPoints).contains(pointsToJSTSPolygon(tubePoints).buffer(-12));

  // rotate floor, tube and principalSegment to be horizontal:
  const tiles = [];
  const tubeCenter = getRectanglePolygonCenter(tubePoints);
  const horizontalTubePoints = rotatePolygon([...tubePoints, tubePoints[0]], tubeCenter, -tubeAngle);
  const horizontalTubePolygon = pointsToJSTSPolygon(horizontalTubePoints);
  const horizontalPrincipalSegment = rotatePolygon(principalSegment, tubeCenter, -tubeAngle);
  const rotatedFloorPoints = rotatePolygon(floorPoints, tubeCenter, -tubeAngle);
  let rotatedFloorJSTSPolygon = pointsToJSTSPolygon(rotatedFloorPoints);
  rotatedFloorJSTSPolygon = morphologyOpen(rotatedFloorJSTSPolygon, 10);

  // a list, starting from 0, with tile partition offset intervals:
  const offsets = [0, ...tilePartitions.map((tilePartition) => tilePartition.offset), vecDistance(...horizontalPrincipalSegment)];
  const currXY = horizontalPrincipalSegment[0]; // starting point of tube
  const maxHorizontalTubePolygonY = Math.max(...horizontalTubePoints.map((p) => p[1]));
  const isPrincipalSegmentTopLine = Math.abs(currXY[1] - maxHorizontalTubePolygonY) < 0.01;

  const tubeLengthAndWidth = getTubeLengthAndDepth(tubePoints, principalSegment);
  const tilePolygonDeltaY = isPrincipalSegmentTopLine ? -tubeLengthAndWidth[1] : tubeLengthAndWidth[1];
  // const tubeJSTSPolygon = pointsToJSTSPolygon(tubePoints);
  let clippedHorizontalTube = horizontalTubePolygon;
  if (!isTubeContainedInFloor) {
    clippedHorizontalTube = rotatedFloorJSTSPolygon.intersection(horizontalTubePolygon);
  }

  for (let idx = 0; idx < offsets.length - 1; idx += 1) {
    // create box between each couple of offsets with height of 2*tube width
    const startOffset = offsets[idx];
    const endOffset = offsets[idx + 1];
    const tileEnclosingBox = [[currXY[0] + startOffset, currXY[1]],
      [currXY[0] + endOffset, currXY[1]],
      [currXY[0] + endOffset, currXY[1] + tilePolygonDeltaY],
      [currXY[0] + startOffset, currXY[1] + tilePolygonDeltaY],
      [currXY[0] + startOffset, currXY[1]]];

    // cut the bot to fit the tube and floor:
    const tileEnclosingBoxPolygon = pointsToJSTSPolygon(tileEnclosingBox);
    // const squareTilePolygon = horizontalTubePolygon.intersection(tileEnclosingBoxPolygon);
    const tilePolygon = isTubeContainedInFloor ? tileEnclosingBoxPolygon : clippedHorizontalTube.intersection(tileEnclosingBoxPolygon);

    if (tilePolygon.getGeometryType() === 'Polygon') {
      const tilePoints = tilePolygon.getExteriorRing().getCoordinates().map((point) => [point.x, point.y]);

      // rotate everything back to original angle and add to the tile list
      tiles.push({
        id: `tile_${idx}_${tubeId}`,
        points: rotatePolygon(tilePoints, tubeCenter, tubeAngle),
        tubeId,
      });
    }
  }

  // add nooks to the first and last tile in tube:
  addNooksToTile({ tile: tiles[0], nooks, principalSegment });
  addNooksToTile({ tile: tiles[tiles.length - 1], nooks, principalSegment });

  return tiles;
};

const addNooksToTile = (kwargs) => {
  const { tile, nooks, principalSegment } = kwargs;
  let tilePoly = pointsToJSTSPolygon(tile.points);
  nooks.forEach((nook) => {
    const nookPoly = pointsToJSTSPolygon(nook.points);
    const sharedEdge = getSharedEdges(nook.points, tile.points);
    if (sharedEdge.length === 1 && (((((vecAngle(vecSub(sharedEdge[0][1], sharedEdge[0][0])) - vecAngle(vecSub(principalSegment[1], principalSegment[0]))) % Math.PI) + Math.PI) % Math.PI) - Math.PI / 2) < 0.01) {
      tilePoly = tilePoly.union(nookPoly);
      tilePoly = morphologyClose(tilePoly, 0.1);
      tile.points = tilePoly.getBoundary().getCoordinates().map((coord) => [coord.x, coord.y]);
    }
  });
  tile.points = getOrientedBoundingBox(tile.points);
};

const getSharedEdges = (polyPoints1, polyPoints2) => {
  const sharedEdges = [];
  const polyPointsPairs1 = polyPoints1.map((point, idx) => {
    if (idx < polyPoints1.length - 1) {
      return [point, polyPoints1[idx + 1]];
    }
  }).filter((obj) => obj);

  const polyPointsPairs2 = polyPoints2.map((point, idx) => {
    if (idx < polyPoints2.length - 1) {
      return [point, polyPoints2[idx + 1]];
    }
  }).filter((obj) => obj);

  polyPointsPairs1.forEach((polyPointPair1) => {
    polyPointsPairs2.forEach((polyPointPair2) => {
      if (isEdgeEquale(polyPointPair1, polyPointPair2) || isEdgeEquale(polyPointPair1, [polyPointPair2[1], polyPointPair2[0]])) {
        sharedEdges.push(polyPointPair1);
      }
    });
  });

  return sharedEdges;
};

const isEdgeEquale = (edge1, edge2, res = 1.0) => {
  const edgePoints1 = [...edge1[0], ...edge1[1]];
  const edgePoints2 = [...edge2[0], ...edge2[1]];

  for (let i = 0; i < 4; i++) {
    if (Math.abs(edgePoints1[i] - edgePoints2[i]) > res) {
      return false;
    }
  }

  return true;
};

const setFloorTubes = (floor, floorId, colors) => {
  const { structures: holes } = floor;
  if (!floor) return [];
  const tubes = floor.tubes.map((tube, index) => {
    const tubeId = `tube_${index}_${floorId}`;
    const tubeLengthAndDepth = getTubeLengthAndDepth(tube.points, tube.principal_segment);
    const tubeAngle = vecAngle(vecSub(tube.principal_segment[1], tube.principal_segment[0]));
    const nooks = floor.nooks.map((nook) => ((nook.points[0] === nook.points[nook.points.length - 1]) ? nook : { points: [...nook.points, nook.points[0]] }));
    const tiles = getTubeTiles({ tubePoints: tube.points, tilePartitions: tube.tile_partitions, principalSegment: tube.principal_segment, tubeAngle, tubeId, floorPoints: floor.boundary_points, nooks });
    const rooms = getRoomsByTube({ tiles, tubePoints: tube.points, rooms: floor.rooms, tubeId, holes, principalSegment: tube.principal_segment, tubeDepth: tubeLengthAndDepth[1], colors });
    const roomsPartitions = rooms.map((room) => {
      const vertices = lodashIsEmpty(room.innerPoints) ? room.points : room.innerPoints;
      const pointsToLine = vertices.map((p) => projectPointToLine(p, tube.principal_segment[1], tube.principal_segment[0]));
      const maxPoints = Math.max(...pointsToLine.map((p) => p.t));
      return { positionData: pointsToLine.find((p) => p.t === maxPoints), roomInfo: room };
    });
    const subTubes = divideToSubTubes(rooms, roomsPartitions, tube, tubeLengthAndDepth);
    const newRooms = rooms.map((room) => ({ ...room, subTubeId: subTubes.find((subTube) => lodashIncludes(subTube.roomIds, room.id))?.id || null }));

    return {
      tubeId,
      tubeAngle,
      rooms: newRooms,
      size: {
        length: tubeLengthAndDepth[0],
        depth: tubeLengthAndDepth[1],
      },
      subTubes,
      roomsPartitions,
      principalSegment: tube.principal_segment,
      points: (tube.points[0] !== tube.points[-1]) ? [...tube.points, tube.points[0]] : tube.points,
      tiles,
    };
  });
  return tubes;
};

const getRoomHoles = (room, holes) => {
  if (!room || !holes) return [];
  const structureJSTSPolygons = holes.map((structurePoints) => pointsToJSTSPolygon(structurePoints));
  const roomJSTSPolygon = pointsToJSTSPolygon(room.points);
  const roomHoles = structureJSTSPolygons.map((structureJSTSPolygon, index) => {
    if (roomJSTSPolygon.contains(structureJSTSPolygon)) return index;
  }).filter((index) => index != null).map((index) => holes[index]);

  return roomHoles;
};

export const floorPlanParse = (result, colors, theme, testFitStandard) => {
  const { multiBoundaryFile } = result;
  if (!multiBoundaryFile || multiBoundaryFile.version < CURRENT_MULTI_BOUNDARY_FILE_VERSION) {
    return;
  }

  const floors = multiBoundaryFile.floors.map((floor, index) => {
    // eslint-disable-next-line camelcase
    const { boundary_points, outer_contours, mullions, structures, corridor_polygons, name } = floor;
    const id = `floor_${index}`;
    const floorData = {
      id,
      boundaryPoints: boundary_points,
      outerContours: outer_contours,
      mullions,
      structures,
      name,
      corridorPolygons: corridor_polygons,
      extraRooms: setExtraRooms(floor, id, colors), // rooms that didnt go in the tubes
      tubes: setFloorTubes(floor, id, colors),
      envUrl: result.mbfWithOriginalPlanUrl || result.envUrl,
    };
    return floorData;
  });

  return {
    version: result.multiBoundaryFile.version,
    roomsRequirements: result.multiBoundaryFile.requirements.rooms_requirements,
    floors,
    furnitureDefinitions: multiBoundaryFile.custom_furniture,
    roomDefinitions: getRoomDefinitions(multiBoundaryFile, floors, colors, theme),
    roomsDefinitions: getRoomsDefinitions(result, colors, testFitStandard),
    floorDefinitions: getFloorDefinitions(multiBoundaryFile, colors),
    departmentDefinition: getDepartmentDefinitions(multiBoundaryFile, colors),
  };
};

const getTilesAmountByRequirements = (roomsRequirements, roomType) => {
  const currentRoom = roomsRequirements.find((roomRequirements) => {
    const isArray = Array.isArray(roomRequirements);
    const requirementRoomType = isArray ? roomRequirements[1] : roomRequirements.room_type;
    return roomType === requirementRoomType;
  });

  if (!currentRoom) {
    return;
  }

  const isArray = Array.isArray(currentRoom);

  const totalTileLengthByReq = lodashGet(currentRoom, isArray ? '[10]' : 'per_tube_lengths', { X: 0 });

  return Math.round(Object.values(totalTileLengthByReq)[0] / tileLength) || 0;
};

const getFloorDefinitions = (multiBoundaryFile, colors) => {
  const roomsRequirement = lodashGet(multiBoundaryFile, 'requirements.rooms_requirements', []);

  const floorDefinitions = multiBoundaryFile.floors.map((floor, index) => {
    const rooms = {};
    floor.rooms.forEach((room) => {
      const vertices = lodashIsEmpty(room.inner_points) ? room.points : room.inner_points;
      if (!room.type) {
        return;
      }

      if (rooms[room.type]) {
        rooms[room.type] = {
          ...rooms[room.type],
          amount: rooms[room.type].amount + 1,
          area: rooms[room.type].area + sqfToSqm(getPolygonArea(vertices) / 144),
        };
      } else {
        rooms[room.type] = {
          id: room.type,
          name: room.type,
          amount: 1,
          tilesAmount: getTilesAmountByRequirements(roomsRequirement, room.type),
          color: lodashGet(colors, `[${room.category}][${room.type}]`),
          category: !lodashIsEmpty(floor.name) ? floor.name : `${T.translate('EDITOR_ROOMS_PANEL_FLOOR')} ${index + 1 <= 9 ? `0${index + 1}` : index + 1}`,
          roomCategory: room.category,
          area: sqfToSqm(getPolygonArea(vertices) / 144),
        };
      }
    });

    return lodashValues(rooms);
  });

  return lodashFlattenDepth(floorDefinitions, 1);
};

const getRoomsDefinitions = (result, colors, testFitStandard) => {
  const { multiBoundaryFile, LSF, PSFA, PSFB, ASF, FSF } = result;
  const totalAreaByCategory = { LSF: LSF.totalArea, PSF: PSFA.totalArea + PSFB.totalArea, ASF: ASF.totalArea, FSF: FSF.totalArea };
  const sortByCategory = { LSF: 0, PSF: 1, FSF: 2, ASF: 3, EMPTY: 4 };
  const roomsRequirement = lodashGet(multiBoundaryFile, 'requirements.rooms_requirements', []);
  const departmentsFromBe = lodashGet(multiBoundaryFile, 'departments', []).map((department) => ({ key: department.id, color: department.color, departmentName: department.name }));
  const standardTypes = lodashGet(getTestFitStandards(), `standards[${testFitStandard}].roomDefinitions`, {});
  const allRoomsFromStandard = lodashFlattenDepth(Object.values(standardTypes), 1);

  const sortList = {};
  lodashKeys(standardTypes).forEach((type) => standardTypes[type].forEach((unit) => {
    sortList[unit.key] = unit.index;
  }));

  const floorDefinitions = multiBoundaryFile.floors.map((floor, index) => floor.rooms.map((room) => {
    const vertices = lodashIsEmpty(room.inner_points) ? room.points : room.inner_points;
    const area = getPolygonArea(vertices) / 144;
    const totalCategoryArea = totalAreaByCategory[room.category];
    const color = lodashGet(colors, `[${room.category}][${room.type}]`);

    const numOfSeats = () => {
      const roomInStandard = allRoomsFromStandard.find((g) => g.key === room.type);

      if (roomInStandard) {
        return roomInStandard.seats;
      } if (room.category === 'PSF') {
        return Math.round(area / 25);
      }

      return lodashGet(room, 'furniture', []).filter((item) => item.furniture_type === 'desk').length;
    };
    if (!room.type || !color || !totalCategoryArea) {
      return;
    }

    return (
      {
        type: room.type,
        name: T.translate(room.type),
        hasWalls: !lodashIsEmpty(room.inner_points),
        tilesAmount: getTilesAmountByRequirements(roomsRequirement, room.type),
        color,
        areaPercentage: (area / totalCategoryArea) * 100,
        floor: !lodashIsEmpty(floor.name) ? floor.name : `${T.translate('EDITOR_ROOMS_PANEL_FLOOR')} ${index + 1 <= 9 ? `0${index + 1}` : index + 1}`,
        category: room.category,
        department: departmentsFromBe.find((department) => department.key === room.department_id),
        area,
        seats: numOfSeats(),
      }
    );
  }));
  const allRooms = lodashFlattenDepth(floorDefinitions, 1).filter((e) => e); // flatten all rooms from each floor and filter empty items;

  return lodashSortBy(allRooms, [
    (room) => lodashGet(sortByCategory, `[${room.category}]`), // sort by category first
    (room) => lodashGet(sortList, `[${room.type}]`), // then by names from standard
    'name', // then others by name a-z
  ]);
};

const getDepartmentDefinitions = (multiBoundaryFile, colors) => {
  const allRooms = lodashFlattenDepth(multiBoundaryFile.floors.map((floor) => floor.rooms), 1);
  const allDepartmentIds = lodashUniq(allRooms.map((room) => room.department_id)).filter((e) => e);
  const departmentsFromBe = lodashGet(multiBoundaryFile, 'departments', []).map((department) => ({ key: department.id, color: department.color, departmentName: department.name }));
  const roomsRequirement = lodashGet(multiBoundaryFile, 'requirements.rooms_requirements', []);

  const departments = allDepartmentIds.map((departmentId) => {
    const departmentFromBe = departmentsFromBe.find((department) => department.key === departmentId);
    const rooms = {};
    const roomsInDepartment = allRooms.filter((room) => room.department_id === departmentId);
    roomsInDepartment.forEach((room) => {
      const vertices = lodashIsEmpty(room.inner_points) ? room.points : room.inner_points;
      if (!room.type || !room.department_id) {
        return;
      }

      if (rooms[room.type]) {
        rooms[room.type] = {
          ...rooms[room.type],
          amount: rooms[room.type].amount + 1,
          area: rooms[room.type].area + sqfToSqm(getPolygonArea(vertices) / 144),
        };
      } else {
        rooms[room.type] = {
          id: room.type,
          name: room.type,
          amount: 1,
          tilesAmount: getTilesAmountByRequirements(roomsRequirement, room.type),
          color: lodashGet(colors, `[${room.category}][${room.type}]`),
          category: departmentFromBe?.departmentName,
          categoryColor: departmentFromBe?.color,
          roomCategory: room.category,
          area: sqfToSqm(getPolygonArea(vertices) / 144),
        };
      }
    });

    return lodashValues(rooms);
  });

  return lodashFlattenDepth(departments, 1);
};

const getLengthAndDepth = (roomRequirements, multiBoundaryFile) => {
  const isArray = Array.isArray(roomRequirements);
  const perTubeLengths = isArray ? roomRequirements[10] : roomRequirements.per_tube_lengths;

  if (lodashIsEmpty(perTubeLengths)) {
    return { length: 0, depth: 0 };
  }

  const tubeType = Object.keys(perTubeLengths)[0];
  const length = perTubeLengths[tubeType];
  const { depth } = multiBoundaryFile.requirements.tube_definitions[tubeType];

  return { length, depth };
};

const getRoomDefinitions = (multiBoundaryFile, floors, colors, theme) => {
  const roomDefinitions = [];
  const requirementsType = lodashGet(multiBoundaryFile, 'requirements_type', null);

  if (requirementsType === 'Classic') {
    const { rooms_requirements: roomsRequirements } = multiBoundaryFile.requirements;
    const allRooms = multiBoundaryFile.floors.reduce((a, b) => a.concat(b.rooms), []);
    // const allTubes = multiBoundaryFile.floors.reduce((a, b) => a.concat(b.tubes), []);

    roomsRequirements.forEach((roomRequirements) => {
      // There are multiple versions of this format - in some its [LSF, ONE_PERSON, ...] and in others its {category:LSF, room_type:ONE_PERSON, ...}
      const isArray = Array.isArray(roomRequirements);
      const name = isArray ? roomRequirements[1] : roomRequirements.room_type;
      const roomsOfCurrType = allRooms.filter((room) => room.type === name);

      const { length: roomLengthInTube, depth } = getLengthAndDepth(roomRequirements, multiBoundaryFile);
      const area = roomLengthInTube * depth;
      const category = isArray ? roomRequirements[0] : roomRequirements.category;

      const roomDefinition = {
        id: name, // room_type
        name, // room_type
        category, // category
        roomCategory: category, // category
        amount: roomsOfCurrType.length, // foreach floor foreach room add to histogram, read from histogram
        area: lodashSum(roomsOfCurrType.map((room) => sqfToSqm(getPolygonArea(room.points) / 144))),
        tilesAmount: getTilesAmountByRequirements(roomsRequirements, name), // Find tube in MBF with matching tube type, divide tube_length by tube's tile_length
        color: lodashGet(colors, `[${category}][${name}]`),
        minLength: roomLengthInTube, // module.num_tiles * tileLength
        minArea: area, // module.num_tiles * tileLength * tubeDepth
      };
      roomDefinitions.push(roomDefinition);
    });
  }

  const allTubesRoomsInProfile = lodashFlattenDepth(floors.map((floor) => floor?.tubes?.map((tube) => tube?.rooms)), 2);
  const allExtraRoomsInProfile = lodashFlattenDepth(floors.map((floor) => floor?.extraRooms), 2);
  const allRoomsInProfile = [...allTubesRoomsInProfile, ...allExtraRoomsInProfile];
  const allEmptyRoomsInProfile = lodashFilter(allRoomsInProfile, (e) => e?.category === 'EMPTY_ROOM' || e?.category === 'EMPTY' || e?.category === 'Leftovers');

  // empty rooms
  roomDefinitions.push(...[
    {
      id: 'DEALLOCATE',
      name: 'EDITOR_ROOMS_PANEL_EMPTY_TILES',
      category: 'EMPTY',
      roomCategory: 'EMPTY',
      amount: allEmptyRoomsInProfile.length,
      area: lodashSum(allEmptyRoomsInProfile.map((emptyRoom) => sqfToSqm(getPolygonArea(emptyRoom.points) / 144))),
      tilesAmount: null,
      color: theme.colors.layers.EMPTY.MAIN,
    },
    // { // not for now
    //   id: 'WARNINGS',
    //   name: 'EDITOR_ROOMS_PANEL_WARNINGS',
    //   category: 'EMPTY',
    //   amount: 33, // TODO - getData
    //   area: 3333, // TODO - getData
    //   tilesAmount: 13, // TODO - getData
    //   color: '#c10707',
    // },
  ]);

  return roomDefinitions;
};

export const isRoomValid = (room, multiBoundaryFile) => {
  let currRoomDefinition = multiBoundaryFile.roomDefinitions.filter((roomDefinition) => roomDefinition.name === room.type);
  if (lodashIsEmpty(currRoomDefinition)) return false;

  [currRoomDefinition] = currRoomDefinition;
  const roomBoundingBox = getOrientedBoundingBox(room.points);
  const longestEdge = getPolygonLongestEdge(roomBoundingBox);
  const roomLength = vecDistance(longestEdge[0], longestEdge[1]);
  const roomJSTSPolygon = pointsToJSTSPolygon(room.points);

  if (roomLength < currRoomDefinition.minLength) return false;
  if (roomJSTSPolygon.getArea() < currRoomDefinition.minArea) return false;

  return true;
};
