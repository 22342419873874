import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import styled from 'styled-components';
import { message, Popover, Tooltip, Popconfirm } from 'antd';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFlatten from 'lodash/flatten';
import lodashSortBy from 'lodash/sortBy';
import { SliderPicker } from 'react-color';
import Icon from '@ant-design/icons';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { StyledInput } from 'styles/commonComponents.styles';
import AddNewRowButton from 'components/common/AddNewRowButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import { swappProfileResultsSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { addOperations } from 'store/editor';
import { currentThemeSelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';
import deleteIcon from 'styles/static/icons/comon/deleteIcon';
import plusIcon from 'styles/static/icons/comon/plusIcon';
import dropdownIcon from 'styles/static/icons/comon/dropdownIcon';
import { ColorPill } from 'components/testFit/study/result/program/EditorRoomsPanel.styles';

const ColorPiker = styled.div`
  width: 25px;
  height: 25px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.gray_06};
  background-color: ${({ color }) => color};
  cursor: pointer;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 7px;
  width: 100%;
  background-color: ${({ theme }) => `${theme.colors.gray_01}40`};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.11);
  margin-bottom: 5px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  background-color: ${({ theme, hasBackground }) => (hasBackground ? `${theme.colors.gray_06}40` : 'unset')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: 25px;
  max-height: 25px;
  
  &:first-child {
    margin: 0;
  }

  &:nth-child(2) {
    margin-top: 10px;
  }
`;

const Cell = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 23px;
`;

const StyledIcon = styled(Icon)`
  margin-top: 4px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.textColor)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transform: rotate(${({ rotate }) => `${rotate || 0}deg`});
  
  svg {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  
  :hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColorHover)};
  }
`;

// const GridTileWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
// `;

const colorSet = [
  '#80BFA0', '#FFD0A1', '#F08CE0', '#80ADE1',
  '#C56E90', '#C1A4FE', '#FFEDBA', '#9AE6F6',
  '#BAF2C2', '#FF767E',
];

let debounceTimer = null;

const EditDepartmentsPanel = (props) => {
  const { isDisabled } = props;

  const locationData = parseLocationUrl(window.location);
  const editorType = useSelector(({ editor }) => editor.editorType);
  const selectedRooms = useSelector(({ editor }) => editor.selectedObject);
  const currentTheme = useSelector(currentThemeSelector);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);
  const dispatch = useDispatch();

  const [tableDataSource, setTableDataSource] = useState(lodashGet(swappProfileResults, 'departments', []));
  const [rowSettings, setRowSettings] = useState({});

  const isDepartmentEdit = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS;
  const disabled = isDisabled || !isDepartmentEdit;
  const areSelectedRoomsUnassigned = Array.isArray(selectedRooms) ? lodashIsEmpty(selectedRooms.filter((e) => e.departmentId)) : !selectedRooms?.departmentId;

  useEffect(() => {
    setTableDataSource(lodashGet(swappProfileResults, 'departments', []));
  }, [swappProfileResults]);

  const deallocateItem = {
    key: 'DEALLOCATE',
    color: currentTheme.colors.layers.EMPTY.MAIN,
    name: T.translate('EDIT_DEPARTMENTS_PANEL_DEALLOCATE_ROOM'),
    assignRoom: '',
    delete: '',
  };

  const getAssignRoomAction = (departmentKey) => ({
    name: 'TEST_FIT_ASSIGN_ROOMS_TO_DEPARTMENT',
    parameters: {
      department_id: departmentKey,
      room_ids: Array.isArray(selectedRooms) ? selectedRooms.map((e) => e.id) : selectedRooms.id,
    },
    localViewParameters: { selectedRooms },
  });

  const addNewRow = () => {
    const newRow = {
      key: uuidv4(),
      color: colorSet[tableDataSource.length % colorSet.length],
      name: '',
      assignRoom: '',
      delete: '',
    };

    const actions = [
      {
        name: 'TEST_FIT_ADD_DEPARTMENT',
        parameters: {
          department_id: newRow.key,
          color: newRow.color,
          name: '',
        },
      },
    ];

    if (!lodashIsEmpty(selectedRooms)) {
      actions.push(getAssignRoomAction(newRow.key));
    }

    dispatch(addOperations(actions));
    setTableDataSource([...tableDataSource, newRow]);
  };

  const deleteRow = (key) => {
    const currentTableDataSource = [...tableDataSource].filter((e) => e.key !== key);
    setTableDataSource(currentTableDataSource);
    dispatch(addOperations([{ name: 'TEST_FIT_DELETE_DEPARTMENT', parameters: { department_id: key } }]));
  };

  const expandRow = (key) => {
    const currentRowSettings = { ...rowSettings };
    currentRowSettings[key] = !lodashGet(rowSettings, `[${key}]`, false);

    setRowSettings(currentRowSettings);
  };

  const assignRooms = (key) => {
    if (lodashIsEmpty(selectedRooms)) {
      message.warning(T.translate('EDIT_DEPARTMENTS_PANEL_NO_SELECTED_ROOMS_MESSAGE'));
    } else {
      dispatch(addOperations([getAssignRoomAction(key)]));
    }
  };

  const unassignRooms = () => {
    if (areSelectedRoomsUnassigned) {
      return;
    }

    if (lodashIsEmpty(selectedRooms)) {
      message.warning(T.translate('EDIT_DEPARTMENTS_PANEL_NO_SELECTED_ROOMS_MESSAGE'));
    } else {
      dispatch(addOperations([{ name: 'TEST_FIT_ASSIGN_ROOMS_TO_DEPARTMENT', parameters: { department_id: null, room_ids: Array.isArray(selectedRooms) ? selectedRooms.map((e) => e.id) : selectedRooms.id }, localViewParameters: { selectedRooms } }]));
    }
  };

  const editDepartment = (value, key, departmentKey, debounceTime = 150) => {
    const currentTableDataSource = [...tableDataSource];
    const rowIndex = currentTableDataSource.findIndex((e) => e.key === departmentKey);
    currentTableDataSource[rowIndex][key] = value;

    setTableDataSource(currentTableDataSource);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      dispatch(addOperations([{ name: 'TEST_FIT_EDIT_DEPARTMENT', parameters: { department_id: departmentKey, [key]: value } }]));
    }, debounceTime);
  };

  const renderColorPicker = (color, key) => {
    if (key === 'DEALLOCATE') {
      return null;
    }

    return (
      <div style={{ width: '340px' }}>
        <SliderPicker disableAlpha onChange={(e) => editDepartment(e.hex, 'color', key)} color={color} />
      </div>
    );
  };

  // const renderGrid = () => (
  //   <GridTileWrapper>
  //     {[...tableDataSource, deallocateItem].map((department) => (
  //       <GridTile
  //         key={department.key}
  //         title={department.name || T.translate('UNTITLED')}
  //         subtitle=""
  //         color={department.color}
  //         onClick={() => assignRooms(department.key)}
  //         size={3.06}
  //         tooltip={department.name}
  //       />
  //     ))}
  //   </GridTileWrapper>
  // );

  const renderTable = () => {
    const data = [...tableDataSource, deallocateItem];

    const renderColorPill = (row) => {
      if (row.key === 'DEALLOCATE') {
        return <ColorPiker color={row.color} />;
      }

      return (
        <Popover trigger="click" width={340} content={renderColorPicker(row.color, row.key)}>
          <ColorPiker color={row.color} />
        </Popover>
      );
    };

    const renderName = (row) => {
      if (row.key === 'DEALLOCATE') {
        return row.name;
      }

      return (
        <StyledInput
          onChange={(e) => editDepartment(e.target.value, 'name', row.key, 1200)}
          value={row.name}
          disabled={disabled || row.key === 'DEALLOCATE'}
          placeholder={T.translate('EDIT_DEPARTMENTS_PANEL_DEPARTMENT_NAME_PLACEHOLDER')}
          height={25}
        />
      );
    };

    const renderActionButton = (row) => {
      if (row.key === 'DEALLOCATE') {
        return (
          <Tooltip mouseEnterDelay={0.7} title={T.translate('EDIT_DEPARTMENTS_PANEL_DEALLOCATE_ROOMS_TOOLTIP')}>
            <StyledIcon component={plusIcon} onClick={() => unassignRooms(row.key)} disabled={disabled || areSelectedRoomsUnassigned} />
          </Tooltip>
        );
      }

      return (
        <Tooltip mouseEnterDelay={0.7} title={T.translate('EDIT_DEPARTMENTS_PANEL_ASSIGN_ROOMS_TOOLTIP')}>
          <StyledIcon component={plusIcon} onClick={() => assignRooms(row.key)} disabled={disabled} />
        </Tooltip>
      );
    };

    const renderDeleteButton = (row) => {
      if (row.key === 'DEALLOCATE') {
        return null;
      }

      return (
        <Tooltip mouseEnterDelay={0.7} title={T.translate('EDIT_DEPARTMENTS_PANEL_DELETE_DEPARTMENT_TOOLTIP')}>
          <Popconfirm
            onConfirm={() => deleteRow(row.key)}
            title={T.translate('EDIT_DEPARTMENTS_PANEL_DELETE_MODAL_CONTENT')}
            okText="Yes"
            cancelText={T.translate('EDIT_DEPARTMENTS_PANEL_DELETE_MODAL_CANCEL_TEXT')}
          >
            <StyledIcon component={deleteIcon} disabled={disabled} />
          </Popconfirm>
        </Tooltip>
      );
    };

    const renderExpandButton = (row) => {
      const rooms = [
        ...lodashGet(row, 'rooms.LSF', []),
        ...lodashGet(row, 'rooms.PSF', []),
        ...lodashGet(row, 'rooms.ASF', []),
        ...lodashGet(row, 'rooms.FSF', []),
      ];

      if (lodashIsEmpty(rooms) && row.key !== 'DEALLOCATE') {
        return null;
      }

      return (
        <StyledIcon component={dropdownIcon} rotate={rowSettings[row.key] ? 180 : 0} onClick={() => expandRow(row.key)} />
      );
    };

    const renderMainRow = (row) => (
      <Row>
        {/* ============ color pill ============ */}
        <Cell width={30}>{renderColorPill(row)}</Cell>
        {/* ============ name ============ */}
        <Cell width={230}>{renderName(row)}</Cell>
        {/* ============ action button + - ============ */}
        <Cell width={30}>{renderActionButton(row)}</Cell>
        {/* ============ delete ============ */}
        <Cell width={30}>{renderDeleteButton(row)}</Cell>
        <Cell width={30}>{renderExpandButton(row)}</Cell>
      </Row>
    );

    const renderSubRows = (row) => {
      if (!rowSettings[row.key]) {
        return null;
      }
      const isRowUnassigned = row.key === 'DEALLOCATE';

      const unassignedRooms = {};
      if (isRowUnassigned) {
        const allRoomsInFloors = swappProfileResults.multiBoundaryFile.floors.map((floor) => {
          const { extraRooms } = floor;
          const roomsFromTubes = lodashFlatten(floor.tubes.map((tube) => tube.rooms));

          return [...extraRooms, ...roomsFromTubes];
        });

        const allRooms = lodashFlatten(allRoomsInFloors);
        const allUnassignedRooms = allRooms.filter((room) => !room.departmentId);
        const sortByCategory = {
          LSF: 0,
          PSF: 1,
          ASF: 2,
          FSF: 3,
          EMPTY: 4,
        };
        allUnassignedRooms.forEach((room) => {
          unassignedRooms[room.type] = {
            key: room.type,
            color: room.color,
            name: room.type,
            sortIndex: sortByCategory[room.category],
            modelRooms: lodashGet(unassignedRooms, `[${room.type}].modelRooms`, 0) + 1,
          };
        });
      }

      const rooms = isRowUnassigned
        ? lodashSortBy(Object.keys(unassignedRooms).map((key) => unassignedRooms[key]), ['sortIndex', 'name'])
        : [
          ...lodashGet(row, 'rooms.LSF', []),
          ...lodashGet(row, 'rooms.PSF', []),
          ...lodashGet(row, 'rooms.ASF', []),
          ...lodashGet(row, 'rooms.FSF', []),
        ];

      return rooms.map((room) => (
        <Row hasBackground key={`subRows${room.key}`}>
          {/* ============ color pill ============ */}
          <Cell width={25}><ColorPill color={room.color} marginTop={2.5} /></Cell>
          {/* ============ name ============ */}
          <Cell width={300}>{room.name}</Cell>
          {/* ============ num of rooms ============ */}
          <Cell width={25}>{room.modelRooms}</Cell>
        </Row>
      ));
    };

    return (
      <Table>
        {data.map((row) => (
          <RowWrapper key={`tableRow_${row.key}`}>
            {renderMainRow(row)}
            {renderSubRows(row)}
          </RowWrapper>
        ))}
      </Table>
    );
  };

  return (
    <>
      {renderTable()}
      <AddNewRowButton
        marginTop={10}
        disabled={disabled}
        onClick={addNewRow}
        text={T.translate('EDIT_DEPARTMENTS_PANEL_NEW_ROW')}
      />
    </>
  );
};

EditDepartmentsPanel.propTypes = {
  isDisabled: PropTypes.bool,
};

export default React.memo(EditDepartmentsPanel);
