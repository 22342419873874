import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashKeyBy from 'lodash/keyBy';
import lodashFlatten from 'lodash/flatten';
import * as SwappEditor from 'store/editor';
import { addOperations, EDIT_TYPES } from 'store/editor';
import { getProfileByIdSelector } from 'store/swappProfile';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { filterByType } from '@swapp/swappcommonjs/dist/utils/tsUtils';
import SwpSectionLine from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpSectionLine';
import SwpSpatialZone from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpSpatialZone';
import { useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { MARKUPS_TOOLS_NAME, MARKUPS_COLORS, MARKUPS_WIDTH } from 'constants/markupsConts';
import { buildLocalToGlobalMatrix, getSwpProjectBuildings, transformGeometry } from 'utils/model/swpProjectModel';
import DrawingContainer from './DrawingContainer';

let debounceTimer = null;

const TYPES = {
  SwpSectionLine: MARKUPS_TOOLS_NAME.SECTION,
  SwpSpatialZone: MARKUPS_TOOLS_NAME.SCOPE_BOX,
};

const trimPoints = (e = []) => e.map((p) => [Number(p[0].toFixed(3)), Number(p[1].toFixed(3))]);

const Views = (props) => {
  const { isViewOnly, profileId, attachmentId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => getProfileByIdSelector(state, profileId));
  const operations = useSelector(({ editor }) => editor.operations) || [];
  const editSessionType = useSelector(({ editor }) => editor.editSessionType);
  const result = lodashGet(profile, 'result');

  const operationsRef = useRef(operations);

  const buildingInfo = useActiveProfileBuildingInfo();
  const swpBuildings = getSwpProjectBuildings(buildingInfo.swpProject) || [];

  const serverViews = useMemo(() => {
    const selectedMassData = lodashFlatten(swpBuildings.map((mass) => mass.childObjects));
    const sectionLines = filterByType(selectedMassData, SwpSectionLine);
    const scopeBoxes = filterByType(selectedMassData, SwpSpatialZone);

    return [...sectionLines, ...scopeBoxes];
  }, [swpBuildings]);

  const serverData = lodashKeyBy(serverViews.map((item) => {
    const matrix = buildLocalToGlobalMatrix(item);
    const transformData = TYPES[item.type] === MARKUPS_TOOLS_NAME.SECTION
      ? transformGeometry(matrix, item.curve?.data)
      : transformGeometry(matrix, item.geom?.data);
    const viewItem = {
      id: item.id,
      type: TYPES[item.type],
      color: MARKUPS_COLORS.RED,
      lineWidth: MARKUPS_WIDTH.THICK,
      subType: lodashIsEmpty(item.subtype) ? undefined : item.subtype,
      // eslint-disable-next-line no-underscore-dangle
      attachmentId: item._parent.id,
      transform: {
        points: trimPoints(transformData.saveToObject()),
      },
    };

    if (TYPES[item.type] === MARKUPS_TOOLS_NAME.SCOPE_BOX) {
      viewItem.transform.rotation = item.angle;
    }

    return viewItem;
  }), 'id');

  const beginEditing = useCallback(() => {
    dispatch(SwappEditor.startEditing({ profileId, result, editSessionType: EDIT_TYPES.VIEWS, editorType: 'SECTION_LINE' }));
  }, [result, profileId]);

  const finishEditing = useCallback(() => {
    const currentOperations = operationsRef.current;
    if (currentOperations.length === 0) {
      dispatch(SwappEditor.stopEditing());
    } else {
      dispatch(SwappEditor.saveAndContinueEditing());
    }
  }, [profileId, history, operations]);

  // mount
  useEffect(() => {
    clearTimeout(debounceTimer);
    beginEditing();
  }, []);

  // profile change
  useEffect(() => {
    if (editSessionType !== EDIT_TYPES.VIEWS) {
      return;
    }

    finishEditing();
    beginEditing();
  }, [profileId]);

  useEffect(() => {
    // we save operations in ref so "finishEditing()" will have the updated operations when called via useEffect unmount
    operationsRef.current = operations;
  }, [operations]);

  // unmount
  useEffect(() => () => {
    clearTimeout(debounceTimer);
    finishEditing();
  }, []);

  const handleSave = (views) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // we need to trim the points in order to compare them with the server data
    const modifiedViews = !lodashIsEmpty(views) && lodashKeyBy(Object.values(views).map((view) => ({ ...view, transform: { ...view?.transform, points: trimPoints(view?.transform?.points) } })), 'id');
    if (lodashIsEqual(modifiedViews, serverData) || lodashIsEmpty(views) || isSharedUrl()) {
      return;
    }

    debounceTimer = setTimeout(() => {
      const viewsArray = Object.values(views).map((item) => {
        const rotation = lodashGet(item, 'transform.rotation');
        return {
          building_id: item.attachmentId,
          points: item.transform.points,
          angle: item.transform.rotation || 0,
          id: item.id,
          subtype: item.subType,
          type: item.type,
          ...(rotation ? { angle: rotation } : {}),
        };
      });
      dispatch(addOperations([{ name: 'SET_VIEWS_STATE', parameters: { views: viewsArray } }]));
    }, [1000]);
  };

  return <DrawingContainer isViewOnly={isViewOnly} profileId={profileId} handleOnChange={handleSave} attachmentId={attachmentId} serverData={serverData} />;
};

Views.propTypes = {
  isViewOnly: PropTypes.bool,
  profileId: PropTypes.number,
  attachmentId: PropTypes.string,
};

export default React.memo(Views);
