var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "three"], function (require, exports, THREE) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.mergeBufferGeometries = void 0;
    THREE = __importStar(THREE);
    // Use instead of BufferGeometryUtils.mergeBufferGeometries when:
    // Merge indexed and non-indexed THREE.BufferGeometry into a new indexed THREE.BufferGeometry. All missing uniforms are set to 0.
    // source: https://gist.github.com/kchapelier/99317526579659be50bddb01e2cf3d61
    const mergeBufferGeometries = (geometries) => {
        let indexLength = 0, verticesLength = 0, attributesInfos = {}, geometriesInfos = [], geometryInfo, referenceAttributesKeys = [], attributesKeys, i, j;
        // read the geometries and attributes information, calculate indexLength and verticesLength
        for (i = 0; i < geometries.length; i++) {
            attributesKeys = Object.keys(geometries[i].attributes);
            geometryInfo = {
                indexed: geometries[i].index !== null,
                vertices: geometries[i].attributes[attributesKeys[0]].count
            };
            geometriesInfos.push(geometryInfo);
            if (geometryInfo.indexed) {
                indexLength += geometries[i].index.count;
            }
            else {
                indexLength += geometryInfo.vertices;
            }
            verticesLength += geometryInfo.vertices;
            for (j = 0; j < attributesKeys.length; j++) {
                if (referenceAttributesKeys.indexOf(attributesKeys[j]) === -1) {
                    referenceAttributesKeys.push(attributesKeys[j]);
                    attributesInfos[attributesKeys[j]] = {
                        array: null,
                        constructor: geometries[i].attributes[attributesKeys[j]].array.constructor,
                        itemSize: geometries[i].attributes[attributesKeys[j]].itemSize
                    };
                }
            }
        }
        // prepare the new BufferGeometry and its attributes
        var newGeometry = new THREE.BufferGeometry(), indexArray = verticesLength > 0xFFFF ? new Uint32Array(indexLength) : new Uint16Array(indexLength);
        for (i = 0; i < referenceAttributesKeys.length; i++) {
            attributesInfos[referenceAttributesKeys[i]].array = new (attributesInfos[referenceAttributesKeys[i]].constructor)(verticesLength * attributesInfos[referenceAttributesKeys[i]].itemSize);
            newGeometry.setAttribute(referenceAttributesKeys[i], new THREE.BufferAttribute(attributesInfos[referenceAttributesKeys[i]].array, attributesInfos[referenceAttributesKeys[i]].itemSize));
        }
        // copy all the data in the new BufferGeometry
        var offsetIndices = 0, offsetVertices = 0, offsetAttribute;
        for (i = 0; i < geometries.length; i++) {
            geometryInfo = geometriesInfos[i];
            if (geometryInfo.indexed) {
                for (j = 0; j < geometries[i].index.count; j++) {
                    indexArray[offsetIndices + j] = offsetVertices + geometries[i].index.array[j];
                }
                offsetIndices += geometries[i].index.count;
            }
            else {
                for (j = 0; j < geometryInfo.vertices; j++) {
                    indexArray[offsetIndices + j] = offsetVertices + j;
                }
                offsetIndices += geometryInfo.vertices;
            }
            for (j = 0; j < referenceAttributesKeys.length; j++) {
                offsetAttribute = offsetVertices * attributesInfos[referenceAttributesKeys[j]].itemSize;
                if (geometries[i].attributes[referenceAttributesKeys[j]]) {
                    attributesInfos[referenceAttributesKeys[j]].array.set(geometries[i].attributes[referenceAttributesKeys[j]].array, offsetAttribute);
                }
            }
            offsetVertices += geometryInfo.vertices;
        }
        newGeometry.setIndex(new THREE.BufferAttribute(indexArray, 1));
        return newGeometry;
    };
    exports.mergeBufferGeometries = mergeBufferGeometries;
});
